import type {
  Component,
  ParameterValue,
  StructList,
  StructValue
} from 'types/Pipeline'

import type {
  ComponentMetadata,
  ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import { type Parameters } from 'job-lib/types/Job'
import type {
  ElementCollection,
  Parameter,
  ParameterCollection,
  ValueCollection
} from 'job-lib/types/Parameters'

import {
  isGridValueFromVariableParameterValue,
  isGridValueParameterValue,
  isListValueParameterValue,
  isScalarParameterValue,
  isStructListParameterValue,
  isStructValueParameterValue
} from '../../utils/parameters'

const convertGridParameterValueToMetl = (
  parameter: ComponentParameter,
  elements: string[][]
) => {
  const metlElements: ElementCollection = {}

  elements.forEach((element, elementIdx) => {
    const metlValues: ValueCollection = {}

    element.forEach((value, valueIdx) => {
      metlValues[valueIdx + 1] = {
        slot: valueIdx + 1,
        type: 'STRING',
        dataType: parameter.dataType,
        value
      }
    })

    metlElements[elementIdx + 1] = {
      slot: elementIdx + 1,
      values: metlValues
    }
  })

  return metlElements
}

const convertStructParameterValueToMetl = (
  parameter: ComponentParameter,
  elements: StructValue
) => {
  const metlElements: ElementCollection = {}

  Object.values(elements).forEach((element, elementIdx) => {
    metlElements[elementIdx + 1] = {
      slot: elementIdx + 1,
      values: {
        1: {
          slot: 1,
          value: element as string,
          type: 'STRING',
          dataType: parameter.dataType
        }
      }
    }
  })

  return metlElements
}

const convertStructListParameterValueToMetl = (
  parameter: ComponentParameter,
  elements: StructList
) => {
  const metlElements: ElementCollection = {}

  elements.forEach((element, elementIdx) => {
    const metlValues: ValueCollection = {}

    Object.values(element).forEach((value, valueIdx) => {
      metlValues[valueIdx + 1] = {
        slot: valueIdx + 1,
        type: 'STRING',
        dataType: parameter.dataType,
        value: (value as string) ?? ''
      }
    })

    metlElements[elementIdx + 1] = {
      slot: elementIdx + 1,
      values: metlValues
    }
  })

  return metlElements
}

const convertListParameterValueToMetl = (
  parameter: ComponentParameter,
  elements: string[]
) => {
  const metlElements: ElementCollection = {}

  elements.forEach((element, elementIdx) => {
    metlElements[elementIdx + 1] = {
      slot: elementIdx + 1,
      values: {
        1: {
          slot: 1,
          type: 'STRING',
          dataType: parameter.dataType,
          value: element
        }
      }
    }
  })

  return metlElements
}

export const convertParameterValueToMetl = (
  parameter: ComponentParameter,
  value: ParameterValue
): ElementCollection => {
  if (value === null) {
    return {}
  }

  if (isScalarParameterValue(value)) {
    return {
      1: {
        slot: 1,
        values: {
          1: {
            slot: 1,
            dataType: parameter.dataType,
            type: 'STRING',
            value
          }
        }
      }
    }
  }

  // TODO: The designer does not currently support grid variables
  if (isGridValueFromVariableParameterValue(value)) {
    console.debug(
      `Could not convert parameter ${parameter.dplID}: GridValueFromVariable is not currently supported`
    )
    return {}
  }

  if (isStructListParameterValue(value)) {
    return convertStructListParameterValueToMetl(parameter, value)
  }

  if (isStructValueParameterValue(value)) {
    return convertStructParameterValueToMetl(parameter, value)
  }

  if (isGridValueParameterValue(value)) {
    return convertGridParameterValueToMetl(parameter, value)
  }

  if (isListValueParameterValue(value)) {
    return convertListParameterValueToMetl(parameter, value)
  }

  console.debug(
    `Could not convert parameter ${parameter.dplID}: unrecognised parameter type`
  )
  return {}
}

const convertParameterToMetl = (
  parameter: ComponentParameter,
  value?: ParameterValue
): Parameter => {
  /* we need to explicitly check for undefined here,
   * as null is an acceptable value for a parameter */
  if (value === undefined) {
    return {
      slot: parameter.metlSlot,
      name: parameter.dplID,
      elements: {},
      visible: false
    }
  }

  return {
    slot: parameter.metlSlot,
    name: parameter.dplID,
    elements: convertParameterValueToMetl(parameter, value),
    visible: true
  }
}

const convertParametersToMetl = (
  componentInstance: Component,
  componentMetadata: ComponentMetadata
) => {
  const metlParameters: ParameterCollection = {}

  componentMetadata.parameters.forEach((parameter) => {
    const value = componentInstance.parameters[parameter.dplID]
    const metlParameter = convertParameterToMetl(parameter, value)

    // TODO: Multi-warehouse support
    // is this component supported in the current warehouse?
    // if not, exclude it from conversion

    // TODO: Multi-warehouse support
    // override metlSlots for other warehouses
    const metlSlot = parameter.metlSlot

    metlParameters[metlSlot] = {
      ...metlParameter,
      slot: metlSlot
    }
  })

  /* this assertion is needed as the Parameters type specifies that the first
   * slot is always the name parameter. This should be true--but is quite
   * hard to type when we're dynamically generating the entire set */
  return metlParameters as Parameters
}

export default convertParametersToMetl
