import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert, AutoComplete, Typography } from '@matillion/component-library'

import classes from './EnvironmentDropdown.module.scss'
import { type EnvironmentDropdownProps } from './types'

const FailureAlert = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'environment.dropdown'
  })

  return (
    <>
      <Typography format="bcs" weight="bold">
        {t('alert.title')}
      </Typography>
      <Typography format="bcs">{t('alert.body')}</Typography>
    </>
  )
}

const EnvironmentDropdown = ({
  disabled,
  onSelect,
  environments,
  onUnavailable
}: EnvironmentDropdownProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'environment.dropdown'
  })

  const hasNoEnvironments = environments?.length === 0

  useEffect(() => {
    if (hasNoEnvironments) {
      onUnavailable()
    }
  }, [hasNoEnvironments, onUnavailable])

  const handleChange = useCallback(
    (newValue: { target: any }) => {
      const value = newValue.target.value
      onSelect(value ? value.id.toString() : undefined)
    },
    [onSelect]
  )

  if (hasNoEnvironments) {
    return (
      <Alert
        type="error"
        theme="dark"
        message={<FailureAlert />}
        className={classes.FailureAlert}
        data-testid="no-environments-alert"
      />
    )
  }

  return (
    <div
      className={classes.Container}
      data-testid="environment-dropdown-container"
    >
      <Typography as="p" format="bcs" weight="bold" className={classes.Label}>
        {t('label')}
      </Typography>

      <AutoComplete
        colorTheme="white"
        onChange={handleChange}
        placeholder={t('placeholder')}
        className={classes.AutoComplete}
        data-testid="environment-dropdown"
        availableItems={environments ?? []}
        disabled={disabled || hasNoEnvironments}
      />
    </div>
  )
}

export default EnvironmentDropdown
