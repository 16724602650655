import { useEffect, type ChangeEvent, type Dispatch } from 'react'
import { useTranslation } from 'react-i18next'

import { Field, Icon, Radio, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import {
  JobVariableVisibility,
  type JobVariableType,
  type VariableScope
} from 'job-lib/types/Variables'

import {
  Fields,
  ReducerActions,
  type FormState,
  type ProjectVariableType
} from 'modules/ManageVariables/types'

import classes from '../Form.module.scss'
import { type ReducerAction } from '../formReducer/formReducer'

export const JobSpecificFields = ({
  state,
  dispatch,
  selectedVariableScope,
  selectedVariableType,
  editing
}: {
  state: FormState
  dispatch: Dispatch<ReducerAction>
  selectedVariableScope: VariableScope
  selectedVariableType: JobVariableType | ProjectVariableType | null
  editing: boolean
}) => {
  const { t } = useTranslation()
  const { [Fields.DEFAULT_VALUE]: defaultValue } = state
  useEffect(() => {
    if (selectedVariableType && editing) {
      dispatch({
        type: ReducerActions.UPDATE_DEFAULT_VALUE,
        field: Fields.DEFAULT_VALUE,
        value: defaultValue.value,
        selectedVariableScope,
        selectedVariableType
      })
    }
  }, [
    selectedVariableType,
    selectedVariableScope,
    defaultValue.value,
    dispatch,
    editing
  ])
  return (
    <>
      <fieldset className={classes.Form__ScopeFieldset}>
        <legend className="u-visually-hidden">
          {t('manageVariables.jobVariables.fields.variableVisibilityScope')}
        </legend>

        <Typography
          format="bcs"
          weight="bold"
          className={classes.Form__CustomLabel}
        >
          {t('manageVariables.jobVariables.fields.visibility')}
        </Typography>

        <Field
          className={classNames(classes.Field, classes.RadioField)}
          inputComponent={Radio}
          onBlur={() => {
            dispatch({
              type: ReducerActions.VALIDATE_FORM,
              selectedVariableScope,
              selectedVariableType
            })
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: ReducerActions.UPDATE_FIELD,
              field: Fields.VISIBILITY,
              value: e.target.value as JobVariableVisibility,
              selectedVariableScope,
              selectedVariableType
            })
          }}
          text={t(
            'manageVariables.jobVariables.fields.variableVisibility.public'
          )}
          hasError={state[Fields.VISIBILITY].isValid === false}
          value={JobVariableVisibility.PUBLIC}
          checked={
            state[Fields.VISIBILITY].value === JobVariableVisibility.PUBLIC
          }
        />
        <Field
          className={classes.Field}
          inputComponent={Radio}
          onBlur={() => {
            dispatch({
              type: ReducerActions.VALIDATE_FORM,
              selectedVariableScope,
              selectedVariableType
            })
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: ReducerActions.UPDATE_FIELD,
              field: Fields.VISIBILITY,
              value: e.target.value as JobVariableVisibility,
              selectedVariableScope,
              selectedVariableType
            })
          }}
          text={t(
            'manageVariables.jobVariables.fields.variableVisibility.private'
          )}
          hasError={state[Fields.VISIBILITY].isValid === false}
          value={JobVariableVisibility.PRIVATE}
          checked={
            state[Fields.VISIBILITY].value === JobVariableVisibility.PRIVATE
          }
        />

        <div aria-live="polite">
          {state[Fields.VISIBILITY].isValid === false && (
            <div className={classes.FieldError}>
              <Icon.Error className={classes.FieldError__Icon} />
              <Typography
                className={classes.FieldError__Text}
                as="span"
                format="bcs"
              >
                {t(
                  'manageVariables.jobVariables.fields.errors.pleaseSelectAnOption'
                )}
              </Typography>
            </div>
          )}
        </div>
      </fieldset>

      <Field
        className={classes.Field}
        title={t('manageVariables.jobVariables.fields.defaultValue')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          dispatch({
            type: ReducerActions.UPDATE_DEFAULT_VALUE,
            field: Fields.DEFAULT_VALUE,
            value: e.target.value,
            selectedVariableScope,
            selectedVariableType
          })
        }}
        value={state[Fields.DEFAULT_VALUE].value}
        hasError={state[Fields.DEFAULT_VALUE].isValid === false}
        errorText={
          state[Fields.DEFAULT_VALUE].isValid === false &&
          t(state[Fields.DEFAULT_VALUE].error, {
            field: t('manageVariables.jobVariables.fields.defaultValue')
          })
        }
      />
    </>
  )
}
