import type { FunctionComponent } from 'react'

import { Typography } from '@matillion/component-library'

import { SelectableCard, SelectableCardBody } from 'components/SelectableCard'

import { Stack } from '../../Stack'

interface Props {
  title: string
  description: string
  onClick: () => void
  icon: React.ReactNode
}

export const OnboardingCard: FunctionComponent<Props> = ({
  title,
  description,
  onClick,
  icon
}) => {
  return (
    <SelectableCard onSelection={onClick}>
      <SelectableCardBody>
        <Stack isCentered>
          {icon}
          <Typography format="ts">{title}</Typography>
          <Typography>{description}</Typography>
        </Stack>
      </SelectableCardBody>
    </SelectableCard>
  )
}
