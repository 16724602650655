import { cloneDeep, toUpper } from 'lodash'

import type {
  ComponentMetadata,
  ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

const concat = (parentKey: string | undefined, childKey: string) =>
  parentKey ? `${parentKey}.${childKey}` : childKey

function filterSupportedAndPutRemovedInSet(
  componentParameters: ComponentParameter[],
  projectWarehouse: string,
  unSupportedParameters: Set<string>,
  parentDplId?: string
) {
  return componentParameters.filter((parameter) => {
    const childDplId = concat(parentDplId, parameter.dplID)
    let isSupported = true
    if (parameter.warehouses) {
      const paramOverride = parameter.warehouses[toUpper(projectWarehouse)]
      if (paramOverride?.metlSlot) {
        parameter.metlSlot = paramOverride?.metlSlot
      }
      isSupported = !!paramOverride
      if (!isSupported) {
        unSupportedParameters.add(childDplId)
      }
    }
    if (isSupported && parameter.childProperties) {
      parameter.childProperties = filterSupportedAndPutRemovedInSet(
        parameter.childProperties,
        projectWarehouse,
        unSupportedParameters,
        childDplId
      )
    }
    return isSupported
  })
}

// parameter should be removed from visibleWhen if it refers to some unsupported parameter
function removeUnsupportedParametersFromVisibleWhen(
  parameters: ComponentParameter[],
  unSupportedParameters: Set<string>,
  parentDplId?: string
) {
  parameters.forEach((parameter) => {
    const childDplId = concat(parentDplId, parameter.dplID)
    if (parameter.childProperties) {
      removeUnsupportedParametersFromVisibleWhen(
        parameter.childProperties,
        unSupportedParameters,
        childDplId
      )
    }
    if (parameter.visibleWhen) {
      parameter.visibleWhen = parameter.visibleWhen.filter(({ param }) => {
        return param === null || !unSupportedParameters.has(param)
      })
    }
  })
}

export const removeUnsupportedParametersAndReplaceSlot = (
  projectWarehouse: string,
  metadata: ComponentMetadata
) => {
  const output = cloneDeep(metadata)
  const unSupportedParameters = new Set<string>()
  output.parameters = filterSupportedAndPutRemovedInSet(
    output.parameters,
    projectWarehouse,
    unSupportedParameters
  )

  removeUnsupportedParametersFromVisibleWhen(
    output.parameters,
    unSupportedParameters
  )

  return output
}
