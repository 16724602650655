import { act } from 'react-dom/test-utils'

import { renderHook } from '@testing-library/react'

import PactHookProviders from '../PactHookProviders'
import {
  type ApiHookResult,
  type MutationResult,
  type PactMutationHookConfig
} from './types'
import { assertPactApiHook } from '../assertPactApiHook'

/**
 * Renders the given API hook using {@link renderHook}, invokes
 * {@link MutationResult#mutateAsync}, waits for success and
 * returns the query result. The hook must use useMutation()
 *
 * @param hook The API hook to render
 * @param config Optional configuration
 * @returns The {@link QueryResult} from the API hook
 */
const usePactApiMutation = async <ApiResponse, MutationArgs = void>(
  hook: () => MutationResult<ApiResponse, MutationArgs>,
  config?: Partial<PactMutationHookConfig<ApiResponse, MutationArgs>>
) => {
  const { result, unmount } = renderHook(hook, {
    wrapper: ({ children }) => (
      <PactHookProviders {...config}>{children}</PactHookProviders>
    )
  }) as ApiHookResult<ApiResponse, MutationArgs>

  await act(async () => {
    await result.current.mutateAsync(config?.mutationArgs).catch((error) => {
      const message =
        'Caught an error during a pact test while trying to invoke mutateAsync() on your API hook.'
      console.debug(message)
      console.error(error)
    })
  })

  const mutationError = result.current.error
  if (!config?.expectError && mutationError) {
    const message =
      'An error was returned from your mutation hook during a pact test:\n'
    throw new Error(message, { cause: mutationError })
  }

  await assertPactApiHook<ApiResponse, MutationArgs | undefined>({
    result,
    config
  })

  unmount()

  return result
}

export default usePactApiMutation
