import { Button, Icon, Modal, Typography } from '@matillion/component-library'
import classes from './EnterpriseLicenseRequiredModal.module.scss'
import { useTranslation } from 'react-i18next'
import { type EnterpriseLicenseRequiredModalProps } from './types'

const EnterpriseLicenseRequiredModal = ({
  onClose
}: EnterpriseLicenseRequiredModalProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'byog.enterprise-license.modal'
  })

  const handleUpgrade = () => {
    window.open(t('upgrade-link'), '_blank')
  }

  return (
    <Modal
      size="mid"
      onCancel={onClose}
      disableBackdropClick
      className={classes.modal}
      id="enterprise-license-required-modal"
      ariaLabelledBy="enterprise-license-required-title"
      data-testid="enterprise-license-required-modal"
    >
      <Typography
        className={classes.title}
        format="tm"
        id="enterprise-license-required-title"
        data-testid="enterprise-license-required-title"
      >
        {t('title')}
      </Typography>

      <Typography
        className={classes.body}
        format="bcm"
        id="enterprise-license-required-body"
        data-testid="enterprise-license-required-body"
      >
        {t('body')}
      </Typography>

      <div className={classes.buttons}>
        <Button
          id="cancel"
          alt="secondary"
          onClick={onClose}
          text={t('cancel')}
          className={classes.button}
          data-testid="enterprise-license-required-cancel"
        />

        <Button
          alt="positive"
          id="upgrade-account"
          onClick={handleUpgrade}
          className={classes.button}
          data-testid="enterprise-license-required-upgrade"
        >
          <Typography>{t('upgrade-account')}</Typography>
          <Icon.Export className={classes.exportIcon} />
        </Button>
      </div>
    </Modal>
  )
}

export default EnterpriseLicenseRequiredModal
