import { rest } from 'msw'

import { AGENT_FLAKY_ID, AGENT_ID, AGENT_VERSION } from '__test-utils__/ids'

import config from 'config'

import { AgentStatusEnum } from '../useAgentDetails'

export const makeAgentDetailsURL = (agentId = AGENT_ID) =>
  `${config.getApiBaseUrl()}/v1/agents/${agentId}`

export const useAgentDetailsHandlers = [
  rest.get(makeAgentDetailsURL(AGENT_ID), async (req, res, ctx) => {
    return res(
      ctx.json({
        agentId: AGENT_ID,
        agentVersion: AGENT_VERSION,
        status: AgentStatusEnum.Running,
        host: 'CUSTOMER'
      })
    )
  }),
  rest.get(makeAgentDetailsURL(AGENT_FLAKY_ID), async (req, res, ctx) => {
    return res.once(
      ctx.json({
        agentId: AGENT_FLAKY_ID,
        agentVersion: AGENT_VERSION,
        status: AgentStatusEnum.Stopped
      })
    )
  })
]
