import {
  type ComponentMetadataResponse,
  type ConnectorComponentMetadataResponse
} from 'api/hooks/useGetComponentMetadata/types'
import { type JobSummary } from 'api/hooks/useGetJobSummaries'

import { JobType } from 'job-lib/types/JobType'

import { type ValidationDependencies } from '../types'

export const getValidationDependencies = (
  validationDependencies: string[] | null,
  jobSummaries: JobSummary[],
  componentMetadata: ComponentMetadataResponse | undefined
): ValidationDependencies | undefined => {
  if (!validationDependencies || validationDependencies.length === 0) {
    return
  }

  const getJobRuntimeNamesByType = (type: JobType) => {
    return jobSummaries
      .filter((summary) => summary.type === type)
      .map((summary) => summary.runtimeName)
  }

  const dependencies = validationDependencies.reduce(
    (dependencyBag, dependency) => {
      const [dependencyName] = dependency.split('.')
      switch (dependencyName) {
        case 'orchestration-pipeline-names': {
          return {
            ...dependencyBag,
            [dependency]: getJobRuntimeNamesByType(JobType.Orchestration)
          }
        }
        case 'transformation-pipeline-names': {
          return {
            ...dependencyBag,
            [dependency]: getJobRuntimeNamesByType(JobType.Transformation)
          }
        }
        case 'cc_profile': {
          return {
            ...dependencyBag,
            // We're cheating a little bit and using the connectorProfile contained within the component metadata here. This will work
            // fine for now given we're wrapping api-extract and expecting the connectorProfile to always be correct. But, this might need
            // changing in future if we support grabbing a different profile and/or want to support api-extract as a native component.
            [dependency]: (
              componentMetadata as ConnectorComponentMetadataResponse
            ).connectorProfile
          }
        }
      }
      console.warn(
        'unsupported validationDependency found',
        validationDependencies
      )
      return dependencyBag
    },
    {}
  )

  return dependencies
}
