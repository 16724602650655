import { type Branch } from 'types/Branch'

export const PROJECT_ID = '6f8bc2f3-96d5-449d-9551-15d0cdaaf413'
export const PROJECT_ID_WITH_NO_ENVS = '27355dca-a015-4dff-bcf8-8e3c8e984b64'
export const BRANCH_ID = '4aaf06b1-7313-4ff7-8f8c-6158c8f9633a'
export const BRANCH_NAME = 'test-branch'
export const BRANCH_BEHIND_NO_CONFLICTS =
  'test-branch-behind-remote-no-conflicts'
export const BRANCH_BEHIND_WITH_CONFLICTS =
  'test-branch-behind-remote-with-conflicts'

export const BRANCHES: Branch[] = [
  {
    id: 'df2bcad0-c892-4a05-9a53-b61614aa42ad',
    name: 'main'
  },
  {
    id: '6e3403aa-ed89-4a9b-b2c1-fc219e2a4be8',
    name: BRANCH_NAME
  },
  {
    id: 'ebcccbc8-18d2-4110-bfb4-29eae7df5f2e',
    name: 'BranchNoConflicts'
  },
  {
    id: '65c3d4d4-9787-47df-88a5-6a82c9cdd088',
    name: 'BranchWithConflicts'
  },
  {
    id: 'a4e5af0f-b403-4cf8-841e-0fb296e6ac70',
    name: 'BranchManyConflicts'
  },
  {
    id: '2682b37c-c24f-4e6b-9582-6b63a7366f96',
    name: 'BranchFullOffset'
  },
  {
    id: '53cb804f-7036-4a5a-a63e-191b306d3a55',
    name: 'BranchPartialOffset'
  },
  {
    id: BRANCH_ID,
    name: 'secondary-test-branch'
  },
  {
    id: BRANCH_ID,
    name: BRANCH_ID
  }
]
