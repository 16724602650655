import {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  type KeyboardEvent,
  type PropsWithChildren
} from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { ComponentDragSource } from 'components/ComponentDrag/ComponentDragSource'

import { type ExtendedProps } from 'hooks/useAvailableComponents/useAvailableComponents'

import { OutputPortType } from 'job-lib/types/Components'

import { ComingSoonLabel } from '../ComingSoonLabel/ComingSoonLabel'
import { ComponentName } from '../ComponentName'
import classes from './ComponentSummaryListItem.module.scss'

interface ComponentSummaryListItemProps {
  component: ExtendedProps
  displayName: string
  searchTerm: string
  isDraggable?: boolean
  componentIndex: number
  selectedComponentIndex: number | null
  onSelectComponent?: (id: string) => Promise<void>
  onKeyDown: (e: KeyboardEvent) => void
}

interface WrapperProps extends PropsWithChildren {
  component: ExtendedProps
  isDraggable?: boolean
  icon: string
}

const Wrapper = ({ children, isDraggable, component, icon }: WrapperProps) => {
  const isIterator =
    component.outputPorts.filter((o) => o.portId === OutputPortType.ITERATION)
      .length > 0

  return isDraggable ? (
    <ComponentDragSource
      data-testid="component-drag-source"
      isIterator={isIterator}
      componentId={component.componentId}
      name={component.displayName}
      imageUrl={icon}
      dragOrigin="component-browser"
    >
      {children}
    </ComponentDragSource>
  ) : (
    <Fragment>{children}</Fragment>
  )
}

const ComponentSummaryListItem = ({
  component,
  displayName,
  searchTerm,
  isDraggable,
  componentIndex,
  selectedComponentIndex,
  onSelectComponent,
  onKeyDown
}: ComponentSummaryListItemProps) => {
  const { t } = useTranslation()
  const componentRef = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (componentIndex === selectedComponentIndex) {
      componentRef?.current?.focus()
    }
  }, [displayName, componentIndex, selectedComponentIndex])

  const handleClick = useCallback(() => {
    if (!onSelectComponent || !component.isAvailableForAgent) {
      return
    }

    onSelectComponent(component.componentId)
  }, [onSelectComponent, component])

  const canBeDragged = component.isAvailableForAgent ? isDraggable : false

  const getTabIndex = () => {
    if (selectedComponentIndex === null) {
      return componentIndex === 0 ? 0 : -1
    }

    return selectedComponentIndex === componentIndex ? 0 : -1
  }

  return (
    <div role="row">
      <div role="gridcell" data-testid="component-list-item">
        <Wrapper
          component={component}
          isDraggable={canBeDragged}
          icon={component.icon}
        >
          <button
            data-testid={`row-${displayName}`}
            ref={componentRef}
            tabIndex={getTabIndex()}
            onClick={handleClick}
            onKeyDown={onKeyDown}
            type="button"
            className={classNames(
              classes.ComponentSummaryListItem,
              isDraggable && classes.ComponentSummaryListItem__Draggable,
              !component.isAvailableForAgent &&
                classes.ComponentSummaryListItem__Unselectable
            )}
          >
            {isDraggable && (
              <Icon.DragHandle
                className={classNames(
                  classes.DragHandle,
                  !component.isAvailableForAgent &&
                    classes.DragHandle__Invisible
                )}
                data-testid={`drag-icon-${component.componentId}`}
              />
            )}

            <div className={classes.ComponentSummaryListItem__Icon}>
              <img role="presentation" src={component.icon} alt={displayName} />
            </div>

            <ComponentName displayName={displayName} searchTerm={searchTerm} />

            <div className={classes.ComponentSummaryListItem__Badge}>
              {!component.isAvailableForAgent ? (
                <ComingSoonLabel labelText={t('componentBrowser.comingSoon')} />
              ) : (
                <Typography
                  format="bcs"
                  className={classes.ComponentSummaryListItem__Tag}
                >
                  {component.tags[0]}
                </Typography>
              )}
            </div>
          </button>
        </Wrapper>
      </div>
    </div>
  )
}

export { ComponentSummaryListItem }
