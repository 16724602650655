import { type Dispatch } from 'react'
import { useDispatch } from 'react-redux'

import { type CreateProjectVariablesRequest } from 'api/hooks/useCreateProjectVariables/types'
import { useCreateProjectVariables } from 'api/hooks/useCreateProjectVariables/useCreateProjectVariables'

import { jobActions } from 'job-lib/store'
import {
  JobVariableBehaviour,
  type JobVariableType,
  type JobVariableVisibility,
  type VariableScope
} from 'job-lib/types/Variables'

import {
  ProjectVariableBehaviour,
  Stages,
  type FormState,
  type ManageVariableStageProps,
  type ProjectVariableType
} from 'modules/ManageVariables/types'

import { Form } from '../Form/Form'

export const Create = ({
  setStage,
  selectedVariableScope,
  setSelectedVariableScope,
  selectedVariableType,
  setSelectedVariableType
}: {
  setStage: ManageVariableStageProps['setStage']
  selectedVariableScope: VariableScope
  setSelectedVariableScope?: Dispatch<VariableScope>
  selectedVariableType?: JobVariableType | ProjectVariableType | null
  setSelectedVariableType?: Dispatch<
    JobVariableType | ProjectVariableType | null
  >
}) => {
  const { mutateAsync: createProjectVariables, isLoading } =
    useCreateProjectVariables()
  const jobDispatch = useDispatch()

  const onSubmit = (state: FormState, isProjectVariable: boolean) => {
    if (isProjectVariable) {
      const postData: CreateProjectVariablesRequest = {
        name: state.NAME.value,
        type: selectedVariableType as ProjectVariableType,
        behaviour: ProjectVariableBehaviour.COPIED,
        description: state.DESCRIPTION.value,
        value:
          state.DEFAULT_VALUE.value !== '' ? state.DEFAULT_VALUE.value : null,
        overrides: Object.values(state.PROJECT_DEFAULT_OVERRIDES.value).map(
          ({ environmentId, value }) => ({
            environmentId,
            value: value !== '' ? value : null
          })
        )
      }
      createProjectVariables(postData).then(() => {
        setStage(Stages.MANAGE)
      })
    } else {
      jobDispatch(
        jobActions.addJobVariable({
          definition: {
            name: state.NAME.value,
            description: state.DESCRIPTION.value,
            type: selectedVariableType as JobVariableType,
            behaviour: JobVariableBehaviour.COPIED,
            visibility: state.VISIBILITY.value as JobVariableVisibility
          },
          value: state.DEFAULT_VALUE.value
        })
      )

      setStage(Stages.MANAGE)
      setSelectedVariableScope?.(selectedVariableScope)
    }
  }

  const onCancel = () => {
    setStage(Stages.MANAGE)
  }

  return (
    <Form
      selectedVariableScope={selectedVariableScope}
      onCancel={onCancel}
      onSubmit={onSubmit}
      submitting={isLoading}
      selectedVariableType={selectedVariableType}
      setSelectedVariableType={setSelectedVariableType}
    />
  )
}
