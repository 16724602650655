import { rest, type RequestHandler } from 'msw'

import { PROJECT_ID } from '__test-utils__/ids'

import config from 'config'

import { type GetProjectResponse } from '../types'
import { useGetProjectMockResponse } from './useGetProjectMockResponse'

export const useGetProjectHandlers: RequestHandler[] = [
  rest.get(
    `${config.getProjectExplorerApiBaseUrl()}/v1/projects/${PROJECT_ID}`,
    async (_req, res, ctx) => {
      return res(ctx.json<GetProjectResponse>(useGetProjectMockResponse))
    }
  )
]
