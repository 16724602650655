export type JobVariableCollection = Record<string, JobVariable>

export enum VariableScope {
  PROJECT_VARIABLE = 'PROJECT_VARIABLE',
  JOB_VARIABLE = 'JOB_VARIABLE'
}

export enum JobVariableType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER'
}

export enum JobVariableBehaviour {
  SHARED = 'SHARED',
  COPIED = 'COPIED'
}

export enum JobVariableVisibility {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export interface JobVariable {
  definition: JobVariableDefinition
  value: string
}

export interface JobVariableDefinition {
  name: string
  description?: string
  type: JobVariableType
  behaviour: JobVariableBehaviour
  visibility: JobVariableVisibility
}
