import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Field, Radio, Typography } from '@matillion/component-library'

import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import { ReactComponent as PlusInCircle } from 'assets/plus-in-circle.svg'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import { useComponentInstanceMetadataQuery } from 'hooks/useComponentInstanceMetadataQuery/useComponentInstanceMetadataQuery'

import { useMakeComponent } from 'job-lib/hooks/useMakeComponent/useMakeComponent'
import { jobActions } from 'job-lib/store'
import {
  OutputPortType,
  type Cardinality,
  type Port
} from 'job-lib/types/Components'
import { type ComponentInstanceId } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'

import classes from './AddNextComponent.module.scss'
import { AddComponentList } from './components/AddComponentList'

interface AddNextComponentProps {
  sourceComponentId?: ComponentInstanceId
}

export interface SourceComponentConnection {
  sourceComponentId: ComponentInstanceId
  sourceType: OutputPortType
  sourceCardinality: Cardinality
}

export const getDefaultPortConnection = (outputPorts: Port[]) => {
  if (outputPorts?.find((port) => port.portId === OutputPortType.SUCCESS)) {
    return OutputPortType.SUCCESS
  }

  if (outputPorts?.find((port) => port.portId === OutputPortType.TRUE)) {
    return OutputPortType.TRUE
  }

  return OutputPortType.UNCONDITIONAL
}

export const AddNextComponent = ({
  sourceComponentId
}: AddNextComponentProps) => {
  const { t } = useTranslation()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const dispatch = useDispatch()
  const [makeComponent] = useMakeComponent()
  const { job, jobType } = useWorkingCopy()
  const sourceComponentMetadata =
    useComponentInstanceMetadataQuery(sourceComponentId)

  const [sourceType, setSourceType] = useState<OutputPortType>(() =>
    getDefaultPortConnection(
      sourceComponentMetadata.metadata?.outputPorts ?? []
    )
  )

  const onSelectComponent = useCallback(
    async (id: ComponentSummaryId, componentName?: string) => {
      const position = {
        x: 0,
        y: 0
      }

      if (!job) {
        return
      }

      let sourceComponentConnection

      if (sourceComponentId) {
        position.x = job?.components[sourceComponentId].x + 120
        position.y = job?.components[sourceComponentId].y

        const sourceCardinality =
          sourceComponentMetadata.metadata?.outputPorts?.find(
            (port) => port.portId === sourceType
          )?.cardinality as Cardinality

        sourceComponentConnection = {
          sourceComponentId,
          sourceType,
          sourceCardinality
        }
      }

      setIsDialogOpen(false)

      const newComponent = await makeComponent({
        id,
        sourceComponentConnection,
        ...position,
        componentName
      })

      dispatch(jobActions.addComponent(newComponent))
    },
    [
      sourceComponentId,
      job,
      sourceComponentMetadata,
      sourceType,
      dispatch,
      makeComponent
    ]
  )

  const handleSourceTypeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSourceType(event.target.value as OutputPortType)
    },
    []
  )

  return (
    <>
      <button
        type="button"
        className={classes.AddNextComponentCTA}
        onClick={() => {
          setIsDialogOpen(true)
        }}
        data-testid="add-next-component"
        aria-label={t('canvas.addComponent')}
      >
        <PlusInCircle />
      </button>

      {isDialogOpen && (
        <DesignerModal
          ariaLabelledBy="modal-title"
          onCancel={() => {
            setIsDialogOpen(false)
          }}
          className={classes.ModalContainer}
          setFocus={false}
          size="mid"
        >
          <div className={classes.ModalContainer__Title}>
            <Typography as="h2" format="tm" id="modal-title">
              {t('addNextComponent.title')}
            </Typography>
          </div>
          {jobType === JobType.Orchestration && (
            <div
              className={classes.AddNextComponentCTA__OutputPortSelectorWrapper}
            >
              <Typography
                as="h3"
                format="bcs"
                weight="bold"
                id="output-connection-title"
              >
                {t('addNextComponent.portSelectorTitle')}
              </Typography>
              {sourceComponentMetadata.metadata?.outputPorts.length === 1 ? (
                <Typography
                  format="bcs"
                  aria-labelledby="output-connection-title"
                  className={classes.AddNextComponentCTA__OutputPortSelector}
                >
                  {t(
                    `addNextComponent.outputPorts.${sourceComponentMetadata.metadata?.outputPorts[0].portId}`
                  )}
                </Typography>
              ) : (
                <ul
                  aria-labelledby="output-connection-title"
                  className={classes.AddNextComponentCTA__OutputPortSelector}
                >
                  {sourceComponentMetadata.metadata?.outputPorts
                    .filter((port) => port.portId !== OutputPortType.ITERATION)
                    .map((port) => (
                      <li key={port.portId}>
                        <Field
                          name="portType"
                          text={t(
                            `addNextComponent.outputPorts.${port.portId}`
                          )}
                          value={port.portId}
                          inputComponent={Radio}
                          checked={sourceType === port.portId}
                          onChange={handleSourceTypeChange}
                        />
                      </li>
                    ))}
                </ul>
              )}
            </div>
          )}

          <div className={classes.ModalContainer__Content}>
            <AddComponentList onSelectComponent={onSelectComponent} />
          </div>
        </DesignerModal>
      )}
    </>
  )
}
