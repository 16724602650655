import { createContext } from 'react'

import { noop } from 'lodash'

import {
  type UserPreference,
  type UserPreferenceProviderContextInterface
} from './types'

export const userPreferenceInitialValue: UserPreference = {
  snapToGridEnabled: true
}
export const UserPreferenceProviderContext =
  createContext<UserPreferenceProviderContextInterface>({
    userPreference: userPreferenceInitialValue,
    setUserPreference: noop,
    setSpecificUserPreference: noop
  })
