import { AnaplanConfig } from './Anaplan'
import { type ComponentLayout } from './types'
import { WorkdayConfig } from './Workday'

export interface WizardConfigs {
  [key: string]: ComponentLayout
}

export const wizardConfigs: WizardConfigs = {
  'modular-anaplan-input-v1': AnaplanConfig,
  'modular-workday-input-v1': WorkdayConfig
}
