import { useMemo } from 'react'

import { useAuth, useUser } from '@matillion/hub-client'
import axios, { type AxiosRequestConfig } from 'axios'

import config from 'config'

const useEosClient = () => {
  const { getToken } = useAuth()
  const { organisation } = useUser()

  return useMemo(() => {
    const client = axios.create({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      baseURL: config.getEosApiBaseUrl(organisation.region),
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: 50000
    })

    client.interceptors.request.use(
      async (interceptedConfig: AxiosRequestConfig) => {
        const tokenOptions = {
          audience: config.apiTokenAudience
        }
        if (interceptedConfig.headers) {
          interceptedConfig.headers.Authorization = `Bearer ${await getToken(
            tokenOptions
          )}`
          interceptedConfig.headers['account-id'] = organisation.id
        }

        return interceptedConfig
      }
    )
    return client
  }, [organisation.id, organisation.region, getToken])
}

export default useEosClient
