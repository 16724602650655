import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import SearchBar from 'components/SearchBar/SearchBar'

import classes from './PanelWrapper.module.scss'

interface PanelSearchProps {
  name: string
  searchTerm: string
  setSearchTerm: (value: string) => void
  onClear: () => void
}
export const PanelSearch: FC<PanelSearchProps> = ({
  name,
  searchTerm,
  setSearchTerm,
  onClear
}) => {
  const { t } = useTranslation()

  return (
    <div className={classes.SearchBar}>
      <SearchBar
        placeholder={t('translation:sideBar.componentPanel.searchPlaceholder', {
          searchableItem: name
        })}
        onChange={(event) => {
          setSearchTerm(event.target.value)
        }}
        value={searchTerm}
        className={classes.searchActive}
        onClear={onClear}
      />
    </div>
  )
}
