import { type ComponentMetadataResponse } from '../../types'

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const mockExtractNestedDataSF = {
  componentId: 'extract-nested-data-sf',
  metadata: {
    schemaVersion: 1,
    emeraldId: '-793576480',
    componentType: 'TRANSFORMATION',
    classification: 'TRANSFORMATION',
    inputPorts: [
      {
        portId: 'INPUT',
        cardinality: 'ONE'
      }
    ],
    outputPorts: [
      {
        portId: 'UNCONDITIONAL',
        cardinality: 'MANY'
      }
    ],
    deprecated: false,
    exports: [],
    editorTabs: ['PROPERTIES', 'SAMPLE', 'METADATA', 'SQL', 'PLAN', 'HELP'],
    parameters: [
      {
        dplID: 'componentName',
        resourceID: 'componentName',
        metlSlot: 1,
        useVariable: 'NO',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'FREETEXT',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        dplID: 'includeInputColumns',
        resourceID: 'includeInputColumns',
        metlSlot: 2,
        useVariable: 'YES',
        dataType: 'BOOLEAN',
        authProvider: null,
        editorType: 'DROPDOWN',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            resourceID: null,
            type: 'TEXT',
            options: ['Yes', 'No'],
            columnEditorType: 'DROPDOWN',
            defaultValue: 'Yes',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: 'Yes',
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        dplID: 'columns',
        resourceID: 'columns',
        metlSlot: 3,
        useVariable: 'YES',
        dataType: 'GRID',
        authProvider: null,
        editorType: 'NESTED_DATA_PICKER_SF',
        lookupType: 'DYNAMIC_NESTED_DATA_SF',
        lookupDependencies: ['transform.sql'],
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        dplID: 'outerJoin',
        resourceID: 'outerJoin',
        metlSlot: 6,
        useVariable: 'YES',
        dataType: 'BOOLEAN',
        authProvider: null,
        editorType: 'DROPDOWN',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            resourceID: null,
            type: 'TEXT',
            options: ['Yes', 'No'],
            columnEditorType: 'DROPDOWN',
            defaultValue: 'No',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: 'No',
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        dplID: 'inputAlias',
        resourceID: 'inputAlias',
        metlSlot: 5,
        useVariable: 'YES',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'FREETEXT',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: 'i',
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        dplID: 'arrayPrefix',
        resourceID: 'arrayPrefix',
        metlSlot: 4,
        useVariable: 'YES',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'FREETEXT',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: 'f',
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        dplID: 'castingMethod',
        resourceID: 'castingMethod',
        metlSlot: 7,
        useVariable: 'YES',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'DROPDOWN',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            resourceID: null,
            type: 'TEXT',
            options: [
              'Fail on invalid data',
              'Replace all unparseable values with null',
              'Replace unparseable dates and timestamps with null'
            ],
            columnEditorType: 'DROPDOWN',
            defaultValue: 'Fail on invalid data',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: 'Fail on invalid data',
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        dplID: 'caseColumnAliasNames',
        resourceID: 'caseColumnAliasNames',
        metlSlot: 8,
        useVariable: 'YES',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'DROPDOWN',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            resourceID: null,
            type: 'TEXT',
            options: ['Upper', 'Lower', 'No'],
            columnEditorType: 'DROPDOWN',
            defaultValue: 'No',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: 'No',
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      }
    ]
  }
} as ComponentMetadataResponse
