import { useMemo } from 'react'

import { useGetJobSummaries } from 'api/hooks/useGetJobSummaries'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

export const useActivePipelineSummary = () => {
  const { jobSummaryId: activePipelineId } = useProjectInfo()
  const { data: summaries, ...summariesResponse } = useGetJobSummaries()

  const pipelineSummary = useMemo(() => {
    if (!activePipelineId) {
      return null
    }

    if (!Array.isArray(summaries?.results)) {
      return null
    }

    return summaries?.results.find((s) => s.jobId === activePipelineId) ?? null
  }, [summaries, activePipelineId])

  return {
    ...summariesResponse,
    pipelineSummary
  }
}
