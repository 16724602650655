import { useQuery } from '@tanstack/react-query'
import { type LegacyDescribeMergeResponse } from './types'
import { queryKeys } from 'api/query-keys'
import useDesignerClient from 'api/clients/useDesignerClient'
import { type ReactQueryOptions } from '../../../types/ReactQueryOptions'

/**
 * The identifier in the path variable of the merges resource accepts a "commit range".
 * A commit range is Git terminology for two specifiers (branch name, commit id, or tag)
 * separated by 2 (..) or 3 (...) dots.
 *
 * @param sourceSpecifier A specifier identifying the source of the merge.
 * @param targetSpecifier A specifier identifying the target of the marge.
 * @param options Optional config
 */
const useLegacyDescribeMerge = (
  sourceSpecifier?: string,
  targetSpecifier?: string,
  options?: ReactQueryOptions
) => {
  const { project, client } = useDesignerClient()

  return useQuery(
    [
      queryKeys.legacyMerge,
      project,
      targetSpecifier,
      queryKeys.legacyDescribeMerge
    ],
    async () => {
      const url = `projects/${project}/merges/${sourceSpecifier}...${
        targetSpecifier as string
      }`
      const { data } = await client.get<LegacyDescribeMergeResponse>(url)
      return data
    },
    {
      enabled: Boolean(targetSpecifier) && options?.enabled,
      meta: {
        error: {
          title: 'api.toast.describe-merge.title',
          message: 'api.toast.describe-merge.message'
        }
      }
    }
  )
}

export default useLegacyDescribeMerge
