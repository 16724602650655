import { useQueryClient } from '@tanstack/react-query'

import {
  type ComponentMetadataResponse,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import {
  getComponentMetadataQueryKey,
  getConnectorComponentMetadataQueryKey
} from 'api/hooks/useGetComponentMetadata/useGetComponentMetadata'
import { useConnectorComponentSummary } from 'api/hooks/useGetComponentSummaries'
import { useGetComponentSummary } from 'api/hooks/useGetComponentSummaries/useGetComponentSummary'
import { useGetJobSummaries } from 'api/hooks/useGetJobSummaries'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { type EmeraldId } from 'job-lib/types/Job'
import { type ParameterCollection } from 'job-lib/types/Parameters'

import type { ValidationDependencies } from '../../types'
import { getValidationDependencies } from '../../utils/getValidationDependencies'

export const useValidationDependencies = () => {
  const { data } = useGetJobSummaries()
  const jobSummaries = data?.results ?? []
  const { getSummary } = useConnectorComponentSummary()
  const { getByImplementationId } = useGetComponentSummary()
  const projectInfo = useProjectInfo()
  const queryClient = useQueryClient()

  const processParameter = (
    parameter: ComponentParameter,
    componentMetadata: ComponentMetadataResponse | undefined
  ): Record<string, string[]> => {
    let dependencyBag: Record<string, string[]> = {}

    if (parameter.validationDependencies) {
      dependencyBag = {
        ...dependencyBag,
        ...getValidationDependencies(
          parameter.validationDependencies,
          jobSummaries,
          componentMetadata
        )
      }
    }

    if (parameter.childProperties) {
      parameter.childProperties.forEach((childParameter) => {
        dependencyBag = {
          ...dependencyBag,
          ...processParameter(childParameter, componentMetadata)
        }
      })
    }

    return dependencyBag
  }
  const getDependencies = (
    implementationId: EmeraldId,
    parameters: ParameterCollection
  ) => {
    const componentId = getByImplementationId(
      implementationId.toString(),
      parameters
    )?.componentId as string
    const customSummary = getSummary(componentId)
    const componentMetadata: ComponentMetadataResponse | undefined =
      customSummary
        ? queryClient.getQueryData(
            getConnectorComponentMetadataQueryKey(projectInfo, componentId)
          )
        : queryClient.getQueryData(
            getComponentMetadataQueryKey(projectInfo, componentId)
          )

    const validationDependencies: ValidationDependencies =
      componentMetadata?.metadata?.parameters.reduce(
        (
          dependencyBag: Record<string, string[]>,
          parameter: ComponentParameter
        ) => {
          return {
            ...dependencyBag,
            ...processParameter(parameter, componentMetadata)
          }
        },
        {}
      ) ?? {}

    return validationDependencies
  }

  return getDependencies
}
