// projects/${projectId}/branches/${branchId}/jobs/${jobSummaryId}

import { rest } from 'msw'

import {
  MOCK_ORCHESTRATION_JOB_NAME,
  MOCK_TRANSFORMATION_JOB_NAME
} from '__test-utils__/ids'

import { mockOrchestrationJob } from 'api/hooks/useGetJob/__handlers__/mockOrchestrationJob'
import { mockTransformationJob } from 'api/hooks/useGetJob/__handlers__/mockTransformationJob'

import config from 'config'

import {
  type OrchestrationJob,
  type TransformationJob
} from 'job-lib/types/Job'

import { type Job } from 'modules/Canvas/hooks/useCanvasModel/useCanvasModel'

export const useSaveJobHandlers = [
  rest.put<Job>(
    `${config.getApiBaseUrl()}/v1/projects/*/branches/*/jobs/:jobSummaryId`,
    async (req, res, ctx) => {
      const body = await req.json<Job>()

      if (!body) {
        return res(ctx.status(400))
      }

      if (MOCK_TRANSFORMATION_JOB_NAME === req.params.jobSummaryId) {
        Object.entries(body as TransformationJob).forEach(([key, value]) => {
          // @ts-expect-error this is acceptable, TS doesn't like us normally merging objects like this
          mockTransformationJob[key as keyof TransformationJob] =
            value as TransformationJob[keyof TransformationJob]
        })
      } else if (MOCK_ORCHESTRATION_JOB_NAME === req.params.jobSummaryId) {
        Object.entries(body as OrchestrationJob).forEach(([key, value]) => {
          // @ts-expect-error this is acceptable, TS doesn't like us normally merging objects like this
          mockOrchestrationJob[key as keyof OrchestrationJob] =
            value as OrchestrationJob[keyof OrchestrationJob]
        })
      }

      return res(ctx.json(body))
    }
  )
]
