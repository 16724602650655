import { useMemo, useState, type Dispatch, type SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import {
  type DataGridColumnProps,
  type DataGridRow
} from '@matillion/component-library'

import classes from '../../UrlEditor.module.scss'
import NameCell from '../NameCell/NameCell'

interface ContainerRow {
  id: string
  container: {
    containerName: string
  }
  actions: {
    onClick: () => void
    upOneLevel: () => void
  }
}

export type GridRow = ContainerRow & DataGridRow

export type GridColumns = Array<DataGridColumnProps<GridRow>>

interface Props {
  fullPath: string[]
  setFullPath: Dispatch<SetStateAction<string[]>>
  setWarehouseUrl: Dispatch<SetStateAction<string>>
  buckets: string[]
  folders: string[]
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export const sortRowsWithUpOneLevel = (rows: GridRow[], sortOrder: string) => {
  return rows.sort((a, b) => {
    const bContainerName = b.container.containerName
    const aContainerName = a.container.containerName

    if (bContainerName === '..') {
      return 1
    } else if (sortOrder === SortOrder.ASC) {
      return aContainerName.localeCompare(bContainerName)
    } else {
      return bContainerName.localeCompare(aContainerName)
    }
  })
}

export const useGrid = ({
  fullPath,
  setFullPath,
  setWarehouseUrl,
  buckets,
  folders
}: Props) => {
  const { t } = useTranslation()

  const [sort, setSort] = useState<SortOrder>(SortOrder.ASC)

  const columns: GridColumns = [
    {
      key: 'container',
      title: t('common.name'),
      as: NameCell,
      mapValues: (row) => ({
        containerName: row.container.containerName,
        onClick: row.actions.onClick,
        upOneLevel: row.actions.upOneLevel
      }),
      headerClassName: classes['Buckets--NameHeader'],
      className: classes['Buckets--NameCell'],
      sortable: true,
      sortIconPosition: 'right'
    }
  ]

  const rows = useMemo<GridRow[]>(() => {
    const rowData = fullPath[0] ? ['..', ...folders] : buckets
    const newRows = rowData.map((containerName: string) => ({
      id: containerName,
      container: {
        containerName
      },
      actions: {
        onClick: () => {
          setWarehouseUrl(
            fullPath[0]
              ? `${fullPath.join('/')}/${containerName}`
              : `${containerName}`
          )
          setFullPath((prevPath) => [...prevPath, containerName])
        },
        upOneLevel: () => {
          setWarehouseUrl(
            fullPath.length > 1 ? `${fullPath.slice(0, -1).join('/')}` : ''
          )
          setFullPath((prevPath) => prevPath.slice(0, -1))
        }
      }
    }))

    return sortRowsWithUpOneLevel(newRows, sort)
  }, [folders, buckets, setWarehouseUrl, setFullPath, fullPath, sort])

  return { columns, rows, setSort, sort }
}
