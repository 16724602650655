import { useState, type ChangeEvent, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AutoComplete,
  Field,
  Icon,
  Typography,
  type AutoCompleteItem,
  type AutoCompleteItemId
} from '@matillion/component-library'
import { ServiceKey, useServiceUrl } from '@matillion/hub-client'

import {
  ParameterOverlayButton,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { type ParameterOptions } from 'modules/ParameterEditors/types'

import {
  getProjectExplorerLink,
  ProjectExplorerPaths
} from 'utils/getProjectExplorerLink'

import classes from './SecretReferenceEditor.module.scss'

export interface SecretReferenceEditorProps extends ParameterOptions {
  parameterName: string
  value: string[]
  onDone: (editedValue: string[]) => unknown
  allowFreetext?: boolean
}

export const SecretReferenceEditor: FC<SecretReferenceEditorProps> = ({
  value,
  parameterName,
  onDone,
  editorColumns,
  allowFreetext = true
}) => {
  const { t } = useTranslation()
  const projectExplorerOrigin = useServiceUrl(
    ServiceKey.projectExplorerFrontend,
    true
  )
  const { projectId } = useProjectInfo()
  const [secret, setSecret] = useState<AutoCompleteItemId | null>({
    name: value[0],
    id: value[0]
  })

  return (
    <>
      <ParameterOverlayHeader title={parameterName} />
      <Field
        id="secret"
        name="secret"
        aria-label={t('parameterEditor.SECRET_REFERENCE.title')}
        placeholder={t('parameterEditor.SECRET_REFERENCE.title')}
        title={t('parameterEditor.SECRET_REFERENCE.title')}
        inputComponent={AutoComplete}
        value={secret}
        availableItems={(editorColumns[0]?.options ?? []).map((option) => ({
          name: option,
          id: option
        }))}
        onChange={(e: ChangeEvent<AutoCompleteItem>) => {
          setSecret(e.target.value)
        }}
        allowFreetext={allowFreetext}
      />

      <div className={classes.SecretReferenceEditor__LinkContainer}>
        <a
          data-testid={`secret-reference-editor-${parameterName}-secret-link`}
          className={classes.SecretReferenceEditor__ManageLink}
          href={getProjectExplorerLink(
            projectExplorerOrigin,
            projectId,
            ProjectExplorerPaths.SECRET_DEFINITIONS
          )}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Icon.Lock />
          <Typography format="bcs">
            {t('parameterEditor.SECRET_REFERENCE.manage')}
          </Typography>
        </a>
      </div>
      <ParameterOverlayFooter>
        <ParameterOverlayButton
          onClick={() => onDone(secret ? [secret.name] : [])}
          text={t('common.save')}
        />
      </ParameterOverlayFooter>
    </>
  )
}
