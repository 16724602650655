import { useTranslation } from 'react-i18next'

import {
  Button,
  Icon,
  MicroCta,
  Toaster,
  Typography
} from '@matillion/component-library'

import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { type SampleComponentFailResponse } from 'api/hooks/useSampleComponent/types'
import { useSampleComponent } from 'api/hooks/useSampleComponent/useSampleComponent'
import { useSampleRowCount } from 'api/hooks/useSampleRowCount/useSampleRowCount'

import { ReactComponent as RotateRightIcon } from 'assets/rotate-right.svg'

import { type ComponentInstance } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { useComponentValidationResult } from 'modules/core/ComponentValidation'

import classes from './SampleComponent.module.scss'
import { SampleContent } from './SampleContent'
import { useSampleProvider } from './SampleProvider'

interface SampleComponentProps {
  componentInstance: ComponentInstance
  componentName: string
  jobType: JobType
}

export const SampleComponent = ({
  componentInstance,
  componentName,
  jobType
}: SampleComponentProps) => {
  const { t } = useTranslation()
  const { makeToast } = Toaster.useToaster()

  const {
    isError: isValidationError,
    isLoading: isValidating,
    isSuccess: isValidated,
    componentCache
  } = useComponentValidationResult(componentInstance.id)

  const {
    mutateAsync: sampleComponent,
    isLoading: isSampling,
    error: sampleErrorDetail,
    isError
  } = useSampleComponent(jobType)

  const {
    data: rowCount,
    mutateAsync: sampleRowCount,
    isLoading: isFetchingRowCount
  } = useSampleRowCount(jobType)

  const { setCurrentSample } = useSampleProvider()
  const { hasPermission } = useProjectPermission('sampling_pipelines')

  const isTransformationJob = jobType === JobType.Transformation
  const isLoading = isValidating || isSampling
  const isComponentValidated = isValidationError || isValidated
  const isDisabled =
    !isComponentValidated || isValidationError || !hasPermission
  const isRowCountDisabled = isDisabled || isFetchingRowCount

  const handleSample = () => {
    if (isTransformationJob && !componentCache) {
      return
    }

    const runSample = async () =>
      sampleComponent({ componentCache, componentInstance })

    runSample().then((response) => {
      /* istanbul ignore else */
      if (response) {
        setCurrentSample(response)
      }
    })
  }

  const handleRowCount = () => {
    const getRowCount = async () => {
      try {
        await sampleRowCount({ componentCache })
      } catch (error) {
        const rowCountError = error as SampleComponentFailResponse

        makeToast({
          type: 'error',
          title: t('sampleComponent.rowCountErrorTitle'),
          message: rowCountError?.detail ?? t('sampleComponent.rowCountError')
        })
      }
    }

    getRowCount()
  }

  return (
    <>
      <form className={classes.SampleComponentForm}>
        <div className={classes.SampleComponentForm__Container}>
          <Button
            data-testid="sample-button"
            className={classes.SampleComponentForm__SampleButton}
            size="sm"
            text={t('sampleComponent.sampleButtonText')}
            disabled={isDisabled}
            waiting={isLoading}
            onClick={handleSample}
          />
        </div>
        {jobType === JobType.Transformation && (
          <div className={classes.SampleComponentForm__Container}>
            <Typography format="bcs" data-testid="sample-row-count">
              {t('sampleComponent.totalRowCount', {
                rowCount: isFetchingRowCount ? '-' : rowCount ?? 0
              })}
            </Typography>
            <MicroCta
              data-testid="sample-row-count-button"
              className={classes.SampleComponentForm__SampleRowCountButton}
              aria-label={t('sampleComponent.rowCountButtonLabel')}
              alt="transparent"
              size="lg"
              disabled={isRowCountDisabled}
              onClick={handleRowCount}
            >
              {isFetchingRowCount ? <Icon.Loading /> : <RotateRightIcon />}
            </MicroCta>
          </div>
        )}
      </form>

      <SampleContent
        componentName={componentName}
        isLoading={isLoading}
        isComponentValidated={isComponentValidated}
        isValidationError={isValidationError}
        isSampleError={isError}
        sampleErrorDetail={sampleErrorDetail as SampleComponentFailResponse}
      />
    </>
  )
}
