import { type PropsWithChildren } from 'react'
import useGitQueryClient from '../useGitQueryClient'
import { QueryClientProvider } from '@tanstack/react-query'

const GitQueryClientProvider = ({ children }: PropsWithChildren) => {
  const queryClient = useGitQueryClient()

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

export default GitQueryClientProvider
