import {
  type GetRepositoriesActionProps,
  type GetRepositoriesActionResponse
} from './types'
import useGetRepositories from 'api/hooks/useGetRepositories'
import { useEffect } from 'react'
import useOAuthContext from 'provider/OAuthProvider/useOAuthContext'

const useGetRepositoriesAction = ({
  provider,
  canUseBYOG
}: GetRepositoriesActionProps): GetRepositoriesActionResponse => {
  const { setSuccessAction } = useOAuthContext()
  const { data, isLoading, fetchStatus, refetch } = useGetRepositories({
    provider,
    enabled: canUseBYOG && Boolean(provider)
  })

  useEffect(() => {
    setSuccessAction(refetch)
  }, [refetch, setSuccessAction])

  return {
    refetch,
    repositories: data?.repositories,
    loading: isLoading && fetchStatus !== 'idle'
  }
}

export default useGetRepositoriesAction
