import { useMemo } from 'react'

import { useFlags as useHubFlags } from '@matillion/hub-client'

export interface EtlFlags {
  [key: string]: boolean | string[]
  pipelineFolders: boolean
  enableAgentStackTrace: boolean
  enableAgentAvailabilityModal: boolean
  enableAddSchedule: boolean
  /** Enables both custom and flex connectors */
  enableCustomConnectors: boolean
  enableSelectAll: boolean
  enableExperimentalAiAutoDocs: boolean
  enableEosTaskHistory: boolean
  enableExpandedPipelineActionsExperiment: boolean
  enableWizardUiForComponents: boolean
  enableAddAllGridEditor: boolean
  enableRecentlyAccessedPipelines: boolean
  enableAutofillNestedPicker: boolean
}

export const useFlags = (): EtlFlags => {
  const hubFlags = useHubFlags()

  return useMemo(
    () => ({
      pipelineFolders: Boolean(hubFlags['pipeline-folders']),
      enableAgentStackTrace: Boolean(hubFlags['enable-agent-stack-trace']),
      enableAgentAvailabilityModal: Boolean(
        hubFlags['enable-agent-availability-modal']
      ),
      enableAddSchedule: Boolean(hubFlags['enable-add-schedule']),
      /** Enables both custom and flex connectors */
      enableCustomConnectors: Boolean(hubFlags['enable-custom-connectors']),
      enableSelectAll: Boolean(hubFlags['enable-select-all']),
      enableExperimentalAiAutoDocs: Boolean(
        hubFlags['enable-experimental-ai-auto-docs']
      ),
      enableEosTaskHistory: Boolean(hubFlags['enable-eos-task-history']),
      enableExpandedPipelineActionsExperiment: Boolean(
        hubFlags['enable-expanded-pipeline-actions-experiment']
      ),
      enableWizardUiForComponents: Boolean(
        hubFlags['enable-wizard-ui-for-components']
      ),
      enableAddAllGridEditor: Boolean(hubFlags['enable-add-all-grid-editor']),
      enableRecentlyAccessedPipelines: Boolean(
        hubFlags['enable-recently-accessed-pipelines']
      ),
      enableAutofillNestedPicker: Boolean(
        hubFlags['enable-autofill-nested-picker']
      ),
      enableCanvasAutoLayout: Boolean(hubFlags['enable-canvas-auto-layout']),

      ...hubFlags
    }),
    [hubFlags]
  )
}
