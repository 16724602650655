import { useMemo, type FunctionComponent, type PropsWithChildren } from 'react'
import { Provider } from 'react-redux'

import { useGetJob } from 'api/hooks/useGetJob/useGetJob'
import { useGetJobSummaries } from 'api/hooks/useGetJobSummaries'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { WorkingCopyContext } from './context'
import { useStore } from './useStore/useStore'

/**
 * useCurrentJob() is an internal working copy hook to resolve the job
 * it resolves the job from the URL's job id
 * it is important that the job and job summary maintain referential integrity
 * note that in some cases this is not guaranteed
 * for example the summary ID might change in the future as a result of renaming a pipeline
 **/
const useCurrentJob = () => {
  const { jobSummaryId: jobId } = useProjectInfo()
  const { data: summaries, ...summariesResponse } = useGetJobSummaries()

  const activeJobSummary = useMemo(() => {
    if (!Array.isArray(summaries?.results)) {
      return null
    }

    const summary = summaries?.results.find((s) => s.jobId === jobId)
    return summary ?? null
  }, [summaries, jobId])

  const jobResponse = useGetJob(jobId)

  return {
    job: jobResponse.data,
    summary: activeJobSummary,
    isLoading:
      summariesResponse.isInitialLoading || jobResponse.isInitialLoading,
    isError: summariesResponse.isError || jobResponse.isError
  }
}

const WorkingCopyProvider: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  const { job, summary, isLoading, isError } = useCurrentJob()

  const { store, undoManager } = useStore({
    job,
    summary
  })

  const value = useMemo(
    () => ({
      isLoading,
      isError,
      undoManager
    }),
    [isLoading, isError, undoManager]
  )

  return (
    <WorkingCopyContext.Provider value={value}>
      <Provider store={store}>{children}</Provider>
    </WorkingCopyContext.Provider>
  )
}

export default WorkingCopyProvider
