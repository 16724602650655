import keyBy from 'lodash/keyBy'

import { ParameterDataType } from 'api/hooks/useGetComponentMetadata/types'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import {
  type ElementCollection,
  type ValueCollection
} from 'job-lib/types/Parameters'

import { type ExpressionEntry } from './types'

export function blankEntry(elementSlot = 1): ExpressionEntry {
  return {
    name: '',
    expression: '',
    elementSlot
  }
}

export function init(elements: ElementCollection): ExpressionEntry[] {
  const existingEntries = convertFromElements(elements)
  if (!existingEntries.length) {
    existingEntries.push(blankEntry())
  }
  return existingEntries
}

export function convertFromElements(
  elements: ElementCollection
): ExpressionEntry[] {
  return Object.entries(elements).map(([, element]) => {
    return {
      elementSlot: element.slot,
      name: element.values[2]?.value || '',
      expression: element.values[1]?.value || ''
    }
  })
}

export function convertFromElementsAndSanitise(
  elements: ElementCollection,
  options: string[]
): ExpressionEntry[] {
  const existing = keyBy(convertFromElements(elements), 'name')

  return options.map((option) => {
    return {
      name: option,
      expression: existing[option]?.expression || '',
      elementSlot: existing[option]?.elementSlot
    }
  })
}

export function convertToElements(
  entries: ExpressionEntry[],
  editorColumns: EditorColumn[]
): ElementCollection {
  const expressionDataType = editorColumns[0]?.type || ParameterDataType.TEXT
  const nameDataType = editorColumns[1]?.type || ParameterDataType.TEXT

  const asValues: ValueCollection[] = entries.map((entry) => {
    return {
      1: {
        slot: 1,
        value: entry.expression,
        type: 'STRING',
        dataType: expressionDataType as ParameterDataType
      },
      2: {
        slot: 2,
        value: entry.name,
        type: 'STRING',
        dataType: nameDataType as ParameterDataType
      }
    }
  })

  const elementCollection: ElementCollection = {}

  asValues.forEach((values, index) => {
    const slot = index + 1

    elementCollection[slot] = {
      slot,
      values
    }
  })

  return elementCollection
}
