import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Icon, Tooltip } from '@matillion/component-library'

import { ReactComponent as ThemePickerIcon } from 'assets/color-picker.svg'

import { IconButton } from 'components/IconButton/IconButton'

import { jobActions } from 'job-lib/store'

import { EtlNoteInfoTooltipContent } from './EtlNoteInfoTooltipContent/EtlNoteInfoTooltipContent'
import { EtlNoteThemePicker } from './EtlNoteThemePicker/EtlNoteThemePicker'
import classes from './EtlNoteToolbar.module.scss'

interface EtlNoteToolbarProps {
  id: number
  selectedTheme?: string
  selectTemporaryTheme: (theme?: string) => void
  onDelete: () => void
}

export const EtlNoteToolbar = ({
  id,
  selectedTheme,
  selectTemporaryTheme,
  onDelete
}: EtlNoteToolbarProps) => {
  const dispatch = useDispatch()
  const [showThemePicker, setShowThemePicker] = useState(false)
  const { t } = useTranslation('translation', {
    keyPrefix: 'notes'
  })

  return (
    <>
      <Tooltip
        className={classes.EtlNoteToolbar__Tooltip}
        content={<EtlNoteInfoTooltipContent />}
      >
        <a
          href="https://commonmark.org/help/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton
            className={classes.EtlNoteToolbar__Button}
            hideTooltip
            label={t('markdown.label')}
          >
            <Icon.InfoNoBg />
          </IconButton>
        </a>
      </Tooltip>
      <IconButton
        onClick={() => {
          setShowThemePicker(!showThemePicker)
        }}
        className={classes.EtlNoteToolbar__Button}
        label={t('theme.label')}
        hideTooltip={showThemePicker}
      >
        <ThemePickerIcon />
        {showThemePicker && (
          <EtlNoteThemePicker
            selectedTheme={selectedTheme}
            selectTemporaryTheme={selectTemporaryTheme}
            selectTheme={(theme) => {
              setShowThemePicker(false)
              dispatch(jobActions.updateNote({ id, partialNote: { theme } }))
            }}
          />
        )}
      </IconButton>

      <IconButton
        onClick={() => {
          onDelete()
          dispatch(jobActions.deleteNote({ id }))
        }}
        className={classes.EtlNoteToolbar__Button}
        label={t('delete.label')}
      >
        <Icon.Trash />
      </IconButton>
    </>
  )
}
