import { rest, type RequestHandler } from 'msw'

import config from 'config'

import {
  type SampleComponentFailResponse,
  type SampleComponentResponse
} from '../types'
import mockSampleComponentResponse from './mockNewSampleComponentResponse.json'

const createSampleComponentHandler = (
  mockSampleResponse: SampleComponentResponse | SampleComponentFailResponse,
  statusCode = 200
): RequestHandler[] => {
  return [
    rest.post(
      `${config.getApiBaseUrl()}/:version/sample/:jobType`,
      async (req, res, ctx) => {
        const request = await req.json()

        if (req.params.jobType === 'orchestration' && !request.component) {
          return res(ctx.status(400))
        }

        if (
          req.params.jobType === 'transformation' &&
          !request.componentCache
        ) {
          return res(ctx.status(400))
        }

        const response: SampleComponentResponse | SampleComponentFailResponse =
          mockSampleResponse

        return res(ctx.status(statusCode), ctx.json(response))
      }
    )
  ]
}
const useSampleComponentHandler = createSampleComponentHandler(
  mockSampleComponentResponse
)

const failedResponse: SampleComponentFailResponse = {
  detail: 'Error encountered attempting to sample transformation component',
  status: 500,
  title: 'Application error',
  type: 'designer/invalid-sample-request'
}

const useSampleComponentFailHandler = createSampleComponentHandler(
  failedResponse,
  500
)

const emptyRowResponse: SampleComponentResponse = {
  metadata: mockSampleComponentResponse.metadata,
  rows: []
}
const useSampleComponentNoResultsHandler =
  createSampleComponentHandler(emptyRowResponse)

const undefinedRowResponse: SampleComponentResponse = {
  metadata: mockSampleComponentResponse.metadata,
  rows: [{ values: undefined }, { values: ['Value 1', 'Value 2'] }]
}

const useSampleComponentUndefinedResultsHandler =
  createSampleComponentHandler(undefinedRowResponse)

const useSampleComponentLimitHandler: RequestHandler[] = [
  rest.post(`${config.getApiBaseUrl()}/v2/sample/*`, async (req, res, ctx) => {
    const request = await req.json()
    const { limit } = request as Record<string, number>

    const response: SampleComponentResponse = {
      metadata: mockSampleComponentResponse.metadata,
      rows: Array(limit).fill(mockSampleComponentResponse.rows[0])
    }

    return res(ctx.status(200), ctx.json(response))
  })
]

export const useSampleComponentHandlers = [
  ...useSampleComponentHandler,
  ...useSampleComponentFailHandler,
  ...useSampleComponentNoResultsHandler,
  ...useSampleComponentUndefinedResultsHandler
]

export {
  useSampleComponentHandler,
  useSampleComponentFailHandler,
  useSampleComponentNoResultsHandler,
  useSampleComponentUndefinedResultsHandler,
  useSampleComponentLimitHandler,
  mockSampleComponentResponse
}
