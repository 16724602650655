import { type ParameterDataType } from 'api/hooks/useGetComponentMetadata/types'

export type ParameterSlot = number

/**
 * Parameters on job's components are special, the label is held in
 * the first param's first elements's first value's value
 */
export type ParameterCollection = Record<ParameterSlot, Parameter>

export const dplParamsKey = 'dplParams' as const

export interface Parameter<Elements = ElementCollection> {
  slot: ParameterSlot
  /* name of the parameter */
  name: string
  elements: Elements
  visible: boolean
  status?: Record<EnvironmentId, ValidationStatus>
  columnNames?: string[]
}

export type NameParameter = Parameter<
  ElementCollection & {
    1: { slot: 1 } & Element<
      ValueCollection & {
        1: { slot: 1 } & Value
      }
    >
  }
>

export type EnvironmentId = number

export interface ValidationStatus {
  environmentID: EnvironmentId
  validationState: ValidationState
  validationMessage: string
}

export enum ValidationState {
  INPUT_REQUIRED = 'INPUT_REQUIRED',
  OK = 'OK',
  PARAMS_ERROR = 'PARAMS_ERROR',
  INPUT_ERROR = 'INPUT_ERROR'
}

export type ElementSlot = number

export type ElementCollection = Record<ElementSlot, Element>

export interface Element<Values = ValueCollection> {
  slot: ElementSlot
  values: Values
  elements?: ElementCollection
}

export type ValueSlot = number

export type ValueCollection = Record<ValueSlot, Value>

export type ElementValueType =
  | 'STRING'
  | 'INTEGER'
  | 'PASSWORD'
  | 'BIGINT'
  | 'DPL_PARAMS'

export interface Value {
  slot: ValueSlot
  /** @deprecated This field is probably unused, prefer dataType */
  type: ElementValueType
  value: string
  dataType: ParameterDataType
}
