import { useTranslation } from 'react-i18next'
import useGitContext from '../../provider/GitProvider/useGitContext'
import { Toaster } from '@matillion/component-library'
import useDeleteBranch from '../../api/hooks/useDeleteBranch'
import { type HardResetActionArgs, type HardResetActionResponse } from './types'
import { useCallback, useState } from 'react'
import { type ReactQueryMeta } from '../../types/ReactQueryMeta'

const hardResetQueryMeta: ReactQueryMeta = {
  error: {
    title: 'hard-reset.toast.title.failure',
    message: 'hard-reset.toast.message.failure'
  }
}

const useHardResetBranchAction = ({
  onSuccess,
  onFailure,
  onResolve
}: HardResetActionArgs = {}): HardResetActionResponse => {
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation('translation', {
    keyPrefix: 'hard-reset.toast'
  })
  const { branch: branchId } = useGitContext()
  const { makeToast } = Toaster.useToaster()
  const { mutateAsync: deleteBranch } = useDeleteBranch({
    meta: hardResetQueryMeta
  })

  const hardResetBranch = useCallback(async () => {
    try {
      setIsLoading(true)
      await deleteBranch()
      makeToast({
        type: 'success',
        title: t('title.success'),
        message: t('message.success', { branch: branchId })
      })
      onSuccess?.()
    } catch (error) {
      onFailure?.()
    } finally {
      setIsLoading(false)
      onResolve?.()
    }
  }, [deleteBranch, makeToast, t, branchId, onSuccess, onFailure, onResolve])

  return {
    hardResetBranch,
    loading: isLoading
  }
}

export default useHardResetBranchAction
