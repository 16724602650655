import { useQuery } from '@tanstack/react-query'

import {
  type GetBranchMetadataResponse,
  type GetBranchRequest
} from 'api/hooks/useGetBranch/types'
import { queryKeys } from 'api/query-keys'
import useWorkingTreeClient from 'api/clients/useWorkingTreeClient'
import useGitContext from 'provider/GitProvider/useGitContext'
import { useCallback } from 'react'

export const useGetBranchQueryKey = (
  branch?: string,
  remoteBranch?: string
) => {
  const { project } = useGitContext()
  return [
    queryKeys.legacyMerge,
    project,
    branch,
    remoteBranch,
    queryKeys.getBranch
  ]
}

const useGetBranch = ({
  branch,
  remoteBranch,
  config,
  meta
}: GetBranchRequest) => {
  const { project } = useGitContext()
  const client = useWorkingTreeClient()

  const getBranch = useCallback(async () => {
    let url = `/projects/${project}/branches/${branch}`

    if (remoteBranch) {
      url += `?remoteBranchName=${remoteBranch}`
    }

    const { data } = await client.get<GetBranchMetadataResponse>(url)
    return data
  }, [branch, client, project, remoteBranch])

  const queryKey = useGetBranchQueryKey(branch, remoteBranch)

  return useQuery(queryKey, getBranch, {
    ...config,
    meta: {
      error: {
        title: 'api.toast.get-branch.title',
        message: 'api.toast.get-branch.message'
      },
      ...meta
    }
  })
}

export default useGetBranch
