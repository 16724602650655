import { useEffect, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { datadogLogs } from '@datadog/browser-logs'
import { Button } from '@matillion/component-library'
import { ServiceKey, useServiceUrl } from '@matillion/hub-client'
import axios from 'axios'
import differenceInSeconds from 'date-fns/differenceInSeconds'

import { useAgentDetails } from 'api/hooks/useAgentDetails/useAgentDetails'

import { ReactComponent as ConnectionIssueIcon } from 'assets/connection-issue.svg'

import { ErrorPage } from 'components/ErrorPage/ErrorPage'

let timeoutSeconds = 5

if ('Cypress' in window) {
  timeoutSeconds = 1
}

const TIMEOUT = timeoutSeconds

export const AgentStatus: FC = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'agentStatus'
  })
  const projectExplorerUrl = useServiceUrl(ServiceKey.projectExplorerFrontend)
  const {
    data: agentStatus,
    isLoading,
    isFetching,
    refetch
  } = useAgentDetails()
  const [buttonEnabled, setButtonEnabled] = useState(false)

  useEffect(() => {
    if (!agentStatus || isLoading) {
      return
    }

    // if the agent is available, then we don't need to do anything
    if (agentStatus?.isAvailable) {
      return
    }

    // check every 500ms if it's been at least 5 seconds since the last fetch
    const interval = setInterval(() => {
      // difference in seconds defaults to truncating the number
      // so if it's been 5.123456 seconds we would get the number 5 back
      const secondsSinceLastFetch = differenceInSeconds(
        new Date(),
        agentStatus.lastFetchTime
      )

      setButtonEnabled(secondsSinceLastFetch >= TIMEOUT)
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [agentStatus, isLoading])

  useEffect(() => {
    if (!agentStatus?.isAvailable && !isLoading) {
      const err = agentStatus?.agentError
      const agentId = agentStatus?.agentId ?? 'unknown'
      const axiosError = axios.isAxiosError(err) ? err : undefined

      datadogLogs.logger.error(
        `Agent (${agentId}) is not available for this user`,
        {
          status: agentStatus?.status,
          agentErrorMessage:
            err && typeof err === 'object' && 'message' in err
              ? err?.message
              : err,
          axiosError: {
            message: axiosError?.message,
            status: axiosError?.response?.status,
            statusText: axiosError?.response?.statusText,
            data: axiosError?.response?.data
          }
        }
      )
    }
  }, [agentStatus, isLoading])

  if (isLoading || agentStatus?.isAvailable) {
    return null
  }

  return (
    <ErrorPage
      overlay
      title={t('title')}
      icon={<ConnectionIssueIcon />}
      actions={
        <>
          <a
            href={projectExplorerUrl}
            data-testid="monitor-agent-status-back-to-projects"
          >
            <Button as="span" text={t('escapeLinkText')} alt="secondary" />
          </a>
          <Button
            onClick={() => {
              refetch()
              setButtonEnabled(false)
            }}
            waiting={isFetching}
            disabled={!buttonEnabled}
            text={t('tryAgainLinkText')}
            alt="primary"
          />
        </>
      }
    >
      <p>
        This could be due to a connection issue or because your agent is being
        updated. Please check that your agent is running and try again.
      </p>
    </ErrorPage>
  )
}
