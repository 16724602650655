import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react'
import { OAuthContext } from './OAuthContext'
import { noOpAuthAction, type OAuthAction, type OAuthContextBag } from './types'
import GitProviderAuthModal from 'modules/BringYourOwnGit/components/GitProviderAuthModal'
import AuthSuccessModal from 'modules/BringYourOwnGit/components/AuthSuccessModal/AuthSuccessModal'
import GitQueryClientProvider from 'api/provider/GitQueryClientProvider'
import AuthMessageListener from 'modules/BringYourOwnGit/components/AuthMessageListener/AuthMessageListener'

const OAuthProvider = ({ children }: PropsWithChildren) => {
  const [showAuthModal, setShowAuthModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const [modalHelpText, setModalHelpText] = useState<string>()
  const [closeAction, setCloseAction] = useState<OAuthAction>(noOpAuthAction)
  const [successAction, setSuccessAction] =
    useState<OAuthAction>(noOpAuthAction)

  const handleAuthSuccess = useCallback(async () => {
    await successAction()
    setShowAuthModal(false)
    setShowSuccessModal(true)
    setSuccessAction(noOpAuthAction)
  }, [successAction])

  const handleAuthError = useCallback(async () => {
    setShowAuthModal(false)
    setSuccessAction(noOpAuthAction)
    setCloseAction(noOpAuthAction)
  }, [])

  const handleAuthRequired = useCallback(() => {
    setShowAuthModal(true)
  }, [])

  const handleCloseAuthModal = useCallback(async () => {
    await closeAction()
    setShowAuthModal(false)
    setModalHelpText(undefined)
    setCloseAction(noOpAuthAction)
  }, [closeAction])

  const setAction = (
    dispatch: Dispatch<SetStateAction<OAuthAction>>,
    action: OAuthAction
  ) => {
    // We must use a nested anonymous function when setting functions in react state hooks.
    dispatch(() => action)
  }

  const values: OAuthContextBag = useMemo(
    () => ({
      onAuthRequired: handleAuthRequired,
      setSuccessAction: (callback) => {
        setAction(setSuccessAction, callback)
      },
      setCloseAction: (callback) => {
        setAction(setCloseAction, callback)
      },
      setModalHelpText
    }),
    [handleAuthRequired]
  )

  return (
    <OAuthContext.Provider value={values}>
      <GitQueryClientProvider>
        <AuthMessageListener
          provider={'github'} // <--- TODO: Where are we sourcing this from?
          onAuthError={handleAuthError}
          onAuthSuccess={handleAuthSuccess}
        />

        {showAuthModal && (
          <GitProviderAuthModal
            provider={'github'} // <--- TODO: Where are we sourcing this from?
            onClose={handleCloseAuthModal}
            additionalInfo={modalHelpText}
          />
        )}

        {showSuccessModal && (
          <AuthSuccessModal
            provider={'github'} // <--- TODO: Where are we sourcing this from?
            onClose={() => {
              setShowSuccessModal(false)
            }}
          />
        )}

        {children}
      </GitQueryClientProvider>
    </OAuthContext.Provider>
  )
}

export default OAuthProvider
