import { type RequestHandler, rest } from 'msw'
import { type EntitlementsResponse } from './types'
import {
  useEntitlementsResponseHasAccess,
  useEntitlementsResponseNoAccess
} from './useEntitlements.responses'

const uri = '/v1/entitlements'

const stubEntitlements = (response: EntitlementsResponse) => {
  return rest.get(uri, async (_req, res, ctx) => {
    return res(ctx.json<EntitlementsResponse>(response))
  })
}

export const useEntitlementsHandlersHasAccess: RequestHandler[] = [
  stubEntitlements(useEntitlementsResponseHasAccess)
]

export const useEntitlementsHandlersNoAccess: RequestHandler[] = [
  stubEntitlements(useEntitlementsResponseNoAccess)
]

export const useEntitlementsHandlersFailure: RequestHandler[] = [
  rest.get(uri, async (_req, res, ctx) => {
    return res(ctx.status(500))
  })
]
