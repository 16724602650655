import { ZoomToolbar } from 'modules/Canvas/components/ZoomToolbar/ZoomToolbar'

import classes from './Taskbar.module.scss'

export const Taskbar = () => {
  return (
    <div className={classes.Taskbar} data-testid="taskbar-zoom-controls">
      <ZoomToolbar />
    </div>
  )
}
