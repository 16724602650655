import { ApplicationLocation } from '../types'
import getCurrentLocation from './getCurrentLocation'
import { parseHost } from './parseHost'

/**
 * Returns the current organisation subdomain and origin from `window.location`.
 * Also returns the `rootOrigin`, which is this application's origin without
 * an organisation subdomain.
 *
 * If no organisation subdomain exists in the current URL, `org` will be `undefined`.
 *
 * The anatomy of a Matillion application hostname is as follows:
 * ```<organisation>.<application>.matillion.com```
 */
const getAppURL = (): ApplicationLocation => {
  const { hostname, origin } = getCurrentLocation()
  const { region, subdomain } = parseHost(hostname)
  const rootOrigin = subdomain ? origin.replace(`${subdomain}.`, '') : origin
  return {
    origin,
    rootOrigin,
    organisation: subdomain,
    region
  }
}

export default getAppURL
