import { useMemo, useState, type FC } from 'react'

import { Button, CollapsiblePanel } from '@matillion/component-library'

import {
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { type ComponentInstance } from 'job-lib/types/Job'

import { type EditedParameter } from 'modules/ComponentParameters/ComponentParametersContainer'
import { useComponentValidationProvider } from 'modules/core/ComponentValidation'

import { ComponentParameterItem } from '../ComponentParameterItem/ComponentParameterItem'
import classes from './ComponentConfigurationPanel.module.scss'
import type {
  ComponentLayout,
  WizardStep,
  WizardStepParameter
} from './wizardConfigs/types'

interface ComponentConfigurationPanelProps {
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  componentSummaryId: ComponentSummaryId
  onEdit: (params: EditedParameter, editorColumns?: EditorColumn[]) => void
  wizardConfig: ComponentLayout
}

const lookForParameter = (
  dplId: string,
  parameters: ComponentParameter[],
  parameterPath: string[]
): [ComponentParameter | null, string[]] => {
  let foundParameter = null

  for (const parameter of parameters) {
    if (parameter.dplID === dplId) {
      foundParameter = parameter
      break
    }

    if (parameter.childProperties) {
      const newPath = [parameter.dplID, ...parameterPath]
      const [foundChildParameter, childPath] = lookForParameter(
        dplId,
        parameter.childProperties,
        newPath
      )
      if (foundChildParameter) {
        return [foundChildParameter, childPath]
      }
    }
  }

  return [foundParameter, parameterPath]
}

const getStepParameters = (
  parameters: WizardStepParameter[],
  componentMetadata: ComponentMetadata
): Array<[ComponentParameter | null, string[]]> => {
  const stepParameters = parameters.map((parameter) => {
    const topLevelParameter = componentMetadata.parameters.find(
      (p) => p.dplID === parameter.dplId
    )

    if (topLevelParameter) {
      return [topLevelParameter, []] as [ComponentParameter | null, string[]]
    }

    return lookForParameter(parameter.dplId, componentMetadata.parameters, [])
  })

  return stepParameters
}

interface StepsConfig {
  [key: string]: {
    stepId: string
    parameters: Array<[ComponentParameter | null, string[]]>
  }
}

export const ComponentConfigurationPanel: FC<
  ComponentConfigurationPanelProps
> = ({
  componentInstance,
  componentMetadata,
  componentSummaryId,
  onEdit,
  wizardConfig
}) => {
  const [currentStep, setCurrentStep] = useState<WizardStep | null>(
    wizardConfig.wizard.steps[0]
  )

  const { setValidationEnabled } = useComponentValidationProvider()

  const stepsConfig: StepsConfig = useMemo(() => {
    return wizardConfig.wizard.steps.reduce((acc: StepsConfig, step) => {
      acc[step.stepId] = {
        stepId: step.stepId,
        parameters: getStepParameters(step.parameters, componentMetadata)
      }
      return acc
    }, {})
  }, [componentMetadata, wizardConfig.wizard.steps])

  const componentNameParameter = componentMetadata.parameters.find(
    (p) => p.dplID === 'componentName'
  )

  return (
    <div
      className={classes.ComponentConfigurationPanel__Container}
      data-testid="wizard-configuration-container"
    >
      {componentNameParameter ? (
        <ComponentParameterItem
          key={'parameter-componentName'}
          parameter={componentNameParameter}
          componentSummaryId={componentSummaryId}
          componentInstance={componentInstance}
          componentMetadata={componentMetadata}
          onEdit={onEdit}
        />
      ) : null}

      <div className={classes.ComponentConfigurationPanel__Accordions}>
        {wizardConfig.wizard.steps.map((step) => {
          return (
            <div data-testid={`wizard-step-${step.stepId}`} key={step.stepId}>
              <CollapsiblePanel
                theme="white"
                title={step.displayName}
                subtitle={' '}
                open={currentStep?.stepId === step.stepId}
                className={classes.ComponentConfigurationPanel__Accordion}
                contentClassName={
                  classes.ComponentConfigurationPanel__Accordions__Content
                }
              >
                {stepsConfig[step.stepId].parameters.map(
                  ([parameter, path]) => {
                    const omitParameters = wizardConfig.wizard.steps
                      .find((s) => s.stepId === step.stepId)
                      ?.parameters.find(
                        (p) => p.dplId === parameter?.dplID
                      )?.omitParameters

                    return (
                      parameter && (
                        <ComponentParameterItem
                          key={`parameter-${parameter.dplID}`}
                          parameter={parameter}
                          componentSummaryId={componentSummaryId}
                          componentInstance={componentInstance}
                          componentMetadata={componentMetadata}
                          onEdit={onEdit}
                          omitParameters={omitParameters}
                          parameterPath={path}
                        />
                      )
                    )
                  }
                )}

                <div className={classes.ComponentConfigurationPanel__Controls}>
                  {step.stepId === currentStep?.stepId &&
                    (step.nextStepId ? (
                      <Button
                        text="Next"
                        onClick={() => {
                          setCurrentStep(
                            (st) =>
                              wizardConfig.wizard.steps.find(
                                (s) => s.stepId === st?.nextStepId
                              ) ?? null
                          )
                        }}
                      />
                    ) : (
                      <Button
                        text="Finish"
                        onClick={() => {
                          setValidationEnabled()
                        }}
                      />
                    ))}
                </div>
              </CollapsiblePanel>
            </div>
          )
        })}
      </div>
    </div>
  )
}
