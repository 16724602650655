import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'

import useCommit from 'api/hooks/useCommit/useCommit'
import useProblemDetails from 'hooks/useProblemDetails'
import { type CommitActionArgs, type CommitActionResult } from './types'
import { type ProblemDetailRegistry } from '../useProblemDetails/types'
import useGitContext from 'provider/GitProvider/useGitContext'
import { Problem } from 'hooks/useProblemDetails/Problem'

const commonFailureTitleKey = 'api.toast.commit.title'

const problemDetailsRegistry: ProblemDetailRegistry = [
  {
    type: Problem.WTS_COMMIT_MESSAGE_TOO_LONG,
    translation: {
      titleKey: commonFailureTitleKey,
      messageKey: 'commit.problem.message-length'
    }
  },
  {
    type: Problem.WTS_NO_CHANGES_TO_COMMIT,
    translation: {
      titleKey: commonFailureTitleKey,
      messageKey: 'commit.problem.no-changes'
    }
  },
  {
    type: Problem.WTS_NOTHING_TO_COMMIT,
    translation: {
      titleKey: commonFailureTitleKey,
      messageKey: 'commit.problem.no-changes'
    }
  },
  {
    type: Problem.WTS_LOCAL_BRANCH_BEHIND,
    translation: {
      titleKey: commonFailureTitleKey,
      messageKey: 'commit.problem.branch-behind'
    }
  },
  {
    type: Problem.WTS_BRANCH_NOT_FOUND,
    translation: {
      titleKey: commonFailureTitleKey,
      messageKey: 'commit.problem.branch-not-found'
    }
  }
]

const useCommitAction = ({
  commitMessage,
  onSuccess,
  onFailure
}: CommitActionArgs): CommitActionResult => {
  const { t } = useTranslation('translation', { keyPrefix: 'commit.toast' })
  const { mutateAsync: doCommit } = useCommit()
  const { makeToast } = Toaster.useToaster()
  const [isLoading, setIsLoading] = useState(false)
  const { resolveProblemDetails } = useProblemDetails(problemDetailsRegistry)
  const { isNativeGit } = useGitContext()

  const commit = useCallback(async () => {
    if (!isLoading) {
      setIsLoading(true)

      try {
        const response = await doCommit(commitMessage)

        makeToast({
          type: 'success',
          title: t('success.title'),
          message: t(`success.message${isNativeGit ? 'NativeGit' : ''}`)
        })

        await onSuccess(response.commitId)
      } catch (error) {
        resolveProblemDetails(error)
        onFailure()
      }
      setIsLoading(false)
    }
  }, [
    isLoading,
    doCommit,
    commitMessage,
    makeToast,
    t,
    isNativeGit,
    onSuccess,
    resolveProblemDetails,
    onFailure
  ])

  return { isLoading, commit }
}

export default useCommitAction
