import { type TranslationArg } from '../useProblemDetails/types'
import { type $Dictionary } from 'i18next/typescript/helpers'
import { type TranslationInterpolationResponse } from './types'
import { useCallback } from 'react'

const useTranslationInterpolation = (): TranslationInterpolationResponse => {
  const computeInterpolationMap = useCallback(
    (messageArgs?: TranslationArg[]): $Dictionary => {
      const reduceArgs = (
        map: $Dictionary,
        { name, value }: TranslationArg
      ) => {
        map[name] = value
        return map
      }

      return messageArgs?.reduce<$Dictionary>(reduceArgs, {}) ?? {}
    },
    []
  )

  return {
    computeInterpolationMap
  }
}

export default useTranslationInterpolation
