import { useTranslation } from 'react-i18next'

import { Alert, Button } from '@matillion/component-library'

import classes from './BehindRemoteForm.module.scss'
import { type BehindRemoteFormProps } from 'components/BehindRemoteForm/types'
import useGitContext from '../../provider/GitProvider/useGitContext'
import React from 'react'
import ModalTitle from 'components/ModalTitle'

const BehindRemoteForm = ({
  loading,
  onPull,
  onCancel
}: BehindRemoteFormProps) => {
  const { branch } = useGitContext()
  const { t } = useTranslation('translation', {
    keyPrefix: 'commit.behind'
  })

  return (
    <div data-testid="behind-remote-form">
      <ModalTitle title={t('title')} data-testId="behind-remote-form-title" />

      <Alert
        theme="dark"
        type="warning"
        className={classes.Alert}
        message={t('warning', { branch })}
        data-testid="behind-remote-warning"
      />

      <div className={classes.Footer}>
        <Button
          id="cancel"
          alt="secondary"
          onClick={onCancel}
          text={t('cancel')}
          data-testid="behind-remote-cancel"
        />

        <Button
          id="commit"
          alt="positive"
          onClick={onPull}
          text={t('submit')}
          waiting={loading}
          data-testid="behind-remote-submit"
        />
      </div>
    </div>
  )
}

export default BehindRemoteForm
