import { type OrchestrationJob } from 'job-lib/types/Job'

export const createEmptyOrchestrationJob = (): OrchestrationJob => {
  return {
    components: {},
    failureConnectors: {},
    falseConnectors: {},
    iterationConnectors: {},
    revision: 0,
    successConnectors: {},
    trueConnectors: {},
    unconditionalConnectors: {}
  }
}
