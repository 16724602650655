import { useTranslation } from 'react-i18next'

import { Loading } from 'components/Loading/Loading'
import { EmptyPanel } from 'components/Panels/EmptyPanel'

import { useAvailableComponents } from 'hooks/useAvailableComponents'

import { ComponentSummaryList } from './components/ComponentSummaryList/ComponentSummaryList'
import classes from './ComponentSummaries.module.scss'

const ComponentSummaries = () => {
  const { components, isLoading, isError } = useAvailableComponents()
  const { t } = useTranslation()

  if (isLoading) {
    return <Loading className={classes.Loading} />
  }

  if (isError) {
    return (
      <EmptyPanel>
        {t('translation:sideBar.componentPanel.networkError')}
      </EmptyPanel>
    )
  }

  if (!Object.keys(components).length) {
    return (
      <EmptyPanel>
        {t('translation:sideBar.componentPanel.noJobSelected')}
      </EmptyPanel>
    )
  }

  return <ComponentSummaryList componentSummaries={components} isDraggable />
}

export { ComponentSummaries }
