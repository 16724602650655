import { type ComponentInstance } from 'job-lib/types/Job'

/**
 * Get the label for a component
 * Components have at least 1 parameter (index "1"), the value of that parameter is the label to show on the canvas
 *
 * @returns string
 */
export const getComponentLabel = (component: ComponentInstance) => {
  try {
    return component.parameters[1].elements[1].values[1].value
  } catch (err) {
    if (err instanceof TypeError) {
      console.error(`${component.id}: missing label parameter`)
    }
    throw err
  }
}
