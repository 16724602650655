import { type CaseReducer, type PayloadAction } from '@reduxjs/toolkit'

import { type JobVariable } from 'job-lib/types/Variables'

import { type JobState } from '../../job.types'

export const updateJobVariable: CaseReducer<
  JobState,
  PayloadAction<{
    oldName: string
    updatedVariable: JobVariable
  }>
> = (state, { payload: { oldName, updatedVariable } }) => {
  const { jobType, job } = state

  if (jobType !== null && job !== null) {
    delete job.variables?.[oldName]

    job.variables = {
      ...job.variables,
      [updatedVariable.definition.name]: updatedVariable
    }
  }

  return state
}
