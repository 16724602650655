import { useUser } from '@matillion/hub-client'

import {
  useGetJobSummaries,
  type JobSummaries
} from 'api/hooks/useGetJobSummaries'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useProjectNames } from 'hooks/useProjectInfo/useProjectNames'

interface AccountsAndPipelines {
  [accountId: string]: PipelineItem[]
}
export interface PipelineItem {
  accountId: string
  projectId: string
  projectName: string | undefined
  warehouse: string | undefined
  branchName: string
  environmentName: string
  pipelineFolder: string | undefined
  pipelineId: string
  pipelineName: string | undefined
  pipelineType: string | undefined
  timeAccessed: number
  url: string
}
export interface RenamedPipeline {
  pipelineId: string
  pipelineName: string
}

const getPipelinesFromCookie = () => {
  let recentPipelines: AccountsAndPipelines = {}

  try {
    const cookieData = document.cookie
    if (cookieData) {
      const cookieArray = cookieData.split(';')
      for (const cookie of cookieArray) {
        const [cookieName, cookieValue] = cookie.split('=').map((c) => c.trim())
        if (cookieName === 'recentlyAccessedPipelines') {
          recentPipelines = JSON.parse(decodeURIComponent(cookieValue))
          break
        }
      }
    }
  } catch (error) {
    recentPipelines = {}
  }

  if (!recentPipelines || Object.keys(recentPipelines).length === 0) {
    recentPipelines = {}
  }
  return recentPipelines
}

const setCookie = (cookieContent: AccountsAndPipelines, accountId: string) => {
  Object.keys(cookieContent).forEach((key) => {
    if (key !== accountId) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete cookieContent[key]
    }
  })

  const pipelineCookieContent = encodeURIComponent(
    JSON.stringify(cookieContent)
  )

  const expirationDate = new Date()
  expirationDate.setDate(expirationDate.getDate() + 30)

  const cookieDomain = window.location.hostname.split('.').slice(-2).join('.')

  document.cookie = `recentlyAccessedPipelines=${pipelineCookieContent}; expires=${expirationDate.toUTCString()}; domain=${cookieDomain}; path=/;`
}

export const useRecentlyAccessedPipelines = () => {
  const {
    jobSummaryId: pipelineId,
    projectId,
    branchId: branchName
  } = useProjectInfo()
  const { projectName, warehouse, environmentName } = useProjectNames()
  const { organisation } = useUser()
  const { data: allPipelines } = useGetJobSummaries()

  const accountId = organisation.id

  const findPipelineById = (pipelines: JobSummaries, id: string) =>
    pipelines.results.find((p) => p.jobId === id)

  const updateRecentPipelines = (
    recentPipelines: AccountsAndPipelines,
    accessedPipeline: PipelineItem
  ) => {
    if (!recentPipelines[accessedPipeline.accountId]) {
      recentPipelines[accessedPipeline.accountId] = [accessedPipeline]
    } else {
      const pipelineIndex = recentPipelines[
        accessedPipeline.accountId
      ].findIndex(
        (item) =>
          item.pipelineId === accessedPipeline.pipelineId &&
          item.projectId === accessedPipeline.projectId &&
          item.branchName === accessedPipeline.branchName &&
          item.environmentName === accessedPipeline.environmentName
      )

      if (pipelineIndex !== -1) {
        recentPipelines[accessedPipeline.accountId].splice(pipelineIndex, 1)
      }
      recentPipelines[accessedPipeline.accountId].unshift(accessedPipeline)
    }

    if (recentPipelines[accessedPipeline.accountId].length > 3) {
      recentPipelines[accessedPipeline.accountId].splice(3)
    }
  }

  const storeRecentlyAccessed = () => {
    if (!allPipelines) {
      return
    }

    const pipeline = findPipelineById(allPipelines, pipelineId)

    if (!pipeline) {
      return
    }

    const {
      name: pipelineName,
      type: pipelineType,
      folder: pipelineFolder
    } = pipeline

    if (
      accountId &&
      projectName &&
      projectId &&
      branchName &&
      pipelineId &&
      environmentName
    ) {
      const accessedPipeline = {
        accountId,
        projectId,
        projectName,
        branchName,
        pipelineName,
        pipelineType,
        pipelineFolder,
        pipelineId,
        warehouse,
        environmentName,
        timeAccessed: Date.now(),
        url: window.location.href
      }

      const recentPipelines = getPipelinesFromCookie()
      updateRecentPipelines(recentPipelines, accessedPipeline)
      setCookie(recentPipelines, accountId)
    }
  }

  const renameRecentlyAccessed = ({
    previousPipelineId,
    newPipelineId
  }: {
    previousPipelineId: string
    newPipelineId: string
  }) => {
    const recentPipelines = getPipelinesFromCookie()

    if (recentPipelines[accountId]) {
      const updatedPipelines = recentPipelines[accountId].map(
        (item: PipelineItem) => {
          if (item.pipelineId === previousPipelineId) {
            const newUrl = item.url.replace(
              `/job/${encodeURIComponent(previousPipelineId)}`,
              `/job/${encodeURIComponent(newPipelineId)}`
            )
            return {
              ...item,
              pipelineId: newPipelineId,
              url: newUrl
            }
          }
          return item
        }
      )

      recentPipelines[accountId] = updatedPipelines
    }

    setCookie(recentPipelines, accountId)
  }

  const removeRecentlyAccessed = (removedPipelineId: string) => {
    const recentPipelines = getPipelinesFromCookie()
    /* istanbul ignore else */
    if (recentPipelines[accountId]) {
      const updatedPipelines = recentPipelines[accountId].filter(
        (item: PipelineItem) => item.pipelineId !== removedPipelineId
      )

      recentPipelines[accountId] = updatedPipelines
    }

    setCookie(recentPipelines, accountId)
  }

  const moveRecentlyAccessed = ({
    previousPipelineId,
    newPipelineId,
    newPipelineFolder
  }: {
    previousPipelineId: string
    newPipelineId: string
    newPipelineFolder: string
  }) => {
    const recentPipelines = getPipelinesFromCookie()
    /* istanbul ignore else */
    if (recentPipelines[accountId]) {
      const updatedPipelines = recentPipelines[accountId].map(
        (item: PipelineItem) => {
          if (item.pipelineId === previousPipelineId) {
            const newUrl = item.url.replace(
              `/job/${encodeURIComponent(previousPipelineId)}`,
              `/job/${encodeURIComponent(newPipelineId)}`
            )
            return {
              ...item,
              pipelineId: newPipelineId,
              pipelineFolder: newPipelineFolder,
              url: newUrl
            }
          }
          return item
        }
      )

      recentPipelines[accountId] = updatedPipelines
    }

    setCookie(recentPipelines, accountId)
  }

  return {
    storeRecentlyAccessed,
    renameRecentlyAccessed,
    removeRecentlyAccessed,
    moveRecentlyAccessed
  }
}
