import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Alert, Button } from '@matillion/component-library'

import { useDeleteProjectVariables } from 'api/hooks/useDeleteProjectVariables/useDeleteProjectVariables'

import { jobActions } from 'job-lib/store'
import { VariableScope } from 'job-lib/types/Variables'

import baseClasses from 'modules/ManageVariables/ManageVariables.module.scss'
import {
  Stages,
  type ManageVariableStageProps
} from 'modules/ManageVariables/types'

import classes from './Delete.module.scss'

export const Delete = ({
  setStage,
  selectedVariable
}: ManageVariableStageProps) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const { mutateAsync: deleteProjectVariable, isLoading } =
    useDeleteProjectVariables()

  const onDeleteHandler = async () => {
    if (selectedVariable) {
      if (selectedVariable.type === VariableScope.JOB_VARIABLE) {
        dispatch(jobActions.deleteJobVariable(selectedVariable.name))
      } else {
        await deleteProjectVariable(selectedVariable.id)
      }
    }
    setStage(Stages.MANAGE)
  }
  return (
    <div className={baseClasses.Stage}>
      <Alert
        className={classes.Delete__Alert}
        message={t('manageVariables.delete.footerWarning', {
          name: selectedVariable?.name
        })}
        type="warning"
      />
      <div className={classes.Delete__Actions}>
        <Button
          disabled={isLoading}
          alt="secondary"
          text={t('common.cancel')}
          onClick={() => {
            setStage(Stages.MANAGE)
          }}
        />
        <Button
          disabled={isLoading}
          alt="danger"
          data-testid="confirm-delete-variable"
          text={t('manageVariables.delete.delete')}
          onClick={() => {
            onDeleteHandler()
          }}
        />
      </div>
    </div>
  )
}
