import { useMutation, useQueryClient } from '@tanstack/react-query'

import { queryKeys } from '../../queryKeys'
import {
  SpringClientVersion,
  useSpringClient
} from '../useSpringClient/useSpringClient'

export const useCancelTask = () => {
  const client = useSpringClient(SpringClientVersion.V2)
  const queryClient = useQueryClient()
  return useMutation(
    async (flowInstanceId: string) =>
      client.patch(`tasks/${flowInstanceId}/cancellation`),
    {
      // for either success or fail, refresh the task history
      onSettled: async () =>
        queryClient.invalidateQueries([queryKeys.taskStatus])
    }
  )
}
