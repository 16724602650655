import classes from '../AuthModalForm.module.scss'
import Loading from 'components/Loading'

const AuthModalLoadingState = () => {
  return (
    <div data-testid="git-auth-modal-loading" className={classes.loading}>
      <Loading />
    </div>
  )
}

export default AuthModalLoadingState
