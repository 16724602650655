import { useContext, useEffect } from 'react'
import { ServiceRegistry } from '../types'
import { ServiceRegistryContext } from '../context'
import { prependSubdomain } from '../utils/prependSubdomain'
import useUser from './useUser'

export const useServiceUrl = (
  serviceKey: ServiceKey | string,
  includeSubdomain = false
) => {
  const { organisation } = useUser()
  useEffect(() => {
    if (includeSubdomain && !organisation.subdomain) {
      console.warn(
        `hub-client: asked to include subdomain on URL for ${serviceKey}, but subdomain unavailable.`
      )
    }
  }, [serviceKey, includeSubdomain, organisation.subdomain])
  const ct = useContext<ServiceRegistry>(ServiceRegistryContext)
  const endpoint = ct.endpoints[serviceKey]?.endpoint
  if (!endpoint) {
    throw new Error(`No endpoint url found for ${serviceKey}`)
  }
  if (includeSubdomain && organisation.subdomain) {
    return prependSubdomain(endpoint, organisation.subdomain).origin
  }
  return endpoint
}
export const useServiceUrls = (...serviceKey: Array<ServiceKey | string>) =>
  serviceKey.map((key) => useServiceUrl(key))

export enum ServiceKey {
  agentManagerFrontend = 'agent-manager-frontend',
  billingFrontend = 'billing-frontend',
  cyocFrontend = 'cyoc-frontend',
  designerFrontend = 'designer-frontend',
  hubFrontend = 'hub-frontend',
  mdlFrontend = 'mdl-frontend',
  observabilityDashboardFrontend = 'observability-dashboard-frontend',
  projectExplorerFrontend = 'project-explorer-frontend',
  schedulerFrontend = 'scheduler-frontend',
  startFrontend = 'start-frontend'
}
