import { Button, Icon, Typography } from '@matillion/component-library'
import classes from '../AuthModalForm.module.scss'
import { useTranslation } from 'react-i18next'
import { type ExternalGitProvider } from 'types/ExternalGitProvider'
import useGetProviderDisplayName from 'modules/BringYourOwnGit/hooks/useGetProviderDisplayName'

export interface AuthModalReadyStateProps {
  provider: ExternalGitProvider
  additionalInfo?: string
  onAuthorize: () => void
  onClose: () => void
  loading: boolean
}

const AuthModalReadyState = ({
  provider,
  loading,
  onAuthorize,
  additionalInfo,
  onClose
}: AuthModalReadyStateProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'auth.modal.ready'
  })

  const { providerName } = useGetProviderDisplayName(provider)

  return (
    <div data-testid="git-auth-modal-ready">
      <Typography
        format="tm"
        id="git-auth-title"
        className={classes.title}
        data-testid="git-auth-modal-title"
      >
        {t('title', { provider: providerName })}
      </Typography>

      <Typography
        format="bcm"
        className={classes.body}
        data-testid="git-auth-modal-body"
      >
        {t('body.first', { provider: providerName })}
      </Typography>

      {additionalInfo && (
        <Typography
          format="bcm"
          className={classes.body}
          data-testid="git-auth-modal-additional-info"
        >
          {additionalInfo}
        </Typography>
      )}

      <div className={classes.buttons}>
        <Button
          id="cancel"
          alt="secondary"
          onClick={onClose}
          text={t('cancel')}
          disabled={loading}
          className={classes.button}
          data-testid="git-auth-cancel"
        />

        <Button
          alt="primary"
          id="auth-submit"
          waiting={loading}
          onClick={onAuthorize}
          className={classes.button}
          data-testid="git-auth-submit"
        >
          <Typography>{t('submit')}</Typography>
          <Icon.Export className={classes.exportIcon} />
        </Button>
      </div>
    </div>
  )
}

export default AuthModalReadyState
