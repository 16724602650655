import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Icon, useTreeItem } from '@matillion/component-library'

import classes from './ContextMenuButton.module.scss'

export interface ContextMenuButtonProps {
  id: string
  onClick: () => void
}

const ContextMenuButton = ({ id, onClick }: ContextMenuButtonProps) => {
  const { t } = useTranslation()
  const { isFocused, isHovered } = useTreeItem(id)

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onClick()
  }

  return (
    <span className={classes.ButtonContainer}>
      {(isFocused || isHovered) && (
        <Button
          onClick={handleClick}
          aria-label={t('translation:sideBar.jobPanel.optionMenu')}
          data-testid={`job-list-actions-${id}`}
          className={classes.Button}
          alt="ghost"
        >
          <Icon.Ellipsis className={classes.ButtonIcon} />
        </Button>
      )}
    </span>
  )
}

export default ContextMenuButton
