import { rest } from 'msw'

import { PROJECT_ID, PROJECT_ID_WITH_NO_ENVS } from '__test-utils__/ids'

import { useLegacyMergeResponses } from 'api/hooks/useLegacyMerge/useLegacyMerge.responses'
import { Problem } from 'hooks/useProblemDetails/Problem'

const buildUri = (projectId: string) => {
  return `projects/${projectId}/merges`
}

const stubForProject = (projectId: string) =>
  rest.post(buildUri(projectId), async (req, res, ctx) => {
    const body = await req.json()
    if (body.sourceCommitId === '500') {
      return res(ctx.status(500))
    } else if (body.targetBranchName === 'branchNotFound') {
      return res(
        ctx.status(404),
        ctx.set('content-type', 'application/problem+json'),
        ctx.json({
          type: Problem.WTS_BRANCH_NOT_FOUND,
          title: 'Not found',
          status: 404
        })
      )
    } else if (body.targetBranchName === 'manualMergeRequired') {
      return res(
        ctx.status(409),
        ctx.set('content-type', 'application/problem+json'),
        ctx.json({
          type: Problem.GIT_MANUAL_MERGE_REQUIRED,
          title: 'Conflict',
          status: 409
        })
      )
    } else {
      return res(ctx.status(201), ctx.json(useLegacyMergeResponses))
    }
  })

export const stubLegacyMerge = (baseUrl = '', project = PROJECT_ID) => {
  return rest.post(`${baseUrl}/${buildUri(project)}`, async (req, res, ctx) => {
    const body = await req.json()
    if (body.sourceCommitId === '500') {
      return res(ctx.status(500))
    } else {
      return res(ctx.status(201), ctx.json(useLegacyMergeResponses))
    }
  })
}

export const useLegacyMergeHandlers = [
  stubForProject(PROJECT_ID),
  stubForProject('test-project'),
  stubForProject(PROJECT_ID_WITH_NO_ENVS)
]
