import { BRANCH_ID } from '__test-utils__/ids'
import { type PushChangesResponse } from './types'

export const usePushChangesResponses: Record<string, PushChangesResponse> = {
  'test-branch': {
    commitId: 'a94a8fe5ccb19ba61c4c0873d391e987982fbbd3'
  },
  [BRANCH_ID]: {
    commitId: '109f4b3c50d7b0df729d299bc6f8e9ef9066971f'
  },
  'failing-branch': {
    commitId: '500'
  },
  'gcl-test-branch': {
    commitId: '109f4b3c50d7b0df729d299bc6f8e9ef9066971f'
  }
}
