import { useCallback } from 'react'

import { useMutation } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useSpringClient } from '../useSpringClient/useSpringClient'
import { type PublishJobsRequest } from './types'

export interface PublishArgs {
  commitId: string
  environmentId: string
  branchName: string
}

export const usePublishJobs = (warehouse: string) => {
  const { projectId } = useProjectInfo()
  const client = useSpringClient()

  const publish = useCallback(
    async (args: PublishArgs) => {
      const request: PublishJobsRequest = {
        environmentId: args.environmentId,
        commitId: args.commitId,
        branchName: args.branchName,
        projectId
      }

      const url = `/job-publications?dataWarehouseType=${warehouse}`

      const { status } = await client.post(url, request)
      return status
    },
    [client, projectId, warehouse]
  )

  return useMutation([projectId], publish, {})
}
