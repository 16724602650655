import type { JobVariableType, VariableScope } from 'job-lib/types/Variables'

import {
  ErrorStatuses,
  Fields,
  ProjectVariableType,
  type FormState,
  type ProjectVariableOverride,
  type ReducerActions
} from 'modules/ManageVariables/types'
import { isNumeric } from 'modules/ManageVariables/utils'

import { errorMessages } from '../errorMessages'
import { isFormValid } from './isFormValid'

export const updateProjectOverride = (
  state: FormState,
  action: {
    type: ReducerActions.UPDATE_PROJECT_OVERRIDE
    field: Fields.PROJECT_DEFAULT_OVERRIDES
    value: ProjectVariableOverride['value']
    environmentId: ProjectVariableOverride['environmentId']
  },
  selectedVariableScope: VariableScope,
  selectedVariableType: JobVariableType | ProjectVariableType | null
): FormState => {
  const isNumberType = selectedVariableType === ProjectVariableType.NUMBER
  const isValidNumber = action.value === '' || isNumeric(action.value as string)
  const isValid = isNumberType ? isValidNumber : true

  const value: ProjectVariableOverride = {
    isValid,
    value: action.value,
    environmentId: action.environmentId,
    error: isValid ? '' : errorMessages[ErrorStatuses.INCORRECT_NUMBER_FORMAT]
  }

  const projectOverridesUpdated = {
    ...state[Fields.PROJECT_DEFAULT_OVERRIDES].value,
    [action.environmentId]: value
  }

  const isProjectOverrideValid = Object.values(projectOverridesUpdated).every(
    (override) => override.isValid ?? /* istanbul ignore next */ true
  )

  const updatedState = {
    ...state,
    [Fields.PROJECT_DEFAULT_OVERRIDES]: {
      ...state[Fields.PROJECT_DEFAULT_OVERRIDES],
      value: projectOverridesUpdated,
      isValid: isProjectOverrideValid
    }
  }

  return {
    ...updatedState,
    isFormValid: isFormValid(updatedState, selectedVariableScope)
  }
}
