import { type ComponentMetadataResponse } from '../../types'

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const mockEndFailureMetadata = {
  componentId: 'end-failure',
  metadata: {
    schemaVersion: 1,
    emeraldId: '515156205',
    componentType: 'ORCHESTRATION',
    classification: 'CONTROL_FLOW',
    inputPorts: [
      {
        portId: 'INPUT',
        cardinality: 'ONE'
      }
    ],
    outputPorts: [],
    deprecated: false,
    exports: [
      'COMPLETED_AT',
      'COMPONENT_NAME',
      'DURATION',
      'MESSAGE',
      'ROW_COUNT',
      'STARTED_AT',
      'STATUS'
    ],
    editorTabs: ['PROPERTIES', 'EXPORT', 'HELP'],
    parameters: [
      {
        dplID: 'componentName',
        resourceID: 'componentName',
        metlSlot: 1,
        useVariable: 'NO',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'FREETEXT',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      }
    ]
  }
} as ComponentMetadataResponse
