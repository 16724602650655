import { useCallback, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Checkbox,
  Field,
  Icon,
  Typography
} from '@matillion/component-library'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import classes from './MovePipelineModal.module.scss'

export interface MovePipelineModalProps {
  onClose: () => void
  onConfirm: (hideWarning: boolean) => void
}

export const MovePipelineModal: FC<MovePipelineModalProps> = ({
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'move-pipeline.modal'
  })
  const [hideWarning, setHideWarning] = useState(false)

  const handleConsentChange = useCallback(() => {
    setHideWarning(!hideWarning)
  }, [hideWarning, setHideWarning])

  const handleConfirm = () => {
    onConfirm(hideWarning)
  }

  return (
    <DesignerModal
      size="mid"
      setFocus={false}
      onCancel={onClose}
      disableBackdropClick
      id="move-pipeline-modal"
      className={classes.MovePipelineModal}
      data-testid="move-pipeline-modal"
      ariaLabelledBy={'move-pipeline-modal'}
    >
      <Icon.Warning className={classes.MovePipelineModal__Icon} />

      <Typography format="tm" className={classes.MovePipelineModal__Title}>
        {t('title')}
      </Typography>

      <div className={classes.MovePipelineModal__Container}>
        <Typography
          as="p"
          format="bcm"
          weight="bold"
          className={classes.MovePipelineModal__Typography}
        >
          {t('alert-move.title')}
        </Typography>{' '}
        <Typography as="p" className={classes.MovePipelineModal__Typography}>
          {t('alert-move.description')}
        </Typography>
      </div>

      <div className={classes.MovePipelineModal__Container}>
        <Typography
          as="p"
          format="bcm"
          weight="bold"
          className={classes.MovePipelineModal__Typography}
        >
          {t('alert-schedule.title')}
        </Typography>{' '}
        <Typography as="p" className={classes.MovePipelineModal__Typography}>
          {t('alert-schedule.description')}
        </Typography>
      </div>

      <Field
        text={t('showWarningAgain')}
        checked={hideWarning}
        inputComponent={Checkbox}
        onChange={handleConsentChange}
        className={classes.MovePipelineModal__ConsentCheckbox}
        data-testid="move-pipeline-consent-box"
      />

      <div className={classes.MovePipelineModal__Buttons}>
        <Button
          alt="secondary"
          text={t('cancel')}
          onClick={onClose}
          id="move-pipeline-modal-cancel"
          data-testid="move-pipeline-modal-cancel"
        />

        <Button
          alt="positive"
          text={t('submit')}
          onClick={handleConfirm}
          id="move-pipeline-modal-submit"
          data-testid="move-pipeline-modal-submit"
        />
      </div>
    </DesignerModal>
  )
}

export default MovePipelineModal
