import {
  EditorType,
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type ColumnType } from 'api/hooks/useGetParameterOptions/types'

import { type ComponentInstanceId } from 'job-lib/types/Job'
import {
  type ElementCollection,
  type ElementValueType
} from 'job-lib/types/Parameters'

import { ModalTriggerEditor } from 'modules/ParameterEditors/components/ModalTriggerEditor/ModalTriggerEditor'

import { type GridCell } from '../../types'
import classes from './GridFreeTextMultiLineEditor.module.scss'

export interface GridFreeTextMultiLine {
  componentMetaData: ComponentMetadata
  elements: ElementCollection
  parameter: ComponentParameter
  componentInstanceId: ComponentInstanceId
  componentSummaryId: ComponentSummaryId
  type: ElementValueType
  value: string | null
  slot: number
  rowSlot: number
  dataType: ColumnType
  parameterName: string
  onChange: (cell: GridCell) => void
}

export const GridFreeTextMultiLineEditor = ({
  componentMetaData,
  elements,
  parameter,
  componentInstanceId,
  componentSummaryId,
  value: _value,
  slot,
  rowSlot,
  dataType,
  type,
  parameterName,
  onChange
}: GridFreeTextMultiLine) => {
  const value = _value ?? ''

  return (
    <div className={classes.GridFreeTextMultiLineEditor}>
      <ModalTriggerEditor
        value={[value]}
        editorType={EditorType.FREETEXT_MULTI_LINE}
        elements={elements}
        onEdit={(args) => {
          onChange({
            type,
            slot,
            rowSlot,
            dataType,
            value: args[1].values[1].value
          })
        }}
        componentInstanceId={componentInstanceId}
        componentMetaData={componentMetaData}
        inputId={`nested-row-${rowSlot}-slot-${slot}`}
        parameter={parameter}
        componentSummaryId={componentSummaryId}
        parameterName={parameterName}
      />
    </div>
  )
}
