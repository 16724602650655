import { useEffect, useState } from 'react'

import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { useAgentDetails } from 'api/hooks/useAgentDetails/useAgentDetails'

import { useOnboarded } from './useOnboarded'
import { useSessionStarted } from './useSessionStarted'

export function useOnboardingModal() {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isSessionStarted, setSessionStarted] = useSessionStarted()
  const [isOnboarded, setOnboarded] = useOnboarded()
  const { hasPermission: canUpdatePipelines } =
    useProjectPermission('update_pipelines')

  const { data: agentStatus, isLoading: isAgentStatusLoading } =
    useAgentDetails()
  const isAgentAvailable = !!agentStatus?.isAvailable
  const shouldLoad =
    !isAgentStatusLoading &&
    isAgentAvailable &&
    !isSessionStarted &&
    !isOnboarded &&
    canUpdatePipelines

  useEffect(() => {
    if (shouldLoad) {
      setSessionStarted(true)
      setIsModalVisible(true)
    }
  }, [isOnboarded, shouldLoad, setSessionStarted])

  const shouldReload =
    !isAgentStatusLoading && !isAgentAvailable && isModalVisible

  useEffect(() => {
    if (shouldReload) {
      setSessionStarted(false)
      setIsModalVisible(false)
    }
  }, [shouldReload, setSessionStarted])

  return {
    isVisible: isModalVisible,
    close: (isHiddenNextTime: boolean) => {
      setOnboarded(isHiddenNextTime)
      setIsModalVisible(false)
    }
  }
}
