import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { type CommitResponse } from 'api/hooks/useCommit/types'
import useDesignerClient from 'api/clients/useDesignerClient'
import useGitContext from 'provider/GitProvider/useGitContext'
import { queryKeys } from 'api/query-keys'

const usePostCommitMutationKey = () => {
  const { project, branch } = useGitContext()
  return [queryKeys.commit, project, branch]
}
const useCommit = () => {
  const { branch, project, client } = useDesignerClient()

  const commit = useCallback(
    async (commitMessage: string) => {
      const url = `branches/${branch}/commits?projectId=${project}`
      const { data } = await client.post<CommitResponse>(url, { commitMessage })
      return data
    },
    [client, project, branch]
  )

  return useMutation(usePostCommitMutationKey(), commit, {
    meta: {
      error: {
        title: 'api.toast.commit.title',
        message: 'api.toast.commit.message'
      }
    }
  })
}

export default useCommit
