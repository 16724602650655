import useGitContext from 'provider/GitProvider/useGitContext'
import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import { queryKeys } from 'api/query-keys'
import { type PushChangesResponse } from './types'
import useWorkingTreeClient from 'api/clients/useWorkingTreeClient'

const usePostPushChangesQueryKey = () => {
  const { project, branch } = useGitContext()
  return [queryKeys.push, project, branch]
}

const usePushChanges = () => {
  const client = useWorkingTreeClient()
  const { project, branch } = useGitContext()

  const push = useCallback(async () => {
    const url = `/projects/${project}/branches/${branch}/push-to-remote`
    const { data } = await client.post<PushChangesResponse>(url)
    return data
  }, [branch, client, project])

  return useMutation(usePostPushChangesQueryKey(), push, {
    meta: {
      error: {
        title: 'api.toast.push.title',
        message: 'api.toast.push.message'
      }
    }
  })
}

export default usePushChanges
