import { forwardRef, type HTMLProps } from 'react'
import { Panel, PanelGroup } from 'react-resizable-panels'

import { Orientation, ResizeableHandler } from 'components/ResizeableHandler'

import { useFlags } from 'hooks/useFlags'

import ComponentPropertiesPanel from 'modules/ComponentProperties/ComponentPropertiesPanel'
import { TaskHistoryPanel } from 'modules/TaskHistoryPanel/TaskHistoryPanel'

export const Footer = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { enableWizardUiForComponents } = useFlags()

    return (
      <PanelGroup
        direction={enableWizardUiForComponents ? 'vertical' : 'horizontal'}
        autoSaveId="footer"
        id="footer-panel-group"
      >
        <Panel id="component-properties" defaultSize={50}>
          <ComponentPropertiesPanel />
        </Panel>

        <ResizeableHandler
          orientation={
            enableWizardUiForComponents
              ? Orientation.HORIZONTAL
              : Orientation.VERTICAL
          }
          id="inner-footer"
        />

        <Panel id="task-history" defaultSize={50}>
          <TaskHistoryPanel />
        </Panel>
      </PanelGroup>
    )
  }
)

Footer.displayName = 'Footer'
