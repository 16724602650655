import classes from './TargetBranchSelector.module.scss'
import BranchSelectorLabel from 'components/BranchSelectorLabel'
import CurrentBranchDisplay from '../CurrentBranchDisplay'
import MergeArrowIcon from 'icons/MergeArrow.svg'
import BranchSelector from 'components/BranchSelector'
import { type TargetBranchSelectorProps } from './types'
import useMergeFeatureFlag from 'modules/Merge/hooks/useMergeFeatureFlag'

const TargetBranchSelector = ({
  branch,
  branches,
  isInvalidating,
  setBranch
}: TargetBranchSelectorProps) => {
  const { keyDiscriminator } = useMergeFeatureFlag()

  return (
    <div className={classes.wrapper}>
      <div className={classes.branch}>
        <BranchSelectorLabel
          prefixKey={`merge.${keyDiscriminator}.sourceBranch.prefix`}
          suffixKey={`merge.${keyDiscriminator}.sourceBranch.suffix`}
        />

        <CurrentBranchDisplay />
      </div>

      <div className={classes.iconWrapper}>
        <img src={MergeArrowIcon} alt={'Merge Arrow Icon'} />
      </div>

      <div className={classes.branch}>
        <BranchSelectorLabel
          prefixKey={`merge.${keyDiscriminator}.targetBranch.prefix`}
          suffixKey={`merge.${keyDiscriminator}.targetBranch.suffix`}
        />

        <BranchSelector
          branch={branch}
          branches={branches}
          loading={isInvalidating}
          onSelectBranch={setBranch}
          excludeSourceBranch={true}
        />
      </div>
    </div>
  )
}

export default TargetBranchSelector
