import {
  type OrchestrationJob,
  type TransformationJob
} from 'job-lib/types/Job'

export const MOCK_DATE_CREATED = 915148800000

export const mockNewOrchestrationJob: OrchestrationJob = {
  revision: 1,
  created: MOCK_DATE_CREATED,
  timestamp: MOCK_DATE_CREATED,
  components: {},
  successConnectors: {},
  failureConnectors: {},
  unconditionalConnectors: {},
  trueConnectors: {},
  falseConnectors: {},
  iterationConnectors: {},
  canUndo: false,
  undoCommand: '',
  undoCreated: MOCK_DATE_CREATED,
  canRedo: false,
  redoCommand: '',
  redoCreated: -1,
  notes: {},
  noteConnectors: {}
}

export const mockNewTransformationJob: TransformationJob = {
  revision: 1,
  created: MOCK_DATE_CREATED,
  timestamp: MOCK_DATE_CREATED,
  components: {},
  connectors: {},
  canUndo: false,
  undoCommand: '',
  undoCreated: MOCK_DATE_CREATED,
  canRedo: false,
  redoCommand: '',
  redoCreated: -1,
  notes: {},
  noteConnectors: {},
  variables: {},
  grids: {}
}
