import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import type { JobSummary } from 'api/hooks/useGetJobSummaries'

import { PopOverItem, PopOverSection } from 'components/PopOverMenu'

import { useSelectedJobs } from 'hooks/useSelectedJobs'

import { PipelineBrowserCommand } from 'modules/PipelineBrowser/context/types'
import { usePipelineBrowserCommandProvider } from 'modules/PipelineBrowser/effects/usePipelineBrowserCommand'

import { PipelineTreeReducerType } from '../../../reducers/pipelineTreeReducer/types'

export interface PipelineItemContextMenuProps {
  job: JobSummary
  path?: string
}

const PipelineItemContextMenu: FC<PipelineItemContextMenuProps> = ({
  job,
  path
}) => {
  const { navigateToJob } = useSelectedJobs()
  const { onCommand, onPipelineTreeCommand } =
    usePipelineBrowserCommandProvider()
  const { hasPermission: canDelete } = useProjectPermission('delete_pipelines')
  const { t } = useTranslation()

  const handleRenamePipeline = () => {
    onCommand({
      type: PipelineBrowserCommand.RENAME_PIPELINE,
      payload: job
    })

    onPipelineTreeCommand({
      type: PipelineTreeReducerType.SET_FOLDER_PATH,
      path
    })
  }

  return (
    <PopOverSection>
      <PopOverItem
        onClick={() => {
          navigateToJob(job.jobId)
        }}
        shortcut={t('shortcuts.enter')}
      >
        {t('translation:jobContextMenu.openJob')}
      </PopOverItem>
      <PopOverItem
        data-testid="job-context-menu-rename-job"
        onClick={handleRenamePipeline}
      >
        {t('translation:jobContextMenu.renameJob')}
      </PopOverItem>
      <PopOverItem
        disabled={!canDelete}
        onClick={() => {
          onCommand({
            type: PipelineBrowserCommand.DELETE_PIPELINE,
            payload: job
          })
        }}
        data-testid="job-context-menu-delete-job"
      >
        {t('translation:jobContextMenu.deleteJob')}
      </PopOverItem>
    </PopOverSection>
  )
}

export default PipelineItemContextMenu
