import { isMacOs } from 'utils/isMacOs'

export interface ContextMenuSection {
  id: string
  items: ContextMenuItem[]
}

export interface ContextMenuItem {
  id: string
  disabled?: boolean
  shortcut?: string
  subtext?: string
  context?: 'macOS' | 'windows'
}

export interface CanvasContext {
  hasSelectedNodes: boolean
  hasSelectedComponents: boolean
  isValidatingJob: boolean
  jobName: string
  canCopy: boolean
  canPaste: boolean
  canUndo: boolean
  canRedo: boolean
  userCanRunPipeline: boolean
  enableExperimentalAiAutoDocs: boolean
}

const notesSection = (
  enableExperimentalAiAutoDocs: boolean,
  hasSelectedComponents: boolean
): ContextMenuSection => {
  const items: ContextMenuItem[] = []

  if (enableExperimentalAiAutoDocs) {
    items.push(
      {
        id: 'addNote'
      },
      {
        id: 'generateDocumentation',
        disabled: !hasSelectedComponents
      }
    )
  } else {
    items.push({
      id: 'addNote'
    })
  }

  return {
    id: 'notes',
    items
  }
}

export const canvasContextMenu = (
  props: CanvasContext
): ContextMenuSection[] => [
  {
    id: 'configuration',
    items: [
      {
        id: 'validateJob',
        shortcut: 'translation:shortcuts.validateJob',
        disabled: props.isValidatingJob,
        subtext: ` (${props.jobName})`
      },
      {
        id: 'runJob',
        shortcut: 'translation:shortcuts.commandEnter',
        disabled: !props.userCanRunPipeline,
        subtext: ` (${props.jobName})`
      }
    ]
  },
  notesSection(props.enableExperimentalAiAutoDocs, props.hasSelectedComponents),
  {
    id: 'history',
    items: [
      {
        id: 'undo',
        disabled: !props.canUndo,
        shortcut: isMacOs()
          ? 'translation:shortcuts.commandZ_macOS'
          : 'translation:shortcuts.controlZ'
      },
      {
        id: 'redo',
        disabled: !props.canRedo,
        shortcut: isMacOs()
          ? 'translation:shortcuts.commandShiftZ_macOS'
          : 'translation:shortcuts.controlY'
      }
    ]
  },
  /**
  {
    id: 'manage',
    items: [
      {
        id: 'otherJobs',
        shortcut: 'translation:shortcuts.controlN'
      },
      {
        id: 'manageVariables',
      }
    ]
  },
  {
    id: 'exports',
    items: [
      {
        id: 'export'
      },
      {
        id: 'failure'
      }
    ]
  }, */
  {
    id: 'actions',
    items: [
      {
        id: 'copy',
        disabled: !props.canCopy,
        shortcut: isMacOs()
          ? 'translation:shortcuts.commandC_macOS'
          : 'translation:shortcuts.controlC'
      },
      {
        id: 'paste',
        disabled: !props.canPaste,
        shortcut: isMacOs()
          ? 'translation:shortcuts.commandV_macOS'
          : 'translation:shortcuts.controlV'
      },
      /**
      {
        id: 'cut',
        shortcut: 'translation:shortcuts.controlX'
      },
      */
      {
        id: 'delete',
        disabled: !props.hasSelectedNodes,
        shortcut: 'translation:shortcuts.delete'
      }
    ]
  }
]
