import { type ExternalGitProvider } from 'types/ExternalGitProvider'
import { type RequestHandler, rest } from 'msw'
import { useInitOAuthResponses } from './useInitOAuth.responses'
import { type InitOAuthResponse } from './types'

const buildUri = (baseUrl: string, provider: ExternalGitProvider) => {
  return `${baseUrl}/git-service/v1/providers/${provider}/oauth`
}

export const stubInitOAuth = (baseUrl = '') => {
  return Object.keys(useInitOAuthResponses).map((provider) => {
    const url = buildUri(baseUrl, provider as ExternalGitProvider)
    return rest.post(url, async (_, res, ctx) => {
      return res(
        ctx.json<InitOAuthResponse>(
          useInitOAuthResponses[provider as ExternalGitProvider]
        )
      )
    })
  })
}

export const stubInitOAuthError = () => {
  const url = 'git-service/v1/providers/FAILED/oauth'

  return rest.post(url, async (_, res, ctx) => {
    return res(ctx.status(401))
  })
}

export const useInitOAuthHandlers: RequestHandler[] = [
  ...stubInitOAuth(),
  stubInitOAuthError()
]
