import { useEffect, useState, type FC, type PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Tab, Tabs } from '@matillion/component-library'

import { useListProjectVariables } from 'api/hooks/useListProjectVariables/useListProjectVariables'

import { VariableScope } from 'job-lib/types/Variables'

import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'

import {
  Stages,
  type ManageVariableStageProps,
  type ProjectVariable
} from '../../types'
import { CreateModal } from './CreateModal'
import { JobVariables } from './JobVariables'
import classes from './Manage.module.scss'
import { ProjectVariables } from './ProjectVariables/ProjectVariables'

const TabContentContainer: FC<PropsWithChildren> = ({ children }) => (
  <div className={classes.ManageVariables__Content}>{children}</div>
)

export const Manage = ({
  setStage,
  setSelectedVariable,
  setSelectedVariableScope,
  selectedVariableScope,
  selectedVariableType,
  setSelectedVariableType
}: ManageVariableStageProps) => {
  const { job } = useWorkingCopy()

  const {
    data: projectVariables,
    isFetching: isLoading,
    isError
  } = useListProjectVariables({ staleTime: 0 })
  const { t } = useTranslation('translation', { keyPrefix: 'manageVariables' })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedVariableName, setSelectedVariableName] = useState<
    string | null
  >(null)

  const handleTabChange = ({ tab }: Record<'tab', unknown>) => {
    const scope: VariableScope = (
      tab as Record<'data-tab-scope', VariableScope>
    )['data-tab-scope']

    /* istanbul ignore else */
    if (scope && setSelectedVariableScope) {
      setSelectedVariableScope(scope)
    }
  }
  const onCancel = () => {
    setIsModalOpen(false)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }

  const numJobVariables = Object.keys(job?.variables ?? {}).length
  const numProjectVariables = projectVariables?.length ?? 0

  const handleEdit = (name: string) => {
    setSelectedVariable?.({
      type: VariableScope.JOB_VARIABLE,
      name
    })
    const cleanedName = name.replace('variable-', '')
    setSelectedVariableName(cleanedName)
  }
  useEffect(() => {
    if (selectedVariableName && job?.variables?.[selectedVariableName]) {
      setSelectedVariableType?.(
        job.variables[selectedVariableName].definition.type
      )
      setStage(Stages.EDIT)
    }
  }, [selectedVariableName, job?.variables, setSelectedVariableType, setStage])

  return (
    <>
      <div className={classes.ManageVariables__ButtonWrapper}>
        <Button
          className={classes.ManageVariables__CreateButton}
          onClick={() => {
            setIsModalOpen(true)
            setSelectedVariableType?.(null)
          }}
          text={t('manage.addVariable')}
          data-testid="create-variable"
        />
      </div>

      {isModalOpen && (
        <CreateModal
          setStage={setStage}
          setSelectedVariable={setSelectedVariable}
          setSelectedVariableScope={setSelectedVariableScope}
          selectedVariableScope={selectedVariableScope}
          selectedVariableType={selectedVariableType}
          setSelectedVariableType={setSelectedVariableType}
          onCancel={onCancel}
          onClose={closeModal}
          isModalOpen={isModalOpen}
        />
      )}

      <Tabs
        className={classes.ManageVariables__Tabs}
        listClassName={classes.ManageVariables__TabList}
        contentClassName={classes.ManageVariables__Content}
        contentContainer={TabContentContainer}
        activeIndex={
          selectedVariableScope === VariableScope.JOB_VARIABLE ? 0 : 1
        }
        onChange={handleTabChange}
      >
        <Tab
          data-tab-scope={VariableScope.JOB_VARIABLE}
          data-testid="tab-job-variables"
          title={t('manage.jobVariablesWithCount', {
            numJobVariables
          })}
        >
          <JobVariables
            variables={job?.variables}
            onDelete={(name) => {
              setSelectedVariable?.({
                type: VariableScope.JOB_VARIABLE,
                name
              })
              setStage(Stages.DELETE)
            }}
            onEdit={handleEdit}
          />
        </Tab>
        <Tab
          data-tab-scope={VariableScope.PROJECT_VARIABLE}
          data-testid="tab-project-variables"
          title={t('manage.projectVariablesWithCount', {
            numProjectVariables
          })}
        >
          <ProjectVariables
            isLoading={isLoading}
            isError={isError}
            variables={projectVariables}
            onDelete={(id, name) => {
              setSelectedVariable?.({
                type: VariableScope.PROJECT_VARIABLE,
                id,
                name
              })
              setStage(Stages.DELETE)
            }}
            onEdit={(id, name) => {
              setSelectedVariable?.({
                type: VariableScope.PROJECT_VARIABLE,
                id,
                name
              })
              const variable = projectVariables?.find(
                (projectVar: ProjectVariable) => projectVar.id === id
              )

              if (variable) {
                setSelectedVariableType?.(variable.type)
                setStage(Stages.EDIT)
              }
            }}
          />
        </Tab>
      </Tabs>
    </>
  )
}
