import { forwardRef } from 'react'

import { Button, Icon, type ButtonProps } from '@matillion/component-library'
import classnames from 'classnames'

import classes from './ChevronButton.module.scss'

interface Props extends Omit<ButtonProps, 'className'> {
  direction: 'up' | 'down'
}

export const ChevronButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, direction, ...otherProps }, ref) => {
    return (
      <Button {...otherProps} ref={ref}>
        {children}
        <Icon.ChevronDown
          className={classnames(
            classes.ChevronIcon,
            classes[`ChevronIcon--${direction}`],
            classes[`ChevronIcon--${getIconAlt(otherProps.alt)}`]
          )}
        />
      </Button>
    )
  }
)

function getIconAlt(btnAlt: ButtonProps['alt']): 'primary' | 'secondary' {
  if (btnAlt === 'secondary' || btnAlt === 'ghost' || btnAlt === 'text') {
    return 'secondary'
  }

  return 'primary'
}

ChevronButton.displayName = 'ChevronButton'
