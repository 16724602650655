import {
  ENVIRONMENT_ID,
  PROJECT_ID,
  PROJECT_ID_WITH_NO_ENVS
} from '__test-utils__/ids'

import { type ListEnvironmentsResponse } from '../types'

export const mockedEnvironments: {
  [projectId: string]: ListEnvironmentsResponse
} = {
  [PROJECT_ID]: {
    results: [
      {
        name: 'Environment 1',
        id: '098e4567-e89b-12d3-a456-111614174000'
      },
      {
        name: 'Environment 2',
        id: '555e6666-e89b-12d3-a456-123414175000'
      },
      {
        name: 'Environment Test',
        id: 'environmentId'
      },
      {
        name: 'Local Environment',
        id: ENVIRONMENT_ID
      }
    ]
  },
  [PROJECT_ID_WITH_NO_ENVS]: {
    results: []
  }
}
