import { useMemo } from 'react'

import { Typography } from '@matillion/component-library'

import classes from './ComponentName.module.scss'

interface Props {
  displayName: string
  searchTerm: string
}

const ComponentName = ({ displayName, searchTerm }: Props) => {
  const text = useMemo(() => {
    return searchTerm
      ? displayName
          .split(new RegExp(`(\\b${searchTerm})`, 'gi'))
          .map((part, i) =>
            part.localeCompare(searchTerm, undefined, {
              sensitivity: 'base'
            }) ? (
              part
            ) : (
              <Typography key={i} format="bcs" as="span" weight="bold">
                {part}
              </Typography>
            )
          )
      : displayName
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  return (
    <div className={classes.ComponentName}>
      <Typography
        format="bcs"
        as="span"
        className={classes.ComponentName__Text}
      >
        {text}
      </Typography>
    </div>
  )
}

export { ComponentName }
