import { type Cardinality } from './Components'
import { type NameParameter, type ParameterCollection } from './Parameters'
import { type JobVariableCollection } from './Variables'

/**
 * The hint determines whether a component should be able to be executed
 *  FLOW(false),
 *  ITERATE(true),
 *  EXECUTE(true),
 *  TRANSFORM(true),
 *  TRANSACTION(false),
 *  NONE(false)
 */
export enum ExecutionHint {
  Flow = 'FLOW',
  Iterate = 'ITERATE',
  Execute = 'EXECUTE',
  Transform = 'TRANSFORM',
  Transaction = 'TRANSACTION',
  None = 'NONE'
}

export type JobID = number

export interface BaseJob {
  revision: number
  created?: number
  /** @deprecated This data comes from old METL and we're not sure if we need it yet. Remove deprecated if this field is needed. */
  timestamp?: number
  /** @deprecated This data comes from old METL and we're not sure if we need it yet. Remove deprecated if this field is needed. */
  canUndo?: boolean
  /** @deprecated This data comes from old METL and we're not sure if we need it yet. Remove deprecated if this field is needed. */
  undoCommand?: string
  /** @deprecated This data comes from old METL and we're not sure if we need it yet. Remove deprecated if this field is needed. */
  undoCreated?: number
  /** @deprecated This data comes from old METL and we're not sure if we need it yet. Remove deprecated if this field is needed. */
  canRedo?: boolean
  /** @deprecated This data comes from old METL and we're not sure if we need it yet. Remove deprecated if this field is needed. */
  redoCommand?: string
  /** @deprecated This data comes from old METL and we're not sure if we need it yet. Remove deprecated if this field is needed. */
  redoCreated?: number
  /** @deprecated This data comes from old METL and we're not sure if we need it yet. Remove deprecated if this field is needed. */
  noteConnectors?: ConnectorCollection
  variables?: JobVariableCollection
  /** New model for SETL, Different from the METL notes model */
  notes?: NotesCollection
}

export interface OrchestrationJob extends BaseJob {
  /** Components indexed by component instance ids */
  components: OrchestrationComponentInstanceCollection
  failureConnectors: ConnectorCollection
  falseConnectors: ConnectorCollection
  iterationConnectors: ConnectorCollection
  successConnectors: ConnectorCollection
  trueConnectors: ConnectorCollection
  unconditionalConnectors: ConnectorCollection
}

export interface TransformationJob extends BaseJob {
  /** Components indexed by component instance ids */
  components: TransformationComponentInstanceCollection
  connectors: ConnectorCollection
  grids?: unknown
}

export type ConnectorCollection = Record<ConnectorId, Connector>

export type ConnectorId = number

export interface Connector {
  id: ConnectorId
  sourceID: ComponentInstanceId
  targetID: ComponentInstanceId
}

/**
 * This points to the same Components that returned by the component summaries endpoint.
 *
 * This is needed to:
 *   - get the parameters input types
 *     i.e. one of https://matillion.atlassian.net/wiki/spaces/PM/pages/3220668421/SaaS+ETL+Component+Parameter+UI+Analysis
 *     e.g. SINGLE_VALUE_FREEFORM
 *   - get the icon image
 */
export type EmeraldId = number

export type OrchestrationComponentInstanceCollection = Record<
  ComponentInstanceId,
  OrchestrationComponentInstance
>

export type TransformationComponentInstanceCollection = Record<
  ComponentInstanceId,
  TransformationComponentInstance
>

export type ComponentInstanceCollection = Record<
  ComponentInstanceId,
  ComponentInstance
>

export type ComponentInstanceId = number
export type ComponentInstance =
  | OrchestrationComponentInstance
  | TransformationComponentInstance

export type Parameters = ParameterCollection & {
  1: NameParameter
}

export interface BaseComponentInstance {
  id: ComponentInstanceId
  // determines port layout depending on how many input connections are allowed
  /** @deprecated This data isn't needed to run a job and should instead be grabbed from the CIS. */
  inputCardinality?: Cardinality
  /** @deprecated This data isn't needed to run a job and should instead be grabbed from the CIS. */
  outputCardinality?: Cardinality
  // This is still a number, we currently get all the component IDs from the component summaries using the emeraldId field
  implementationID: EmeraldId
  x: number
  y: number
  width: number
  height: number
  inputConnectorIDs: ConnectorId[]
  exportMappings: Record<string, unknown>
  gridExportMappings?: Record<string, unknown>
  parameters: Parameters
  /** @deprecated This data comes from old METL and we're not sure if we need it yet. Remove deprecated if this field is needed. */
  validationStatus?: Record<string, unknown>
  /** @deprecated This data comes from old METL and we're not sure if we need it yet. Remove deprecated if this field is needed. */
  expectedFailure?: null
  /** @deprecated This data comes from old METL and we're not sure if we need it yet. Remove deprecated if this field is needed. */
  activationStatus?: string
}

export interface OrchestrationComponentInstance extends BaseComponentInstance {
  /** @deprecated This is no longer used in the application as the CIS returns connector information */
  connectorHint?: string
  /** @deprecated This data comes from old METL and we're not sure if we need it yet. Remove deprecated if this field is needed. */
  executionHint?: unknown
  outputSuccessConnectorIDs: ConnectorId[]
  outputFailureConnectorIDs: ConnectorId[]
  outputUnconditionalConnectorIDs: ConnectorId[]
  outputTrueConnectorIDs: ConnectorId[]
  outputFalseConnectorIDs: ConnectorId[]
  outputIterationConnectorIDs: ConnectorId[]
  inputIterationConnectorIDs: ConnectorId[]
  /** @deprecated This data comes from old METL and we're not sure if we need it yet. Remove deprecated if this field is needed. */
  status?: Record<string, Status>
}

export interface TransformationComponentInstance extends BaseComponentInstance {
  outputConnectorIDs: ConnectorId[]
  /**
   * component status corresponding to the current environment
   * this field is a list of key/value pairs where the key is a long
   * and the value is some metadata related to the current component
   * we don't know the full shape of this yet so for that reason it is unknown
   * until/if we are ready to consume it
   */
  compStatus?: Record<string, unknown>
  /**
   * METL optimises transformation jobs by creating views in the data warehouse
   * We need more information around what this does on the backend side b
   */
  requiresViewRecreation?: boolean
}

export interface Status {
  environmentID: number
  sql: string
  showSql: boolean
}

export type NotesCollection = Record<number, Note>

export interface Note {
  position: {
    x: number
    y: number
  }

  size: {
    width: number
    height: number
  }

  content: string
  theme: string
  isAIGenerated?: boolean
  selection?: string[]
}
