import { Typography } from '@matillion/component-library'

import classes from './ModalTitle.module.scss'
import { type ModalTitleProps } from './types'
import classNames from 'classnames'

const ModalTitle = ({
  title,
  'data-testId': testId,
  titleId,
  description,
  className
}: ModalTitleProps) => (
  <div
    className={classNames(classes.ModalTitle, className)}
    data-testid={testId}
  >
    <Typography format="tm" id={titleId}>
      {title}
    </Typography>
    {description && (
      <Typography className={classes.ModalTitle__Description}>
        {description}
      </Typography>
    )}
  </div>
)

export default ModalTitle
