import { useMemo } from 'react'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import {
  PermissionValue,
  type CheckPermissionResponse,
  type Permission
} from '../checkPermission/checkPermission'
import { usePermission } from './usePermission'

export interface UseProjectPermissionResult {
  hasPermission: boolean
  permission: CheckPermissionResponse | null
  isLoading: boolean
  isError: boolean
  // TODO: parse the error response from the permissions service
  // it will either be { error: "message" } or a ProblemDetail from https://permissions.core-dev.matillion.com/swagger-ui/index.html#model-ProblemDetail
  error: null | unknown
}

export type UseProjectPermission = (
  permission: Permission
) => UseProjectPermissionResult

export const useProjectPermission: UseProjectPermission = (
  permission: Permission
): UseProjectPermissionResult => {
  const { projectId } = useProjectInfo()

  const result = usePermission({
    resource: {
      type: 'project',
      id: projectId
    },
    permission
  })

  return useMemo(() => {
    return {
      hasPermission: result.data?.permission === PermissionValue.HAS_PERMISSION,
      isLoading: result.isLoading,
      isError: result.isError,
      error: result.error,
      permission: result.data || null
    }
  }, [result])
}
