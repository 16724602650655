import { type FC } from 'react'

import { Tooltip, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import classes from '../FlowStatsExplorer.module.scss'

export interface Props {
  children: string
}

const CellTypography: FC<Props> = ({ children }) => {
  return (
    <Tooltip content={children} onlyShowOnOverflow>
      <div className={classNames(classes.RowTypography, 'u-truncate-text')}>
        <Typography className="u-truncate-text" format="bcs" as="span">
          {children}
        </Typography>
      </div>
    </Tooltip>
  )
}

export default CellTypography
