import { useCallback, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ItemSelect } from 'components/ItemSelect'
import {
  ParameterOverlayButton,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'

import { type ParameterOptions } from 'modules/ParameterEditors/types'

import classes from './MultiOptionSelector.module.scss'

export interface MultiOptionSelectorProps extends ParameterOptions {
  parameterName: string
  value: string[]
  onDone: (editedValue: string[]) => unknown
}

const MultiOptionSelector: FC<MultiOptionSelectorProps> = ({
  editorColumns,
  value,
  parameterName,
  onDone
}) => {
  const { t } = useTranslation()

  const labels = {
    allItemsLabel: t('itemSelector.allItemsLabel', {
      label: parameterName
    }),
    selectedItemsLabel: t('itemSelector.selectedItemsLabel', {
      label: parameterName
    }),
    searchLabel: t('itemSelector.searchLabel'),
    noDataAvailable: t('itemSelector.noDataAvailable'),
    controlAddAll: t('itemSelector.controlAddAll'),
    controlAddOne: t('itemSelector.controlAddOne'),
    controlRemoveAll: t('itemSelector.controlRemoveAll'),
    controlRemoveOne: t('itemSelector.controlRemoveOne'),
    controlReset: t('itemSelector.controlReset'),
    controlMoveUp: t('itemSelector.controlMoveUp'),
    controlMoveDown: t('itemSelector.controlMoveDown')
  }
  const [updatedValues, setUpdatedValues] = useState<string[]>(value)
  const options = editorColumns[0]?.options ?? []
  const invalidItems = updatedValues.filter((item) => !options.includes(item))
  const items = [...invalidItems, ...options]

  const onSubmit = useCallback(() => {
    onDone(updatedValues)
  }, [updatedValues, onDone])

  return (
    <>
      <ParameterOverlayHeader title={parameterName} />
      <div className={classes.MultiOptionSelector}>
        <ItemSelect
          invalidItems={invalidItems}
          items={items}
          selected={updatedValues}
          options={{
            listHeight: 500,
            itemSize: 32,
            showDragHandle: false,
            labels
          }}
          onSelect={(item) => {
            setUpdatedValues(item)
          }}
        />
      </div>
      <ParameterOverlayFooter>
        <ParameterOverlayButton onClick={onSubmit} text={t('common.save')} />
      </ParameterOverlayFooter>
    </>
  )
}

export default MultiOptionSelector
