import { type FC, type PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'

import { useGetJobSummaries } from 'api/hooks/useGetJobSummaries'

import { EtlComponent } from 'components/EtlComponent/EtlComponent'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'
import { useComponentInstanceMetadataQuery } from 'hooks/useComponentInstanceMetadataQuery/useComponentInstanceMetadataQuery'
import { useSelectedJobs } from 'hooks/useSelectedJobs'

import {
  orchestrationJobNameParameter,
  runOrchestrationComponentId,
  runTransformationComponentId,
  transformationJobNameParameter
} from 'job-lib/cisIds/knownComponentParameters'
import { isDPLParameterCollection } from 'job-lib/store/jobSlice/utils/isDPLParameterCollection'

import { getParameterValue } from 'modules/ComponentParameters/utils/getParameterValue'
import { useComponentValidationResult } from 'modules/core/ComponentValidation'

export interface ComponentNodeProps {
  id: string
  isAttached?: boolean
  imageUrl?: string
  isSelected?: boolean
  isIterator?: boolean
  summaryId: string
}

export enum ValidationStatus {
  VALID = 'valid',
  INVALID = 'invalid',
  VALIDATING = 'validating',
  UNVALIDATED = 'unvalidated'
}

const getValidationStatus = ({
  isError,
  isSuccess,
  isLoading
}: {
  isError: boolean
  isSuccess: boolean
  isLoading: boolean
}) => {
  if (isError) return ValidationStatus.INVALID
  if (isSuccess) return ValidationStatus.VALID
  if (isLoading) return ValidationStatus.VALIDATING

  return ValidationStatus.UNVALIDATED
}

export const ComponentNode: FC<PropsWithChildren<ComponentNodeProps>> = ({
  id,
  imageUrl,
  isSelected,
  isAttached,
  isIterator,
  summaryId,
  children
}) => {
  const { navigateToComponent, navigateToJob } = useSelectedJobs()
  const { isError, isSuccess, isLoading } = useComponentValidationResult(
    Number(id)
  )

  const validationStatus = getValidationStatus({
    isError,
    isSuccess,
    isLoading
  })

  const componentInfo = useComponentInfo()

  const displayName = componentInfo.getDisplayName(summaryId)
  const { componentInstance } = useComponentInstanceMetadataQuery(Number(id))
  const { makeToast } = Toaster.useToaster()
  const { t } = useTranslation()
  const jobs = useGetJobSummaries()
  const isRunComponent =
    summaryId === runOrchestrationComponentId ||
    summaryId === runTransformationComponentId

  const onDoubleClick = () => {
    if (isRunComponent && componentInstance) {
      const params = componentInstance.parameters

      const isDPLInstance = isDPLParameterCollection(params)
      const paramLabels = {
        [runOrchestrationComponentId]: orchestrationJobNameParameter,
        [runTransformationComponentId]: transformationJobNameParameter
      }
      const paramLabel = paramLabels[summaryId]
      const jobRuntimeName = isDPLInstance
        ? (getParameterValue(params, [paramLabel]) as string)
        : params[2].elements[1]?.values[1].value

      const jobId = jobs.data?.results.find(
        (job) => job.runtimeName === jobRuntimeName
      )?.jobId

      if (jobId) {
        navigateToJob(jobId)
      } else {
        makeToast({
          type: 'error',
          title: t('canvas.pipelineNavigationErrorTitle', {
            pipelineName: jobRuntimeName
          }),
          message: t('canvas.pipelineNavigationErrorMessage')
        })
      }
    }
  }
  return (
    <EtlComponent
      imageUrl={imageUrl}
      isSelected={isSelected}
      isIterator={isIterator}
      isAttached={isAttached}
      isError={isError}
      isSuccess={isSuccess}
      isLoading={isLoading}
      hasDoubleClickNavigation={isRunComponent}
      showLabel={!isIterator}
      data-status={validationStatus}
      onClick={() => {
        navigateToComponent(id)
      }}
      onDoubleClick={onDoubleClick}
      tooltipContent={displayName}
    >
      {children}
    </EtlComponent>
  )
}
