import { mockTransformationValues } from '__test-utils__/mockTransformationValues'

import { ParameterDataType } from 'api/hooks/useGetComponentMetadata/types'

import { Cardinality } from 'job-lib/types/Components'
import { type TransformationJob } from 'job-lib/types/Job'
import {
  JobVariableBehaviour,
  JobVariableType,
  JobVariableVisibility
} from 'job-lib/types/Variables'

export const mockTransformationJob: TransformationJob = {
  revision: 20,
  created: 1665050508643,
  timestamp: 1665050508643,
  components: {
    [mockTransformationValues.TABLE_INPUT_0.id]: {
      id: mockTransformationValues.TABLE_INPUT_0.id,
      inputCardinality: Cardinality.ZERO,
      outputCardinality: Cardinality.MANY,
      implementationID: 1354890871,
      x: 480,
      y: 425,
      width: 32,
      height: 32,
      inputConnectorIDs: [],
      outputConnectorIDs: [3950],
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockTransformationValues.TABLE_INPUT_0.name
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        },
        2: {
          slot: 2,
          name: 'Target Table',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: '1987'
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        },
        3: {
          slot: 3,
          name: 'Column Names',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Year'
                }
              }
            },
            2: {
              slot: 2,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Month'
                }
              }
            },
            3: {
              slot: 3,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'AirTime'
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        },
        5: {
          slot: 5,
          name: 'Schema',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'PUBLIC'
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        },
        6: {
          slot: 6,
          name: 'Database',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'DEV_DB'
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        },
        7: {
          slot: 7,
          name: 'Offset',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'INTEGER',
                  dataType: ParameterDataType.INTEGER,
                  value: ''
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        }
      },
      exportMappings: {},
      compStatus: {},
      validationStatus: {},
      requiresViewRecreation: false,
      expectedFailure: null,
      activationStatus: 'ENABLED'
    },
    [mockTransformationValues.FILTER_0.id]: {
      id: mockTransformationValues.FILTER_0.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.MANY,
      implementationID: -1760161015,
      x: 849,
      y: 426,
      width: 32,
      height: 32,
      inputConnectorIDs: [1569],
      outputConnectorIDs: [306],
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockTransformationValues.FILTER_0.name
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        },
        2: {
          slot: 2,
          name: 'Filter Conditions',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Year'
                },
                2: {
                  slot: 2,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Is'
                },
                3: {
                  slot: 3,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Less than'
                },
                4: {
                  slot: 4,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: '2021'
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        },
        3: {
          slot: 3,
          name: 'Combine Conditions',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'AND'
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        }
      },
      exportMappings: {},
      compStatus: {},
      validationStatus: {},
      requiresViewRecreation: false,
      expectedFailure: null,
      activationStatus: 'ENABLED'
    },
    [mockTransformationValues.REWRITE_TABLE_0.id]: {
      id: mockTransformationValues.REWRITE_TABLE_0.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.ZERO,
      implementationID: 335239555,
      x: 1025,
      y: 428,
      width: 32,
      height: 32,
      inputConnectorIDs: [306],
      outputConnectorIDs: [],
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockTransformationValues.REWRITE_TABLE_0.name
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        },
        2: {
          slot: 2,
          name: 'Target Table',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'chris-etld'
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        },
        6: {
          slot: 6,
          name: 'Database',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'DEV_DB'
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        },
        7: {
          slot: 7,
          name: 'Schema',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'PUBLIC'
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        },
        8: {
          slot: 8,
          name: 'Warehouse',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'DEV_WH'
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        },
        9: {
          slot: 9,
          name: 'Order By',
          elements: {},
          visible: true,
          status: {},
          columnNames: []
        }
      },
      exportMappings: {},
      compStatus: {},
      validationStatus: {},
      requiresViewRecreation: false,
      expectedFailure: null,
      activationStatus: 'ENABLED'
    },
    [mockTransformationValues.DISTINCT_0.id]: {
      id: mockTransformationValues.DISTINCT_0.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.MANY,
      implementationID: 1744268877,
      x: 648,
      y: 427,
      width: 32,
      height: 32,
      inputConnectorIDs: [3950],
      outputConnectorIDs: [1569],
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockTransformationValues.DISTINCT_0.name
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        },
        2: {
          slot: 2,
          name: 'Columns',
          elements: {},
          visible: true,
          status: {},
          columnNames: []
        }
      },
      exportMappings: {},
      compStatus: {},
      validationStatus: {},
      requiresViewRecreation: false,
      expectedFailure: null,
      activationStatus: 'ENABLED'
    },
    [mockTransformationValues.FIXED_FLOW_0.id]: {
      id: mockTransformationValues.FIXED_FLOW_0.id,
      inputCardinality: Cardinality.ZERO,
      outputCardinality: Cardinality.MANY,
      implementationID: 1354890871,
      x: 455,
      y: 303,
      width: 32,
      height: 32,
      inputConnectorIDs: [],
      outputConnectorIDs: [],
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockTransformationValues.FIXED_FLOW_0.name
                }
              }
            }
          },
          visible: true
        },
        2: {
          slot: 2,
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Column 1'
                }
              }
            },
            2: {
              slot: 2,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Column 2'
                }
              }
            }
          },
          name: 'Columns',
          visible: true
        },
        3: {
          slot: 3,
          elements: {},
          name: 'componentProperties.parameterIds.values',
          visible: true
        }
      },
      exportMappings: {},
      compStatus: {},
      validationStatus: {},
      requiresViewRecreation: false,
      expectedFailure: null,
      activationStatus: 'ENABLED'
    },
    [mockTransformationValues.MULTI_TABLE_INPUT_0.id]: {
      id: mockTransformationValues.MULTI_TABLE_INPUT_0.id,
      inputCardinality: Cardinality.ZERO,
      outputCardinality: Cardinality.MANY,
      implementationID: -2009723820,
      x: 600,
      y: 700,
      width: 32,
      height: 32,
      inputConnectorIDs: [],
      outputConnectorIDs: [],
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: mockTransformationValues.MULTI_TABLE_INPUT_0.name
                }
              }
            }
          },
          visible: true,
          status: {},
          columnNames: []
        },
        4: {
          slot: 4,
          name: 'Pattern type',
          elements: {},
          visible: true,
          status: {},
          columnNames: []
        },
        5: {
          slot: 5,
          name: 'Database',
          elements: {},
          visible: true,
          status: {},
          columnNames: []
        },
        6: {
          slot: 6,
          name: 'Schema',
          elements: {},
          visible: true,
          status: {},
          columnNames: []
        },
        7: {
          slot: 7,
          name: 'Pattern',
          elements: {},
          visible: true,
          status: {},
          columnNames: []
        },
        8: {
          slot: 8,
          name: 'Columns',
          elements: {},
          visible: true,
          status: {},
          columnNames: []
        },
        9: {
          slot: 9,
          name: 'Cast types',
          elements: {},
          visible: true,
          status: {},
          columnNames: []
        },
        10: {
          slot: 10,
          name: 'Add source table',
          elements: {},
          visible: true,
          status: {},
          columnNames: []
        }
      },
      exportMappings: {},
      compStatus: {},
      validationStatus: {},
      requiresViewRecreation: false,
      expectedFailure: null,
      activationStatus: 'ENABLED'
    },
    [mockTransformationValues.RANK_0.id]: {
      id: mockTransformationValues.RANK_0.id,
      inputCardinality: Cardinality.ONE,
      outputCardinality: Cardinality.MANY,
      implementationID: 2139809017,
      x: 96,
      y: 240,
      width: 32,
      height: 32,
      inputConnectorIDs: [],
      outputConnectorIDs: [],
      parameters: {
        1: {
          slot: 1,
          name: 'Name',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Rank 0'
                }
              }
            }
          },
          visible: true
        },
        2: {
          slot: 2,
          name: 'Include input columns',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Yes'
                }
              }
            }
          },
          visible: true
        },
        3: {
          slot: 3,
          name: 'Partition Data',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'year'
                }
              }
            }
          },
          visible: true
        },
        4: {
          slot: 4,
          name: 'Ordering within partitions',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'retail_price'
                },
                2: {
                  slot: 2,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Asc'
                }
              }
            }
          },
          visible: true
        },
        5: {
          slot: 5,
          name: 'Functions',
          elements: {
            1: {
              slot: 1,
              values: {
                1: {
                  slot: 1,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Rank'
                },
                2: {
                  slot: 2,
                  type: 'STRING',
                  dataType: ParameterDataType.TEXT,
                  value: 'Retail Price Rank'
                }
              }
            }
          },
          visible: true
        }
      },
      exportMappings: {},
      expectedFailure: null,
      activationStatus: 'ENABLED'
    }
  },

  connectors: {
    306: {
      id: 306,
      sourceID: mockTransformationValues.FILTER_0.id,
      targetID: mockTransformationValues.REWRITE_TABLE_0.id
    },
    1569: {
      id: 1569,
      sourceID: mockTransformationValues.DISTINCT_0.id,
      targetID: mockTransformationValues.FILTER_0.id
    },
    3950: {
      id: 3950,
      sourceID: mockTransformationValues.TABLE_INPUT_0.id,
      targetID: mockTransformationValues.DISTINCT_0.id
    }
  },
  canUndo: true,
  undoCommand: 'Delete',
  undoCreated: 1665050499319,
  canRedo: false,
  redoCommand: '',
  redoCreated: -1,
  notes: {},
  noteConnectors: {},
  variables: {
    variable_with_description: {
      definition: {
        name: 'variable_with_description',
        type: JobVariableType.TEXT,
        behaviour: JobVariableBehaviour.SHARED,
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum volutpat malesuada tristique. Mauris suscipit turpis nec scelerisque convallis. Nullam condimentum mi at justo condimentum semper. Sed at porttitor dolor. Donec lectus tortor, scelerisque non semper sit amet, rhoncus vitae nisi. Mauris ut lacus augue. Integer malesuada neque vitae odio interdum ultricies. Praesent id venenatis justo, at laoreet velit. Suspendisse malesuada metus et eros auctor auctor. Proin aliquet enim ut bibendum laoreet. Fusce tincidunt, risus quis efficitur malesuada, risus ipsum facilisis ligula, ut tempor libero dui non justo. Proin dignissim sagittis elementum. Curabitur viverra facilisis euismod.',
        visibility: JobVariableVisibility.PUBLIC
      },
      value: ''
    },
    private_variable_with_default: {
      definition: {
        name: 'private_variable_with_default',
        type: JobVariableType.TEXT,
        behaviour: JobVariableBehaviour.SHARED,
        description: '',
        visibility: JobVariableVisibility.PRIVATE
      },
      value: 'default value'
    },
    numeric_variable_with_default: {
      definition: {
        name: 'numeric_variable_with_default',
        type: JobVariableType.NUMBER,
        behaviour: JobVariableBehaviour.SHARED,
        description: '',
        visibility: JobVariableVisibility.PUBLIC
      },
      value: '1'
    },
    shared_variable: {
      definition: {
        name: 'shared_variable',
        type: JobVariableType.TEXT,
        behaviour: JobVariableBehaviour.SHARED,
        description: '',
        visibility: JobVariableVisibility.PUBLIC
      },
      value: ''
    },
    copied_variable: {
      definition: {
        name: 'copied_variable',
        type: JobVariableType.TEXT,
        behaviour: JobVariableBehaviour.COPIED,
        description: '',
        visibility: JobVariableVisibility.PUBLIC
      },
      value: ''
    }
  },
  grids: {}
}
