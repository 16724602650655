import { type FunctionComponent, type PropsWithChildren } from 'react'

import classes from './InteractiveTabs.module.scss'

export const ScrollContainer: FunctionComponent<PropsWithChildren> = ({
  children
}) => (
  <div className={classes.InteractiveTabs__Scrollable}>
    <div
      className={classes.InteractiveTabs__ScrollArea}
      data-testid="tabs-scroll-container"
    >
      {children}
    </div>
  </div>
)
