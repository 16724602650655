import { type ParameterIdDependencies } from 'hooks/useParameterOptions/getParameterIdDependencies'
import { type ParameterMetadataDependencies } from 'hooks/useParameterOptions/getParameterMetadataDependencies'
import {
  type NestedDataPickerResponse,
  type ParameterOptionsRequestType
} from 'hooks/useParameterOptions/useParameterOptionsTypes'

import { type ComponentInstance } from 'job-lib/types/Job'

import {
  type ConnectorComponentMetadataResponse,
  type LookUpType,
  type ParameterDataType
} from '../useGetComponentMetadata/types'

export type GetParameterOptionsResponse<T extends ParameterOptionsRequestType> =
  T extends 'parameter-options'
    ? {
        editorColumns: EditorColumn[]
        helperData?: Record<string, unknown>
      }
    : T extends 'autofill-nested'
    ? NestedDataPickerResponse[]
    : string[][]

export interface GetParameterOptionsFailedResponse {
  timestamp: string
  message: string
  errors?: ErrorInformation[]
}

export interface ComponentDependency {
  component: ComponentInstance
  metlSlot: number
}

export interface LookupDefinition {
  component?: ComponentDependency
  parameters?: {
    [key: string]: string
  }
  'transform.sql'?: unknown
  parameterMetadataDependencies?: ParameterMetadataDependencies
  parameterIdDependencies?: ParameterIdDependencies
  customConnectorProfileDependencies?: Record<
    string,
    ConnectorComponentMetadataResponse | undefined
  > | null
}

export interface GridParameter extends EditorColumn {
  id: string
  options: string[] | null
  metlSlot: number
  validationDependencies?: null
  autoFill?: {
    lookupType: LookUpType | null
    lookupDependencies: string[] | null
  }
}

// columns from the dynamic property lookup
export interface EditorColumn {
  name: string | null
  type: ColumnType
  options: string[] | null
  columnEditorType: ColumnEditorType | null
  defaultValue: string | null
  lookupType: LookUpType | null
  lookupDependencies: string[] | null
  resourceID?: string | null
}

export interface NamedEditorColumn extends EditorColumn {
  name: string
}

export interface ErrorInformation {
  // an API field with an associated error
  field: string
  // the value that failed the validation
  rejectedValue: number
  // the underlying failure message
  message: string
  severity: 'LOW' | 'MEDIUM' | 'HIGH'
}

/**
 * The subset of parameter data types that are valid as columns
 * https://bitbucket.org/matillion/saas-component-information/src/8dde20cd036ad8ca18b2cfd407a8f88188fb0998/src/main/java/com/matillion/cloud/metadata/rest/api/v1/model/ColumnType.java?at=main#lines-4
 */
export type ColumnType = `${
  | ParameterDataType.TEXT
  | ParameterDataType.TEXT_SQL
  | ParameterDataType.INTEGER
  | ParameterDataType.FLOAT
  | ParameterDataType.BOOLEAN
  | ParameterDataType.DATE
  | ParameterDataType.TIME
  | ParameterDataType.TIMESTAMP
  | ParameterDataType.TEXT_SECRET_REF}`

export enum ColumnEditorType {
  Freeform = 'FREEFORM',
  Freetext = 'FREETEXT',
  FreetextMultiLine = 'FREETEXT_MULTI_LINE',
  Dropdown = 'DROPDOWN',
  TypeAhead = 'TYPEAHEAD',
  Expression = 'EXPRESSION',
  SqlEditor = 'SQL_EDITOR'
}

export interface APIError {
  detail: string
  status: number
  title: string
  type: string
}
