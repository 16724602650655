import { AgentHostEnum } from 'api/hooks/useAgentDetails/useAgentDetails'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

import { type ComponentSummaryResult } from '../types'

export const mockComponentInfo: ComponentSummaryResult = {
  results: [
    {
      schemaVersion: 1,
      componentId: 'start',
      classification: 'CONTROL_FLOW',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '444132438',
      inputPorts: [],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE', 'DATABRICKS'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'run-transformation',
      classification: 'CONTROL_FLOW',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '1896325668',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE', 'DATABRICKS'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'and',
      classification: 'CONTROL_FLOW',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '235671163',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.MANY
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'or',
      classification: 'CONTROL_FLOW',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '-1343684451',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.MANY
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'end-failure',
      classification: 'CONTROL_FLOW',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '515156205',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'end-success',
      classification: 'CONTROL_FLOW',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '-1946388514',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'retry',
      classification: 'CONTROL_FLOW',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '-158614544',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.ITERATION,
          cardinality: Cardinality.ONE
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'table-input',
      classification: 'TRANSFORMATION',
      presentationResourceVersion: 1,
      componentType: JobType.Transformation,
      emeraldId: '1354890871',
      inputPorts: [],
      outputPorts: [
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'distinct',
      classification: 'TRANSFORMATION',
      presentationResourceVersion: 1,
      componentType: JobType.Transformation,
      emeraldId: '1744268877',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'rank',
      classification: 'TRANSFORMATION',
      presentationResourceVersion: 1,
      componentType: JobType.Transformation,
      emeraldId: '2139809017',
      inputPorts: [],
      outputPorts: [],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'filter',
      classification: 'TRANSFORMATION',
      presentationResourceVersion: 1,
      componentType: JobType.Transformation,
      emeraldId: '-1760161015',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'rewrite-table',
      classification: 'TRANSFORMATION',
      presentationResourceVersion: 1,
      componentType: JobType.Transformation,
      emeraldId: '335239555',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'table-update',
      classification: 'TRANSFORMATION',
      presentationResourceVersion: 1,
      componentType: JobType.Transformation,
      emeraldId: '-1848704949',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'create-table',
      classification: 'EMERALD',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '1611478312',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE', 'DATABRICKS'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'jira-query',
      classification: 'EMERALD',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '1014376285',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'salesforce-query',
      classification: 'EMERALD',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '1121667926',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'run-orchestration',
      classification: 'CONTROL_FLOW',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '1785813072',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'sql',
      classification: 'TRANSFORMATION',
      presentationResourceVersion: 1,
      componentType: JobType.Transformation,
      emeraldId: '-1266674941',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ZEROORMORE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'sql-script',
      classification: 'EMERALD',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '-798585337',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'sqs-message',
      classification: 'EMERALD',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '-159239741',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 's3-load',
      classification: 'EMERALD',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '773867971',
      inputPorts: [
        { portId: ConnectionPortType.INPUT, cardinality: Cardinality.ONE }
      ],
      outputPorts: [
        { portId: ConnectionPortType.SUCCESS, cardinality: Cardinality.MANY },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        },
        { portId: ConnectionPortType.FAILURE, cardinality: Cardinality.MANY }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'generate-sequence',
      classification: 'TRANSFORMATION',
      presentationResourceVersion: 1,
      componentType: JobType.Transformation,
      emeraldId: '1439414779',
      inputPorts: [],
      outputPorts: [
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'create-file-format',
      classification: 'EMERALD',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '655623811',
      inputPorts: [],
      outputPorts: [
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'sns-message',
      classification: 'EMERALD',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '438858066',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'assert-table',
      classification: 'EMERALD',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '-377483579',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'multi-table-input',
      classification: 'TRANSFORMATION',
      presentationResourceVersion: 1,
      componentType: JobType.Transformation,
      emeraldId: '-2009723820',
      inputPorts: [],
      outputPorts: [
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'table-iterator',
      classification: 'CONTROL_FLOW',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '1227580116',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.ITERATION,
          cardinality: Cardinality.ONE
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'if',
      classification: 'CONTROL_FLOW',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '-1357378929',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.TRUE,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FALSE,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE'],
      cloudPlatforms: ['AWS']
    },
    {
      schemaVersion: 1,
      componentId: 'modular-google-sheets-input-v0',
      classification: 'MODULAR',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '-379653764',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        }
      ],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
      dwhTypes: ['SNOWFLAKE', 'REDSHIFT', 'DATABRICKS'],
      cloudPlatforms: ['AWS', 'AZURE']
    }
  ]
}

if (!('Cypress' in window)) {
  mockComponentInfo.results.push({
    schemaVersion: 1,
    componentId: 'dev-test-component',
    classification: 'EMERALD',
    presentationResourceVersion: 1,
    componentType: JobType.Orchestration,
    emeraldId: '9999999999',
    inputPorts: [
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.ONE
      }
    ],
    outputPorts: [
      {
        portId: ConnectionPortType.SUCCESS,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.FAILURE,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      }
    ],
    agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion],
    dwhTypes: ['SNOWFLAKE'],
    cloudPlatforms: ['AWS']
  })
}
