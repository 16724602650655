import usePushChanges from 'api/hooks/usePushChanges'
import { type PushActionArgs, type PushActionResponse } from './types'
import { useCallback } from 'react'
import { Toaster } from '@matillion/component-library'
import useProblemDetails from 'hooks/useProblemDetails'
import { type ProblemDetailRegistry } from 'hooks/useProblemDetails/types'
import { useTranslation } from 'react-i18next'
import { Problem } from 'hooks/useProblemDetails/Problem'

const problemDetailRegistry: ProblemDetailRegistry = [
  {
    type: Problem.WTS_PUSH_REJECTED,
    translation: {
      titleKey: 'push.problem.push-rejected.title',
      messageKey: 'push.problem.push-rejected.message'
    }
  },
  {
    type: Problem.WTS_REMOTE_BRANCH_NOT_FOUND,
    translation: {
      titleKey: 'push.problem.remote-branch-not-found.title',
      messageKey: 'push.problem.remote-branch-not-found.message'
    }
  }
]

const usePushAction = ({
  onSuccess,
  onFailure
}: PushActionArgs = {}): PushActionResponse => {
  const { makeToast } = Toaster.useToaster()
  const { mutateAsync: push, isLoading } = usePushChanges()
  const { t } = useTranslation('translation', { keyPrefix: 'push' })
  const { resolveProblemDetails } = useProblemDetails(problemDetailRegistry)

  const pushChanges = useCallback(async () => {
    try {
      const response = await push()

      onSuccess?.()

      makeToast({
        type: 'success',
        title: t('success.title'),
        message: t('success.message')
      })

      return response
    } catch (error) {
      resolveProblemDetails(error)
      onFailure?.()

      return undefined
    }
  }, [makeToast, onFailure, onSuccess, push, resolveProblemDetails, t])

  return {
    pushChanges,
    loading: isLoading
  }
}

export default usePushAction
