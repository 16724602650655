import { type ReactNode } from 'react'

import { Alert } from '@matillion/component-library'

import classes from './Footer.module.scss'

interface FooterProps {
  children: ReactNode
  warningText?: string
  isModalOpen?: boolean
}

export const Footer = ({ children, warningText, isModalOpen }: FooterProps) => (
  <div className={classes.Footer}>
    {!isModalOpen && <hr className={classes.Footer__Divider} />}
    {warningText && (
      <Alert
        className={classes.Footer__Warning}
        message={warningText}
        type="warning"
      />
    )}
    <div className={classes.Footer__Buttons}>{children}</div>
  </div>
)
