import { type GitProviderAuthModalProps } from './types'
import { Modal } from '@matillion/component-library'
import AuthModalForm from './AuthModalForm'

const GitProviderAuthModal = ({
  provider,
  additionalInfo,
  onClose
}: GitProviderAuthModalProps) => {
  return (
    <Modal
      size="mid"
      onCancel={onClose}
      disableBackdropClick
      id={`${provider}-auth-modal`}
      ariaLabelledBy="git-auth-title"
      data-testid={`${provider}-auth-modal`}
    >
      <AuthModalForm
        onClose={onClose}
        provider={provider}
        additionalInfo={additionalInfo}
      />
    </Modal>
  )
}

export default GitProviderAuthModal
