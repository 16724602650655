import { useReactFlow, type ReactFlowInstance } from 'reactflow'

import {
  type ComponentNodeData,
  type EtlCanvasEdgeData,
  type NoteNodeData
} from './useCanvasModel/useCanvasModel'

export type EtlFlowInstance = ReactFlowInstance<
  ComponentNodeData | NoteNodeData,
  EtlCanvasEdgeData
>

export const useEtlFlow = () =>
  useReactFlow<ComponentNodeData | NoteNodeData, EtlCanvasEdgeData>()
