import { type FC } from 'react'

import { Icon, MicroCta } from '@matillion/component-library'
import classNames from 'classnames'

import type {
  NestedPipeline,
  PipelineStatus
} from 'api/hooks/useEosClient/types/eos'

import { CellTypography } from '../components/CellTypography'
import TaskStatusIcon from '../components/TaskStatusIcon'
import classes from './PipelineNameCell.module.scss'

interface PipelineNameCellProps {
  depth: number
  pipelineName: string
  isExpanded: boolean
  nestedPipeline?: NestedPipeline
  onClick: () => void
  status: PipelineStatus
}

const DEPTH_MARGIN = 12

const PipelineNameCell: FC<PipelineNameCellProps> = ({
  depth,
  pipelineName,
  isExpanded,
  nestedPipeline,
  onClick,
  status
}) => {
  return (
    <div
      className={classNames(
        classes.PipelineNameCell,
        classes.PipelineNameCell__Expander
      )}
    >
      <div style={{ marginLeft: `${depth * DEPTH_MARGIN}px` }}>
        {nestedPipeline && (
          <MicroCta
            aria-label={isExpanded ? 'collapse' : 'expand'}
            alt="transparent"
            size="sm"
            className={classNames(classes.RowExpandTrigger, {
              [classes.expanded]: isExpanded
            })}
            onClick={onClick}
          >
            <Icon.ChevronDown />
          </MicroCta>
        )}
      </div>
      <TaskStatusIcon status={status} depth={String(depth)} />
      <CellTypography text={pipelineName} />
    </div>
  )
}

export default PipelineNameCell
