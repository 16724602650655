import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Field, Toggle, Typography } from '@matillion/component-library'

import classes from './PublishJobsToggle.module.scss'
import { type PublishJobsToggleProps } from './types'

const ToggleLabel = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'publish.toggle'
  })

  return (
    <>
      <Typography as="span" format="bcs" weight="bold">
        {t('label')}
      </Typography>
      <br />
      <Typography as="span" format="bcs">
        {t('desc')}
      </Typography>
    </>
  )
}

const PublishJobsToggle = ({
  disabled,
  checked,
  onToggle,
  onClick
}: PublishJobsToggleProps) => {
  const className = disabled ? classes.disabled : classes.toggle

  const handleChange = useCallback(() => {
    onClick()
    if (!disabled) {
      onToggle()
    }
  }, [disabled, onToggle, onClick])

  return (
    <Field
      small
      checked={checked}
      className={className}
      text={<ToggleLabel />}
      inputComponent={Toggle}
      onChange={handleChange}
      id="publish-jobs-toggle"
      aria-disabled={disabled}
      name="publish-jobs-toggle"
      data-testid="publish-jobs-toggle"
    />
  )
}

export default PublishJobsToggle
