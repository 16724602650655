import { useEffect, useMemo } from 'react'

import useGetBranch from 'api/hooks/useGetBranch/useGetBranch'

import { type IsBehindRemoteArgs, type IsBehindRemoteResponse } from './types'
import { type ReactQueryMeta } from '../../types/ReactQueryMeta'

const unableToPullQueryMeta: ReactQueryMeta = {
  error: {
    title: 'pull.toast.describe.error.title',
    message: 'pull.toast.describe.error.message'
  }
}

const useIsBehindRemote = ({
  branchName,
  onFailure,
  enabled
}: IsBehindRemoteArgs): IsBehindRemoteResponse => {
  const queryRefetchProps = {
    refetchOnMount: true,
    staleTime: 0,
    cacheTime: 0,
    enabled
  }

  const {
    data: localBranch,
    isLoading: localLoading,
    isError: isLocalError,
    fetchStatus: localStatus
  } = useGetBranch({
    branch: branchName,
    config: queryRefetchProps,
    meta: unableToPullQueryMeta
  })

  const {
    data: remoteBranch,
    isLoading: remoteLoading,
    isError: isRemoteError,
    fetchStatus: remoteStatus
  } = useGetBranch({
    branch: branchName,
    remoteBranch: branchName,
    config: queryRefetchProps,
    meta: unableToPullQueryMeta
  })

  useEffect(() => {
    if (isRemoteError || isLocalError) {
      onFailure?.()
    }
  }, [isLocalError, isRemoteError, onFailure])

  const isRemoteLoading = remoteLoading && remoteStatus !== 'idle'
  const isLocalLoading = localLoading && localStatus !== 'idle'

  const isBehind = useMemo(() => {
    if (localBranch && remoteBranch) {
      return localBranch.commitId !== remoteBranch.commitId
    }

    return undefined
  }, [localBranch, remoteBranch])

  return {
    isBehindRemote: isBehind,
    isLoading: isLocalLoading || isRemoteLoading
  }
}

export default useIsBehindRemote
