import React, { FunctionComponent, useCallback } from 'react'
import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react'

import { AuthHelpersContext } from '../context'
import getMarketingPayload from '../utils/getMarketingPayload'
import encodeLocation from '../utils/encodeLocation'
import useEnvironment from '../effects/useEnvironment'

const useAuthHelpers = () => {
  const { hubUrl } = useEnvironment()
  const { getAccessTokenSilently, loginWithRedirect, logout } = useAuth0()

  const logoutHandler = useCallback(
    ({ returnTo = window.location.origin, ...options } = {}) => {
      return logout({ returnTo, ...options })
    },
    [logout]
  )

  const loginHandler = useCallback(
    async ({
      state = {
        pathname: window.location.pathname,
        search: window.location.search
      },
      ...options
    }: RedirectLoginOptions = {}) => {
      const returnTo = {
        origin: window.location.origin,
        ...state
      }

      return loginWithRedirect({
        ...options,
        ...getMarketingPayload(),
        appState: {},
        redirectUri: `${hubUrl}?returnTo=${encodeLocation(returnTo)}`
      })
    },
    [loginWithRedirect, hubUrl]
  )

  return {
    getToken: getAccessTokenSilently,
    login: loginHandler,
    logout: logoutHandler
  }
}

const AuthHelpersProvider: FunctionComponent<React.PropsWithChildren> = ({
  children
}) => {
  const authHelpers = useAuthHelpers()

  return (
    <AuthHelpersContext.Provider value={authHelpers}>
      {children}
    </AuthHelpersContext.Provider>
  )
}

export { useAuthHelpers }
export default AuthHelpersProvider
