import { rest, type RequestHandler } from 'msw'

import config from 'config'

import { type ListProjectResponse } from '../types'
import { mockedProjects } from './MockedProjects'

export const useListProjectsHandlers: RequestHandler[] = [
  rest.get(`${config.getApiBaseUrl()}/v1/projects`, async (_req, res, ctx) => {
    return res(ctx.json<ListProjectResponse>(mockedProjects))
  })
]
