import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { jobActions } from 'job-lib/store'

import { type NodeInfo } from 'modules/Canvas/hooks/useCanvasModel/useCanvasModel'
import { getSelectedNodeInfo } from 'modules/Canvas/hooks/useCanvasModel/utils'
import { useEtlFlow } from 'modules/Canvas/hooks/useEtlFlow'
import { useComponentValidationProvider } from 'modules/core/ComponentValidation'

export const useDeleteNodes = () => {
  const dispatch = useDispatch()
  const { invalidateComponent } = useComponentValidationProvider()
  const etlFlow = useEtlFlow()
  const deleteNodes = useCallback(
    (selectedNodeInfos: NodeInfo[] = getSelectedNodeInfo(etlFlow)) => {
      dispatch(jobActions.deleteNodes(selectedNodeInfos))
      selectedNodeInfos.forEach((info) => {
        if (info.type === 'component') {
          invalidateComponent(info.id)
        }
      })
    },
    [dispatch, invalidateComponent, etlFlow]
  )

  return { deleteNodes }
}
