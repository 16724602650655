import React from 'react'

import { renderHook, waitFor } from '@testing-library/react'

import PactHookProviders from '../PactHookProviders'
import { type PactQueryHookConfig, type QueryResult } from './types'
import { assertPactApiHook } from '../assertPactApiHook'

/**
 * Renders the given API hook using {@link renderHook}, waits for success and
 * returns the query result. The hook must use useQuery().
 *
 * @param hook The API hook to render
 * @param config Optional configuration
 * @returns The {@link QueryResult} from the API hook
 */
const usePactApiQuery = async <ApiResponse, QueryArgs>(
  hook: (props: QueryArgs) => QueryResult<ApiResponse>,
  config?: Partial<PactQueryHookConfig<ApiResponse>>
) => {
  const { result, unmount } = renderHook(hook, {
    wrapper: (props) => (
      <PactHookProviders {...config}>{props.children}</PactHookProviders>
    )
  })

  await waitFor(() => {
    expect(result.current.isFetched).toBe(true)
  })

  await assertPactApiHook<ApiResponse>({ result, config })

  unmount()

  return result
}

export default usePactApiQuery
