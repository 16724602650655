import { useCallback, type FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Toaster, Typography } from '@matillion/component-library'

import { useDeleteJob } from 'api/hooks/useDeleteJob/useDeleteJob'
import { type JobSummary } from 'api/hooks/useGetJobSummaries'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useSelectedJobs } from 'hooks/useSelectedJobs'

import { usePipelineBrowserCommandProvider } from '../../effects/usePipelineBrowserCommand'
import { PipelineTreeReducerType } from '../../reducers/pipelineTreeReducer/types'
import classes from '../Modal.module.scss'

export interface Props {
  onClose: () => void
  jobSummary: JobSummary
}

export const DeletePipelineModal: FC<Props> = ({ onClose, jobSummary }) => {
  const { t } = useTranslation()
  const { closeJob } = useSelectedJobs()
  const { makeToast } = Toaster.useToaster()
  const { branchId, projectId } = useProjectInfo()
  const { onPipelineTreeCommand } = usePipelineBrowserCommandProvider()

  const { mutateAsync: deleteJob } = useDeleteJob()

  const onDeleteJob = useCallback(async () => {
    try {
      onPipelineTreeCommand({
        type: PipelineTreeReducerType.REMOVE_PIPELINE,
        pipelineType: jobSummary.type,
        path: jobSummary.folder,
        branch: branchId,
        project: projectId,
        name: jobSummary.name
      })

      await deleteJob(jobSummary.jobId)

      makeToast({
        type: 'success',
        title: `${t('translation:deleteJob.modal.success', {
          name: jobSummary.name
        })}`,
        message: ''
      })

      closeJob(jobSummary.jobId)
    } catch (error) {
      makeToast({
        type: 'error',
        title: `${t('translation:deleteJob.modal.error', {
          name: jobSummary.name
        })}`,
        message: ''
      })
    }
    onClose()
  }, [
    onClose,
    onPipelineTreeCommand,
    jobSummary.type,
    jobSummary.folder,
    jobSummary.name,
    jobSummary.jobId,
    branchId,
    projectId,
    deleteJob,
    makeToast,
    t,
    closeJob
  ])

  return (
    <DesignerModal
      onCancel={onClose}
      size="mid"
      ariaLabelledBy="delete-job-modal-heading"
      data-testid="delete-job-modal"
    >
      <div className={classes.Modal__Heading}>
        <Typography
          as="h2"
          format="tl"
          id="delete-job-modal-heading"
          className={classes.Modal__Title}
        >
          {t('translation:deleteJob.modal.heading')}
        </Typography>
      </div>
      <div className={classes.Modal__Content}>
        <Trans
          i18nKey={t('translation:deleteJob.modal.confirmationMessage')}
          values={{ name: jobSummary.name }}
          components={{ bold: <strong /> }}
        />

        <Typography format="bcm">
          {t('translation:deleteJob.modal.confirmationInfo')}
        </Typography>
      </div>
      <div className={classes.Modal__Actions}>
        <Button
          alt="secondary"
          text={t('translation:deleteJob.modal.cancel')}
          onClick={onClose}
          data-testid="delete-job-modal-cancel"
        />
        <Button
          alt="danger"
          text={t('translation:deleteJob.modal.validate')}
          onClick={() => {
            onDeleteJob()
          }}
          data-testid="delete-job-modal-validate"
        />
      </div>
    </DesignerModal>
  )
}
