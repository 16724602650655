import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useStore, type OnResizeEnd, type OnResizeStart } from 'reactflow'

import { jobActions } from 'job-lib/store'

import { getNoteReactFlowId } from 'modules/Canvas/hooks/useCanvasModel/utils'

export const useResizeNote = ({ id }: { id: number }) => {
  const dispatch = useDispatch()
  const [resizing, setResizing] = useState(false)
  const { resizingWidth, resizingHeight } = useStore(
    (state) => {
      const node = state.nodeInternals.get(getNoteReactFlowId(id))

      return {
        resizingWidth: node?.width,
        resizingHeight: node?.height
      }
    },
    (a, b) =>
      a.resizingHeight === b.resizingHeight &&
      a.resizingWidth === b.resizingWidth
  )

  const onResizeStart: OnResizeStart = () => {
    setResizing(true)
  }

  const onResizeEnd: OnResizeEnd = (_, params) => {
    const { x, y, width, height } = params
    dispatch(
      jobActions.updateNote({
        id,
        partialNote: {
          position: {
            x,
            y
          },
          size: {
            width,
            height
          }
        }
      })
    )
    setResizing(false)
  }

  return {
    resizingWidth,
    resizingHeight,
    onResizeStart,
    onResizeEnd,
    resizing
  }
}
