import { useMutation } from '@tanstack/react-query'

import { useSpringClient } from 'api/hooks/useSpringClient/useSpringClient'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { type Job } from 'modules/Canvas/hooks/useCanvasModel/useCanvasModel'

import { useAgentDetails } from '../useAgentDetails/useAgentDetails'
import useGetProject from '../useGetProject/useGetProject'

export interface UseSaveJob {
  job: Job
}

export const useSaveJob = () => {
  const { projectId, branchId, jobSummaryId } = useProjectInfo()
  const client = useSpringClient()
  const project = useGetProject()
  const warehouse = project?.data?.warehouse?.toUpperCase() ?? ''
  const { data: agentDetails } = useAgentDetails()
  const agentVersion = agentDetails?.agentVersion ?? ''

  const { mutateAsync } = useMutation(async ({ job }: UseSaveJob) => {
    const { data } = await client.put<Job>(
      `/projects/${projectId}/branches/${branchId}/jobs/${jobSummaryId}?dataWarehouseType=${warehouse}`,
      job,
      {
        headers: {
          // agent-version is required for DPL conversion.
          'agent-version': agentVersion
        }
      }
    )
    return data
  })

  return mutateAsync
}
