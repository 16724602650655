import { useEffect, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@matillion/component-library'

import { ReactComponent as ValidatingOrchestration } from 'assets/canvas/validating-orchestration.svg'
import { ReactComponent as ValidatingTransformation } from 'assets/canvas/validating-transformation.svg'

import { IconButton } from 'components/IconButton/IconButton'

import { JobType } from 'job-lib/types/JobType'

import { useComponentValidationProvider } from 'modules/core/ComponentValidation'

import { ToolboxButton } from './ToolboxButton'

interface ValidateButtonProps {
  jobType: JobType
  context: {
    context: string
  }
  showExpanded: boolean
}

export const ValidateButton: FC<ValidateButtonProps> = ({
  jobType,
  context,
  showExpanded
}) => {
  const { t } = useTranslation()
  const { setValidationEnabled, isValidatingJob, hasFinishedValidating } =
    useComponentValidationProvider()
  const [showInProgressSvg, setShowInProgressSvg] = useState(false)

  const ValidationInProgressIcon =
    jobType === JobType.Transformation
      ? ValidatingTransformation
      : ValidatingOrchestration

  const onButtonClick = () => {
    if (!showExpanded) {
      triggerValidation()
    }
  }
  const triggerValidation = () => {
    setValidationEnabled()
    setShowInProgressSvg(true)
  }

  const getValidationStatusLabel = () => {
    if (isValidatingJob) {
      return t('statuses.validatingJob')
    }

    if (hasFinishedValidating) {
      return t('statuses.validationFinished')
    }

    return t('canvasToolbox.validateJob')
  }

  useEffect(() => {
    let timeout: number

    if (showInProgressSvg) {
      timeout = window.setTimeout(() => {
        setShowInProgressSvg(false)
      }, 1000)
    }

    return () => {
      window.clearTimeout(timeout)
    }
  }, [showInProgressSvg])
  const buttonIcon = showInProgressSvg ? (
    <ValidationInProgressIcon title={t('statuses.loading')} />
  ) : (
    <Icon.ItemTickCircle />
  )
  const label = isValidatingJob
    ? t('statuses.validatingJob')
    : t('canvasToolbox.validateJob')
  const shortcut = isValidatingJob
    ? undefined
    : t('shortcuts.validateJob', context)
  const dataTestId = 'canvas-toolbox-validate-job'
  const disabled = isValidatingJob || showInProgressSvg
  const button = (
    <div>
      <IconButton
        aria-labelledby="canvas-toolbox-validate-job-status"
        data-testid={dataTestId}
        label={label}
        shortcut={shortcut}
        disabled={disabled}
        onClick={onButtonClick}
      >
        {buttonIcon}
      </IconButton>
      <div
        id="canvas-toolbox-validate-job-status"
        className="u-visually-hidden"
      >
        {getValidationStatusLabel()}
      </div>
    </div>
  )

  return (
    <ToolboxButton
      button={button}
      buttonIcon={buttonIcon}
      text={t('canvasToolbox.buttons.validate')}
      onClick={triggerValidation}
      showExpanded={showExpanded}
      disabled={disabled}
      label={label}
      shortcut={shortcut}
      dataTestId={dataTestId}
    />
  )
}
