import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { type AxiosResponse } from 'axios'

import { useSpringClient } from 'api/hooks/useSpringClient/useSpringClient'
import { queryKeys } from 'api/queryKeys'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { type RunJobRequest, type RunJobResponse } from './types'

const useRunJob = ({
  jobId,
  warehouse = ''
}: {
  jobId: string
  warehouse?: string
}) => {
  const { t } = useTranslation()
  const client = useSpringClient()
  const queryClient = useQueryClient()
  const { makeToast, clearToasts } = Toaster.useToaster()
  const { projectId, branchId, agentId, environmentId } = useProjectInfo()

  const url = `/job-executions?dataWarehouseType=${warehouse}`

  return useMutation(
    async () => {
      const { data } = await client.post<
        RunJobResponse,
        AxiosResponse,
        RunJobRequest
      >(url, {
        environmentId,
        agentId,
        projectId,
        branchId,
        jobId
      })

      return data
    },
    {
      onError: () => {
        clearToasts()
        makeToast({
          type: 'error',
          title: t('canvasToolbox.runJob'),
          message: t('canvas.runningJobError')
        })
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries([queryKeys.taskStatus])
      }
    }
  )
}

export default useRunJob
export * from './types'
