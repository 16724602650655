import {
  useCallback,
  useEffect,
  useRef,
  type FunctionComponent,
  type HTMLProps,
  type PropsWithChildren
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import classNames from 'classnames'

import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { useGetComponentSummary } from 'api/hooks/useGetComponentSummaries/useGetComponentSummary'
import { type JobSummary } from 'api/hooks/useGetJobSummaries'
import useGetProject from 'api/hooks/useGetProject/useGetProject'
import useRunJob from 'api/hooks/useRunJob/useRunJob'

import {
  ComponentDropDestination,
  type ComponentDropDestinationProps
} from 'components/ComponentDrag/ComponentDropDestination'
import {
  PopOverMenu,
  type PosXy,
  type RenderContentProps,
  type RenderPopOverContent
} from 'components/PopOverMenu'
import { useShortcut } from 'components/ShortcutProvider'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'

import { useDeleteNodes } from 'job-lib/hooks/useDeleteNodes/useDeleteNodes'
import { useGeneratePipelineDocsForSelection } from 'job-lib/hooks/useGeneratePipelineDocsForSelection/useGeneratePipelineDocsForSelection'
import { useMakeComponent } from 'job-lib/hooks/useMakeComponent/useMakeComponent'
import { useMakeNote } from 'job-lib/hooks/useMakeNote/useMakeNote'
import { jobActions } from 'job-lib/store'
import {
  type OrchestrationJob,
  type TransformationJob
} from 'job-lib/types/Job'

import { useCopyComponent } from 'modules/CopyPasteComponent/useCopyComponent'
import { usePasteComponent } from 'modules/CopyPasteComponent/usePasteComponent'
import { useScopedEvent } from 'modules/CopyPasteComponent/useScopedEvent'
import { useComponentValidationProvider } from 'modules/core/ComponentValidation'
import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'

import { track } from 'utils/heap'
import { isMacOs } from 'utils/isMacOs'

import { addTempComponent } from './addTempComponent'
import classes from './Canvas.module.scss'
import { ContextMenu } from './components/ContextMenu'
import { type ComponentNodeData } from './hooks/useCanvasModel/useCanvasModel'
import { getSelectedNodes, isComponentNode } from './hooks/useCanvasModel/utils'
import { useEtlFlow } from './hooks/useEtlFlow'

interface CanvasProviderProps extends HTMLProps<HTMLDivElement> {
  job: TransformationJob | OrchestrationJob
  jobSummary: JobSummary
}

export const CanvasProvider: FunctionComponent<
  PropsWithChildren<CanvasProviderProps>
> = ({ job, jobSummary, children, ...rest }) => {
  const { getDisplayName, getIcon } = useComponentInfo()
  const { t } = useTranslation()
  const reactFlow = useEtlFlow()
  const dispatch = useDispatch()
  const [makeComponent] = useMakeComponent()
  const { getByComponentId } = useGetComponentSummary()

  const { registerShortcut, unRegisterShortcut } = useShortcut()
  const { setValidationEnabled } = useComponentValidationProvider()
  const { deleteNodes } = useDeleteNodes()

  const project = useGetProject()
  const warehouse = project.data?.warehouse?.toUpperCase()

  const selectedNodes = getSelectedNodes(reactFlow)
  const selectedComponents = selectedNodes
    .filter((node) => isComponentNode(node))
    .map((item) => (item.data as ComponentNodeData).label)

  const { mutate: onRunJob } = useRunJob({
    jobId: jobSummary.jobId,
    warehouse
  })
  const { copyComponent } = useCopyComponent()
  const { pasteComponent } = usePasteComponent(dispatch)
  const { hasPermission: canRunPipelines } =
    useProjectPermission('run_pipelines')
  const { makeNote } = useMakeNote()
  const { generatePipelineDocs } = useGeneratePipelineDocsForSelection()
  const boundsRef = useRef<HTMLDivElement>(null)
  const { undoManager } = useWorkingCopy()
  const { resetValidation } = useComponentValidationProvider()

  useScopedEvent('copy', boundsRef, copyComponent)
  useScopedEvent('paste', boundsRef, () => {
    pasteComponent()
  })

  const getCanvasPosition = useCallback(
    (pos?: PosXy | null): PosXy => {
      if (!boundsRef.current || !pos) {
        return reactFlow.project({ x: 0, y: 0 })
      }

      const canvasBounds = boundsRef.current.getBoundingClientRect()

      return reactFlow.project({
        x: pos.x - canvasBounds.left,
        y: pos.y - canvasBounds.top
      })
    },
    [reactFlow]
  )

  const handleCommand = useCallback(
    async (id: string, { popOverClientPosition }: RenderContentProps) => {
      const pasteComponentWithPosition = () => {
        pasteComponent(getCanvasPosition(popOverClientPosition))
      }

      const undoComponent = () => {
        undoManager?.undo()
        resetValidation()
      }

      const redoComponent = () => {
        undoManager?.redo()
        resetValidation()
      }

      const commands = {
        runJob: () => {
          onRunJob()
        },
        validateJob: setValidationEnabled,
        delete: deleteNodes,
        copy: copyComponent,
        paste: pasteComponentWithPosition,
        undo: undoComponent,
        redo: redoComponent,
        addNote: () => {
          makeNote(getCanvasPosition(popOverClientPosition))
        },
        generateDocumentation: async () =>
          generatePipelineDocs(
            getCanvasPosition(popOverClientPosition),
            selectedComponents
          )
      }

      type Commands = typeof commands

      const command = commands[id as keyof Commands]

      if (!command) {
        console.warn('Canvas: context menu command is not yet supported', [id])
        return
      }

      return command()
    },
    [
      onRunJob,
      setValidationEnabled,
      deleteNodes,
      copyComponent,
      pasteComponent,
      getCanvasPosition,
      makeNote,
      undoManager,
      resetValidation,
      selectedComponents,
      generatePipelineDocs
    ]
  )

  const onDropComponent: ComponentDropDestinationProps['onDropComponent'] =
    useCallback(
      async (
        id,
        { initialParameters, relativeDropPoint, dragOrigin, componentName }
      ) => {
        const tempLabel = `${t('statuses.loadingNew')} ${getDisplayName(id)}...`
        const point = reactFlow.project({
          x: relativeDropPoint.x,
          y: relativeDropPoint.y
        })

        addTempComponent(
          id,
          tempLabel,
          point,
          reactFlow,
          getByComponentId(id),
          getIcon
        )

        if (dragOrigin === 'component-browser') {
          track('etld_add-component_drag', { componentType: id, componentName })
        } else if (dragOrigin === 'job-browser') {
          track('etld_add-component_drag-job', {
            componentType: id,
            componentName
          })
        }

        dispatch(
          jobActions.addComponent(
            await makeComponent({
              id,
              x: point.x,
              y: point.y,
              componentName,
              initialValues: initialParameters
            })
          )
        )
      },
      [
        dispatch,
        makeComponent,
        reactFlow,
        t,
        getDisplayName,
        getByComponentId,
        getIcon
      ]
    )

  useEffect(() => {
    /* istanbul ignore next */
    if (canRunPipelines) {
      /* istanbul ignore next */
      registerShortcut({
        id: 'activeJobTab-RunJob',
        key: 'Enter',
        metaKey: isMacOs(),
        ctrlKey: !isMacOs(),
        callback: () => {
          track('etld_context-menu-run-job-cta_click', {
            jobType: jobSummary?.type
          })
          onRunJob()
        }
      })

      return () => {
        unRegisterShortcut('activeJobTab-RunJob')
      }
    }
  }, [
    job,
    registerShortcut,
    unRegisterShortcut,
    onRunJob,
    jobSummary.type,
    canRunPipelines
  ])

  const makePopoverContent = useCallback<RenderPopOverContent>(
    ({ popOverClientPosition }) => (
      <ContextMenu
        jobName={jobSummary.name}
        jobType={jobSummary.type}
        onCommand={async (command) =>
          handleCommand(command, { popOverClientPosition })
        }
        hasSelectedNodes={Boolean(selectedNodes.length)}
        hasSelectedComponents={Boolean(selectedComponents.length)}
      />
    ),
    [
      handleCommand,
      jobSummary.name,
      jobSummary.type,
      selectedNodes,
      selectedComponents
    ]
  )

  return (
    <div
      {...rest}
      ref={boundsRef}
      className={classNames(classes.CanvasProvider, rest.className)}
      // needs to be able to receive focus so we can detect copy and paste events within this element
      tabIndex={-1}
    >
      <PopOverMenu positionAtMouse content={makePopoverContent}>
        {({ onContextMenu }) => (
          <ComponentDropDestination
            onContextMenu={onContextMenu}
            onDropComponent={onDropComponent}
            data-testid="canvas-container"
            className={classes.Canvas}
          >
            {children}
          </ComponentDropDestination>
        )}
      </PopOverMenu>
    </div>
  )
}
