import { type FC } from 'react'

import { Typography } from '@matillion/component-library'

import { ReactComponent as ExternalLinkIcon } from 'assets/external-link.svg'

import classes from './ExternalLink.module.scss'

interface ExternalLinkProps {
  title: string
  link: string
  ariaLabel: string
}

const ExternalLink: FC<ExternalLinkProps> = ({ title, link, ariaLabel }) => {
  return (
    <div className={classes.ExternalLink}>
      <a
        href={link}
        aria-label={ariaLabel}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography format="bcs">{title}</Typography>
        <ExternalLinkIcon
          title="External Link Icon"
          data-testid="external-link-icon"
        />
      </a>
    </div>
  )
}

export default ExternalLink
