import { type FunctionComponent } from 'react'

import { t } from 'i18next'

import { ReactComponent as TaskIconSvg } from 'assets/TaskIcon.svg'

import { JobTypeIcon } from 'components/JobTypeIcon/JobTypeIcon'

import { TaskType, type EtlDocumentTypes } from 'modules/JobTabs/types'

const TaskTypeIcon: FunctionComponent = () => (
  <TaskIconSvg
    title={t('canvas.orchestrationJob')}
    data-testid="task-job-icon"
  />
)

interface TabIconProps {
  documentType: EtlDocumentTypes
}

export const EtlDocumentTabIcon: FunctionComponent<TabIconProps> = ({
  documentType
}) =>
  documentType === TaskType.FlowSummary ? (
    <TaskTypeIcon />
  ) : (
    <JobTypeIcon jobType={documentType} />
  )
