import { type FC, type ReactNode } from 'react'

import { Tooltip, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './CellTypography.module.scss'

interface Props {
  text: ReactNode
  subtext?: ReactNode
  testId?: string
  subtextTestId?: string
  format?: 'bcs' | 'bcm'
}

export const CellTypography: FC<Props> = ({
  text,
  subtext,
  testId,
  subtextTestId,
  format = 'bcs'
}) => {
  return (
    <>
      <div className={classes.MainTextContainer} data-testid={testId}>
        <Tooltip content={text} onlyShowOnOverflow>
          <Typography
            className={classNames(classes.MainTextContainer__Text, {
              'u-truncate-text-2-lines': !subtext,
              [classes['MainTextContainer__Text--noWrap']]: !!subtext
            })}
            format={format}
            weight="regular"
          >
            {text}
          </Typography>
        </Tooltip>
      </div>
      {subtext && (
        <div className={classes.SubtextContainer} data-testid={subtextTestId}>
          <Typography
            format="bcs"
            className={classNames(classes.Subtext, {
              'u-truncate-text-2-lines': !text,
              [classes['Subtext--noWrap']]: !!text
            })}
          >
            {subtext}
          </Typography>
        </div>
      )}
    </>
  )
}
