import {
  ComponentEditorTabType,
  EditorType,
  ExportVariableType,
  ParameterDataType,
  UseVariable,
  VisibleWhenOperator,
  type ComponentMetadataResponse
} from 'api/hooks/useGetComponentMetadata/types'
import { ColumnEditorType } from 'api/hooks/useGetParameterOptions/types'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

export const mockRetryMetadata: ComponentMetadataResponse = {
  componentId: 'retry',
  metadata: {
    schemaVersion: 1,
    emeraldId: '-158614544',
    componentType: JobType.Orchestration,
    classification: 'CONTROL_FLOW',
    inputPorts: [
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.ONE
      }
    ],
    outputPorts: [
      {
        portId: ConnectionPortType.SUCCESS,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.FAILURE,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.ITERATION,
        cardinality: Cardinality.ONE
      }
    ],
    deprecated: false,
    exports: [
      ExportVariableType.COMPLETED_AT,
      ExportVariableType.COMPONENT_NAME,
      ExportVariableType.DURATION,
      ExportVariableType.ITERATIONS_ATTEMPTED,
      ExportVariableType.ITERATIONS_GENERATED,
      ExportVariableType.ITERATIONS_SUCCESSFUL,
      ExportVariableType.MESSAGE,
      ExportVariableType.ROW_COUNT,
      ExportVariableType.STARTED_AT,
      ExportVariableType.STATUS
    ],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.EXPORT,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'numberOfRetries',
        metlSlot: 2,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.INTEGER,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'retryDelay',
        metlSlot: 3,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            type: 'TEXT',
            options: [
              'Custom',
              'Long delay',
              'Long delay with backoff',
              'Retry immediately',
              'Short delay',
              'Short delay with backoff'
            ],
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null
      },
      {
        dplID: 'retryInterval',
        metlSlot: 4,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.INTEGER,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'retryDelay',
            value: 'Custom',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'retryTimeUnit',
        metlSlot: 5,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'retryDelay',
            value: 'Custom',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: [
          {
            name: null,
            type: 'TEXT',
            options: ['Hour', 'Minute', 'Second'],
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null
      }
    ]
  }
}
