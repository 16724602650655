import useClient from '../useClient'
import { useApiContext } from 'api/context/useApiContext'

const useEntitlementsClient = () => {
  const { entitlements } = useApiContext()

  return useClient({
    host: entitlements,
    contextUrl: '/v1/entitlements'
  })
}

export default useEntitlementsClient
