import React, { useEffect, type FC, type HTMLProps } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import { useGetJobSummaries } from 'api/hooks/useGetJobSummaries'

import { Loading } from 'components/Loading/Loading'
import { ErrorPanel } from 'components/Panels/ErrorPanel'

import { useSelectedJobs } from 'hooks/useSelectedJobs'

import { EmptyPipelineBrowserList } from './components/EmptyPipelineBrowserList/EmptyPipelineBrowserList'
import PipelineBrowserToolBar from './components/PipelineBrowserToolBar/PipelineBrowserToolBar'
import { PipelineBrowserTree } from './components/PipelineBrowserTree/PipelineBrowserTree'
import { PipelineBrowserCommandProvider } from './context/PipelineBrowserCommandProvider'
import classes from './PipelineBrowser.module.scss'

export type Props = HTMLProps<HTMLDivElement>

const PipelineBrowser: FC<Props> = (props) => {
  const { t } = useTranslation()
  const { data, isLoading, isError } = useGetJobSummaries()
  const { invalidateStaleSelectedJobs } = useSelectedJobs()

  useEffect(() => {
    if (data?.results) {
      const jobIds = data.results.map((summary) => summary.jobId)
      invalidateStaleSelectedJobs(jobIds)
    }
  }, [data, invalidateStaleSelectedJobs])

  return (
    <PipelineBrowserCommandProvider>
      <div
        className={classes.PipelineBrowser}
        aria-live="polite"
        aria-busy={isLoading}
        {...props}
      >
        <div
          className={classes.PipelineBrowser__Header}
          data-testid="section-jobs-header"
        >
          <Typography
            weight="bold"
            as="h2"
            format="bcs"
            className={classes.PipelineBrowser__Title}
          >
            {t('sideBar.jobPanel.title')}
          </Typography>
          <PipelineBrowserToolBar />
        </div>

        {isLoading && <Loading className={classes.PipelineBrowser__Loading} />}

        {isError && <ErrorPanel text={t('sideBar.jobPanel.networkError')} />}

        {data && (
          <div className={classes.PipelineBrowser__ListWrapper}>
            <div className={classes.PipelineBrowser__Content}>
              {data?.results.length ? (
                <PipelineBrowserTree jobs={data?.results} />
              ) : (
                <EmptyPipelineBrowserList />
              )}
            </div>
          </div>
        )}
      </div>
    </PipelineBrowserCommandProvider>
  )
}

export default PipelineBrowser
