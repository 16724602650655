import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, MicroCta } from '@matillion/component-library'

import classes from './RowTypography.module.scss'

export interface Props {
  isCompleted: boolean
  isExecuting: boolean
  isCancelling: boolean
  id: string
  onNavigate: () => void
  onCancel: () => void
}

const RowCTA: FC<Props> = ({
  id,
  isCompleted,
  isExecuting,
  isCancelling,
  onNavigate,
  onCancel
}) => {
  const { t } = useTranslation()

  if (isCompleted) {
    return (
      <MicroCta
        aria-label={t('taskHistory.taskShowDetails')}
        alt="transparent"
        size="sm"
        onClick={onNavigate}
        className={classes.RowDetailTrigger}
        data-testid={`task-trigger-details-${id}`}
      >
        <Icon.ArrowLeft className={classes.RightArrow} />
      </MicroCta>
    )
  }
  if (isExecuting || isCancelling) {
    return (
      <MicroCta
        aria-label={t('taskHistory.cancelTask')}
        alt="transparent"
        size="sm"
        disabled={isCancelling}
        onClick={onCancel}
        className={classes.RowDetailTrigger}
        data-testid={`task-cancel-${id}`}
      >
        <Icon.Cross className={classes.CancelIcon} />
      </MicroCta>
    )
  }
  return null
}

export default RowCTA
