import config from 'config'

import { useBrowserStoredFlag } from './useBrowserStoredFlag'

export function useOnboarded() {
  return useBrowserStoredFlag({
    storage: localStorage,
    key: config.browserStoreKeys.onboarded
  })
}
