import { type FC } from 'react'

import { Typography } from '@matillion/component-library'
import classnames from 'classnames'

import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import { type ComponentInstance } from 'job-lib/types/Job'

import classes from './ComponentParameters.module.scss'
import { type EditedParameter } from './ComponentParametersContainer'
import { ComponentParameterItem } from './components/ComponentParameterItem/ComponentParameterItem'

interface ComponentParametersGridProps {
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  componentSummaryId: ComponentSummaryId
  onEdit: (params: EditedParameter) => void
}

export const ComponentParametersGrid: FC<ComponentParametersGridProps> = ({
  componentInstance,
  componentMetadata,
  componentSummaryId,
  onEdit
}) => {
  return (
    <>
      <div role="rowgroup">
        <div
          role="row"
          className={classnames(
            classes.ComponentParameters__Row,
            classes.ComponentParameters__HeaderRow
          )}
        >
          <Typography
            as="div"
            format="mc"
            weight="bold"
            role="columnheader"
            scope="col"
            className={classnames(
              classes.ComponentParameters__Cell,
              classes['ComponentParameters__Cell--Name']
            )}
          >
            Parameter
          </Typography>
          <Typography
            as="div"
            format="mc"
            weight="bold"
            role="columnheader"
            scope="col"
            className={classnames(
              classes.ComponentParameters__Cell,
              classes['ComponentParameters__Cell--Value'],
              classes.ComponentParameters__ValueHeaderCell
            )}
          >
            Value
          </Typography>
        </div>
      </div>

      <div role="rowgroup" data-testid="component-parameters">
        {componentMetadata.parameters.map((parameter) => (
          <ComponentParameterItem
            key={`parameter-${parameter.dplID}`}
            parameter={parameter}
            componentSummaryId={componentSummaryId}
            componentInstance={componentInstance}
            componentMetadata={componentMetadata}
            onEdit={onEdit}
          />
        ))}
      </div>
    </>
  )
}
