import { type ListProjectVariablesResponse } from 'api/hooks/useListProjectVariables/types'

import {
  VariableScope,
  type JobVariableCollection
} from 'job-lib/types/Variables'

import {
  ErrorStatuses,
  Fields,
  type FormState,
  type ReducerActions
} from 'modules/ManageVariables/types'

import { errorMessages } from '../../Form/errorMessages'
import { reservedWords } from '../../Form/reservedWords'
import { isFormValid } from './isFormValid'

export const updateName = (
  state: FormState,
  action: {
    type: ReducerActions.UPDATE_NAME
    value: string
    jobVariables: JobVariableCollection | undefined
    projectVariables: ListProjectVariablesResponse
    originalName?: string
  },
  selectedVariableScope: VariableScope
): FormState => {
  let errorStatus = ErrorStatuses.NO_ERROR

  const { value, jobVariables, projectVariables, originalName } = action

  const isReservedWord = reservedWords.includes(
    action.value.toLowerCase().trim()
  )

  const isCorrectFormat = /^([a-zA-Z_$][\w$]+|[a-zA-Z_]*)$/.test(value)
  const isSingle$ = /^[$]$/.test(value)

  if (!isCorrectFormat) {
    errorStatus = ErrorStatuses.INCORRECT_NAME_FORMAT
  }

  if (isSingle$) {
    errorStatus = ErrorStatuses.SINGLE_$
  }

  if (isReservedWord) {
    errorStatus = ErrorStatuses.RESERVED_WORD
  }

  const isUpdatingPipelineVariable =
    selectedVariableScope === VariableScope.JOB_VARIABLE
  const isExistingPipelineVariable = Boolean(jobVariables?.[value])
  const isDuplicatePipelineVariable =
    isExistingPipelineVariable && isUpdatingPipelineVariable

  const isUpdatingProjectVariable =
    selectedVariableScope === VariableScope.PROJECT_VARIABLE
  const isExistingProjectVariable = projectVariables.some(
    (pv) => pv.name === value
  )
  const isDuplicateProjectVariable =
    isExistingProjectVariable && isUpdatingProjectVariable

  if (
    value !== originalName &&
    (isDuplicatePipelineVariable || isDuplicateProjectVariable)
  ) {
    errorStatus = ErrorStatuses.DUPLICATE
  }

  if (!value) {
    errorStatus = ErrorStatuses.EMPTY
  }

  const isValid = errorStatus === ErrorStatuses.NO_ERROR

  const updatedState = {
    ...state,
    [Fields.NAME]: {
      isValid,
      value,
      error: errorMessages[errorStatus]
    }
  }

  return {
    ...updatedState,
    isFormValid: isFormValid(updatedState, selectedVariableScope)
  }
}
