import { type JobTree, type TreeGridItemDepth } from '../types'

export const DEPTH_PADDING = 8
export const DEPTH_SEPARATOR = '-'

export const sortTreeItems = (item1: JobTree, item2: JobTree): number => {
  const paddedItem1 = item1.depth
    .split('-')
    .map((part) => part.padStart(10, '0'))
    .join('-')
  const paddedItem2 = item2.depth
    .split('-')
    .map((part) => part.padStart(10, '0'))
    .join('-')
  return paddedItem1.localeCompare(paddedItem2)
}

export const createChildrenDepth = (
  parent: JobTree,
  id: number
): TreeGridItemDepth => {
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `${parent.depth}${DEPTH_SEPARATOR}${id}`
}

export const trimItemDepth = (depth: TreeGridItemDepth): TreeGridItemDepth => {
  const newDepth = depth.split(DEPTH_SEPARATOR)
  newDepth.pop()
  return newDepth.join(DEPTH_SEPARATOR)
}

export const getDepthLevel = (depth: TreeGridItemDepth) => {
  return depth.split(DEPTH_SEPARATOR).length
}

export const filterTreeItems =
  (depths: TreeGridItemDepth[]) =>
  (value: JobTree): boolean => {
    const anyFilterMatching = depths.some((depth) => {
      const itemDepth = trimItemDepth(value.depth)
      const filterDepth = depth + DEPTH_SEPARATOR
      return itemDepth.startsWith(filterDepth) || itemDepth === depth
    })

    return !anyFilterMatching
  }

export const findParent = (
  oldItems: JobTree[],
  parentDepth: string
): JobTree | undefined => {
  return oldItems.find((x) => x.depth === parentDepth)
}
