import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'

import classesCanvas from 'modules/Canvas/Canvas.module.scss'

import classes from './FlowStatsExplorer.module.scss'

const EmptyFlowExplorer = () => {
  const { t } = useTranslation()

  return (
    <div
      className={classes.LoadingContainer}
      data-testid="flowStats-error-container"
    >
      <Icon.InfoNoBg className={classesCanvas.Overlay__Icon} />

      <Typography className={classesCanvas.Overlay__Text}>
        {t('translation:taskExplorer.networkError')}
      </Typography>
    </div>
  )
}

export default EmptyFlowExplorer
