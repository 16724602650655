import { type RequestHandler, rest } from 'msw'

import { PROJECT_ID } from '__test-utils__/ids'

import { type WtsDescribeMergeResponse } from 'api/hooks/useDescribeMerge/types'
import { useDescribeMergeResponses } from 'api/hooks/useDescribeMerge/useDescribeMerge.responses'
import { APPLICATION_PROBLEM_JSON } from '__test-utils__/pact.config'
import { Problem } from 'hooks/useProblemDetails/Problem'

export const stubDescribeMerge = (baseUrl = '', projectId = PROJECT_ID) => {
  return Object.keys(useDescribeMergeResponses).map(() => {
    const url = `${baseUrl}/working-tree-service/v1/projects/${projectId}/branches/:branchName/merges`

    return rest.get(url, async (req, res, ctx) => {
      const targetBranch = req.params.branchName as string

      if (targetBranch === 'branchNotFound') {
        return res(
          ctx.status(404),
          ctx.set('content-type', APPLICATION_PROBLEM_JSON),
          ctx.json({
            type: Problem.WTS_BRANCH_NOT_FOUND,
            title: 'Not found',
            status: 404
          })
        )
      } else if (targetBranch === 'uncommittedChangesDescribeConflict') {
        return res(
          ctx.status(409),
          ctx.set('content-type', APPLICATION_PROBLEM_JSON),
          ctx.json({
            type: Problem.WTS_UNCOMMITTED_CHANGES_PREVENT_MERGE,
            title: 'Conflict',
            status: 409
          })
        )
      } else if (targetBranch === 'remoteBranchNotFound') {
        return res(
          ctx.status(404),
          ctx.set('content-type', APPLICATION_PROBLEM_JSON),
          ctx.json({
            type: Problem.WTS_REMOTE_BRANCH_NOT_FOUND,
            title: 'Not Found',
            status: 404
          })
        )
      } else if (targetBranch === '500') {
        return res(ctx.status(500))
      } else {
        return res(
          ctx.json<WtsDescribeMergeResponse>(
            useDescribeMergeResponses[targetBranch]
          )
        )
      }
    })
  })
}

export const describeMergeWithConflictHandlers = [
  rest.get(
    '/working-tree-service/v1/projects/test-project/branches/*/merges',
    async (_req, res, ctx) => {}
  )
]

export const useDescribeMergeHandlers: RequestHandler[] = [
  ...stubDescribeMerge(),
  ...stubDescribeMerge('', '500'),
  ...stubDescribeMerge('', 'test-project')
]
