// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hgSEOhGCoO0QRLNn5VaA{margin-bottom:30px;text-align:center}.PvW97e6qoyRWBTEIMTbr{margin-bottom:15px}.PQfedYS86IhqO7DEoUTl{margin-top:30px}.THFWXxmItmc7Fy0PRiwa{height:40px;margin-left:auto}`, "",{"version":3,"sources":["webpack://./src/modules/BringYourOwnGit/components/AuthSuccessModal/AuthSuccessModal.module.scss"],"names":[],"mappings":"AAEA,sBACE,kBAAA,CACA,iBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,eAAA,CAGF,sBACE,WAAA,CACA,gBAAA","sourcesContent":["@import '@matillion/component-library/dist/assets/styles/colours';\n\n.title {\n  margin-bottom: 30px;\n  text-align: center;\n}\n\n.body {\n  margin-bottom: 15px;\n}\n\n.buttons {\n  margin-top: 30px;\n}\n\n.button {\n  height: 40px;\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `hgSEOhGCoO0QRLNn5VaA`,
	"body": `PvW97e6qoyRWBTEIMTbr`,
	"buttons": `PQfedYS86IhqO7DEoUTl`,
	"button": `THFWXxmItmc7Fy0PRiwa`
};
export default ___CSS_LOADER_EXPORT___;
