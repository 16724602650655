import { OrchestrationJob, TransformationJob } from 'icons'
import { JobType } from 'types/JobType'
import { useTranslation } from 'react-i18next'

export interface JobTypeIconProps {
  jobType: JobType
}

export const JobTypeIcon = ({ jobType }: JobTypeIconProps) => {
  const { t } = useTranslation()

  if (jobType === JobType.Orchestration) {
    return (
      <OrchestrationJob
        title={t('canvas.orchestrationJob')}
        data-testid="orchestration-job-icon"
      />
    )
  }

  return (
    <TransformationJob
      title={t('canvas.transformationJob')}
      data-testid="transformation-job-icon"
    />
  )
}
