// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nd4GoJwdK1GmqogoP_c4{padding:20px}.TENraOF0zr6iuUiyDMtc{padding-left:15px}.kYJNapln6Lbr_LjRI2LQ{margin-bottom:25px}.Uoigdjz0oEy34eTUqoIr{margin-bottom:15px}.BL_2YwdxVueu2jsioELD{margin-bottom:30px}.YxdwOGIziAuWgUsuEPYx{margin-bottom:15px}.dffFxWtSaf6nFhtA0q67{margin-top:15px}`, "",{"version":3,"sources":["webpack://./src/modules/BringYourOwnGit/components/InterstitialAuthRedirectPage/InterstitialAuthRedirectPage.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAGF,sBACE,iBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,eAAA","sourcesContent":[".wrapper {\n  padding: 20px;\n}\n\n.body {\n  padding-left: 15px;\n}\n\n.title {\n  margin-bottom: 25px;\n}\n\n.desc {\n  margin-bottom: 15px;\n}\n\n.info {\n  margin-bottom: 30px;\n}\n\n.loading {\n  margin-bottom: 15px;\n}\n\n.help {\n  margin-top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `nd4GoJwdK1GmqogoP_c4`,
	"body": `TENraOF0zr6iuUiyDMtc`,
	"title": `kYJNapln6Lbr_LjRI2LQ`,
	"desc": `Uoigdjz0oEy34eTUqoIr`,
	"info": `BL_2YwdxVueu2jsioELD`,
	"loading": `YxdwOGIziAuWgUsuEPYx`,
	"help": `dffFxWtSaf6nFhtA0q67`
};
export default ___CSS_LOADER_EXPORT___;
