import { type NestedDataPickerResponse } from 'hooks/useParameterOptions/useParameterOptionsTypes'

import { DataStructureType } from 'modules/ParameterEditors/components/NestedDataPickerEditor/types'

export const mockNestedAutofillResponse: NestedDataPickerResponse[] = [
  {
    name: 'images',
    datatype: DataStructureType.VARIANT,
    size: 0,
    decimalPlaces: 0,
    columnName: 'Data Value',
    aliasName: 'images',
    isArray: false,
    include: true,
    childElements: [
      {
        name: 'large',
        datatype: DataStructureType.VARIANT,
        size: 0,
        decimalPlaces: 0,
        columnName: 'Data Value',
        aliasName: 'images_large',
        isArray: true,
        include: true,
        childElements: [
          {
            name: 'large-element',
            datatype: DataStructureType.VARCHAR,
            size: 0,
            decimalPlaces: 0,
            columnName: 'Data Value',
            aliasName: 'images_large-element',
            isArray: false,
            include: true,
            childElements: []
          }
        ]
      }
    ]
  },
  {
    name: 'launch_attempts',
    datatype: DataStructureType.NUMBER,
    size: 0,
    decimalPlaces: 0,
    columnName: 'Data Value',
    aliasName: 'launch_attempts',
    isArray: false,
    include: true,
    childElements: []
  },
  {
    name: 'timezone',
    datatype: DataStructureType.VARCHAR,
    size: 0,
    decimalPlaces: 0,
    columnName: 'Data Value',
    aliasName: 'timezone',
    isArray: false,
    include: true,
    childElements: []
  },
  {
    name: 'latitude',
    datatype: DataStructureType.FLOAT,
    size: 0,
    decimalPlaces: 0,
    columnName: 'Data Value',
    aliasName: 'latitude',
    isArray: false,
    include: true,
    childElements: []
  },
  {
    name: 'locality',
    datatype: DataStructureType.VARCHAR,
    size: 0,
    decimalPlaces: 0,
    columnName: 'Data Value',
    aliasName: 'locality',
    isArray: false,
    include: true,
    childElements: []
  },
  {
    name: 'rockets',
    datatype: DataStructureType.VARIANT,
    size: 0,
    decimalPlaces: 0,
    columnName: 'Data Value',
    aliasName: 'rockets',
    isArray: true,
    include: true,
    childElements: [
      {
        name: 'rockets-element',
        datatype: DataStructureType.VARCHAR,
        size: 0,
        decimalPlaces: 0,
        columnName: 'Data Value',
        aliasName: 'rockets-element',
        isArray: false,
        include: true,
        childElements: []
      }
    ]
  },
  {
    name: 'full_name',
    datatype: DataStructureType.VARCHAR,
    size: 0,
    decimalPlaces: 0,
    columnName: 'Data Value',
    aliasName: 'full_name',
    isArray: false,
    include: true,
    childElements: []
  },
  {
    name: 'name',
    datatype: DataStructureType.VARCHAR,
    size: 0,
    decimalPlaces: 0,
    columnName: 'Data Value',
    aliasName: 'name',
    isArray: false,
    include: true,
    childElements: []
  },
  {
    name: 'details',
    datatype: DataStructureType.VARCHAR,
    size: 0,
    decimalPlaces: 0,
    columnName: 'Data Value',
    aliasName: 'details',
    isArray: false,
    include: true,
    childElements: []
  },
  {
    name: 'id',
    datatype: DataStructureType.VARCHAR,
    size: 0,
    decimalPlaces: 0,
    columnName: 'Data Value',
    aliasName: 'id',
    isArray: false,
    include: true,
    childElements: []
  },
  {
    name: 'region',
    datatype: DataStructureType.VARCHAR,
    size: 0,
    decimalPlaces: 0,
    columnName: 'Data Value',
    aliasName: 'region',
    isArray: false,
    include: true,
    childElements: []
  },
  {
    name: 'launch_successes',
    datatype: DataStructureType.NUMBER,
    size: 0,
    decimalPlaces: 0,
    columnName: 'Data Value',
    aliasName: 'launch_successes',
    isArray: false,
    include: true,
    childElements: []
  },
  {
    name: 'launches',
    datatype: DataStructureType.VARIANT,
    size: 0,
    decimalPlaces: 0,
    columnName: 'Data Value',
    aliasName: 'launches',
    isArray: true,
    include: true,
    childElements: [
      {
        name: 'launches-element',
        datatype: DataStructureType.VARCHAR,
        size: 0,
        decimalPlaces: 0,
        columnName: 'Data Value',
        aliasName: 'launches-element',
        isArray: false,
        include: true,
        childElements: []
      }
    ]
  },
  {
    name: 'status',
    datatype: DataStructureType.VARCHAR,
    size: 0,
    decimalPlaces: 0,
    columnName: 'Data Value',
    aliasName: 'status',
    isArray: false,
    include: true,
    childElements: []
  },
  {
    name: 'longitude',
    datatype: DataStructureType.FLOAT,
    size: 0,
    decimalPlaces: 0,
    columnName: 'Data Value',
    aliasName: 'longitude',
    isArray: false,
    include: true,
    childElements: []
  },
  {
    name: 'large',
    datatype: DataStructureType.VARIANT,
    size: 0,
    decimalPlaces: 0,
    columnName: 'images',
    aliasName: 'large',
    isArray: true,
    include: true,
    childElements: [
      {
        name: 'large-element',
        datatype: DataStructureType.VARCHAR,
        size: 0,
        decimalPlaces: 0,
        columnName: 'images',
        aliasName: 'large-element',
        isArray: false,
        include: true,
        childElements: []
      }
    ]
  },
  {
    name: 'fake',
    datatype: DataStructureType.FLOAT,
    size: 0,
    decimalPlaces: 0,
    columnName: 'fake',
    aliasName: 'fake',
    isArray: false,
    include: true,
    childElements: []
  }
]
