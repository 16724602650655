import {
  useCallback,
  useEffect,
  useState,
  type FC,
  type ReactNode
} from 'react'

import { ShortcutProviderContext } from './ShortcutProviderContext'
import { type Shortcut } from './types'

export const ShortcutProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [shortcuts, setShortcuts] = useState<Shortcut[]>([])

  useEffect(() => {
    const callback = (e: KeyboardEvent) => {
      const { key, altKey, ctrlKey, metaKey, shiftKey } = e

      shortcuts
        .filter((i) => {
          const {
            key: _key,
            altKey: _altKey = false,
            ctrlKey: _ctrlKey = false,
            metaKey: _metaKey = false,
            shiftKey: _shiftKey = false
          } = i

          return [
            key === _key,
            altKey === _altKey,
            ctrlKey === _ctrlKey,
            metaKey === _metaKey,
            shiftKey === _shiftKey
          ].every(Boolean)
        })
        .forEach((i) => i.callback())
    }

    document.addEventListener('keydown', callback)

    return () => {
      document.removeEventListener('keydown', callback)
    }
  }, [shortcuts])

  const registerShortcut = useCallback((shortcut: Shortcut) => {
    setShortcuts((prevShortcuts) => [...prevShortcuts, shortcut])
  }, [])

  const unRegisterShortcut = useCallback((shortcutId: string) => {
    setShortcuts((prevShortcuts) =>
      prevShortcuts.filter(({ id }) => id !== shortcutId)
    )
  }, [])

  return (
    <ShortcutProviderContext.Provider
      value={{ registerShortcut, unRegisterShortcut }}
    >
      {children}
    </ShortcutProviderContext.Provider>
  )
}
