import { useQuery } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { queryKeys } from '../../queryKeys'
import { useSpringClient } from '../useSpringClient/useSpringClient'
import { type ListEnvironmentsResponse } from './types'

export const useListEnvironments = () => {
  const { projectId } = useProjectInfo()
  const client = useSpringClient()

  return useQuery([projectId, queryKeys.listEnv], async () => {
    const { data } = await client.get<ListEnvironmentsResponse>(
      `environments?projectId=${projectId}`
    )
    return data
  })
}
