import { Modal } from '@matillion/component-library'
import classes from './HardResetBranchModal.module.scss'
import { type HardResetBranchModalProps } from './types'
import HardResetBranchForm from './HardResetBranchForm'

const HardResetBranchModal = (props: HardResetBranchModalProps) => {
  return (
    <div>
      <Modal
        size="mid"
        disableBackdropClick
        data-testid="hard-reset-modal"
        onCancel={() => props.onClose?.()}
        className={classes.HardResetModal}
        ariaLabelledBy="hard-reset-branch-modal"
      >
        <HardResetBranchForm {...props} />
      </Modal>
    </div>
  )
}

export default HardResetBranchModal
