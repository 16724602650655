import { MenuItem, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './GitButtonMenuItem.module.scss'

export interface GitButtonMenuItemProps {
  name: string
  info?: string
  disabled?: boolean
  className?: string
  onClick: () => void
}

const GitButtonMenuItem = ({
  name,
  info,
  onClick,
  disabled,
  className,
  ...rest
}: GitButtonMenuItemProps) => {
  const textClass = info ? classes.TextWithInfo : classes.Text

  return (
    <MenuItem
      {...rest}
      role="menuitem"
      onClick={onClick}
      aria-disabled={disabled}
      className={classNames(className, classes.Wrapper, {
        [classes.Disabled]: disabled
      })}
    >
      <Typography className={textClass} format="bcm">
        {name}
      </Typography>

      {info && (
        <Typography className={classes.Info} format="mc">
          {info}
        </Typography>
      )}
    </MenuItem>
  )
}

export default GitButtonMenuItem
