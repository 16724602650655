import { sub } from 'date-fns'

import { State, type RunTaskStatus } from '../types'

const currentDate = new Date()

currentDate.setHours(4)

const offsetDate = sub(currentDate, { months: 10 }).getTime()

export const completedRunTaskSummaries: RunTaskStatus[] = [
  {
    id: 1,
    versionName: '1',
    jobName: 'alan_russell',
    environmentName: 'snowflake_dev',
    enqueuedTime: offsetDate,
    startTime: offsetDate,
    endTime: offsetDate,
    flowInstanceId: '69bbf9f3-1119-4431-9608-0b3174a7192f',
    state: State.Success
  },
  {
    id: 2,
    versionName: '2',
    jobName: 'alan_russell',
    environmentName: 'snowflake_dev',
    enqueuedTime: offsetDate,
    startTime: offsetDate,
    endTime: offsetDate,
    flowInstanceId: '72a57bc8-e718-4447-8165-4ee6a476ca18',
    state: State.Success
  },
  {
    id: 4,
    versionName: '4',
    jobName: 'alan_russell',
    environmentName: 'snowflake_dev',
    enqueuedTime: offsetDate,
    startTime: offsetDate,
    endTime: offsetDate,
    flowInstanceId: '0732ade2-c6e5-47aa-96b5-6f27df28fedd',
    state: State.Success
  },
  {
    id: 6,
    versionName: '8',
    jobName: 'alan_russell2',
    environmentName: 'snowflake_dev',
    enqueuedTime: offsetDate,
    startTime: offsetDate,
    endTime: offsetDate,
    flowInstanceId: '8e9b5dba-eb11-430c-947e-b6fc18fcafc3',
    state: State.Failed
  },
  {
    id: 7,
    versionName: '9',
    jobName: 'alan_russell3',
    environmentName: 'snowflake_dev',
    enqueuedTime: offsetDate,
    startTime: offsetDate,
    endTime: offsetDate,
    flowInstanceId: 'a2abe5c7-e5f5-421c-93b4-8021a0161f25',
    state: State.Failed
  },
  {
    id: 8,
    versionName: '10',
    jobName: 'alan_russell4',
    environmentName: 'snowflake_dev',
    enqueuedTime: offsetDate,
    startTime: offsetDate,
    endTime: offsetDate,
    flowInstanceId: '228febc4-6ba2-4b67-b74c-586f1c18205f',
    state: State.Failed
  },
  {
    id: 9,
    versionName: '11',
    jobName: 'alan_russell5',
    environmentName: 'snowflake_dev',
    enqueuedTime: offsetDate,
    startTime: offsetDate,
    endTime: offsetDate,
    flowInstanceId: '5e7e215c-be2d-43a8-a2f1-10c007df5a63',
    state: State.Success
  },
  {
    id: 10,
    versionName: '12',
    jobName: 'alan_russell5',
    environmentName: 'snowflake_dev',
    enqueuedTime: offsetDate,
    startTime: offsetDate,
    endTime: offsetDate,
    flowInstanceId: '8c1f54a3-ae5a-46f2-87f4-d584d3502e77',
    state: State.Success
  },
  {
    id: 11,
    versionName: '13',
    jobName: 'alan_russell5',
    environmentName: 'snowflake_dev',
    enqueuedTime: offsetDate,
    startTime: offsetDate,
    endTime: offsetDate,
    flowInstanceId: '0dcc477c-da68-42dd-8d0b-8dbe6f7a0471',
    state: State.Success
  },
  {
    id: 3,
    versionName: '3',
    jobName: 'alan_russell',
    environmentName: 'snowflake_dev',
    enqueuedTime: offsetDate,
    startTime: offsetDate,
    endTime: offsetDate,
    flowInstanceId: 'd6684c04-451f-43e4-9f34-7744b05cd577',
    state: State.Success
  },
  {
    id: 5,
    versionName: '7',
    jobName: 'alan_russell2',
    environmentName: 'snowflake_dev',
    enqueuedTime: offsetDate,
    startTime: offsetDate,
    endTime: offsetDate,
    flowInstanceId: '2b57f3fa-3f2e-4376-bba2-889a216b5ea0',
    state: State.Success
  }
]
