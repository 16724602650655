import {
  StrictMode,
  type FunctionComponent,
  type PropsWithChildren
} from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ReactFlowProvider } from 'reactflow'

import { Toaster } from '@matillion/component-library'
import {
  AuthProvider,
  FlagProvider,
  RegionGuard,
  ServiceRegistryProvider
} from '@matillion/hub-client'
import { QueryClientProvider } from '@tanstack/react-query'

import queryClient from 'api/queryClient'

import { ShortcutProvider } from 'components/ShortcutProvider/ShortcutProvider'
import { UserPreferenceProvider } from 'components/UserPreferenceProvider'

import { CopyPasteProvider } from 'modules/CopyPasteComponent/CopyPasteContext'

import config from './config'

const Providers: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactFlowProvider>
        <AuthProvider environment={config.authEnvironment}>
          <FlagProvider
            waitForFlags
            launchDarklyClientId={config.launchDarkly.clientId}
            flags={config.launchDarkly.defaultFlags}
            useCamelCaseFlagKeys={false}
          >
            <RegionGuard strictAccountRegion>
              <ServiceRegistryProvider>
                <ShortcutProvider>
                  <CopyPasteProvider>
                    <UserPreferenceProvider>
                      <Toaster.Provider
                        withFade={3}
                        align="right"
                        position="top"
                        theme="dark"
                      >
                        <BrowserRouter>{children}</BrowserRouter>
                      </Toaster.Provider>
                    </UserPreferenceProvider>
                  </CopyPasteProvider>
                </ShortcutProvider>
              </ServiceRegistryProvider>
            </RegionGuard>
          </FlagProvider>
        </AuthProvider>
      </ReactFlowProvider>
    </QueryClientProvider>
  </StrictMode>
)

export default Providers
