import { useQuery } from '@tanstack/react-query'

import { useAgentDetails } from 'api/hooks/useAgentDetails/useAgentDetails'
import { useSpringClient } from 'api/hooks/useSpringClient/useSpringClient'

import type { JobType } from 'job-lib/types/JobType'

import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'

import useGetProject from '../../useGetProject/useGetProject'

export interface AutoDocsRequest {
  pipeline: PipelineContext
  warehouse: string
  selectedNodes: string[]
}

export interface AutoDocsResponse {
  description: string
}

export interface PipelineContext {
  type: JobType | null
  source: string
}

export const useGeneratePipelineDocs = (
  id: number,
  selectedNodes: string[]
) => {
  const { job, jobType } = useWorkingCopy()
  const { data: project } = useGetProject()
  const { data: agentDetails } = useAgentDetails()
  const client = useSpringClient()

  return useQuery<AutoDocsResponse, Error>(
    ['generatePipelineDocs', id, selectedNodes],
    async () => {
      const warehouse = project?.warehouse?.toUpperCase() ?? ''
      const agentVersion = agentDetails?.agentVersion ?? ''

      const request: AutoDocsRequest = {
        selectedNodes,
        warehouse,
        pipeline: {
          type: jobType,
          source: JSON.stringify(job)
        }
      }

      const response = await client.post<AutoDocsResponse>(
        '/sidekick/generate-pipeline-docs',
        request,
        {
          headers: {
            'agent-version': agentVersion
          }
        }
      )

      return response.data
    },
    {
      enabled: false, // Disables automatic fetching of data, to manually fetch data use refetch()
      staleTime: 0 // Query data is immediately stale, so refetch always makes a new API call
    }
  )
}
