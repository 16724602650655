import { useCallback, useContext } from 'react'
import { getRectOfNodes, type XYPosition } from 'reactflow'

import { cloneDeep, partition } from 'lodash'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import {
  getSelectedNodeInfo,
  getSelectedNodes
} from 'modules/Canvas/hooks/useCanvasModel/utils'
import { useEtlFlow } from 'modules/Canvas/hooks/useEtlFlow'
import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'

import { CopyPasteContext } from './CopyPasteContext'
import { getFilteredConnectors } from './getFilteredConnectors'

export const useCopyComponent = () => {
  const { setCopiedComponentContent, setCopiedComponentOffsets } =
    useContext(CopyPasteContext)

  const reactFlowInstance = useEtlFlow()
  const { job, jobType } = useWorkingCopy()
  const { jobSummaryId } = useProjectInfo()

  const copyComponent = useCallback(() => {
    if (!job || !jobType) {
      return
    }
    // TODO: support copy/paste for notes
    const [selectedComponentInfos] = partition(
      getSelectedNodeInfo(reactFlowInstance),
      (x) => x.type === 'component'
    )

    const selectedComponentInstances = selectedComponentInfos
      .map((selectedComponent) =>
        cloneDeep(job?.components[selectedComponent.id])
      )
      .filter(Boolean)

    const selectedNodes = getSelectedNodes(reactFlowInstance)

    const selectedComponentBounds = getRectOfNodes(selectedNodes)

    const selectedComponentOffsets: XYPosition[] = []

    selectedComponentInstances.forEach((componentInstance) => {
      const componentOffset = {
        x: componentInstance.x - selectedComponentBounds.x,
        y: componentInstance.y - selectedComponentBounds.y
      }
      selectedComponentOffsets.push(componentOffset)
    })

    setCopiedComponentOffsets(selectedComponentOffsets)

    if (selectedComponentInstances.length > 0 && jobType && jobSummaryId) {
      const connectors = getFilteredConnectors(
        job,
        selectedComponentInstances.map((component) => component.id)
      )
      if (!selectedComponentInstances) {
        return
      }

      setCopiedComponentContent(jobSummaryId, {
        // @ts-expect-error the union is collapsed when the selectedComponentInstances variable is defined
        componentInstances: selectedComponentInstances,
        componentType: jobType,
        currentConnectors: connectors
      })
    }
  }, [
    reactFlowInstance,
    job,
    jobType,
    jobSummaryId,
    setCopiedComponentOffsets,
    setCopiedComponentContent
  ])

  return { copyComponent }
}
