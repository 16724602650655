import { PlatformSubMenuItem } from '@matillion/component-library'
import { useServiceUrl } from '../../effects/useServiceUrl'

interface HubPlatformSubMenuItemProps {
  title: string
  description: string
  serviceKey: string
  url: string
  active: boolean
}

const HubPlatformSubMenuItem = ({
  title,
  description,
  serviceKey,
  url,
  active
}: HubPlatformSubMenuItemProps) => {
  const getUrl = serviceKey ? useServiceUrl(serviceKey, true).toString() : url

  return (
    <PlatformSubMenuItem
      active={active}
      href={getUrl}
      title={title}
      description={description}
    />
  )
}

export default HubPlatformSubMenuItem
