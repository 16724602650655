import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { type OrchestrationComponentInstance } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'

import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'

import {
  SpringClientVersion,
  useSpringClient
} from '../useSpringClient/useSpringClient'
import {
  type SampleComponentRequest,
  type SampleComponentRequestBody,
  type SampleComponentResponse
} from './types'

const jobTypeToUrlMap = {
  [JobType.Orchestration]: '/sample/orchestration',
  [JobType.Transformation]: '/sample/transformation'
}

export const SAMPLE_ROW_LIMIT = 1000

export const useSampleComponent = (jobType: JobType) => {
  const { projectId, agentId, environmentId } = useProjectInfo()
  const { job } = useWorkingCopy()
  const client = useSpringClient(
    jobType === JobType.Transformation
      ? SpringClientVersion.V2
      : SpringClientVersion.V1
  )

  return useMutation(
    async ({ componentCache, componentInstance }: SampleComponentRequest) => {
      const requestBody: SampleComponentRequestBody &
        Record<string, string | number> = {
        projectId,
        agentId,
        environmentId,
        limit: SAMPLE_ROW_LIMIT
      }

      if (jobType === JobType.Orchestration) {
        requestBody.component =
          componentInstance as OrchestrationComponentInstance
        requestBody.variables = job?.variables
      }

      if (jobType === JobType.Transformation) {
        requestBody.componentCache = componentCache
      }

      try {
        const { data } = await client.post<SampleComponentResponse>(
          jobTypeToUrlMap[jobType],
          requestBody
        )
        return data
      } catch (err) {
        if (axios.isAxiosError(err)) {
          throw err.response?.data
        }
        throw Error((err as Error).message)
      }
    }
  )
}
