import {
  ComponentEditorTabType,
  EditorType,
  ExportVariableType,
  LookUpType,
  ParameterDataType,
  UseVariable,
  type ComponentMetadataResponse
} from 'api/hooks/useGetComponentMetadata/types'
import { ColumnEditorType } from 'api/hooks/useGetParameterOptions/types'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

export const mockRunTransformationMetadata: ComponentMetadataResponse = {
  componentId: 'run-transformation',
  metadata: {
    schemaVersion: 1,
    emeraldId: '1896325668',
    componentType: JobType.Orchestration,
    classification: 'CONTROL_FLOW',
    inputPorts: [
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.ONE
      }
    ],
    outputPorts: [
      {
        portId: ConnectionPortType.SUCCESS,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.FAILURE,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      }
    ],
    deprecated: false,
    exports: [
      ExportVariableType.COMPLETED_AT,
      ExportVariableType.COMPONENT_NAME,
      ExportVariableType.DURATION,
      ExportVariableType.MESSAGE,
      ExportVariableType.STARTED_AT,
      ExportVariableType.STATUS
    ],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.EXPORT,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'transformationJob',
        metlSlot: 2,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DESIGNER_TRANSFORM_JOBS,
        lookupDependencies: [],
        validationDependencies: ['transformation-pipeline-names'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'setScalarVariables',
        metlSlot: 4,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.SCALAR_JOB_VARIABLE_OVERRIDE,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: true,
        staticOptions: [
          {
            name: 'Variable',
            type: 'TEXT',
            options: null,
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: '',
            lookupType: LookUpType.DESIGNER_TRANSFORMATION_JOB_VARIABLES,
            lookupDependencies: ['param.transformation-job:jobName']
          },
          {
            name: 'Value',
            type: 'TEXT',
            options: null,
            columnEditorType: ColumnEditorType.Freetext,
            defaultValue: '',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null
      },

      {
        dplID: 'setGridVariables',
        metlSlot: 5,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_JOB_VARIABLE_OVERRIDE,
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: true,
        staticOptions: null,
        defaultValue: null
      }
    ]
  }
}
