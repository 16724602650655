import { waitFor } from '@testing-library/react'
import {
  type PactQueryHookConfig,
  type QueryResult
} from './usePactApiQuery/types'
import {
  type MutationResult,
  type PactMutationHookConfig
} from './usePactApiMutation/types'

export interface PactApiHookResult<Response, MutationArgs> {
  current:
    | QueryResult<Response | undefined>
    | MutationResult<Response, MutationArgs | undefined>
}

export interface PactHookAssertProps<Response, MutationArgs> {
  result: PactApiHookResult<Response, MutationArgs>
  config?:
    | Partial<PactQueryHookConfig<Response>>
    | Partial<PactMutationHookConfig<Response, MutationArgs>>
}

export const assertPactApiHook = async <Response, MutationArgs = void>({
  result,
  config
}: PactHookAssertProps<Response, MutationArgs>) => {
  return waitFor(() => {
    const response = result.current

    if (config?.expectError) {
      expect(response.isError).toBe(true)
    } else {
      expect(response.isSuccess).toBe(true)
    }

    if (config?.assertions) {
      // @ts-expect-error not sure we can make this happy
      config.assertions(response)
    }
  })
}
