import { useQuery } from '@tanstack/react-query'

import { type DescribeMergeProps, type WtsDescribeMergeResponse } from './types'
import { queryKeys } from 'api/query-keys'
import useWorkingTreeClient from 'api/clients/useWorkingTreeClient'
import useGitContext from 'provider/GitProvider/useGitContext'
import { type ReactQueryMeta } from 'types/ReactQueryMeta'

const useDescribeMergeQueryKey = (
  projectId: string | undefined,
  branchName?: string
) => {
  return [
    queryKeys.describeMerge,
    projectId,
    branchName,
    queryKeys.legacyDescribeMerge
  ]
}

const defaultMeta: ReactQueryMeta = {
  error: {
    title: 'api.toast.describe-merge.title',
    message: 'api.toast.describe-merge.message'
  }
}

/**
 * Describes the outcome of merging changes from the remote into the local
 * copy of the working tree store. Will describe conflicts with uncommitted local
 * changes, should there be any.
 */
const useDescribeMerge = ({
  sourceBranch,
  targetBranch,
  enabled = true,
  meta
}: DescribeMergeProps) => {
  const client = useWorkingTreeClient()
  const { project } = useGitContext()

  const queryKey = useDescribeMergeQueryKey(project, targetBranch)

  return useQuery(
    queryKey,
    async () => {
      let url = `/projects/${project}/branches/${targetBranch}/merges`

      if (sourceBranch) {
        url += `?sourceBranchName=${sourceBranch}`
      }

      const { data } = await client.get<WtsDescribeMergeResponse>(url)
      return data
    },
    {
      enabled: Boolean(targetBranch) && enabled,
      meta: meta ?? defaultMeta
    }
  )
}

export default useDescribeMerge
