import { VariableScope } from 'job-lib/types/Variables'

import { Fields, type FormState } from 'modules/ManageVariables/types'

const jobVariableFields = [
  Fields.NAME,
  Fields.DESCRIPTION,
  Fields.VISIBILITY,
  Fields.DEFAULT_VALUE
]

const projectVariableFields = [
  Fields.NAME,
  Fields.DESCRIPTION,
  Fields.DEFAULT_VALUE,
  Fields.PROJECT_DEFAULT_OVERRIDES
]

export const isFormValid = (
  state: FormState,
  selectedVariableScope: VariableScope
): boolean => {
  if (selectedVariableScope === VariableScope.JOB_VARIABLE) {
    return jobVariableFields.every((field) => state[field].isValid)
  }

  return projectVariableFields.every((field) => state[field].isValid)
}
