import { useCallback, type DragEvent, type PropsWithChildren } from 'react'

import { type JobType } from 'job-lib/types/JobType'

import classes from './PipelineFolderDropDestination.module.scss'

export interface PipelineDropDetails {
  sourceDirectory: string
  existingPipelineName: string
  existingPipelineType: JobType
  existingPipelineId: string
}

export interface PipelineFolderDropDestinationProps {
  id: string
  onFolderEnter?: () => void
  onFolderLeave?: () => void
  onDropPipeline: (details: PipelineDropDetails) => void
}

const PipelineFolderDropDestination = ({
  id,
  onDropPipeline,
  onFolderEnter,
  onFolderLeave,
  children
}: PropsWithChildren<PipelineFolderDropDestinationProps>) => {
  const onDragEnter = useCallback(
    (event: DragEvent<HTMLElement>) => {
      event.preventDefault()
      onFolderEnter?.()
    },
    [onFolderEnter]
  )

  const onDragOver = (event: DragEvent<HTMLElement>) => {
    // We don't care about drag over as this event fires
    // repeatedly while moving over the drop target, so we
    // stop the event here. Instead, we care about onDragEnter,
    // so we can inform the drop target when the element enters it.
    event.preventDefault()
    event.stopPropagation()
  }

  const onDragLeave = useCallback(
    (event: DragEvent<HTMLElement>) => {
      event.preventDefault()
      onFolderLeave?.()
    },
    [onFolderLeave]
  )

  const onDrop = useCallback(
    (event: DragEvent<HTMLElement>) => {
      const dataTransfer = event.dataTransfer
      const existingPipelineName = dataTransfer.getData('pipelineName')
      const sourceDirectory = dataTransfer.getData('sourceFolderPath')
      const existingPipelineId = dataTransfer.getData('existingPipelineId')
      const existingPipelineType = dataTransfer.getData(
        'pipelineType'
      ) as JobType

      if (
        !existingPipelineName ||
        !existingPipelineId ||
        !existingPipelineType
      ) {
        return
      }

      event.preventDefault()
      event.stopPropagation()

      onDropPipeline({
        existingPipelineId,
        existingPipelineName,
        existingPipelineType,
        sourceDirectory
      })
    },
    [onDropPipeline]
  )

  return (
    <div
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDragExit={onDragLeave}
      className={classes.Wrapper}
      data-testid={`pipeline-folder-drop-destination-${id}`}
    >
      {children}
    </div>
  )
}

export default PipelineFolderDropDestination
