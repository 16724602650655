import { type ComponentMetadataResponse } from '../../types'

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const mockLoopIteratorMetadata = {
  componentId: 'loop-iterator',
  metadata: {
    schemaVersion: 1,
    emeraldId: '1025742816',
    componentType: 'ORCHESTRATION',
    inputPorts: [
      {
        portId: 'INPUT',
        cardinality: 'ONE'
      }
    ],
    outputPorts: [
      {
        portId: 'SUCCESS',
        cardinality: 'MANY'
      },
      {
        portId: 'UNCONDITIONAL',
        cardinality: 'MANY'
      },
      {
        portId: 'FAILURE',
        cardinality: 'MANY'
      },
      {
        portId: 'ITERATION',
        cardinality: 'ONE'
      }
    ],
    deprecated: false,
    exports: [
      'COMPLETED_AT',
      'COMPONENT_NAME',
      'DURATION',
      'ITERATIONS_ATTEMPTED',
      'ITERATIONS_GENERATED',
      'ITERATIONS_SUCCESSFUL',
      'MESSAGE',
      'ROW_COUNT',
      'STARTED_AT',
      'STATUS'
    ],
    editorTabs: ['PROPERTIES', 'EXPORT', 'HELP'],
    parameters: [
      {
        dplID: 'componentName',
        resourceID: 'componentName',
        metlSlot: 1,
        useVariable: 'NO',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'FREETEXT',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        dplID: 'concurrency',
        resourceID: 'concurrency',
        metlSlot: 7,
        useVariable: 'YES',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'DROPDOWN',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            resourceID: null,
            type: 'TEXT',
            options: ['Concurrent', 'Sequential'],
            columnEditorType: 'DROPDOWN',
            defaultValue: 'Sequential',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: 'Sequential',
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        dplID: 'variableToIterate',
        resourceID: 'variableToIterate',
        metlSlot: 2,
        useVariable: 'YES',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'DROPDOWN',
        lookupType: 'DYNAMIC_VARIABLES_NUMERIC',
        lookupDependencies: ['param.concurrency'],
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null,
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        dplID: 'startingValue',
        resourceID: 'startingValue',
        metlSlot: 3,
        useVariable: 'YES',
        dataType: 'INTEGER',
        authProvider: null,
        editorType: 'FREETEXT',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: '0',
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        dplID: 'incrementValue',
        resourceID: 'incrementValue',
        metlSlot: 4,
        useVariable: 'YES',
        dataType: 'INTEGER',
        authProvider: null,
        editorType: 'FREETEXT',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: '1',
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        dplID: 'endValue',
        resourceID: 'endValue',
        metlSlot: 5,
        useVariable: 'YES',
        dataType: 'INTEGER',
        authProvider: null,
        editorType: 'FREETEXT',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: '10',
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      },
      {
        dplID: 'breakOnFailure',
        resourceID: 'breakOnFailure',
        metlSlot: 6,
        useVariable: 'YES',
        dataType: 'TEXT',
        authProvider: null,
        editorType: 'DROPDOWN',
        lookupType: null,
        lookupDependencies: null,
        validationDependencies: null,
        visibleWhen: [
          {
            param: 'concurrency',
            value: 'Sequential',
            operator: 'EQUALS'
          }
        ],
        optional: false,
        staticOptions: [
          {
            name: null,
            resourceID: null,
            type: 'TEXT',
            options: ['Yes', 'No'],
            columnEditorType: 'DROPDOWN',
            defaultValue: 'No',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: 'No',
        secretTypes: null,
        childProperties: null,
        options: null,
        displayName: null,
        continuationToken: null,
        warehouses: null
      }
    ]
  }
} as ComponentMetadataResponse
