import { useContext } from 'react'
import { UserInfo } from '..'

import { UserInfoContext } from '../context'

/**
 * Returns details of the currently logged in user.
 *
 *  useUser can only be used beneath the [[AuthProvider]].
 *
 * @returns The user info bag, containing the [[UserInfo | details of the currently logged in user]].
 * @category Hooks
 */
const useUser = () => useContext<UserInfo>(UserInfoContext)

export default useUser
