import {
  ComponentEditorTabType,
  EditorType,
  LookUpType,
  ParameterDataType,
  UseVariable,
  VisibleWhenOperator,
  type ComponentMetadataResponse
} from 'api/hooks/useGetComponentMetadata/types'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

export const mockGoogleSheetsDynamicLookupParam = {
  dplID: 'enumWithDynamicOptions',
  resourceID: 'mockResourceId',
  metlSlot: 0,
  useVariable: UseVariable.YES,
  dataType: ParameterDataType.TEXT,
  editorType: EditorType.DROPDOWN,
  lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP,
  lookupDependencies: [
    'paramId.enumWithDynamicOptions',
    'param.stub-output-connector-v0.textArray',
    'param.inputId',
    'param.google-sheets-input-v0:param.inputConnector'
  ],
  optional: true,
  displayName: 'Text (with dynamic options)'
}

export const mockGoogleSheetsModularConnectorMetadata: ComponentMetadataResponse =
  {
    componentId: 'modular-google-sheets-input-v0',
    metadata: {
      schemaVersion: 1,
      emeraldId: '-379653764',
      classification: 'MODULAR',
      componentType: JobType.Orchestration,
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        }
      ],
      deprecated: false,
      exports: [],
      editorTabs: [
        ComponentEditorTabType.PROPERTIES,
        ComponentEditorTabType.EXPORT,
        ComponentEditorTabType.SAMPLE,
        ComponentEditorTabType.HELP
      ],
      parameters: [
        {
          dplID: 'componentName',
          resourceID: 'componentName',
          metlSlot: 0,
          useVariable: UseVariable.NO,
          dataType: ParameterDataType.TEXT,
          editorType: EditorType.FREETEXT,
          optional: false,
          displayName: 'Component Name'
        },
        {
          dplID: 'inputId',
          resourceID: 'inputId',
          metlSlot: 0,
          useVariable: UseVariable.NO,
          dataType: ParameterDataType.TEXT,
          editorType: EditorType.FREETEXT,
          optional: false,
          defaultValue: 'google-sheets-input-v0',
          displayName: 'Input ID'
        },
        {
          dplID: 'google-sheets-input-v0',
          resourceID: 'GoogleSheetsInputConnector',
          metlSlot: 0,
          dataType: ParameterDataType.STRUCT,
          editorType: EditorType.PARAMETER_GROUP,
          visibleWhen: [
            {
              param: 'inputId',
              value: 'google-sheets-input-v0',
              operator: VisibleWhenOperator.EQUALS
            }
          ],
          optional: false,
          childProperties: [
            {
              optional: false,

              dplID: 'connection',
              resourceID: 'connection',
              metlSlot: 0,
              useVariable: UseVariable.NO,
              dataType: ParameterDataType.STRUCT,
              editorType: EditorType.CONNECTION_EDITOR,
              authProvider: 'Google',
              secretTypes: ['SERVICE_ACCOUNT'],
              childProperties: [
                {
                  dplID: 'authType',
                  resourceID: 'authType',
                  metlSlot: 0,
                  useVariable: UseVariable.NO,
                  dataType: ParameterDataType.TEXT,
                  editorType: EditorType.DROPDOWN,
                  options: ['SERVICE_ACCOUNT'],
                  displayName: 'Authentication Type',
                  optional: true
                },
                {
                  dplID: 'secretReferenceId',
                  resourceID: 'secretReferenceId',
                  metlSlot: 0,
                  useVariable: UseVariable.NO,
                  dataType: ParameterDataType.TEXT_SECRET_REF,
                  editorType: EditorType.DROPDOWN,
                  lookupType: LookUpType.DESIGNER_SECRET_TYPE,
                  lookupDependencies: [
                    'param.google-sheets-input-v0.connection.authType:authType'
                  ],
                  defaultValue: '[Custom]',
                  visibleWhen: [
                    {
                      param: 'google-sheets-input-v0.connection.authType',
                      operator: VisibleWhenOperator.HAS_VALUE,
                      value: null
                    }
                  ],
                  displayName: 'Auth Secret',
                  optional: true
                },
                {
                  dplID: 'overrides',
                  resourceID: 'overrides',
                  metlSlot: 0,
                  useVariable: UseVariable.NO,
                  dataType: ParameterDataType.STRUCT,
                  childProperties: [
                    {
                      dplID: 'serviceAccountJson',
                      resourceID:
                        'GoogleSheetsInput_connection_serviceAccountJson',
                      metlSlot: 0,
                      useVariable: UseVariable.YES,
                      dataType: ParameterDataType.TEXT_SECRET_REF,
                      editorType: EditorType.DROPDOWN,
                      visibleWhen: [
                        {
                          param:
                            'google-sheets-input-v0.connection.secretReferenceId',
                          value: '[Custom]',
                          operator: VisibleWhenOperator.EQUALS
                        },
                        {
                          param: 'google-sheets-input-v0.connection.authType',
                          value: 'SERVICE_ACCOUNT',
                          operator: VisibleWhenOperator.EQUALS
                        }
                      ],
                      displayName: 'Service Account (JSON blob)',
                      optional: true
                    },
                    {
                      dplID: 'permittedResourceHost',
                      resourceID:
                        'GoogleSheetsInput_connection_permittedResourceHost',
                      metlSlot: 0,
                      useVariable: UseVariable.YES,
                      dataType: ParameterDataType.TEXT,
                      editorType: EditorType.FREETEXT,
                      optional: true,
                      visibleWhen: [
                        {
                          param:
                            'google-sheets-input-v0.connection.secretReferenceId',
                          value: '[Custom]',
                          operator: VisibleWhenOperator.EQUALS
                        },
                        {
                          param: 'google-sheets-input-v0.connection.authType',
                          value: 'SERVICE_ACCOUNT',
                          operator: VisibleWhenOperator.EQUALS
                        }
                      ],
                      displayName: 'Permitted host (to access data)'
                    }
                  ],
                  displayName: 'Overrides',
                  optional: true,
                  editorType: EditorType.PARAMETER_GROUP
                }
              ],
              displayName: 'Connection'
            },
            {
              dplID: 'spreadsheetId',
              resourceID: 'spreadsheetId',
              metlSlot: 0,
              useVariable: UseVariable.YES,
              dataType: ParameterDataType.TEXT,
              editorType: EditorType.FREETEXT,
              optional: false,
              displayName: 'Spreadsheet Id'
            },
            {
              dplID: 'sheetName',
              resourceID: 'sheetName',
              metlSlot: 0,
              useVariable: UseVariable.YES,
              dataType: ParameterDataType.TEXT,
              editorType: EditorType.FREETEXT,
              optional: false,
              displayName: 'Sheet Name'
            },
            {
              dplID: 'containsHeaderRow',
              resourceID: 'containsHeaderRow',
              metlSlot: 0,
              useVariable: UseVariable.YES,
              dataType: ParameterDataType.BOOLEAN,
              editorType: EditorType.DROPDOWN,
              optional: false,
              options: ['Yes', 'No'],
              displayName: 'Contains Header Row'
            },
            {
              dplID: 'serviceAccountRef',
              resourceID: 'serviceAccountRef',
              metlSlot: 0,
              useVariable: UseVariable.YES,
              dataType: ParameterDataType.TEXT_SECRET_REF,
              editorType: EditorType.SECRET_REFERENCE,
              optional: false,
              displayName: 'Service Account'
            }
          ],
          displayName: 'Google Sheets Input'
        },
        {
          dplID: 'outputId',
          resourceID: 'outputId',
          metlSlot: 0,
          useVariable: UseVariable.NO,
          dataType: ParameterDataType.TEXT,
          editorType: EditorType.DROPDOWN,
          optional: false,
          options: [
            'com.matillion.connectors.output.stub.StubOutputConnector#v0'
          ],
          displayName: 'Output ID'
        },
        {
          dplID: 'stub-output-connector-v0',
          resourceID: 'StubOutputConnector',
          metlSlot: 0,
          dataType: ParameterDataType.STRUCT,
          editorType: EditorType.PARAMETER_GROUP,
          visibleWhen: [
            {
              param: 'outputId',
              value:
                'com.matillion.connectors.output.stub.StubOutputConnector#v0',
              operator: VisibleWhenOperator.EQUALS
            }
          ],
          optional: false,
          childProperties: [
            {
              dplID: 'struct',
              resourceID: 'struct',
              metlSlot: 0,
              useVariable: UseVariable.YES,
              dataType: ParameterDataType.STRUCT,
              editorType: EditorType.MULTI_PROPERTIES_EDITOR,
              lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_STRUCT_KEYS,
              optional: true,
              displayName: 'Struct'
            },
            {
              dplID: 'float64',
              resourceID: 'float64',
              metlSlot: 0,
              useVariable: UseVariable.YES,
              dataType: ParameterDataType.FLOAT,
              editorType: EditorType.FREETEXT,
              optional: true,
              displayName: 'Decimal'
            },
            {
              dplID: 'textArray',
              resourceID: 'textArray',
              metlSlot: 0,
              useVariable: UseVariable.YES,
              dataType: ParameterDataType.TEXT,
              editorType: EditorType.FREETEXT,
              optional: true,
              displayName: 'Text (array)'
            },
            mockGoogleSheetsDynamicLookupParam
          ],
          displayName: 'Stub Output Connector'
        },
        {
          dplID: 'hash#123',
          resourceID: 'hash#123',
          metlSlot: 0,
          useVariable: UseVariable.NO,
          dataType: ParameterDataType.TEXT,
          editorType: EditorType.FREETEXT,
          optional: false,
          displayName: 'hash'
        }
      ]
    }
  }
