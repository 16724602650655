import { useTranslation } from 'react-i18next'
import { Button, Typography } from '@matillion/component-library'
import useGetProviderDisplayName from 'modules/BringYourOwnGit/hooks/useGetProviderDisplayName'
import { type ExternalGitProvider } from 'types/ExternalGitProvider'
import classes from '../AuthModalForm.module.scss'

export interface AuthModalFailedStateProps {
  provider: ExternalGitProvider
  onCancel: () => void
}

const AuthModalFailedState = ({
  provider,
  onCancel
}: AuthModalFailedStateProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'auth.modal.failed'
  })

  const { providerName } = useGetProviderDisplayName(provider)

  return (
    <div data-testid="git-auth-modal-failed">
      <Typography
        className={classes.title}
        data-testid="git-auth-modal-title"
        id="git-auth-title"
        format="tm"
      >
        {t('title', { provider: providerName })}
      </Typography>

      <Typography
        className={classes.body}
        data-testid="git-auth-modal-body-1"
        format="bcm"
      >
        {t('body.first', { provider: providerName })}
      </Typography>

      <Typography
        className={classes.body}
        data-testid="git-auth-modal-body-2"
        format="bcm"
      >
        <span>{t('body.second.prefix')}</span>
        <a
          href="https://docs.matillion.com/data-productivity-cloud/designer/docs/troubleshooting-github-repository-connection/#authorization-fails"
          rel="noreferrer"
          target="_blank"
        >
          {t('body.second.support-link')}
        </a>
        <span>{t('body.second.suffix')}</span>
      </Typography>

      <div className={classes.buttons}>
        <Button
          id="cancel"
          alt="secondary"
          onClick={onCancel}
          text={t('cancel')}
          className={classes.button}
          data-testid="git-auth-failed-cancel"
        />
      </div>
    </div>
  )
}

export default AuthModalFailedState
