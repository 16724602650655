import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'

import componentSummaries from './en/component-summaries.json'
import componentSummariesTranslations from './en/component-summaries.translations.json'
import parametersValues from './en/parameterValues.json'
import en from './en/translation.json'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
      componentSummaries: {
        components: componentSummaries,
        ...componentSummariesTranslations
      },
      parametersValues: { ...parametersValues }
    }
  },
  lng: 'en',
  interpolation: {
    escapeValue: false // React protects against XSS
  }
})

export default i18n
