import { rest, type RequestHandler } from 'msw'

import config from 'config'

import { type ListProjectVariablesFailResponse } from '../types'
import mockGetProjectVariablesResponse from './mockGetProjectVariablesResponse.json'

const useListProjectVariablesHandler: RequestHandler[] = [
  rest.get(
    `${config.getProjectExplorerApiBaseUrl()}/v1/projects/:projectId/variables`,
    async (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockGetProjectVariablesResponse))
    }
  )
]

const useListProjectVariablesFailHandler: RequestHandler[] = [
  rest.get(
    `${config.getProjectExplorerApiBaseUrl()}/v1/projects/:projectId/variables`,
    async (req, res, ctx) => {
      const response: ListProjectVariablesFailResponse = {
        type: 'PERSISTENCE_VALIDATION',
        detail:
          'Database integrity constraints were violated on [uk_name_project_id]',
        title: 'Bad Request',
        status: 400,
        violations: []
      }

      return res(ctx.status(400), ctx.json(response))
    }
  )
]

export const useProjectVariablesHandlers = [...useListProjectVariablesHandler]

export { useListProjectVariablesHandler, useListProjectVariablesFailHandler }
