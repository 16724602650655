import { useState, type FC } from 'react'

import { type Step } from 'api/hooks/useEosClient/types/eos'

import CellMessage from '../components/CellMessage/CellMessage'
import { CellTypography } from '../components/CellTypography'
import { DateTime } from '../components/DateTime'
import { Cell, Row } from '../components/PipelineTable'
import { calcDuration } from '../utils/calcDuration'
import NestedPipelineSteps from './NestedPipelineSteps'
import PipelineNameCell from './PipelineNameCell'

interface StepRowProps {
  step: Step
  depth: number
  pipelineName?: string
  rowClassName?: string
  cellClassName?: string
}

const StepRow: FC<StepRowProps> = ({
  step,
  depth,
  pipelineName,
  rowClassName,
  cellClassName
}) => {
  const {
    result: { startedAt, finishedAt, rowCount, message, status },
    nestedPipeline,
    name
  } = step
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <>
      <Row className={rowClassName} data-testid="step-row">
        <Cell className={cellClassName}>
          <PipelineNameCell
            depth={depth}
            isExpanded={isExpanded}
            onClick={() => {
              setIsExpanded(!isExpanded)
            }}
            nestedPipeline={nestedPipeline}
            pipelineName={pipelineName ?? ''}
            status={status}
          />
        </Cell>
        <Cell className={cellClassName}>
          <CellTypography text={name} />
        </Cell>
        <Cell className={cellClassName}>
          <CellTypography text={<DateTime timestamp={startedAt} />} />
        </Cell>
        <Cell className={cellClassName}>
          <CellTypography
            text={calcDuration(startedAt, finishedAt ?? '')}
            subtext={<DateTime timestamp={finishedAt} />}
          />
        </Cell>
        <Cell className={cellClassName}>
          <CellTypography text={String(rowCount) || '-'} />
        </Cell>
        <Cell className={cellClassName}>
          <CellMessage value={message} stepId={step.id} />
        </Cell>
      </Row>

      {isExpanded && nestedPipeline && (
        <NestedPipelineSteps
          rowClassName={rowClassName}
          nestedPipeline={nestedPipeline}
          parentDepth={depth}
        />
      )}
    </>
  )
}

export default StepRow
