import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'api/query-keys'
import { useCallback } from 'react'
import useEntitlementsClient from 'api/clients/useEntitlementsClient'
import { type EntitlementsResponse } from './types'
import { type ApiHookProps } from 'types/ReactQueryMeta'

export const useEntitlementsQueryKey = () => {
  return [queryKeys.entitlements]
}

const useEntitlements = ({ enabled }: ApiHookProps = {}) => {
  const client = useEntitlementsClient()

  const getEntitlements = useCallback(async () => {
    const { data } = await client.get<EntitlementsResponse>('')
    return data
  }, [client])

  const queryKey = useEntitlementsQueryKey()
  return useQuery(queryKey, getEntitlements, {
    enabled,
    meta: {
      error: {
        title: 'api.toast.entitlements.title',
        message: 'api.toast.entitlements.message'
      }
    }
  })
}

export default useEntitlements
