import { type FC, type PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { type JobSummary } from 'api/hooks/useGetJobSummaries'

import {
  ComponentDragSource,
  type ComponentDragSourceProps
} from 'components/ComponentDrag/ComponentDragSource'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'

import { createHomogeneousElements } from 'job-lib/builders/createElementsCollection'
import {
  orchestrationJobNameParameter,
  runOrchestrationComponentId,
  runTransformationComponentId,
  transformationJobNameParameter
} from 'job-lib/cisIds/knownComponentParameters'
import { JobType } from 'job-lib/types/JobType'

import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'

import { getComponentIcon } from 'utils/component-icons/component-icons'

export const useJobDragProps = (
  job: JobSummary
): ComponentDragSourceProps | undefined => {
  const { pipelineSummary: activePipelineSummary } = useActivePipelineSummary()
  const { jobType: workingCopyJobType } = useWorkingCopy()
  const { t } = useTranslation()

  // we can only drag jobs on to orchestration components, this also captures when there is no open job
  if (workingCopyJobType !== JobType.Orchestration) {
    return
  }

  // also stop users from dragging the same job onto itself
  if (activePipelineSummary?.jobId === job.jobId) {
    return
  }

  if (job.type === JobType.Orchestration) {
    return {
      componentId: runOrchestrationComponentId,
      dragOrigin: 'job-browser',
      name: `${t('common.run')} ${job.name}`,
      imageUrl: getComponentIcon(runOrchestrationComponentId),
      initialParameters: {
        [orchestrationJobNameParameter]: createHomogeneousElements(
          job.runtimeName
        )
      }
    }
  } else if (job.type === JobType.Transformation) {
    return {
      componentId: runTransformationComponentId,
      dragOrigin: 'job-browser',
      name: `${t('common.run')} ${job.name}`,
      imageUrl: getComponentIcon(runTransformationComponentId),
      initialParameters: {
        [transformationJobNameParameter]: createHomogeneousElements(
          job.runtimeName
        )
      }
    }
  }
}

interface OptionalDragProps extends PropsWithChildren {
  dragProps?: ComponentDragSourceProps
  className?: string
}

export const OptionalDrag: FC<OptionalDragProps> = ({
  children,
  dragProps,
  className
}) => {
  if (dragProps) {
    return (
      <ComponentDragSource {...dragProps}>
        <div className={className}>{children}</div>
      </ComponentDragSource>
    )
  }

  return <div className={className}>{children}</div>
}
