import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'

import { useCreateJob } from 'api/hooks/useCreateJob/useCreateJob'

import { useSelectedJobs } from 'hooks/useSelectedJobs'

import { type JobType } from 'job-lib/types/JobType'

import { track } from 'utils/heap'

interface CreateJobActionProps {
  type: JobType
  onComplete: () => void
}

interface CreateJobActionResult {
  isLoading: boolean
  create: (jobName: string) => unknown
}

export function useCreateJobAction({
  type,
  onComplete
}: CreateJobActionProps): CreateJobActionResult {
  const { t } = useTranslation()
  const { makeToast } = Toaster.useToaster()
  const { mutateAsync, isLoading } = useCreateJob()
  const { navigateToJob } = useSelectedJobs()

  const create = useCallback(
    async (runtimeName: string) => {
      if (isLoading) return

      try {
        track('etld_create_job_click', { jobType: type })
        const response = await mutateAsync({ jobType: type, runtimeName })
        makeToast({
          title: t('translation:createPipeline.success'),
          type: 'success',
          message: ''
        })
        navigateToJob(response.id)
      } catch (e) {
        makeToast({
          title: t('translation:managePipeline.error.somethingWrong'),
          type: 'error',
          message: t('translation:managePipeline.error.tryLater')
        })
      }
      onComplete()
    },
    [isLoading, type, onComplete, makeToast, navigateToJob, mutateAsync, t]
  )

  return {
    isLoading,
    create
  }
}
