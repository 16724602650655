import {
  EditorType,
  ParameterDataType,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

export function renderChildProperties(
  childParam: ComponentParameter,
  parameterPath: string[],
  renderComponentParameterItem: (
    componentParameter: ComponentParameter,
    path: string[]
  ) => JSX.Element
) {
  if (shouldRenderConnectionEditorFields(childParam, parameterPath)) {
    return renderConnectionEditorFields(
      childParam,
      parameterPath,
      renderComponentParameterItem
    )
  }
  return renderComponentParameterItem(childParam, parameterPath)
}

/**
 * This function accounts for the fact that the connection editor is a special case
 * All of the connection specific fields are nested under the 'overrides' child parameter
 * so if the current path is 'connection' and the current component parameter is 'overrides'
 * we know the child parameters are specific authentication fields
 *
 * @param componentParameter connection editor component parameter
 * @param path the path to the connection editor
 * @returns true if the connection editor fields should be rendered
 */
function shouldRenderConnectionEditorFields(
  componentParameter: ComponentParameter,
  path: string[]
) {
  return (
    componentParameter.editorType === EditorType.CONNECTION_EDITOR ||
    (path[path.length - 1] === 'connection' &&
      componentParameter.dplID === 'overrides')
  )
}

/**
 * The usual pattern for rendering an editor is to open a modal
 * with the editors inside, however for the connection editor the requirement is to instead
 * render the fields as if the child parameters were flattened on to the same level as the parent.
 * I think it goes without saying this is not ideal behaviour - but it's very tricky
 * to change the structure of the backend data without breaking stuff
 * @param componentParameter the connnection editor component parameter
 * @param path the path to the connection editor
 * @param renderComponentParameterItem the function to render the component parameter item
 * @returns an array of JSX elements to render for the connection editor fields
 */
function renderConnectionEditorFields(
  componentParameter: ComponentParameter,
  path: string[],
  renderComponentParameterItem: (
    componentParameter: ComponentParameter,
    path: string[]
  ) => JSX.Element
) {
  return [
    ...(componentParameter.childProperties?.map((childParameter) => {
      // further flatten the struct fields and don't actually render the struct itself
      if (childParameter.dataType === ParameterDataType.STRUCT) {
        return childParameter.childProperties?.map((grandChildParameter) => {
          return renderComponentParameterItem(grandChildParameter, [
            ...path,
            componentParameter.dplID,
            childParameter.dplID
          ])
        })
      }
      return renderComponentParameterItem(childParameter, [
        ...path,
        componentParameter.dplID
      ])
    }) ?? [])
  ]
}
