import { type EntitlementsResponse } from './types'

export const useEntitlementsResponseHasAccess: EntitlementsResponse = {
  canAccessDesigner: true,
  canExecuteMDLBatchPipeline: true,
  canExecutePipeline: true,
  canUseBYOG: true,
  canUseCHA: true
}

export const useEntitlementsResponseNoAccess: EntitlementsResponse = {
  canAccessDesigner: false,
  canExecuteMDLBatchPipeline: false,
  canExecutePipeline: false,
  canUseBYOG: false,
  canUseCHA: false
}
