import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EditPanel } from 'components/EditPanel/EditPanel'

import { VariableScope, type JobVariableType } from 'job-lib/types/Variables'

import { Create } from './components/Create/Create'
import { Delete } from './components/Delete/Delete'
import { Edit } from './components/Edit/Edit'
import { Manage } from './components/Manage/Manage'
import {
  Stages,
  type ManageVariableStageProps,
  type ProjectVariableType,
  type SelectedVariable
} from './types'

interface ModalConfig {
  title: string
  subtext?: string
  Content: ({
    onCancel,
    setStage,
    setSelectedVariable,
    setSelectedVariableScope,
    selectedVariable,
    selectedVariableScope,
    selectedVariableType,
    setSelectedVariableType
  }: ManageVariableStageProps) => JSX.Element
}

export const SEARCH_DEBOUNCE_TIME_MS = 500

export const stages: Record<Stages, ModalConfig> = {
  [Stages.MANAGE]: {
    title: 'manageVariables.common.manageYourVars',
    Content: Manage
  },
  [Stages.CREATE]: {
    title: 'manageVariables.create.title',
    Content: Create
  },
  [Stages.DELETE]: {
    title: 'manageVariables.delete.title',
    subtext: 'manageVariables.delete.subtext',
    Content: Delete
  },
  [Stages.EDIT]: {
    title: 'manageVariables.edit.jobVariableTitle',
    Content: Edit
  }
}

export const ManageVariables = ({ onCancel }: { onCancel: () => void }) => {
  const { t } = useTranslation()

  const [stage, setStage] = useState<Stages>(Stages.MANAGE)
  const [selectedVariable, setSelectedVariable] = useState<SelectedVariable>()
  const [selectedVariableScope, setSelectedVariableScope] =
    useState<VariableScope>(VariableScope.JOB_VARIABLE)
  const [selectedVariableType, setSelectedVariableType] = useState<
    JobVariableType | ProjectVariableType | null
  >()

  const { title: titleKey, subtext = '', Content } = stages[stage]
  const getTitle = (): string => {
    if (stage === Stages.EDIT) {
      return selectedVariableScope === VariableScope.JOB_VARIABLE
        ? 'manageVariables.edit.jobVariableTitle'
        : 'manageVariables.edit.projectVariableTitle'
    }
    return titleKey
  }

  return (
    <EditPanel onCancel={onCancel} title={t(getTitle())} subtext={t(subtext)}>
      <Content
        onCancel={onCancel}
        setStage={setStage}
        setSelectedVariable={setSelectedVariable}
        setSelectedVariableScope={setSelectedVariableScope}
        selectedVariable={selectedVariable}
        selectedVariableScope={selectedVariableScope}
        selectedVariableType={selectedVariableType}
        setSelectedVariableType={setSelectedVariableType}
      />
    </EditPanel>
  )
}
