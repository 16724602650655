import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { Organisation, Roles } from '../../types'
import { getCurrentOrganisation } from '../../api/hub'

interface OrganisationPayload {
  organisation: Organisation
  roles: Roles
}

/**
 * Returns the current user's organisation, by looking it up from the API based
 * on their current Auth0 token.
 */
const useOrganisation = () => {
  const {
    getAccessTokenSilently,
    isLoading: isUserLoading,
    isAuthenticated
  } = useAuth0()

  const [isPending, setPending] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState<Error>()
  const [data, setData] = useState<OrganisationPayload>()

  useEffect(() => {
    // Don't try to load organisation information if auth0 hasn't finished determining
    // whether or not the user is authenticated
    if (isUserLoading) {
      return
    }

    // Don't try to load organisation information if the user isn't logged in
    if (!isAuthenticated) {
      setPending(false)
      return
    }

    const fetchOrg = async () => {
      setPending(false)
      setLoading(true)

      try {
        const token = await getAccessTokenSilently()
        const {
          data: { userRoles, ...organisation }
        } = await getCurrentOrganisation(token)

        setData({
          roles: new Set(userRoles),
          organisation
        })
        setError(undefined)
      } catch (e) {
        console.error('Failed to load Organisation for user', e)
        setData(undefined)
        setError(e as Error)
      }

      setLoading(false)
    }

    fetchOrg()
  }, [
    getAccessTokenSilently,
    isAuthenticated,
    isUserLoading,
    setLoading,
    setPending,
    setData
  ])

  return [data, isPending || isLoading, error] as const
}

export default useOrganisation
