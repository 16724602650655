import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  type Connection,
  type Edge,
  type Node,
  type OnSelectionChangeParams
} from 'reactflow'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useSelectedJobs } from 'hooks/useSelectedJobs'

import { useDeleteNodes } from 'job-lib/hooks/useDeleteNodes/useDeleteNodes'
import { jobActions } from 'job-lib/store'
import { useUpdateLinks } from 'job-lib/store/jobSlice/hooks/useUpdateLinks/useUpdateLinks'
import { ConnectionPortType, OutputPortType } from 'job-lib/types/Components'

import {
  type EtlCanvasNode,
  type EtlCanvasNodeId
} from 'modules/Canvas/hooks/useCanvasModel/useCanvasModel'
import {
  getIdFromReactFlowId,
  getNodeInfo,
  isComponentNode
} from 'modules/Canvas/hooks/useCanvasModel/utils'
import { useComponentValidationProvider } from 'modules/core/ComponentValidation'

export const useCanvasHandlers = (
  canvasNodes: Map<EtlCanvasNodeId, EtlCanvasNode>,
  syncCanvasModel: () => void
) => {
  const dispatch = useDispatch()
  const updateLinks = useUpdateLinks()
  const { componentId: selectedComponentId } = useProjectInfo()
  const { invalidateTransformationComponent } = useComponentValidationProvider()
  const { navigateToComponent } = useSelectedJobs()
  const { deleteNodes } = useDeleteNodes()

  const onNodeDragStop = useCallback(
    (_: React.MouseEvent, movedNode: Node) => {
      const { id, type } = getNodeInfo(movedNode)
      dispatch(
        jobActions.updateNodePosition({
          type,
          id,
          x: Math.round(movedNode.position.x),
          y: Math.round(movedNode.position.y)
        })
      )
    },
    [dispatch]
  )

  const onSelectionDragStop = useCallback(
    (_: React.MouseEvent, movedNodes: Node[]) => {
      movedNodes.forEach((movedNode) => {
        const { id, type } = getNodeInfo(movedNode)

        dispatch(
          jobActions.updateNodePosition({
            type,
            id,
            x: Math.round(movedNode.position.x),
            y: Math.round(movedNode.position.y)
          })
        )
      })
    },
    [dispatch]
  )

  const onNodesDelete = useCallback(
    (deletedNodes: Node[]) => {
      deleteNodes(deletedNodes.map(getNodeInfo))
    },
    [deleteNodes]
  )

  const onEdgesDelete = useCallback(
    (deletedEdges: Edge[]) => {
      // TODO: Make delete actions consistent; both should accept arrays of ids to remove
      deletedEdges.forEach((deletedEdge) => {
        dispatch(jobActions.deleteLink(parseInt(deletedEdge.id)))
        const targetId = Number.parseInt(
          getIdFromReactFlowId(deletedEdge.target)
        )
        invalidateTransformationComponent(targetId)
      })
    },
    [dispatch, invalidateTransformationComponent]
  )

  const onConnect = useCallback(
    (connection: Connection) => {
      if (!connection.source || !connection.target) {
        return
      }
      const sourceId = Number.parseInt(getIdFromReactFlowId(connection.source))
      const targetId = Number.parseInt(getIdFromReactFlowId(connection.target))
      const sourceType = connection.sourceHandle as OutputPortType | null

      const sourceNode = canvasNodes.get(connection.source as EtlCanvasNodeId)
      const targetNode = canvasNodes.get(connection.target as EtlCanvasNodeId)

      if (!sourceType || !sourceNode || !targetNode) {
        return
      }

      if (!isComponentNode(sourceNode)) {
        console.warn('source node does not support connections')
        return
      }

      if (!isComponentNode(targetNode)) {
        console.warn('target node does not support connections')
        return
      }

      const sourcePort = (
        sourceType === ConnectionPortType.ITERATION
          ? sourceNode.data.iteratorPorts
          : sourceNode.data.outputPorts
      ).find(({ portId }) => portId === sourceType)

      const targetPort = targetNode.data.inputPorts.find(
        ({ portId }) => portId === ConnectionPortType.INPUT
      )

      if (!sourcePort || !targetPort) {
        return
      }

      updateLinks({
        sourceComponentId: sourceId,
        targetComponentId: targetId,
        sourceCardinality: sourcePort.cardinality,
        targetCardinality: targetPort.cardinality,
        sourceType
      })

      // As we are using React-Flow in a controlled manner, we are manually syncing updates.
      // Where updateLinks will conditionally update state, we must always re-sync the job with
      // React-Flow after updating the connectors. If we don't do this then a connection will be
      // made by React-Flow, but not stored in the job model.  The next time the job updates this will be lost
      syncCanvasModel()

      invalidateTransformationComponent(targetId)
    },
    [
      canvasNodes,
      updateLinks,
      syncCanvasModel,
      invalidateTransformationComponent
    ]
  )

  const onSelectionChange = useCallback(
    ({ nodes }: OnSelectionChangeParams) => {
      if (nodes.length > 1) {
        /* when multiple nodes are selected, we don't want any component to be selected */
        navigateToComponent()
      }

      if (nodes.length === 1 && isComponentNode(nodes[0])) {
        /*
         * if there is only one selected node on the canvas, the selected component
         * should match it. this allows us to programatically set the selected state
         * on canvas nodes, and have the rest of the UI match it
         */
        const node = nodes[0]
        const nodeId = getIdFromReactFlowId(node.id)
        const nestedNodeId =
          node.data.attachedNode &&
          getIdFromReactFlowId(node.data.attachedNode.id)

        /*
         * if the currently selected canvas node is our selected component,
         * we don't need to re-navigate to it
         */
        if (selectedComponentId === Number(nodeId)) {
          return
        }

        /*
         * if a child of the currently selected canvas node is our selected component,
         * we shouldn't navigate away, as this could override an explict action by users;
         * canvas nodes contain a `navigateToComponent` call on click.
         * this can't be changed until attached nodes are real canvas nodes instead of nested
         * react elements
         */
        if (selectedComponentId === Number(nestedNodeId)) {
          return
        }

        navigateToComponent(nodeId)
      }

      if (nodes.length === 0 && selectedComponentId) {
        /*
         * when a user deselects all nodes, we want to clear the currently selected component.
         * we need to make sure we don't do this on every change, though, as change events
         * can get fired on canvas re-render or job updates--if we didn't check for a selected
         * component id here, we could get into an infinite redirect loop
         */
        navigateToComponent()
      }
    },
    [navigateToComponent, selectedComponentId]
  )

  const isValidConnection = useCallback(
    (connection: Connection) => {
      if (!connection.source || !connection.target) {
        return false
      }

      const sourceType = connection.sourceHandle as OutputPortType | null

      if (sourceType === OutputPortType.ITERATION) {
        const targetNode = canvasNodes.get(connection.target as EtlCanvasNodeId)

        if (
          isComponentNode(targetNode) &&
          targetNode?.data.iteratorPorts.length > 0
        ) {
          return false
        }
      }

      /* prevents a node from being connected to itself */
      if (connection.source === connection.target) {
        return false
      }

      return true
    },
    [canvasNodes]
  )

  return {
    onNodesDelete,
    onNodeDragStop,
    onSelectionDragStop,
    onEdgesDelete,
    onConnect,
    onSelectionChange,
    isValidConnection
  }
}
