import { useCallback } from 'react'
import {
  type CaptureOAuthTokenArgs,
  type CaptureOAuthTokenRequest
} from './types'
import { useMutation } from '@tanstack/react-query'
import { type ExternalGitProvider } from 'types/ExternalGitProvider'
import { queryKeys } from 'api/query-keys'
import useGitClient from 'api/clients/useGitClient'

export const useCaptureOAuthTokenQueryKey = (
  provider?: ExternalGitProvider
) => {
  return [queryKeys.captureOAuthToken, provider]
}

const useCaptureOAuthToken = ({ provider }: CaptureOAuthTokenArgs) => {
  const client = useGitClient()

  const capture = useCallback(
    async (req: CaptureOAuthTokenRequest) => {
      const uri = `/providers/${provider}/oauth/tokens`
      const { data } = await client.post(uri, req)
      return data
    },
    [client, provider]
  )

  const queryKey = useCaptureOAuthTokenQueryKey(provider)
  return useMutation(queryKey, capture, {
    meta: {
      error: {
        title: 'auth.toast.failure.title',
        message: 'auth.toast.failure.message',
        args: [
          {
            name: 'provider',
            value: provider
          }
        ]
      }
    }
  })
}

export default useCaptureOAuthToken
