import { Typography } from '@matillion/component-library'

import classes from './ComingSoonLabel.module.scss'

interface ComingSoonLabelProps {
  labelText: string
}

export const ComingSoonLabel = ({ labelText }: ComingSoonLabelProps) => (
  <Typography
    data-testid="disabled-label"
    as="span"
    format="bcs"
    weight="bold"
    className={classes.Component__ComingSoonLabel}
  >
    {labelText}
  </Typography>
)
