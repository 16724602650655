import {
  ComponentEditorTabType,
  EditorType,
  LookUpType,
  ParameterDataType,
  UseVariable,
  type ComponentMetadataResponse
} from 'api/hooks/useGetComponentMetadata/types'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

export const mockDistinctMetadata: ComponentMetadataResponse = {
  componentId: 'distinct',
  metadata: {
    schemaVersion: 1,
    emeraldId: '1744268877',
    componentType: JobType.Transformation,
    classification: 'TRANSFORMATION',
    inputPorts: [
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.ONE
      }
    ],
    outputPorts: [
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      }
    ],
    deprecated: false,
    exports: null,
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.SAMPLE,
      ComponentEditorTabType.METADATA,
      ComponentEditorTabType.SQL,
      ComponentEditorTabType.PLAN,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'columnNames',
        metlSlot: 2,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.MULTI_OPTION_SELECTOR,
        lookupType: LookUpType.DYNAMIC_TRANSFORM_SOURCE_COLUMNS,
        lookupDependencies: ['transform.sql'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      }
    ]
  }
}
