import { useTranslation } from 'react-i18next'

import { Typography } from '@matillion/component-library'

import classes from './BranchSelectorLabel.module.scss'
import { type BranchSelectorLabelProps } from './types'

const BranchSelectorLabel = ({
  prefixKey,
  suffixKey
}: BranchSelectorLabelProps) => {
  const { t } = useTranslation()

  return (
    <p className={classes.Label}>
      <Typography format="bcm" weight="bold" as={'span'}>
        {t(prefixKey)}
      </Typography>{' '}
      <Typography format="bcm" as={'span'}>
        {t(suffixKey)}
      </Typography>
    </p>
  )
}

export default BranchSelectorLabel
