import config from 'config'

import { useBrowserStoredFlag } from './useBrowserStoredFlag'

export function useSessionStarted() {
  return useBrowserStoredFlag({
    storage: sessionStorage,
    key: config.browserStoreKeys.onboardingSessionStarted
  })
}
