import { useFormikContext } from 'formik'
import {
  type BringYourOwnGitDetails,
  type BringYourOwnGitFormProps,
  defaultGitProvider
} from './types'
import { useCallback, useEffect, useMemo } from 'react'
import useGetRepositoriesAction from 'hooks/useGetRepositoriesAction'
import GitProviderSelector from '../GitProviderSelector'
import GitRepositoryDropdown from '../GitRepositoryDropdown'
import { type ExternalGitProvider } from 'types/ExternalGitProvider'
import styles from './BringYourOwnGitForm.module.scss'
import classNames from 'classnames'
import { LoadingSpinner } from '@matillion/component-library'
import useOAuthContext from 'provider/OAuthProvider/useOAuthContext'
import { useTranslation } from 'react-i18next'
import useEntitlements from 'api/hooks/useEntitlements'
import EnterpriseLicenseRequiredModal from '../EnterpriseLicenseRequiredModal'

const BringYourOwnGitForm = ({ classes }: BringYourOwnGitFormProps) => {
  const { setCloseAction } = useOAuthContext()
  const { setModalHelpText } = useOAuthContext()
  const { t } = useTranslation('translation', { keyPrefix: 'byog.form' })
  const { values, setFieldValue } = useFormikContext<BringYourOwnGitDetails>()

  const resetProvider = useCallback(async () => {
    await setFieldValue('provider', defaultGitProvider)
  }, [setFieldValue])

  useEffect(() => {
    setModalHelpText(t('auth-modal.additional-text'))
  }, [setModalHelpText, t])

  useEffect(() => {
    setCloseAction(resetProvider)
  }, [resetProvider, setCloseAction, values.provider])

  const hasSelectedBYOG = useMemo(() => {
    return values.provider !== 'matillion'
  }, [values.provider])

  const externalGitProvider = useMemo(() => {
    return hasSelectedBYOG
      ? (values.provider as ExternalGitProvider)
      : undefined
  }, [hasSelectedBYOG, values.provider])

  const {
    data,
    isError: isEntitlementsError,
    status: entitlementsFetchStatus
  } = useEntitlements({
    enabled: Boolean(externalGitProvider)
  })
  const canUseBYOG = data?.canUseBYOG ?? false

  const { repositories, loading } = useGetRepositoriesAction({
    provider: externalGitProvider,
    canUseBYOG
  })

  useEffect(() => {
    if (isEntitlementsError) {
      resetProvider()
    }
  }, [isEntitlementsError, resetProvider])

  return (
    <>
      <div data-testid="bring-your-own-git-form-content">
        <GitProviderSelector className={classes?.providerSelector} />

        {loading && (
          <LoadingSpinner
            className={styles.loading}
            data-testid="external-git-repos-loading"
          />
        )}

        {repositories && externalGitProvider && (
          <GitRepositoryDropdown
            loading={loading}
            repositories={repositories}
            provider={externalGitProvider}
            className={classNames(
              classes?.repositoryDropdown,
              styles.repositorySelector
            )}
          />
        )}
      </div>

      {!canUseBYOG &&
        externalGitProvider &&
        entitlementsFetchStatus !== 'loading' && (
          <EnterpriseLicenseRequiredModal onClose={resetProvider} />
        )}
    </>
  )
}

export default BringYourOwnGitForm
