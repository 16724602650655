import { useTranslation } from 'react-i18next'

import { Grid, Section, Typography } from '@matillion/component-library'

import config from 'config'

import { JobType } from 'job-lib/types/JobType'

import {
  PipelineBrowserCommand,
  PipelineBrowserCommandProvider,
  usePipelineBrowserCommandProvider
} from 'modules/PipelineBrowser'

import {
  OnboardingModal,
  useOnboardingModal
} from './components/OnboardingModal'
import { PipelineCard } from './components/PipelineCard'
import classes from './GettingStarted.module.scss'

export function GettingStarted() {
  const { t } = useTranslation()
  const { isVisible, close } = useOnboardingModal()

  return (
    <div className={classes.GettingStarted}>
      <div className={classes.GettingStarted__Title}>
        <Typography as="h2" format="tl">
          {t('translation:canvas.noJobSelectedTitle')}
        </Typography>
      </div>
      <PipelineBrowserCommandProvider>
        <CreatePipelineWizard />
      </PipelineBrowserCommandProvider>
      {isVisible && <OnboardingModal onClose={close} />}
    </div>
  )
}

const sectionSize = {
  small: 12,
  medium: 10,
  large: 8
}

const sectionOffset = {
  small: 0,
  medium: 1,
  large: 2
}

function CreatePipelineWizard() {
  const { t } = useTranslation()
  const { onCommand } = usePipelineBrowserCommandProvider()

  return (
    <Grid>
      <Section size={sectionSize} offset={sectionOffset}>
        <div className={classes.GettingStarted__CardsContainer}>
          <PipelineCard
            data-testid="orchestration-card"
            videoGuideURL={config.docs.orchestrationVideoUrl}
            positiveTag={t('translation:canvas.jobRecommendation')}
            onClick={() => {
              onCommand({
                type: PipelineBrowserCommand.CREATE_PIPELINE,
                payload: JobType.Orchestration
              })
            }}
            title={t('translation:canvas.orchestrationJob')}
            description={t('translation:canvas.orchestrationDescription')}
          />
          <PipelineCard
            data-testid="transformation-card"
            videoGuideURL={config.docs.transformationVideoUrl}
            onClick={() => {
              onCommand({
                type: PipelineBrowserCommand.CREATE_PIPELINE,
                payload: JobType.Transformation
              })
            }}
            title={t('translation:canvas.transformationJob')}
            description={t('translation:canvas.transformationDescription')}
          />
        </div>
      </Section>
    </Grid>
  )
}
