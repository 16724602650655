import { useTranslation } from 'react-i18next'

import { Icon, MicroCta } from '@matillion/component-library'

interface ActionsCellProps {
  name: string
  onDelete: () => unknown
  onEdit: () => unknown
}

export const ActionsCell = ({ name, onDelete, onEdit }: ActionsCellProps) => {
  const { t } = useTranslation()

  return (
    <>
      <MicroCta
        alt="transparent"
        aria-label={t('manageVariables.edit.editVariableTooltip', { name })}
        onClick={onEdit}
      >
        <Icon.Pencil />
      </MicroCta>
      <MicroCta
        alt="transparent"
        aria-label={t('manageVariables.delete.deleteVariableTooltip', { name })}
        onClick={onDelete}
      >
        <Icon.Trash />
      </MicroCta>
    </>
  )
}
