import { type RequestHandler, rest } from 'msw'

import { PROJECT_ID, PROJECT_ID_WITH_NO_ENVS } from '__test-utils__/ids'

import { type LegacyDescribeMergeResponse } from 'api/hooks/useLegacyDescribeMerge/types'
import { MockedLegacyMergeDescription } from 'api/hooks/useLegacyDescribeMerge/useLegacyDescribeMerge.responses'

export const stubLegacyDescribeMerge = (
  baseUrl = '',
  projectId = PROJECT_ID
) => {
  return Object.keys(MockedLegacyMergeDescription).map(
    (targetSpecifier: string) => {
      // We don't want to have to write stubs for every combination of source and
      // target specifier. So instead, we just care about the target.
      const url = `${baseUrl}/projects/${projectId}/merges/*...${targetSpecifier}`
      return rest.get(url, async (_req, res, ctx) => {
        const response = MockedLegacyMergeDescription[targetSpecifier]
        return res(ctx.json<LegacyDescribeMergeResponse>(response))
      })
    }
  )
}

export const useLegacyDescribeMergeHandlers: RequestHandler[] = [
  ...stubLegacyDescribeMerge('', PROJECT_ID),
  ...stubLegacyDescribeMerge('', 'test-project'),
  ...stubLegacyDescribeMerge('', PROJECT_ID_WITH_NO_ENVS),
  rest.get(
    `v1/projects/${PROJECT_ID}/merges/500...internalServerError`,
    async (_req, res, ctx) => res(ctx.status(500))
  )
]
