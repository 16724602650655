import { useContext } from 'react'

import { PopOverContext } from './PopOverContext'

const usePopOverContext = () => {
  const context = useContext(PopOverContext)

  if (context === undefined) {
    throw new Error('PopOverContext must be used within a PopOverMenu')
  }

  const { setIsOpen, setHoveredNode } = context

  return { setIsOpen, setHoveredNode }
}

export default usePopOverContext
