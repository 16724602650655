import { useCallback, useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { type AxiosResponseHeaders } from 'axios'

import { useSpringClient } from 'api/hooks/useSpringClient/useSpringClient'
import { queryKeys } from 'api/queryKeys'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { FileStatus } from 'job-lib/types/FileStatus'

import {
  type GetJobSummariesProps,
  type JobSummaries,
  type JobSummary,
  type JobSummaryResponse,
  type JobSummaryResponseItem
} from './types'

export const useGetJobSummariesQueryKey = ({
  includeDeleted = false
}: GetJobSummariesProps = {}) => {
  const { projectId, branchId } = useProjectInfo()

  return useMemo(
    () => [
      queryKeys.pull,
      projectId,
      branchId,
      queryKeys.jobSummaries,
      includeDeleted
    ],
    [projectId, branchId, includeDeleted]
  )
}

const isWorkingTreeJGitHeaderPresent = (headers: AxiosResponseHeaders) => {
  const key = 'matillion.wts.jgit'
  const header = Object.keys(headers).find((headerKey) => {
    return headerKey.toLowerCase() === key
  })
  return Boolean(header)
}

export const useGetJobSummaries = ({
  includeDeleted = false
}: GetJobSummariesProps = {}) => {
  const { projectId, branchId } = useProjectInfo()
  const client = useSpringClient()

  const getSummaries = useCallback(async (): Promise<JobSummaries> => {
    const { data, headers } = await client.get<JobSummaryResponse>(
      `/projects/${projectId}/branches/${branchId}/jobs`
    )

    const isUsingJGit = isWorkingTreeJGitHeaderPresent(headers)

    const summaries: JobSummary[] = data.results.map(
      (summary: JobSummaryResponseItem) => {
        return {
          jobId: summary.id,
          name: summary.name,
          folder: summary.folder,
          runtimeName: summary.runtimeName,
          type: summary.type,
          status: summary.status
        }
      }
    )

    if (includeDeleted) {
      return {
        isUsingJGit,
        results: summaries
      }
    }

    return {
      isUsingJGit,
      results: summaries.filter(
        (summary) => summary.status !== FileStatus.DELETED
      )
    }
  }, [branchId, client, includeDeleted, projectId])

  return useQuery(useGetJobSummariesQueryKey(), getSummaries)
}

export * from './types'
