import { useTranslation } from 'react-i18next'

import { ReactComponent as VariableSvg } from 'assets/variable-icon.svg'

import { IconButton } from 'components/IconButton/IconButton'

import { ToolboxButton } from './ToolboxButton'

interface VariablesButtonProps {
  showExpanded: boolean
  onClick: () => unknown
}

export const VariablesButton = ({
  showExpanded,
  onClick
}: VariablesButtonProps) => {
  const { t } = useTranslation()
  const label = t('canvasToolbox.manageVariables')
  const buttonIcon = <VariableSvg />
  const dataTestId = 'canvas-toolbox-manage-variables'

  const button = (
    <IconButton label={label} data-testid={dataTestId} onClick={onClick}>
      {buttonIcon}
    </IconButton>
  )

  return (
    <ToolboxButton
      onClick={onClick}
      button={button}
      buttonIcon={buttonIcon}
      text={t('canvasToolbox.buttons.variables')}
      showExpanded={showExpanded}
      label={label}
      dataTestId={dataTestId}
    />
  )
}
