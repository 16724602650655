import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'

import useDescribeMerge from 'api/hooks/useDescribeMerge/useDescribeMerge'
import useMerge from 'api/hooks/useMerge/useMerge'

import { type ConflictResolutionSelection } from 'components/ConflictResolutionTable/types'
import useGitContext from 'provider/GitProvider/useGitContext'
import {
  type PullActionResponse,
  type PullArgs
} from 'hooks/usePullAction/types'
import useProblemDetails from 'hooks/useProblemDetails'
import { type ProblemDetailRegistry } from 'hooks/useProblemDetails/types'
import { Problem } from 'hooks/useProblemDetails/Problem'
import useEventContext from 'provider/EventProvider/useEventContext'
import { type ReactQueryMeta } from 'types/ReactQueryMeta'

const problemDetailRegistry: ProblemDetailRegistry = [
  {
    type: Problem.WTS_BRANCH_NOT_FOUND,
    translation: {
      titleKey: 'pull.problem.default.title',
      messageKey: 'pull.problem.branch-not-found'
    }
  },
  {
    type: Problem.WTS_REMOTE_BRANCH_NOT_FOUND,
    translation: {
      titleKey: 'pull.problem.remote-branch-not-found.title',
      messageKey: 'pull.problem.remote-branch-not-found.message'
    }
  },
  {
    type: Problem.WTS_UNCOMMITTED_CHANGES_PREVENT_MERGE,
    translation: {
      titleKey: 'pull.problem.uncommitted.title',
      messageKey: 'pull.problem.uncommitted.message'
    }
  }
]

const mergeQueryMeta: ReactQueryMeta = {
  error: {
    title: 'api.toast.pull.title',
    message: 'api.toast.pull.message'
  }
}

const usePullAction = ({
  onSuccess,
  onFailure,
  onConflict
}: PullArgs): PullActionResponse => {
  const { branch } = useGitContext()
  const { makeToast } = Toaster.useToaster()
  const [loading, setLoading] = useState(false)
  const { refreshWorkingTreeStatus } = useEventContext()
  const [remoteHeadCommit, setRemoteHeadCommit] = useState<string>()
  const { t } = useTranslation('translation', { keyPrefix: 'pull.toast' })
  const { resolveProblemDetails } = useProblemDetails(problemDetailRegistry)

  const { refetch: describePull } = useDescribeMerge({
    enabled: false,
    targetBranch: branch,
    meta: mergeQueryMeta
  })

  const { mutateAsync: pullChanges } = useMerge({
    meta: mergeQueryMeta
  })

  const doPullChanges = useCallback(
    async (
      remoteId: string,
      selections: ConflictResolutionSelection[] = []
    ) => {
      setLoading(true)

      try {
        await pullChanges({
          conflictSelections: selections,
          remoteCommitId: remoteId,
          sourceBranch: branch
        }).then(async () => {
          await refreshWorkingTreeStatus?.({
            refreshFileSummaries: true,
            refreshPipelines: true
          })
        })

        makeToast({
          type: 'success',
          title: t('do.success.title'),
          message: t('do.success.message')
        })

        onSuccess?.()
      } catch (error) {
        resolveProblemDetails(error)
        onFailure?.()
      } finally {
        setLoading(false)
      }
    },
    [
      pullChanges,
      makeToast,
      t,
      branch,
      onSuccess,
      refreshWorkingTreeStatus,
      resolveProblemDetails,
      onFailure
    ]
  )

  const resolveConflicts = useCallback(
    async (selections: ConflictResolutionSelection[]) => {
      if (!remoteHeadCommit) {
        makeToast({
          type: 'error',
          title: t('missing-commit-id.error.title'),
          message: t('missing-commit-id.error.message')
        })

        onFailure?.()

        return
      }

      await doPullChanges(remoteHeadCommit, selections)
    },
    [doPullChanges, makeToast, onFailure, remoteHeadCommit, t]
  )

  const pull = useCallback(async () => {
    setLoading(true)

    makeToast({
      type: 'info',
      title: t('do.in-progress.title'),
      message: t('do.in-progress.message')
    })

    try {
      const {
        error,
        isError,
        data: pullDescription,
        isLoading: isDescribeLoading
      } = await describePull()

      if (isError) {
        resolveProblemDetails(error)

        onFailure?.()
        return
      }

      if (!isDescribeLoading && pullDescription) {
        const { conflicts, remoteCommitId } = pullDescription
        setRemoteHeadCommit(remoteCommitId)

        if (conflicts.length === 0) {
          await doPullChanges(remoteCommitId)
        } else {
          onConflict(conflicts)
        }
      }
    } finally {
      setLoading(false)
    }
  }, [
    makeToast,
    t,
    describePull,
    resolveProblemDetails,
    onFailure,
    doPullChanges,
    onConflict
  ])

  return {
    isLoading: loading,
    pullRemoteChanges: pull,
    resolvePullConflicts: resolveConflicts
  }
}

export default usePullAction
