import type { VariableScope } from 'job-lib/types/Variables'

import {
  ErrorStatuses,
  Fields,
  ReducerActions,
  type FormState
} from 'modules/ManageVariables/types'

import { errorMessages } from '../errorMessages'
import { type ReducerAction } from './formReducer'
import { isFormValid } from './isFormValid'

export const updateValue = (
  state: FormState,
  action: ReducerAction,
  selectedVariableScope: VariableScope
): FormState => {
  if (action.type !== ReducerActions.UPDATE_FIELD) {
    return state
  }

  if (action.field === Fields.NAME) {
    throw new Error(
      'The name field requires stricter validation checking, updateValue cannot be used in this instance'
    )
  }

  let errorStatus = ErrorStatuses.NO_ERROR

  const { field, value } = action
  const isNonInputField = [Fields.VISIBILITY].includes(field)

  if (isNonInputField && !value) {
    errorStatus = ErrorStatuses.SELECT_OPTION
  }

  const updatedState = {
    ...state,
    [field]: {
      isValid: errorStatus === ErrorStatuses.NO_ERROR,
      value,
      error: errorMessages[errorStatus]
    },
    [Fields.NAME]: state[Fields.NAME]
  }

  return {
    ...updatedState,
    isFormValid: isFormValid(updatedState, selectedVariableScope)
  }
}
