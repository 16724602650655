import useGitContext from 'provider/GitProvider/useGitContext'
import useFeatureFlags from 'hooks/useFeatureFlags'

export interface MergeFeatureFlagResponse {
  isMergingFromBranch: boolean
  isMergingToBranch: boolean
  keyDiscriminator: string
}

const useMergeFeatureFlag = (): MergeFeatureFlagResponse => {
  const { isNativeGit } = useGitContext()
  const { enableWorkingTreeMerge } = useFeatureFlags()
  const isMergingFromBranch = isNativeGit && enableWorkingTreeMerge
  const keyDiscriminator = isMergingFromBranch ? 'from' : 'to'

  return {
    isMergingFromBranch,
    isMergingToBranch: !isMergingFromBranch,
    keyDiscriminator
  }
}

export default useMergeFeatureFlag
