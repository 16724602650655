import { useMemo } from 'react'

import { useAuth, useUser } from '@matillion/hub-client'
import axios, { type AxiosInstance, type AxiosRequestConfig } from 'axios'

import config from 'config'

export enum SpringClientVersion {
  V1 = 'v1',
  V2 = 'v2'
}

export const useSpringClient = (
  version: SpringClientVersion = SpringClientVersion.V1,
  axiosConfig?: AxiosRequestConfig
) => {
  const { organisation } = useUser()
  const { getToken } = useAuth()

  const client: AxiosInstance = useMemo(createInstance, [
    axiosConfig,
    getToken,
    organisation.id,
    organisation.region,
    version
  ])

  function createInstance() {
    const instance = axios.create(axiosConfig ?? {})

    instance.defaults.baseURL = `${config.getApiBaseUrl(
      organisation.region
    )}/${version}`

    instance.interceptors.request.use(async (interceptedConfig) => {
      const authHeader = `Bearer ${await getToken({
        audience: config.apiTokenAudience
      })}`

      interceptedConfig.headers = {
        ...interceptedConfig.headers,
        'account-id': organisation.id,
        Authorization: authHeader
      }

      return interceptedConfig
    })

    return instance
  }

  return client
}
