import { type FC, type PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'
import classnames from 'classnames'

import { useComponentSummaries } from 'api/hooks/useGetComponentSummaries'

import { Loading } from 'components/Loading/Loading'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'
import { useFlags } from 'hooks/useFlags'

import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'

import classes from './Canvas.module.scss'
import { CanvasProvider } from './CanvasProvider'
import { FlowCanvas } from './components/FlowCanvas/FlowCanvas'
import { Toolbox } from './components/Toolbox/Toolbox'
import { ZoomToolbar } from './components/ZoomToolbar/ZoomToolbar'

const CanvasOverlay: FC<PropsWithChildren> = ({ children }) => (
  <div className={classes.Canvas} data-testid="canvas-container">
    <div
      data-testid="canvas-overlay-container"
      className={classnames(classes.Canvas__Overlay, classes.Overlay)}
    >
      {children}
    </div>
  </div>
)

const useCanvasData = () => {
  const {
    pipelineSummary,
    isLoading: isPipelineSummaryLoading,
    isError: isPipelineSummaryError
  } = useActivePipelineSummary()
  const {
    job,
    isLoading: isWorkingCopyLoading,
    isError: isWorkingCopyError
  } = useWorkingCopy()
  const {
    isLoading: isComponentSummariesLoading,
    isError: isComponentSummariesError
  } = useComponentSummaries()

  return {
    pipelineSummary,
    pipeline: job,
    isLoading:
      isPipelineSummaryLoading ||
      isWorkingCopyLoading ||
      isComponentSummariesLoading,
    isError:
      isPipelineSummaryError || isWorkingCopyError || isComponentSummariesError
  }
}

export const Canvas: FC = () => {
  const { enableWizardUiForComponents } = useFlags()

  const { t } = useTranslation()

  const { pipeline, pipelineSummary, isLoading, isError } = useCanvasData()

  if (isError) {
    return (
      <CanvasOverlay>
        <Icon.Error className={classes.Overlay__Icon} />

        <Typography className={classes.Overlay__Text}>
          {t('translation:canvas.noJobInfo')}
        </Typography>
      </CanvasOverlay>
    )
  }

  if (isLoading || !pipeline || !pipelineSummary) {
    return (
      <CanvasOverlay>
        <Loading />
      </CanvasOverlay>
    )
  }

  return (
    <CanvasProvider job={pipeline} jobSummary={pipelineSummary}>
      <div data-testid="canvas-toolbox" className={classes.Canvas__Toolbox}>
        <Toolbox />
      </div>

      <FlowCanvas job={pipeline} />

      {!enableWizardUiForComponents && (
        <div
          data-testid="canvas-zoom-controls"
          className={classes.Canvas__ZoomToolbar}
        >
          <ZoomToolbar />
        </div>
      )}
    </CanvasProvider>
  )
}
