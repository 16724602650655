import { useTranslation } from 'react-i18next'

import { Button, Icon } from '@matillion/component-library'

import {
  PopOverItem,
  PopOverMenu,
  PopOverSection
} from 'components/PopOverMenu'

import classes from 'modules/core/EtlDesigner/components/PanelWrapper/PanelWrapper.module.scss'

interface Props {
  refetch: () => void
}

const SchemaMenu = ({ refetch }: Props) => {
  const { t } = useTranslation()
  return (
    <PopOverMenu
      content={
        <PopOverSection>
          <PopOverItem
            key="refetch"
            data-testid="schemas-action-refetch"
            onClick={() => {
              refetch()
            }}
          >
            {t('sideBar.contextMenu.refresh')}
          </PopOverItem>
        </PopOverSection>
      }
    >
      {({ onClick }) => (
        <Button
          size="sm"
          alt="ghost"
          className={classes.PanelHeader__Button}
          aria-label={t('sideBar.contextMenu.cta')}
          data-testid="schema-browser-actions"
          onClick={onClick}
        >
          <Icon.Ellipsis className={classes.ContextIcon} />
        </Button>
      )}
    </PopOverMenu>
  )
}

export default SchemaMenu
