import { type FC } from 'react'
import { PanelResizeHandle } from 'react-resizable-panels'

import { Icon } from '@matillion/component-library'
import classnames from 'classnames'

import classes from './ResizeableHandler.module.scss'
import { type Props } from './types'

export const ResizeableHandler: FC<Props> = ({
  orientation,
  dragHandle,
  disabled,
  id
}) => {
  if (disabled) {
    return (
      <div
        className={classnames(
          classes.ResizeableHandler,
          classes['ResizeableHandler--Inactive'],
          classes[`ResizeableHandler--${orientation}`]
        )}
      >
        <div
          className={classnames(
            classes.ResizeableHandler__Indicator,
            classes[`ResizeableHandler__Indicator--${orientation}`]
          )}
        />
      </div>
    )
  }

  return (
    <PanelResizeHandle
      className={classnames(
        classes.ResizeableHandler,
        classes['ResizeableHandler--Active'],
        classes[`ResizeableHandler--${orientation}`],
        classes[
          `ResizeableHandler--${dragHandle ? 'VerticalWithDragHandle' : null}`
        ]
      )}
      id={`resizeable-${id as string}-bar`}
    >
      {dragHandle ? (
        <div
          className={classnames(
            classes.ResizeableHandler__DragHandle,
            classes[`ResizeableHandler__DragHandle--${orientation}`]
          )}
          data-testid="drag-handle"
        >
          <Icon.DragHandle />
        </div>
      ) : (
        <div
          className={classnames(
            classes.ResizeableHandler__Indicator,
            classes[`ResizeableHandler__Indicator--${orientation}`]
          )}
          data-testid="resize-indicator"
        />
      )}
    </PanelResizeHandle>
  )
}
