import { useCallback } from 'react'

import { useMutation } from '@tanstack/react-query'

import { type MergeRequest, type MergeMutationArgs } from './types'
import useMapConflictSelections from 'hooks/useMapConflictSelections'
import { type ConflictVersion } from 'components/ConflictVersionSelector/types'
import useGitContext from 'provider/GitProvider/useGitContext'
import useWorkingTreeClient from 'api/clients/useWorkingTreeClient'
import { queryKeys } from 'api/query-keys'
import { type ApiHookProps, type ReactQueryMeta } from 'types/ReactQueryMeta'

const conflictMapping = new Map<ConflictVersion, string>([
  ['OURS', 'LOCAL'],
  ['THEIRS', 'REMOTE']
])

const usePostMergeMutationKey = () => {
  const { project } = useGitContext()
  return [queryKeys.merge, project]
}

const defaultMeta: ReactQueryMeta = {
  error: {
    title: 'api.toast.merge.title',
    message: 'api.toast.merge.message'
  }
}

const useMerge = ({ meta }: ApiHookProps = {}) => {
  const client = useWorkingTreeClient()
  const { project, branch } = useGitContext()
  const { map } = useMapConflictSelections(conflictMapping)

  const merge = useCallback(
    async ({
      remoteCommitId,
      conflictSelections,
      commitMessage,
      sourceBranch
    }: MergeMutationArgs) => {
      const body: MergeRequest = {
        remoteCommitId,
        commitMessage,
        sourceBranchName: sourceBranch,
        conflictResolutions: map(conflictSelections)
      }

      const url = `projects/${project}/branches/${branch}/merges`
      const { status } = await client.post(url, body)
      return status
    },
    [branch, client, map, project]
  )

  return useMutation(usePostMergeMutationKey(), merge, {
    meta: meta ?? defaultMeta
  })
}

export default useMerge
