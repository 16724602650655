import { type FC, type PropsWithChildren } from 'react'

import { Tree, type TreeProps } from '@matillion/component-library'

import classes from './BrowserTree.module.scss'

interface BrowserTreeProps extends PropsWithChildren<TreeProps> {
  expanded?: boolean
  expandedItems?: Record<string, boolean>
}

const BrowserTree: FC<BrowserTreeProps> = ({
  children,
  expanded,
  expandedItems = { Orchestration: true, Data: true }
}) => (
  <div className={classes.BrowserTree} tabIndex={0}>
    <Tree
      initialExpanded={expandedItems}
      isAllFoldersExpanded={expanded}
      compact
    >
      {children}
    </Tree>
  </div>
)

export default BrowserTree
