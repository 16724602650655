import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'

import { queryKeys } from 'api/queryKeys'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import {
  type OrchestrationJob,
  type TransformationJob
} from 'job-lib/types/Job'

import { useAgentDetails } from '../useAgentDetails/useAgentDetails'
import { type JobSummaryId } from '../useGetJobSummaries'
import useGetProject from '../useGetProject/useGetProject'
import { useSpringClient } from '../useSpringClient/useSpringClient'

export const getJobQueryKey = (
  projectId: string,
  branchId: string,
  jobId: JobSummaryId
) => [queryKeys.pull, queryKeys.job, projectId, branchId, jobId]

export const useGetJob = (jobId: JobSummaryId) => {
  const { projectId, branchId } = useProjectInfo()
  const client = useSpringClient()
  const project = useGetProject()
  const warehouse = project?.data?.warehouse?.toUpperCase() ?? ''
  const { data: agentDetails } = useAgentDetails()
  const agentVersion = agentDetails?.agentVersion ?? ''

  const getAJob = useCallback(async () => {
    const { data } = await client.get<OrchestrationJob | TransformationJob>(
      `/projects/${projectId}/branches/${branchId}/jobs/${jobId}?dataWarehouseType=${warehouse}`,
      {
        headers: {
          // agent-version is required for DPL conversion.
          'agent-version': agentVersion
        }
      }
    )

    return data
  }, [client, projectId, branchId, agentVersion, jobId, warehouse])

  return useQuery(
    [queryKeys.pull, queryKeys.job, projectId, branchId, jobId, warehouse],
    getAJob,
    {
      cacheTime: 0,
      staleTime: 0,
      enabled: Boolean(jobId) && Boolean(warehouse) && Boolean(agentVersion)
    }
  )
}
