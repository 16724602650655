import { useQueryClient } from '@tanstack/react-query'

import {
  type ComponentMetadata,
  type ConnectorComponentMetadataResponse
} from 'api/hooks/useGetComponentMetadata/types'
import { getConnectorComponentMetadataQueryKey } from 'api/hooks/useGetComponentMetadata/useGetComponentMetadata'
import { type UseGetParameterOptionsRequest } from 'api/hooks/useGetParameterOptions/useGetParameterOptions'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { JobType } from 'job-lib/types/JobType'

import { useComponentValidationProvider } from 'modules/core/ComponentValidation'
import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'

import { useComponentInstanceMetadataQuery } from '../useComponentInstanceMetadataQuery/useComponentInstanceMetadataQuery'
import { getComponentDependencies } from './getComponentDependencies'
import { getCustomConnectorDependencies } from './getCustomConnectorDependencies'
import { getParameterDependencies } from './getParameterDependencies'
import { getParameterIdDependencies } from './getParameterIdDependencies'
import { getParameterMetadataDependencies } from './getParameterMetadataDependencies'
import { getTransformSQLDependencies } from './getTransformSQLDependencies'
import {
  getLookupData,
  isGridParameter,
  type Parameter
} from './useParameterOptionsHelpers'
import { type ParameterOptionsRequestType } from './useParameterOptionsTypes'

export const useParameterOptionsRequest = <
  T extends ParameterOptionsRequestType
>(
  isEnabled: boolean,
  componentMetaData: ComponentMetadata,
  parameter: Parameter | undefined,
  requestType: T
): UseGetParameterOptionsRequest<T> => {
  const { job, jobType } = useWorkingCopy()
  const projectInfo = useProjectInfo()
  const { validationQueryCache } = useComponentValidationProvider()
  const queryClient = useQueryClient()

  const { componentId } = projectInfo
  const { componentInstance } = useComponentInstanceMetadataQuery(componentId)
  const { lookupDependencies, lookupType } = getLookupData(
    parameter,
    requestType
  )
  const cCLookupDepProfiles = lookupDependencies.filter((v) =>
    v.startsWith('cc_profile.')
  )
  const parameters = componentInstance?.parameters

  const getConnectorMetadata = (
    profile: string
  ): ConnectorComponentMetadataResponse | undefined =>
    queryClient.getQueryData(
      getConnectorComponentMetadataQueryKey(projectInfo, profile)
    )

  const customConnectorProfileDependencies = getCustomConnectorDependencies(
    cCLookupDepProfiles,
    parameters,
    getConnectorMetadata
  )

  const transformSQLDependencies =
    isEnabled && jobType === JobType.Transformation
      ? getTransformSQLDependencies({
          validationQueryCache,
          lookupDependencies,
          componentId,
          job
        })
      : undefined

  const parameterDependencies = isEnabled
    ? getParameterDependencies({
        lookupDependencies,
        componentMetaData,
        componentId,
        job
      })
    : undefined

  const parameterIdDependencies = isEnabled
    ? getParameterIdDependencies(lookupDependencies)
    : undefined

  const componentDependencies = isEnabled
    ? getComponentDependencies({
        parameter,
        componentId,
        job
      })
    : undefined

  const parameterMetadataDependencies =
    getParameterMetadataDependencies(parameter)

  return {
    lookupDefinition: {
      parameters: parameterDependencies,
      component: componentDependencies,
      parameterMetadataDependencies,
      parameterIdDependencies,
      customConnectorProfileDependencies,
      ...transformSQLDependencies
    },
    lookupType,
    parameterId: isGridParameter(parameter) ? parameter?.id : parameter?.dplID,
    isEnabled: isEnabled && Boolean(lookupType),
    variables: job?.variables ?? {},
    requestType
  }
}
