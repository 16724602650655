import { forwardRef, useImperativeHandle, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Tree } from '@matillion/component-library'

import { useSchemas } from 'api/hooks/useSchemas'

import { Loading } from 'components/Loading/Loading'
import { EmptyPanel } from 'components/Panels/EmptyPanel'
import { ErrorPanel } from 'components/Panels/ErrorPanel'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useProjectNames } from 'hooks/useProjectInfo/useProjectNames'

import { SchemaRow } from './components/SchemaRow'
import classes from './SchemaBrowser.module.scss'
import { type SimpleRefetchRef } from './types'

const SchemaBrowser = forwardRef<SimpleRefetchRef>((props, ref) => {
  const { warehouse } = useProjectNames()
  const {
    isLoading,
    isError,
    data: schemas,
    refetch,
    isRefetching,
    isRefetchError
  } = useSchemas(warehouse)
  const { jobSummaryId } = useProjectInfo()
  const { t } = useTranslation(['translation', 'components'])

  useImperativeHandle(
    ref,
    () => ({
      refetch
    }),
    [refetch]
  )

  const validSchemas = useMemo(() => {
    if (!schemas) return []

    return schemas.results.filter(
      (schema) => schema.name !== '[Environment Default]'
    )
  }, [schemas])

  if (isLoading || isRefetching) {
    return <Loading className={classes.ComponentBrowser__Loading} />
  }

  if (isError || isRefetchError) {
    return (
      <ErrorPanel
        includeLink={false}
        text={t('sideBar.schemaPanel.networkError')}
      />
    )
  }

  if (!jobSummaryId) {
    return (
      <EmptyPanel>
        {t('translation:sideBar.schemaPanel.noJobSelected')}
      </EmptyPanel>
    )
  }

  if (!validSchemas.length) {
    return (
      <EmptyPanel>
        {t('translation:sideBar.schemaPanel.noEnvironmentsInProject')}
      </EmptyPanel>
    )
  }

  return (
    <div className={classes.BrowserTree} tabIndex={0}>
      <Tree initialExpanded={{ Orchestration: true, Data: true }} compact>
        {validSchemas.map((schema) => (
          <SchemaRow key={schema.name} schema={schema} />
        ))}
      </Tree>
    </div>
  )
})

SchemaBrowser.displayName = 'SchemaBrowser'

export default SchemaBrowser
