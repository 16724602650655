// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XWfVN_cOfQMxAEKhKwos{display:flex;flex-direction:column;align-items:center}.oQTaSCu6HX54fbFV6pwS{margin-bottom:8px}.I0TczP0YlxB9xPnJwT1w{text-align:center}`, "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,kBAAA,CAEA,sBACE,iBAAA,CAGF,sBACE,iBAAA","sourcesContent":[".Loading {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  &__Icon {\n    margin-bottom: 8px;\n  }\n\n  &__Text {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Loading": `XWfVN_cOfQMxAEKhKwos`,
	"Loading__Icon": `oQTaSCu6HX54fbFV6pwS`,
	"Loading__Text": `I0TczP0YlxB9xPnJwT1w`
};
export default ___CSS_LOADER_EXPORT___;
