import { type ComponentMetadataResponse } from '../types'
import { mockAnaplanModularConnectorMetadata } from './mockComponentsMetadata/mockAnaplanModularConnectorMetadata'
import { mockAndMetadata } from './mockComponentsMetadata/mockAndMetadata'
import { mockAssertTableMetadata } from './mockComponentsMetadata/mockAssertTableMetadata'
import { mockCreateFileFormatMetaData } from './mockComponentsMetadata/mockCreateFileFormatMetadata'
import { mockCreateTableMetadata } from './mockComponentsMetadata/mockCreateTableMetadata'
import { mockDistinctMetadata } from './mockComponentsMetadata/mockDistinctMetadata'
import { mockEndFailureMetadata } from './mockComponentsMetadata/mockEndFailureMetadata'
import { mockEndSuccessMetadata } from './mockComponentsMetadata/mockEndSuccessMetadata'
import { mockExtractNestedDataSF } from './mockComponentsMetadata/mockExtractNestedDataSF'
import { mockFilterMetadata } from './mockComponentsMetadata/mockFilterMetadata'
import { mockFixedFlowMetadata } from './mockComponentsMetadata/mockFixedFlowMetadata'
import { mockFlattenVariantMetadata } from './mockComponentsMetadata/mockFlattenVariantMetadata'
import { mockGenerateSequenceMetadata } from './mockComponentsMetadata/mockGenerateSequenceMetadata'
import { mockGoogleSheetsModularConnectorMetadata } from './mockComponentsMetadata/mockGoogleSheetsModularConnectorMetadata'
import { mockIfMetadata } from './mockComponentsMetadata/mockIfMetadata'
import { mockJoinMetadata } from './mockComponentsMetadata/mockJoinMetadata'
import { mockLoopIteratorMetadata } from './mockComponentsMetadata/mockLoopIteratorMetadata'
import { mockMarketoQueryMetadata } from './mockComponentsMetadata/mockMarketoQueryMetadata'
import { mockMultiTableInputMetadata } from './mockComponentsMetadata/mockMultiTableInputMetadata'
import { mockPythonScriptMetadata } from './mockComponentsMetadata/mockPythonScriptMetadata'
import { mockRankMetadata } from './mockComponentsMetadata/mockRankMetadata'
import { mockRetryMetadata } from './mockComponentsMetadata/mockRetryMetadata'
import { mockRewriteTableMetadata } from './mockComponentsMetadata/mockRewriteTableMetadata'
import { mockRunOrchestrationMetadata } from './mockComponentsMetadata/mockRunOrchestrationMetadata'
import { mockRunTransformationMetadata } from './mockComponentsMetadata/mockRunTransformationMetadata'
import { mockS3LoadMetadata } from './mockComponentsMetadata/mockS3LoadMetadata'
import { mockSNSMessageMetadata } from './mockComponentsMetadata/mockSNSMessageMetadata'
import { mockSqlMetadata } from './mockComponentsMetadata/mockSqlMetadata'
import { mockSQLScriptMetadata } from './mockComponentsMetadata/mockSQLScriptMetadata'
import { mockSqsMessageMetaData } from './mockComponentsMetadata/mockSqsMessageMetadata'
import { mockStartMetadata } from './mockComponentsMetadata/mockStartMetadata'
import { mockTableInputMetadata } from './mockComponentsMetadata/mockTableInputMetadata'
import { mockTableIteratorMetadata } from './mockComponentsMetadata/mockTableIteratorMetaData'
import { mockTableUpdateMetadata } from './mockComponentsMetadata/mockTableUpdateMetadata'
import { mockTestComponentMetadata } from './mockComponentsMetadata/mockTestComponentMetadata'
import { mockTransposeColumnsMetadata } from './mockComponentsMetadata/mockTransposeColumnsMetadata'
import { mockUniteMetadata } from './mockComponentsMetadata/mockUniteMetadata'

export const referenceMetadata: ComponentMetadataResponse[] = [
  mockAssertTableMetadata,
  mockCreateFileFormatMetaData,
  mockCreateTableMetadata,
  mockDistinctMetadata,
  mockFilterMetadata,
  mockFixedFlowMetadata,
  mockFlattenVariantMetadata,
  mockGenerateSequenceMetadata,
  mockGoogleSheetsModularConnectorMetadata,
  mockIfMetadata,
  mockJoinMetadata,
  mockMarketoQueryMetadata,
  mockMultiTableInputMetadata,
  mockRankMetadata,
  mockRetryMetadata,
  mockRewriteTableMetadata,
  mockRunOrchestrationMetadata,
  mockRunTransformationMetadata,
  mockS3LoadMetadata,
  mockSNSMessageMetadata,
  mockSQLScriptMetadata,
  mockSqlMetadata,
  mockSqsMessageMetaData,
  mockStartMetadata,
  mockTableInputMetadata,
  mockTableIteratorMetadata,
  mockTableUpdateMetadata,
  mockTestComponentMetadata,
  mockTransposeColumnsMetadata,
  mockUniteMetadata,
  mockRunOrchestrationMetadata,
  mockIfMetadata,
  mockGoogleSheetsModularConnectorMetadata,
  mockAnaplanModularConnectorMetadata,
  mockTableUpdateMetadata,
  mockPythonScriptMetadata,
  mockLoopIteratorMetadata,
  mockEndFailureMetadata,
  mockEndSuccessMetadata,
  mockAndMetadata,
  mockExtractNestedDataSF
]
