import { rest, type RequestHandler } from 'msw'

import { PROJECT_ID } from '__test-utils__/ids'

import config from 'config'

export const useDeleteBranchHandlers: RequestHandler[] = [
  rest.delete(
    `${config.getApiBaseUrl()}/v1/projects/${PROJECT_ID}/branches/500/workingtree`,
    async (req, res, ctx) => {
      return res(ctx.status(500))
    }
  ),
  rest.delete(
    `${config.getApiBaseUrl()}/v1/projects/${PROJECT_ID}/branches/*/workingtree`,
    async (req, res, ctx) => {
      return res(ctx.status(204))
    }
  )
]
