import { useTranslation } from 'react-i18next'

import { Button, Icon, Typography } from '@matillion/component-library'
import { useGitContext } from '@matillion/git-component-library'

import GitActionLoadingIcon from './GitActionLoadingIcon'
import classes from './GitButtonAnchor.module.scss'
import UncommittedChangesIcon from './UncommittedChangesIcon'

export interface GitButtonAnchorProps {
  onClick: () => void
  isLoading?: boolean
  isMenuOpen: boolean
}

const GitButtonAnchor = ({
  isMenuOpen,
  isLoading,
  onClick
}: GitButtonAnchorProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'button' })
  const { hasUncommittedChanges } = useGitContext()

  return (
    <Button
      onClick={onClick}
      aria-haspopup="menu"
      data-testid="git-button"
      className={classes.Button}
      aria-expanded={isMenuOpen}
      aria-label={t('menu.aria-label')}
    >
      {hasUncommittedChanges && !isLoading && (
        <UncommittedChangesIcon className={classes.Info} />
      )}

      {isLoading && <GitActionLoadingIcon />}

      <Typography format="bcs" weight="bold">
        {t('text')}
      </Typography>

      <Icon.ChevronDown
        className={isMenuOpen ? classes.ChevronUp : classes.ChevronDown}
      />
    </Button>
  )
}

export default GitButtonAnchor
