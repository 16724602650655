import { useState, type FC, type KeyboardEvent } from 'react'

import {
  Icon,
  Tooltip,
  TreeItem,
  Typography,
  useTreeItem
} from '@matillion/component-library'
import classNames from 'classnames'

import { type JobSummary } from 'api/hooks/useGetJobSummaries'

import { JobTypeIcon } from 'components/JobTypeIcon'
import { PopOverMenu } from 'components/PopOverMenu'

import { useSelectedJobs } from 'hooks/useSelectedJobs'

import ContextMenuButton from '../ContextMenuButton/ContextMenuButton'
import { OptionalDrag, useJobDragProps } from '../OptionalDrag'
import PipelineItemContextMenu from '../PipelineBrowserTree/PipelineItemContextMenu/PipelineItemContextMenu'
import PipelineItemDragSource from '../PipelineItemDragSource/PipelineItemDragSource'
import classes from './PipelineItem.module.scss'

interface PipelineItemProps {
  pipeline: JobSummary
  className?: string
  isLeaf: boolean
  path?: string
}

const PipelineItem: FC<PipelineItemProps> = ({
  pipeline,
  isLeaf,
  className,
  path
}) => {
  const { jobId } = pipeline
  const { navigateToJob } = useSelectedJobs()
  const dragProps = useJobDragProps(pipeline)
  const { isFocused, isHovered } = useTreeItem(jobId)
  const [isDragging, setIsDragging] = useState(false)

  const handleDragStart = () => {
    setIsDragging(true)
  }

  const handleDragEnd = () => {
    setIsDragging(false)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      navigateToJob(jobId)
    }
  }

  return (
    <PipelineItemDragSource
      path={path}
      pipeline={pipeline}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <div
        className={classNames(classes.TreeItemWrapper, {
          [classes.Disabled]: isDragging
        })}
      >
        {isHovered && <Icon.DragHandle className={classes.DragHandle} />}

        <TreeItem
          nodeId={jobId}
          label={pipeline.name}
          leaf={
            <PopOverMenu
              key={jobId}
              content={<PipelineItemContextMenu job={pipeline} path={path} />}
            >
              {({ onContextMenu, onClick }) => {
                const conditionalContextMenuProps = {
                  ...(isFocused && { onContextMenu })
                }

                return (
                  <div
                    tabIndex={0}
                    className={classNames(className, classes.ItemContainer, {
                      [classes.Leaf]: isLeaf
                    })}
                    onKeyDown={handleKeyDown}
                    {...conditionalContextMenuProps}
                    data-testid={`job-list-item-${jobId}`}
                    onDoubleClick={() => {
                      navigateToJob(jobId)
                    }}
                  >
                    <OptionalDrag
                      dragProps={dragProps}
                      className={classes.Item}
                    >
                      <span className={classes.Icon}>
                        <JobTypeIcon jobType={pipeline.type} />
                      </span>

                      <Tooltip
                        content={isDragging ? '' : pipeline.name}
                        onlyShowOnOverflow
                        className={classes.ItemContainer__Tooltip}
                      >
                        <div className={classes.TitleText}>
                          <Typography
                            format="bcs"
                            as="span"
                            className={classes.Text}
                            data-testid="job-name"
                          >
                            {pipeline.name}
                          </Typography>
                        </div>
                      </Tooltip>

                      <ContextMenuButton
                        id={jobId}
                        key={jobId}
                        onClick={onClick}
                      />
                    </OptionalDrag>
                  </div>
                )
              }}
            </PopOverMenu>
          }
        />
      </div>
    </PipelineItemDragSource>
  )
}

export default PipelineItem
