import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingSpinner, Typography } from '@matillion/component-library'

import { type StorageEditorType } from '../../../../types'
import classes from '../../UrlEditor.module.scss'
import { SortOrder, type GridColumns, type GridRow } from '../hooks/useGrid'
import ContainerGrid from './ContainerGrid'

interface ContainerListProps {
  isLoading: boolean
  columns: GridColumns
  rows: GridRow[]
  setSort: (sort: SortOrder) => void
  storageEditorType: StorageEditorType
}

const ContainerList = ({
  isLoading,
  columns,
  rows,
  setSort,
  storageEditorType
}: ContainerListProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      setSort(SortOrder.ASC)
    }
  }, [setSort])

  return (
    <div className={classes.Buckets}>
      <Typography
        weight="bold"
        format="bcs"
        className={classes['Buckets--Subheader']}
      >
        {t(
          `parameterEditor.${storageEditorType}_URL.table.titles.containersName`
        )}
      </Typography>
      <div className={classes['Buckets--TableContainer']}>
        {isLoading ? (
          <div className={classes['Buckets--LoadingContainer']}>
            <LoadingSpinner />
          </div>
        ) : (
          <ContainerGrid columns={columns} rows={rows} setSort={setSort} />
        )}
      </div>
    </div>
  )
}

export default ContainerList
