import { type ListProjectVariablesResponse } from 'api/hooks/useListProjectVariables/types'

import type {
  JobVariable,
  JobVariableCollection,
  JobVariableType,
  JobVariableVisibility,
  VariableScope
} from 'job-lib/types/Variables'

import {
  ErrorStatuses,
  Fields,
  ReducerActions,
  type FormState,
  type ProjectDefaultOverrides,
  type ProjectVariable,
  type ProjectVariableOverride,
  type ProjectVariableType
} from 'modules/ManageVariables/types'
import { isJobVariable } from 'modules/ManageVariables/utils'

import { errorMessages } from '../errorMessages'
import { updateDefaultValue } from './updateDefaultValue'
import { updateName } from './updateName'
import { updateProjectOverride } from './updateProjectOverride'
import { updateValue } from './updateValue'
import { validateForm } from './validateForm'

export type ReducerAction =
  | {
      type: ReducerActions.UPDATE_FIELD
      field: Fields.NAME
      value: string
      selectedVariableScope: VariableScope
      selectedVariableType: JobVariableType | ProjectVariableType | null
    }
  | {
      type: ReducerActions.UPDATE_FIELD
      field: Fields.VISIBILITY
      value: JobVariableVisibility | null
      selectedVariableScope: VariableScope
      selectedVariableType: JobVariableType | ProjectVariableType | null
    }
  | {
      type: ReducerActions.UPDATE_FIELD
      field: Fields.DEFAULT_VALUE | Fields.DESCRIPTION
      value: string
      selectedVariableScope: VariableScope
      selectedVariableType: JobVariableType | ProjectVariableType | null
    }
  | {
      type: ReducerActions.VALIDATE_FORM
      selectedVariableScope: VariableScope
      selectedVariableType: JobVariableType | ProjectVariableType | null
    }
  | {
      type: ReducerActions.UPDATE_NAME
      value: string
      jobVariables: JobVariableCollection | undefined
      projectVariables: ListProjectVariablesResponse
      originalName?: string
      selectedVariableScope: VariableScope
      selectedVariableType: JobVariableType | ProjectVariableType | null
    }
  | {
      type: ReducerActions.UPDATE_PROJECT_OVERRIDE
      field: Fields.PROJECT_DEFAULT_OVERRIDES
      value: ProjectVariableOverride['value']
      environmentId: ProjectVariableOverride['environmentId']
      selectedVariableScope: VariableScope
      selectedVariableType: JobVariableType | ProjectVariableType | null
    }
  | {
      type: ReducerActions.UPDATE_DEFAULT_VALUE
      field: Fields.DEFAULT_VALUE
      value: string
      selectedVariableScope: VariableScope
      selectedVariableType: JobVariableType | ProjectVariableType | null
    }

export const formReducer = (
  state: FormState,
  action: ReducerAction
): FormState => {
  const { selectedVariableScope, selectedVariableType } = action

  switch (action.type) {
    case ReducerActions.UPDATE_FIELD:
      return updateValue(state, action, selectedVariableScope)
    case ReducerActions.UPDATE_NAME:
      return updateName(state, action, selectedVariableScope)
    case ReducerActions.VALIDATE_FORM:
      return validateForm(state)
    case ReducerActions.UPDATE_PROJECT_OVERRIDE:
      return updateProjectOverride(
        state,
        action,
        selectedVariableScope,
        selectedVariableType
      )
    case ReducerActions.UPDATE_DEFAULT_VALUE:
      return updateDefaultValue(
        state,
        action,
        selectedVariableScope,
        selectedVariableType
      )
    default:
      throw new Error('Please provide a valid action to formReducer')
  }
}

const initState: FormState = {
  [Fields.DEFAULT_VALUE]: {
    value: '',
    isValid: true,
    error: ''
  },
  [Fields.DESCRIPTION]: {
    value: '',
    isValid: true,
    error: ''
  },
  [Fields.NAME]: {
    value: '',
    isValid: null,
    error: errorMessages[ErrorStatuses.EMPTY]
  },
  [Fields.VISIBILITY]: {
    value: null,
    isValid: null,
    error: errorMessages[ErrorStatuses.SELECT_OPTION]
  },
  [Fields.PROJECT_DEFAULT_OVERRIDES]: {
    value: {},
    isValid: true,
    error: ''
  },
  isFormValid: false
}

export const getInitialState = (
  variableScope?: VariableScope,
  variableType?: JobVariableType | ProjectVariableType | null,
  variableToEdit?: JobVariable | ProjectVariable
): FormState => {
  if (!variableToEdit) {
    return {
      ...initState
    }
  } else if (isJobVariable(variableToEdit)) {
    return getInitialStateForJobVariable(variableToEdit)
  } else {
    return getInitialStateForProjectVariable(variableToEdit)
  }
}

export function getInitialStateForJobVariable(
  variable: JobVariable
): FormState {
  const { definition, value } = variable
  const { name, visibility, description } = definition
  return {
    ...initState,
    isFormValid: true,
    [Fields.DEFAULT_VALUE]: {
      ...initState[Fields.DEFAULT_VALUE],
      value,
      isValid: true
    },
    [Fields.DESCRIPTION]: {
      ...initState[Fields.DESCRIPTION],
      value: description ?? '',
      isValid: true
    },
    [Fields.NAME]: { ...initState[Fields.NAME], value: name, isValid: true },
    [Fields.VISIBILITY]: {
      ...initState[Fields.VISIBILITY],
      value: visibility,
      isValid: true
    }
  }
}
export function getInitialStateForProjectVariable(
  variable: ProjectVariable
): FormState {
  const { description, name, overrides, value } = variable

  return {
    ...initState,
    isFormValid: true,
    [Fields.DEFAULT_VALUE]: {
      ...initState[Fields.DEFAULT_VALUE],
      value: value ? value.toString() : '',
      isValid: true
    },
    [Fields.DESCRIPTION]: {
      ...initState[Fields.DESCRIPTION],
      value: description,
      isValid: true
    },
    [Fields.NAME]: { ...initState[Fields.NAME], value: name, isValid: true },
    [Fields.PROJECT_DEFAULT_OVERRIDES]: {
      ...initState[Fields.PROJECT_DEFAULT_OVERRIDES],
      value: overrides.reduce<ProjectDefaultOverrides>((accumulator, next) => {
        return {
          ...accumulator,
          [next.environmentId]: {
            environmentId: next.environmentId,
            value: next.value,
            isValid: true,
            error: ''
          }
        }
      }, {}),
      isValid: true
    }
  }
}
