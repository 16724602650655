import {
  MutationCache,
  type Query,
  QueryCache,
  QueryClient,
  type QueryClientConfig,
  type QueryMeta
} from '@tanstack/react-query'
import useProblemDetails from 'hooks/useProblemDetails/useProblemDetails'
import { useCallback, useMemo } from 'react'
import { Problem } from 'hooks/useProblemDetails/Problem'
import useOAuthContext from 'provider/OAuthProvider/useOAuthContext'
import { useTranslation } from 'react-i18next'
import { Toaster } from '@matillion/component-library'
import { type TranslationArg } from 'hooks/useProblemDetails/types'
import { type GitMetadata, type GitMutation } from './types'
import useTranslationInterpolation from 'hooks/useTranslationInterpolation'

export const useGitQueryClient = (overrides?: QueryClientConfig) => {
  const { t } = useTranslation()
  const { makeToast } = Toaster.useToaster()
  const { onAuthRequired } = useOAuthContext()
  const { isKnownProblem, isProblemType } = useProblemDetails()
  const { computeInterpolationMap } = useTranslationInterpolation()

  const fireErrorToastMessage = useCallback(
    (meta?: QueryMeta) => {
      const errorMeta = meta?.error as Record<string, unknown>

      if (errorMeta) {
        const args = (errorMeta.args ?? []) as TranslationArg[]
        const interpolationMap = computeInterpolationMap(args)
        const title = t(errorMeta.title as string, interpolationMap)
        const message = t(errorMeta.message as string, interpolationMap)

        makeToast({
          type: 'error',
          title,
          message
        })
      } else {
        makeToast({
          type: 'error',
          title: t('api.toast.default.title'),
          message: t('api.toast.default.message')
        })
      }
    },
    [computeInterpolationMap, makeToast, t]
  )

  const handleError = useCallback(
    (error: unknown, meta?: GitMetadata) => {
      const shouldPromptUserForAuth = isProblemType(
        error,
        Problem.GIT_PROVIDER_AUTH_REQUIRED,
        Problem.GIT_PROVIDER_AUTH_FAILED,
        Problem.WTS_PROVIDER_AUTH_REQUIRED,
        Problem.WTS_PROVIDER_AUTH_FAILED
      )

      if (shouldPromptUserForAuth) {
        onAuthRequired()
      } else if (!isKnownProblem(error)) {
        fireErrorToastMessage(meta)
      }
    },
    [fireErrorToastMessage, isKnownProblem, isProblemType, onAuthRequired]
  )

  const handleQueryError = useCallback(
    (error: unknown, query: Query) => {
      handleError(error, query.meta)
    },
    [handleError]
  )

  const handleMutationError = useCallback(
    (
      error: unknown,
      _variables: unknown,
      _context: unknown,
      mutation: GitMutation
    ) => {
      handleError(error, mutation.meta)
    },
    [handleError]
  )

  return useMemo(() => {
    return new QueryClient({
      ...overrides,
      mutationCache: new MutationCache({
        onError: handleMutationError
      }),
      queryCache: new QueryCache({
        onError: handleQueryError
      }),
      defaultOptions: {
        queries: {
          staleTime: 1000 * 60 * 5, // <-- 5 minutes
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
          refetchOnMount: true,
          retry: (failureCount: number, error: unknown) => {
            if (process.env.REACT_APP_ENVIRONMENT === 'test') {
              return false
            }

            return !isKnownProblem(error) && failureCount < 3
          },
          ...overrides?.defaultOptions?.queries
        }
      }
    })
  }, [overrides, handleMutationError, handleQueryError, isKnownProblem])
}

export default useGitQueryClient
