import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Tooltip, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import {
  type HistoryTaskType,
  type State
} from 'api/hooks/useGetRunTasks/types'

import StateIcon from 'modules/FlowStatsExplorer/components/StateIcon'

import classes from './TaskHistory.module.scss'

export interface Props {
  state: State
  taskType: HistoryTaskType
}

export const TaskStatusRow: FC<Props> = ({ state, taskType }) => {
  const { t } = useTranslation()

  return (
    <div
      className={classNames(
        classes.TaskHistory__TaskStatus,
        classes[`TaskHistory__TaskStatus--${state}`]
      )}
      data-testid="task-history-row-task-status"
    >
      <Tooltip content={t(`taskExplorer.status.${state}`)}>
        <div>
          <StateIcon
            status={state}
            className={classes.TaskHistory__TaskStatus__Icon}
            aria-label={t(`taskExplorer.status.${state}`)}
          />
        </div>
      </Tooltip>
      <Typography format="bcs" data-task-type={taskType}>
        {t(`taskHistory.taskStatus.${taskType}`)}
      </Typography>
    </div>
  )
}
