import { type FC } from 'react'

import { type ComponentMetadata } from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

import { type ComponentInstance } from 'job-lib/types/Job'

import { ComponentOverview } from 'modules/ComponentProperties/components/ComponentOverview/ComponentOverview'

import { type EditedParameter } from './ComponentParametersContainer'
import { ComponentConfigurationPanel } from './components/ComponentConfigurationPanel/ComponentConfigurationPanel'
import { wizardConfigs } from './components/ComponentConfigurationPanel/wizardConfigs'
import { ComponentParameterItem } from './components/ComponentParameterItem/ComponentParameterItem'

interface ComponentParametersWizardProps {
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  componentSummaryId: ComponentSummaryId
  onEdit: (params: EditedParameter) => void
}

export const ComponentParametersWizard: FC<ComponentParametersWizardProps> = ({
  componentInstance,
  componentMetadata,
  componentSummaryId,
  onEdit
}) => {
  return (
    <>
      <ComponentOverview
        componentId={componentSummaryId}
        componentInstance={componentInstance}
        metadata={componentMetadata}
      />

      {wizardConfigs[componentSummaryId] ? (
        <ComponentConfigurationPanel
          onEdit={onEdit}
          componentMetadata={componentMetadata}
          componentSummaryId={componentSummaryId}
          componentInstance={componentInstance}
          wizardConfig={wizardConfigs[componentSummaryId]}
        />
      ) : (
        <div role="rowgroup" data-testid="component-parameters">
          {componentMetadata.parameters.map((parameter) => (
            <ComponentParameterItem
              key={`parameter-${parameter.dplID}`}
              parameter={parameter}
              componentSummaryId={componentSummaryId}
              componentInstance={componentInstance}
              componentMetadata={componentMetadata}
              onEdit={onEdit}
            />
          ))}
        </div>
      )}
    </>
  )
}
