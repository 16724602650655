import { memo, type FunctionComponent } from 'react'
import { BaseEdge, Position, type EdgeProps } from 'reactflow'

import { ConnectionPortType } from 'job-lib/types/Components'

export const getEdgeColor = (sourcePort?: string | null) => {
  switch (sourcePort) {
    case ConnectionPortType.SUCCESS:
      return '#2d8825' // plasma-green
    case ConnectionPortType.FAILURE:
      return '#c2292b' // fire-red
    case ConnectionPortType.TRUE:
      return '#366fe5' // ocean-blue
    case ConnectionPortType.FALSE:
      return '#ea6d39' // lava-orange
    case ConnectionPortType.ITERATION:
      return '#366fe5' // ocean-blue
    default:
      return '#757575' // hurricane
  }
}

export const getEdgeStyle = (source?: string | null, selected = false) => ({
  stroke: getEdgeColor(source),
  strokeWidth: selected ? 3 : 2,
  opacity: selected ? 0.5 : 1
})

const getEdgeMarker = (sourcePort?: string | null) => {
  switch (sourcePort) {
    case ConnectionPortType.SUCCESS:
      return 'url(#marker-success)'
    case ConnectionPortType.FAILURE:
      return 'url(#marker-fail)'
    case ConnectionPortType.TRUE:
      return 'url(#marker-true)'
    case ConnectionPortType.FALSE:
      return 'url(#marker-false)'
    default:
      return 'url(#marker-unconditional)'
  }
}

const handleDirections = {
  [Position.Left]: { x: -1, y: 0 },
  [Position.Right]: { x: 1, y: 0 },
  [Position.Top]: { x: 0, y: -1 },
  [Position.Bottom]: { x: 0, y: 1 }
}

const getFlowEdgePath = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  offset = 13,
  shortfall = 2
}: {
  sourceX: number
  sourceY: number
  targetX: number
  targetY: number
  sourcePosition: Position
  targetPosition: Position
  offset?: number
  shortfall?: number
}) => {
  /*
   * on the ETLD canvas, each node has a set distance that a connecting
   * line should go before bending; this is its offset. we need to figure
   * out what direction the offset should be moving in, based on where the
   * source port is on the node
   */
  const sourceDir = handleDirections[sourcePosition]
  const targetDir = handleDirections[targetPosition]

  /* how many pixels away from the target destination the line should fall;
   * this is used to account for svg marker arrowheads needing to stop early
   * to be able to render a sharp point */
  const shortfallX = targetDir.x * shortfall
  const shortfallY = targetDir.y * shortfall

  /*
   * once we know the direction the offset is going, we can find the point
   * the offset finishes at using Maths. this lets us build an svg path
   * with these points later on
   */
  const sourceOffsetX = sourceX + sourceDir.x * offset
  const sourceOffsetY = sourceY + sourceDir.y * offset
  const targetOffsetX = targetX + targetDir.x * offset
  const targetOffsetY = targetY + targetDir.y * offset

  const modifiedTargetX = targetX + shortfallX
  const modifiedTargetY = targetY + shortfallY

  /* where labels should be rendered on this edge;
   * this will be halfway along the offset-to-offset connecting line */
  const labelOffsetX = Math.abs(targetX - sourceX) / 2
  const labelX =
    targetX < sourceX ? targetX + labelOffsetX : targetX - labelOffsetX

  const labelOffsetY = Math.abs(targetY - sourceY) / 2
  const labelY =
    targetY < sourceY ? targetY + labelOffsetY : targetY - labelOffsetY

  return [
    /*
     * this builds an SVG path representing the connecting line between nodes. on the ETLD canvas,
     * this line is made of the following points:
     *
     *                   sourceOffset
     *                       x,y
     * [sourceX,sourceY]------
     *                        \
     *                         \
     *                          \
     *                           ------[targetX,targetY]
     *                          x,y
     *                      targetOffset
     */
    `M${sourceX},${sourceY} L${sourceOffsetX},${sourceOffsetY} L${targetOffsetX},${targetOffsetY} L${modifiedTargetX},${modifiedTargetY}`,
    labelX,
    labelY
  ] as const
}

export const FlowEdge: FunctionComponent<EdgeProps> = memo(
  ({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    ...props
  }) => {
    const [path, labelX, labelY] = getFlowEdgePath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition
    })

    return (
      <BaseEdge
        {...props}
        path={path}
        labelX={labelX}
        labelY={labelY}
        markerEnd={getEdgeMarker(props.data?.sourceHandle)}
        style={getEdgeStyle(props.data?.sourceHandle, props.selected)}
      />
    )
  }
)

FlowEdge.displayName = 'FlowEdge'
