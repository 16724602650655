import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Input } from '@matillion/component-library'
import classnames from 'classnames'

import classes from './SearchBar.module.scss'

export interface SearchBarProps {
  value?: string
  error?: boolean
  onChange: (event: { target: { value: string } }) => void
  className?: string
  placeholder?: string
  disabled?: boolean
  onClear: () => void
}

const SearchBar: FC<SearchBarProps> = ({
  className,
  value,
  onChange,
  onClear,
  ...otherProps
}) => {
  const { t } = useTranslation()
  return (
    <Input
      {...otherProps}
      value={value}
      onChange={onChange}
      autoFocus
      className={classnames(classes.SearchBar__Input, className)}
      type="search"
      aria-label={t('sideBar.componentPanel.searchPlaceholder')}
      iconBefore={{ icon: <Icon.Search /> }}
      iconAfter={{
        icon: (
          <button
            aria-label={t('sideBar.componentPanel.clearButton')}
            type="button"
            className={classes.SearchBar__Toggle}
            onClick={() => {
              onClear()
            }}
          >
            <Icon.Clear />
          </button>
        ),
        clickable: true
      }}
    />
  )
}

export default SearchBar
