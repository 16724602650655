import {
  useEffect,
  useReducer,
  useState,
  type ChangeEvent,
  type Dispatch,
  type FocusEvent,
  type FormEvent
} from 'react'
import { useTranslation } from 'react-i18next'

import {
  AutoComplete,
  Button,
  Field,
  Textarea
} from '@matillion/component-library'
import classNames from 'classnames'

import { useListProjectVariables } from 'api/hooks/useListProjectVariables/useListProjectVariables'

import {
  JobVariableType,
  VariableScope,
  type JobVariable
} from 'job-lib/types/Variables'

import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'
import baseClasses from 'modules/ManageVariables/ManageVariables.module.scss'
import {
  Fields,
  ReducerActions,
  type FormState,
  type ProjectVariable,
  type ProjectVariableType
} from 'modules/ManageVariables/types'
import { getVariableName } from 'modules/ManageVariables/utils'

import { Footer } from '../Footer/Footer'
import { JobSpecificFields } from './components/JobSpecificFields'
import { ProjectSpecificFields } from './components/ProjectSpecificFields'
import classes from './Form.module.scss'
import { formReducer, getInitialState } from './formReducer/formReducer'

export const Form = ({
  selectedVariableScope,
  onSubmit,
  onCancel,
  submitting,
  variableToEdit,
  selectedVariableType = null,
  setSelectedVariableType
}: {
  selectedVariableScope: VariableScope

  onSubmit?: (state: FormState, isProjectVariable: boolean) => void
  onCancel: () => void
  submitting?: boolean
  variableToEdit?: JobVariable | ProjectVariable
  selectedVariableType?: JobVariableType | ProjectVariableType | null
  setSelectedVariableType?: Dispatch<
    JobVariableType | ProjectVariableType | null
  >
}) => {
  const editing = Boolean(variableToEdit)
  const { t } = useTranslation()
  const { job } = useWorkingCopy()
  const { data: projectVariables = [] } = useListProjectVariables()
  const [isValid, setIsValid] = useState<boolean | null>()
  const [isTouched, setIsTouched] = useState(false)
  useEffect(() => {
    setIsValid(
      selectedVariableType !== null && selectedVariableType !== undefined
    )
  }, [selectedVariableType])
  const [state, dispatch] = useReducer(
    formReducer,
    getInitialState(selectedVariableScope, selectedVariableType, variableToEdit)
  )

  const validateJobVariable = () => {
    const isJobValid =
      state.isFormValid && selectedVariableType && state.VISIBILITY.value

    if (!isJobValid) {
      dispatch({
        type: ReducerActions.VALIDATE_FORM,
        selectedVariableScope,
        selectedVariableType
      })
    }

    return Boolean(isJobValid)
  }

  const validateProjectVariable = () => {
    const isProjectValid = state.isFormValid

    if (!isProjectValid) {
      dispatch({
        type: ReducerActions.VALIDATE_FORM,
        selectedVariableScope,
        selectedVariableType
      })
    }

    return isProjectValid
  }

  const onSubmitHandler = (e: FormEvent<HTMLFormElement>) => {
    setIsTouched(true)
    e.preventDefault()
    const isProjectVariable =
      selectedVariableScope === VariableScope.PROJECT_VARIABLE

    const formValid = isProjectVariable
      ? validateProjectVariable()
      : validateJobVariable()

    if (formValid && onSubmit) {
      onSubmit(state, isProjectVariable)
    }
  }

  return (
    <form
      className={classNames(baseClasses.Stage, classes.Form)}
      onSubmit={onSubmitHandler}
    >
      <div className={classNames(classes.Form__Content)}>
        {editing && (
          <Field
            name={t('manageVariables.jobVariables.fields.type')}
            className={classNames(classes.Field, {
              [classes['Field--Type']]: !editing
            })}
            title={t('manageVariables.jobVariables.fields.type')}
            errorText={
              !isValid &&
              isTouched &&
              t('manageVariables.jobVariables.fields.type')
            }
            value={selectedVariableType}
          >
            <AutoComplete
              placeholder={t(
                'manageVariables.jobVariables.fields.dropdownPlaceholder'
              )}
              onChange={(value) => {
                setSelectedVariableType?.(
                  value.target.value?.id as JobVariableType
                )
              }}
              onBlur={() => {
                dispatch({
                  type: ReducerActions.VALIDATE_FORM,
                  selectedVariableScope,
                  selectedVariableType
                })
              }}
              availableItems={[
                {
                  id: JobVariableType.TEXT,
                  name: t(
                    'manageVariables.jobVariables.fields.variableType.text'
                  )
                },
                {
                  id: JobVariableType.NUMBER,
                  name: t(
                    'manageVariables.jobVariables.fields.variableType.number'
                  )
                }
              ]}
              value={selectedVariableType}
            />
          </Field>
        )}

        <Field
          name={Fields.NAME}
          className={classes.Field}
          title={t('manageVariables.jobVariables.fields.variableName')}
          value={state[Fields.NAME].value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dispatch({
              type: ReducerActions.UPDATE_NAME,
              value: e.target.value,
              originalName: getVariableName(variableToEdit),
              jobVariables: job?.variables,
              projectVariables,
              selectedVariableScope,
              selectedVariableType
            })
          }}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            dispatch({
              type: ReducerActions.UPDATE_NAME,
              value: e.target.value,
              originalName: getVariableName(variableToEdit),
              jobVariables: job?.variables,
              projectVariables,
              selectedVariableScope,
              selectedVariableType
            })
          }}
          errorText={
            state[Fields.NAME].isValid === false &&
            t(state[Fields.NAME].error, {
              field: t('manageVariables.jobVariables.fields.variableName')
            })
          }
        />
        <Field
          name={Fields.DESCRIPTION}
          className={classes.Field}
          title={t('manageVariables.jobVariables.fields.description')}
          value={state[Fields.DESCRIPTION].value}
        >
          <Textarea
            className={classes.Form__Description}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              dispatch({
                type: ReducerActions.UPDATE_FIELD,
                field: Fields.DESCRIPTION,
                value: e.target.value,
                selectedVariableScope,
                selectedVariableType
              })
            }}
          />
        </Field>

        {selectedVariableScope === VariableScope.JOB_VARIABLE && (
          <JobSpecificFields
            state={state}
            dispatch={dispatch}
            selectedVariableScope={selectedVariableScope}
            selectedVariableType={selectedVariableType}
            editing={editing}
          />
        )}
        {selectedVariableScope === VariableScope.PROJECT_VARIABLE && (
          <ProjectSpecificFields
            state={state}
            dispatch={dispatch}
            selectedVariableScope={selectedVariableScope}
            selectedVariableType={selectedVariableType}
            editing={editing}
          />
        )}
      </div>
      <Footer
        warningText={
          selectedVariableScope === VariableScope.PROJECT_VARIABLE
            ? t(getFooterWarningKey(editing))
            : undefined
        }
      >
        <Button
          disabled={Boolean(submitting)}
          alt="secondary"
          onClick={onCancel}
          text={t('common.cancel')}
        />
        <Button
          disabled={Boolean(submitting)}
          data-testid={editing ? 'edit-job-variable' : 'add-variable-to-job'}
          type="submit"
          text={
            editing
              ? t('manageVariables.edit.update')
              : t('manageVariables.create.create')
          }
        />
      </Footer>
    </form>
  )
}
function getFooterWarningKey(editing: boolean): string {
  return `manageVariables.${editing ? 'edit' : 'create'}.footerWarning`
}
