// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wcXD_mnpaedIRZC4uhLz{min-width:768px}.LEjUIM_D41uTWOPORMrS textarea{resize:vertical}.fetxMbF5Za_gAf9mg6GJ{margin-top:38px;margin-bottom:20px}.A2iHXNouZqPcw43vVjtm{display:flex;justify-content:space-between;margin-bottom:-8px;padding-top:32px}.ghDf2SKuoohk14gcGLjk{margin-bottom:8px}`, "",{"version":3,"sources":["webpack://./src/modules/Merge/components/MergeModal/MergeModal.module.scss"],"names":[],"mappings":"AAEA,sBACE,eAAA,CAIA,+BACE,eAAA,CAIJ,sBACE,eAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,gBAAA,CAGF,sBACE,iBAAA","sourcesContent":["@import '@matillion/component-library/dist/assets/styles/colours';\n\n.modal {\n  min-width: 768px;\n}\n\n.commitMessage {\n  textarea {\n    resize: vertical;\n  }\n}\n\n.publishContainer {\n  margin-top: 38px;\n  margin-bottom: 20px;\n}\n\n.footer {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: -8px;\n  padding-top: 32px;\n}\n\n.conflictsLabel {\n  margin-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `wcXD_mnpaedIRZC4uhLz`,
	"commitMessage": `LEjUIM_D41uTWOPORMrS`,
	"publishContainer": `fetxMbF5Za_gAf9mg6GJ`,
	"footer": `A2iHXNouZqPcw43vVjtm`,
	"conflictsLabel": `ghDf2SKuoohk14gcGLjk`
};
export default ___CSS_LOADER_EXPORT___;
