// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.npexqZBwJRwxHCZwSNKB{width:100%}.npexqZBwJRwxHCZwSNKB input,.npexqZBwJRwxHCZwSNKB input:hover,.npexqZBwJRwxHCZwSNKB input:focus{padding-left:0;border:0 solid rgba(0,0,0,0) !important;box-shadow:none}.npexqZBwJRwxHCZwSNKB ul{z-index:1}`, "",{"version":3,"sources":["webpack://./src/components/ConflictVersionSelector/ConflictVersionSelector.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAEA,gGAGE,cAAA,CACA,uCAAA,CACA,eAAA,CAGF,yBACE,SAAA","sourcesContent":[".Selector {\n  width: 100%;\n\n  input,\n  input:hover,\n  input:focus {\n    padding-left: 0;\n    border: 0 solid transparent !important;\n    box-shadow: none;\n  }\n\n  ul {\n    z-index: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Selector": `npexqZBwJRwxHCZwSNKB`
};
export default ___CSS_LOADER_EXPORT___;
