import { useRef, type PropsWithChildren } from 'react'

import { type JobSummary } from 'api/hooks/useGetJobSummaries'

import PipelineItemPortal from './PipelineItemPortal'

export interface PipelineItemDragSourceProps {
  path?: string
  pipeline: JobSummary
  onDragEnd?: () => void
  onDragStart?: () => void
}

const PipelineItemDragSource = ({
  path,
  pipeline,
  children,
  onDragEnd,
  onDragStart
}: PropsWithChildren<PipelineItemDragSourceProps>) => {
  const draggableElement = useRef<HTMLDivElement>(null)

  return (
    <>
      <PipelineItemPortal pipeline={pipeline} dragRef={draggableElement} />

      <div
        draggable
        tabIndex={-1}
        data-testid={`draggable-pipeline-${pipeline.jobId}`}
        onDragEnd={onDragEnd}
        onDragStart={(event) => {
          onDragStart?.()
          event.dataTransfer.setData('pipelineName', pipeline.name)
          event.dataTransfer.setData('pipelineType', pipeline.type)
          event.dataTransfer.setData('existingPipelineId', pipeline.jobId)
          event.dataTransfer.setData('sourceFolderPath', path ?? '')

          if (draggableElement.current) {
            event.dataTransfer.setDragImage(draggableElement.current, 40, 0)
          }
        }}
      >
        {children}
      </div>
    </>
  )
}

export default PipelineItemDragSource
