import { type CaseReducer, type PayloadAction } from '@reduxjs/toolkit'

import { type JobState } from '../../job.types'

export const deleteJobVariable: CaseReducer<JobState, PayloadAction<string>> = (
  state,
  { payload: variableName }
) => {
  const { jobType, job } = state

  if (jobType !== null && job !== null) {
    delete job.variables?.[variableName]
  }

  return state
}
