import { rest, type RequestHandler } from 'msw'

import config from 'config'

import { mockResponse } from './mockResponse'

const endpoint = '/v1/job-executions'

export const useRunJobHandlers: RequestHandler[] = [
  rest.post(`${config.getApiBaseUrl()}${endpoint}`, async (_req, res, ctx) =>
    res(ctx.json(mockResponse))
  )
]

export const useRunJobErrorHandlers: RequestHandler[] = [
  rest.post(`${config.getApiBaseUrl()}${endpoint}`, async (_req, res, ctx) =>
    res(ctx.status(500))
  )
]
