import { Tooltip, Typography } from '@matillion/component-library'

import { ContentWithShortcut } from 'components/ContentWithShortcut/ContentWithShortcut'

import classes from './ToolboxButton.module.scss'

interface ToolboxButtonProps {
  showExpanded: boolean
  button: JSX.Element
  buttonIcon: JSX.Element
  text: string
  onClick: () => void
  disabled?: boolean
  label: string
  shortcut?: string
  dataTestId: string
}

export const ToolboxButton = ({
  button,
  buttonIcon,
  text,
  onClick,
  showExpanded,
  disabled = false,
  label,
  shortcut,
  dataTestId
}: ToolboxButtonProps) => {
  return showExpanded ? (
    <Tooltip
      className={classes.Tooltip}
      content={
        shortcut ? (
          <ContentWithShortcut
            content={label}
            shortcut={shortcut}
            format="mc"
          />
        ) : (
          label
        )
      }
    >
      <button
        data-testid={dataTestId}
        disabled={disabled}
        type="button"
        onClick={onClick}
        className={classes.ButtonWithText}
      >
        <Typography>{text}</Typography>
        <div className={classes.ButtonIconWrapper}>{buttonIcon}</div>
      </button>
    </Tooltip>
  ) : (
    button
  )
}
