import { type JobSummary } from 'api/hooks/useGetJobSummaries'

import { JobType } from 'job-lib/types/JobType'

import {
  initialPipelineTreeReducerState,
  PipelineTreeReducerType,
  type PipelineNode,
  type PipelineNodeType,
  type PipelineTree,
  type PipelineTreeAction,
  type PipelineTreeState
} from './types'

/**
 * Parses a single pipeline summary instance and determines
 * its path from the root with the extension stripped and
 * whether the pipeline is in the root of the project.
 * @param summary The pipeline summary instance
 */
const parsePipelinePath = (summary: JobSummary) => {
  const fullyQualifiedPath = summary.jobId
  const segments = fullyQualifiedPath.split('/')
  const lastIndex = segments.length - 1
  const folders = segments.slice(0, lastIndex)
  const name = segments.slice(lastIndex)[0]
  const nameSegments = name.split('.')
  const pipelineName = nameSegments[0]
  const extension = nameSegments.slice(1).join('.')
  const isRoot = folders.length === 0
  const path = isRoot ? pipelineName : `${folders.join('/')}/${pipelineName}`

  return {
    extension,
    isRoot,
    path
  }
}

/**
 * Generates a basic tree data structure of pipelines from an
 * array of pipeline summaries. Fully qualified folder paths are
 * parsed into a single nested tree.
 *
 * @param pipelines A summary of pipeline information.
 * @return A consolidated tree structure of pipelines in their folders
 */
export const buildPipelineTree = (pipelines: JobSummary[]): PipelineTree => {
  return pipelines.reduce((tree: PipelineTree, summary: JobSummary) => {
    const { path, extension, isRoot } = parsePipelinePath(summary)

    // The segments of the folder path for the current pipeline summary that
    // is being parsed. I.e. "a/b/c" would be "[a, b, c]".
    const segments = path.split('/')

    // The workingTree represents the current subtree that is
    // being worked on for the current array of path segments.
    // The main tree accumulator is updated by reference through this variable.
    let workingTree = tree

    // Holds the current path that we're traversing down
    // This is used for folders, so we know their unique paths from the root
    let currentPath: string[] = []

    segments.forEach((name: string, i: number) => {
      // Check if the current segment iteration is a tree leaf (A.K.A pipeline)
      const isLeaf = i === segments.length - 1
      const nodeType: PipelineNodeType = isLeaf ? 'pipeline' : 'folder'

      // Check if the node already exists in the workingTree
      let workingNode = workingTree.find((evaluationNode) => {
        const hasSameName = evaluationNode.name === name

        if (nodeType === 'pipeline') {
          // If the path segment is deemed to be a pipeline, then
          // we need to ensure the pipeline type (orch, tran) is the same too
          const hasSameType = evaluationNode.pipeline?.type === summary.type
          return hasSameName && hasSameType
        }

        // If the path segment is deemed to be folder, then
        // we only care about evaluating nodes that are also folders
        const isEvaluatingFolder = evaluationNode.type === 'folder'
        return isEvaluatingFolder && hasSameName
      })

      // Update the current path as we traverse the next path segment
      currentPath.push(name)
      const folderPath = currentPath.join('/')

      // If the current path segment doesn't exist in the tree, we're
      // parsing a new node, so the workingNode is assigned to a new one
      if (!workingNode) {
        // The new working node has no children currently as we don't yet
        // know if there are further path segments to be parsed
        workingNode = {
          name,
          path: folderPath,
          root: isRoot,
          children: [],
          type: nodeType
        }

        // If the node type is a pipeline (because it's a leaf) then
        // we can assign the pipeline summary object to it for future rendering
        if (nodeType === 'pipeline') {
          workingNode.pipeline = summary
          workingNode.path = `${folderPath}.${extension}`
        }

        // If the node type is folder and there the index is 0
        // then the folder must be in the root of the tree
        if (nodeType === 'folder' && i === 0) {
          workingNode.root = true
        }

        // Push the node into the workingTree instance, so it can
        // be found in the next segment iteration
        workingTree.push(workingNode)
      }

      // Assign the working tree to the children of the workingNode
      // so that the next segment is working off of that subtree
      workingTree = workingNode.children
    })

    // After exhausting the current paths' segments, reset for the next
    currentPath = []

    return sort(tree)
  }, [])
}

/**
 * Calculates the number of ancestors directly above the node
 * at the given path when following the shortest path to the root.
 * Used to calculate how deeply nested a folder is in the tree.
 * @param tree The instance of the pipeline tree to introspect
 * @param path The path to the node from the root
 * @return the number of nodes deep into the tree
 */
export const getAncestorCount = (tree: PipelineTree, path: string) => {
  const next = [...tree]
  const segments = path.length > 0 ? path.split('/').reverse() : []
  let depth = 1

  while (segments.length > 0) {
    const segment = segments.pop()
    const found = next.find((node) => node.name === segment)

    if (!found) {
      return null
    }

    if (segments.length === 0) {
      return depth
    }

    depth += 1
    next.push(...found.children)
  }

  return depth
}

/**
 * Compares two {@link PipelineNode} instances for sorting.
 * -1 indicates node "a" is "lesser" and should be ordered first.
 *  0 indicates that both nodes are considered equal.
 *  1 indicates that node "a" is "greater" and should be ordered second.
 *
 * @param a The first node to compare.
 * @param b The second node to compare.
 * @return an integer representing the order precedence for sorting.
 */
const nodeComparator = (a: PipelineNode, b: PipelineNode): number => {
  if (a.type === 'folder' && b.type === 'pipeline') {
    return -1
  }

  if (a.type === 'pipeline' && b.type === 'folder') {
    return 1
  }

  return a.name.localeCompare(b.name, undefined, { numeric: true })
}

/**
 * Sorts an array of pipeline nodes in-place.
 * Folders will always take precedence and come before a pipeline.
 * Within groups of folders or pipelines, they are sorted naturally
 * as case-sensitive strings.
 *
 * Nodes are sorted recursively so that all nested children
 * are also sorted.
 *
 * @param nodes An array of nodes to sort.
 */
export const sort = (nodes: PipelineNode[]): PipelineTree => {
  const tree = [...nodes]

  tree.sort(nodeComparator)
  tree.forEach((node) => {
    node.children = sort(node.children)
  })

  return tree
}

/**
 * Checks if adding a folder at the given path would overflow
 * the maximum nested folder depth of 10. That it is to say, if
 * the ancestor count of the new folder would be 11, then we're
 * already at the nested limit of 10.
 * @param tree The tree instance to introspect.
 * @param path The path of the proposed new folder.
 * @return true if the limit has been reached, else false.
 */
const hasReachedNestedFolderLimit = (
  tree: PipelineTree,
  path: string
): boolean => {
  const ancestorCount = getAncestorCount(tree, path)
  return (ancestorCount ?? 0) >= 10
}

/**
 * Checks if a folder already exists at the given
 * path and with the given name.
 * @param tree The instance of the tree to check
 * @param name The name of the new folder
 * @param path The path to check in
 * @return true if folder exists, else false
 */
export const folderExists = (
  tree: PipelineTree,
  name: string,
  path?: string
): boolean => {
  let fullyQualifiedPath = name
  if (path) fullyQualifiedPath = `${path}/${name}`
  const existing = findFolderNode(tree, fullyQualifiedPath)
  return Boolean(existing)
}

/**
 * Finds a folder node in the tree with the given path.
 * Returns null if a node cannot be found.
 * @param tree The instance of the tree to search.
 * @param path The absolute folder path from the root delimited by slashes
 * @return The instance of the node that matches, else null
 */
export const findFolderNode = (
  tree: PipelineTree,
  path: string
): PipelineNode | null => {
  let next = [...tree]
  const segments = path.length > 0 ? path.split('/').reverse() : []

  while (segments.length > 0) {
    const segment = segments.pop()
    const found = next.find((node) => {
      return node.name === segment && node.type === 'folder'
    })

    if (!found) {
      return null
    }

    if (segments.length === 0) {
      return found
    }

    next = found.children
  }

  return null
}

/**
 * Removes a pipeline node from the tree with the given path, name and type.
 *
 * @param tree The instance of the tree to remove from.
 * @param path The folder path of the node to remove.
 * @param name The name of the node to remove.
 * @param type The type of the pipeline to remove.
 * @return The folder node that was removed. Undefined if not found.
 */
const removePipelineNode = (
  tree: PipelineTree,
  path: string,
  name: string,
  type: JobType
): PipelineNode | undefined => {
  const findPipelineIndex = (nodes: PipelineNode[]) => {
    return nodes.findIndex((node: PipelineNode) => {
      return node.name === name && node.pipeline?.type === type
    })
  }

  if (path === '') {
    const pipelineIndex = findPipelineIndex(tree)

    if (pipelineIndex !== -1) {
      return tree.splice(pipelineIndex, 1)[0]
    }
  } else {
    const sourceDirectoryNode = findFolderNode(tree, path)

    const pipelineIndex = findPipelineIndex(sourceDirectoryNode?.children ?? [])

    if (pipelineIndex !== undefined && pipelineIndex !== -1) {
      return sourceDirectoryNode?.children.splice(pipelineIndex, 1)[0]
    }
  }

  return undefined
}

/**
 * Removes a folder node from the tree with the given path, name and type.
 *
 * @param tree The instance of the tree to remove from.
 * @param path The folder path of the node to remove.
 */
export const removeFolderNode = (
  tree: PipelineTree,
  path: string
): PipelineNode | undefined => {
  const findFolderIndex = (nodes: PipelineNode[], folderName: string) => {
    return nodes.findIndex((node: PipelineNode) => {
      return node.name === folderName && node.type === 'folder'
    })
  }

  const segments = path.split('/')
  const targetFolderName = segments[segments.length - 1]

  if (segments.length === 1) {
    const pipelineIndex = findFolderIndex(tree, targetFolderName)

    if (pipelineIndex !== -1) {
      return tree.splice(pipelineIndex, 1)[0]
    }
  } else {
    const parentFolderPath = segments.splice(0, segments.length - 1).join('/')
    const parentDirectoryNode = findFolderNode(tree, parentFolderPath)
    const pipelineIndex = findFolderIndex(
      parentDirectoryNode?.children ?? [],
      targetFolderName
    )

    if (pipelineIndex !== undefined && pipelineIndex !== -1) {
      return parentDirectoryNode?.children.splice(pipelineIndex, 1)[0]
    }
  }

  return undefined
}

/**
 * Checks if a given node is an empty folder.
 * @param node The node instance to check
 * @return true if a folder and empty, else false
 */
const isNodeEmptyFolder = (node: PipelineNode): boolean => {
  return node.type === 'folder' && node.children.length === 0
}

/**
 * Collects an array of child nodes that reside under the given node instance.
 * This operation is recursive and so includes every node from the sub-tree under it.
 *
 * @param node The node to collect from.
 * @return An array of collected nodes.
 */
const collectChildNodes = (node: PipelineNode): PipelineNode[] => {
  const result = [...node.children]

  node.children.forEach((child) => {
    result.push(...collectChildNodes(child))
  })

  return result
}

const addFolderNode = (tree: PipelineTree, name: string, path: string) => {
  const newPath = `${path}/${name}`
  const parent = findFolderNode(tree, path)

  parent?.children.push({
    name,
    root: false,
    children: [],
    type: 'folder',
    path: newPath
  })
}

const addRootFolderNode = (tree: PipelineTree, name: string) => {
  tree.push({
    name,
    path: name,
    root: true,
    children: [],
    type: 'folder'
  })
}

/**
 * Builds a string key used to identify empty folder paths in
 * the browsers local storage. They're keyed on the current branch
 * and project to ensure tenancy isolation across projects. User ID
 * is omitted as its unlikely multiple users will be creating empty
 * folders in the same browser instance.
 *
 * @param branch The name of the current Git branch.
 * @param project The name of the current active project.
 * @return A key used to store empty folders.
 */
const buildEmptyFoldersKey = (branch: string, project: string) => {
  return `empty-pipeline-folders-${project}-${branch}`
}

/**
 * Adds a new folder path into local storage.
 * Empty folders aren't tracked by the working tree store, and
 * so we persist them in the local storage of the browser.
 *
 * @param path The folder path to store. E.g. examples/matillion
 * @param branch The name of the current Git branch.
 * @param project The name of the current active project.
 */
export const addNewEmptyFolderToLocalStorage = (
  path: string,
  branch: string,
  project: string
) => {
  const storageKey = buildEmptyFoldersKey(branch, project)
  const currentValue = localStorage.getItem(storageKey)
  const defaultValue = JSON.stringify([])
  const emptyFolderPaths = JSON.parse(currentValue ?? defaultValue) as string[]

  if (!emptyFolderPaths.includes(path)) {
    emptyFolderPaths.push(path)
    localStorage.setItem(storageKey, JSON.stringify(emptyFolderPaths))
  }
}

/**
 * Removes the path of an empty folder from local storage.
 * @param path The path to be removed.
 * @param branch The name of the current Git branch.
 * @param project The name of the current active project.
 * @param filterPredicate An optional predicate for filtering empty folder paths
 */
export const removeEmptyFolderFromLocalStorage = (
  path: string,
  branch: string,
  project: string,
  filterPredicate?: (path: string) => boolean
) => {
  const storageKey = buildEmptyFoldersKey(branch, project)
  const currentValue = localStorage.getItem(storageKey)

  if (currentValue) {
    const emptyFolderPaths = JSON.parse(currentValue) as string[]
    const defaultFilter = (value: string) => value !== path
    const newValue = emptyFolderPaths.filter(filterPredicate ?? defaultFilter)

    if (newValue.length === 0) {
      localStorage.removeItem(storageKey)
    } else {
      localStorage.setItem(storageKey, JSON.stringify(newValue))
    }
  }
}

/**
 * Retrieves an array of empty folder paths tracked
 * in local storage.
 *
 * @param branch The name of the current Git branch.
 * @param project The name of the current active project.
 * @return An array of folder paths
 */
const getEmptyFolderPaths = (branch: string, project: string): string[] => {
  const storageKey = buildEmptyFoldersKey(branch, project)
  const value = localStorage.getItem(storageKey)
  return value ? (JSON.parse(value) as string[]) : []
}

/**
 * Adds empty folders tracked in the local storage to the given pipeline tree
 * instance. Folders that appear in the tree already are pruned from the local
 * storage value to prevent duplication.
 *
 * @param tree The tree instance to mutate.
 * @param branch The name of the current Git branch.
 * @param project The name of the current active project.
 * @return the updated pipeline tree instance.
 */
const addEmptyFoldersToTree = (
  tree: PipelineTree,
  branch: string,
  project: string
): PipelineTree => {
  // Because the WTS does not track empty folders server-side, if a
  // folder node exists in the tree that was built up from the WTS
  // pipeline summaries, then it MUST be populated with something.
  // Therefore, we remove it from the local storage, so we're in sync.
  getEmptyFolderPaths(branch, project).forEach((path) => {
    if (findFolderNode(tree, path) !== null) {
      removeEmptyFolderFromLocalStorage(path, branch, project)
    }
  })

  // Once the empty folders in the local storage have been pruned, we
  // can add them into the pipeline tree.
  getEmptyFolderPaths(branch, project).forEach((emptyFolder) => {
    const segments = emptyFolder.split('/')

    // Tracks the current directory as we iterate over the path
    // segments of the empty folder.
    let workingDirectory = ''

    for (let i = 0; i < segments.length; i++) {
      const folderName = segments[i]
      const isFirstSegment = i === 0
      const absolutePath = `${workingDirectory}/${folderName}`
      const folderPath = isFirstSegment ? folderName : absolutePath
      const folderNode = findFolderNode(tree, folderPath)

      // Since empty folder paths are stored as absolute paths from the root,
      // when iterating over nested folders (i.e. "test" and then "test/nested")
      // then the same folder paths will be encountered and should be skipped to
      // prevent duplicate empty folder from being added to the tree.
      if (folderNode) {
        workingDirectory += isFirstSegment ? folderName : `/${folderName}`
        continue
      }

      if (isFirstSegment) {
        addRootFolderNode(tree, folderName)
        workingDirectory = folderName
      } else {
        addFolderNode(tree, folderName, workingDirectory)
        workingDirectory += `/${folderName}`
      }
    }
  })

  return tree
}

const handleAddFolder = (
  state: PipelineTreeState,
  name?: string,
  path?: string,
  branch?: string,
  project?: string
): PipelineTreeState => {
  const tree = [...state.pipelineTree]

  if (name && path && branch && project) {
    const newPath = `${path}/${name}`

    if (folderExists(tree, name, path)) {
      return {
        ...state,
        pipelineTree: tree
      }
    }

    addFolderNode(tree, name, path)

    addNewEmptyFolderToLocalStorage(newPath, branch, project)
  }

  return {
    ...state,
    pipelineTree: sort(tree)
  }
}

const handleAddRootFolder = (
  state: PipelineTreeState,
  name?: string,
  branch?: string,
  project?: string
): PipelineTreeState => {
  const tree = [...state.pipelineTree]

  if (name && branch && project) {
    addRootFolderNode(tree, name)
    addNewEmptyFolderToLocalStorage(name, branch, project)
  }

  return {
    ...state,
    pipelineTree: sort(tree)
  }
}

const handleRemoveFolder = (
  state: PipelineTreeState,
  path?: string,
  branch?: string,
  project?: string
): PipelineTreeState => {
  const tree = [...state.pipelineTree]

  if (!path || !branch || !project) {
    return state
  }

  removeFolderNode(tree, path)

  const segments = path.split('/')

  // If there is only 1 segment, it means the given folder path
  // was in the root, and so the parent is the pseudo project root folder.
  // Since we don't track this as an empty folder, we skip these scenarios.
  if (segments.length > 1) {
    const parentPath = segments.splice(0, segments.length - 1).join('/')
    const parentFolderNode = findFolderNode(tree, parentPath)
    if (parentFolderNode?.children.length === 0) {
      addNewEmptyFolderToLocalStorage(parentPath, branch, project)
    }
  }

  return {
    ...state,
    pipelineTree: sort(tree)
  }
}

const handleRemovePipeline = (
  state: PipelineTreeState,
  path?: string,
  name?: string,
  type?: JobType,
  branch?: string,
  project?: string
): PipelineTreeState => {
  const tree = [...state.pipelineTree]

  if (path === undefined || !name || !type || !branch || !project) {
    return state
  }

  const pipelineNode = removePipelineNode(tree, path, name, type)
  const folderPath = pipelineNode?.pipeline?.folder

  if (folderPath) {
    const folderNode = findFolderNode(tree, folderPath)
    if (folderNode?.children.length === 0) {
      addNewEmptyFolderToLocalStorage(folderPath, branch, project)
    }
  }

  return {
    ...state,
    pipelineTree: sort(tree)
  }
}

const handleMovePipeline = (
  state: PipelineTreeState,
  path?: string,
  name?: string,
  type?: JobType,
  branch?: string,
  project?: string,
  targetDirectory?: string
): PipelineTreeState => {
  const tree = state.pipelineTree

  if (path === undefined || !name || !type || !branch || !project) {
    return { ...state }
  }

  // Since the "root folder" is not actually tracked in the tree state,
  // when the target directory is falsy, we know that's because the pipeline
  // is being moved out of a folder and into the root.
  if (!targetDirectory) {
    // Check if the source directory will be empty after the move to root.
    // Since the state isn't actually mutated here, a single child means the
    // source directory will be empty after the move has happened server-side.
    const sourceFolderNode = findFolderNode(tree, path)
    if (sourceFolderNode?.children.length === 1) {
      addNewEmptyFolderToLocalStorage(path, branch, project)
    }

    return { ...state }
  }

  const pipelineNode = removePipelineNode(tree, path, name, type)

  if (pipelineNode?.pipeline) {
    // Re-calculate the new path after moving
    let targetPath = `${targetDirectory}/${pipelineNode.name}`
    const isOrch = pipelineNode.pipeline.type === JobType.Orchestration
    const extension = isOrch ? 'orch' : 'tran'
    targetPath += `.${extension}.yaml`
    pipelineNode.path = targetPath

    // Re-calculate the 'root' status
    pipelineNode.root = targetPath === ''

    // Push the updated node into the target directory in the tree
    const targetDirectoryNode = findFolderNode(tree, targetDirectory)
    targetDirectoryNode?.children.push(pipelineNode)

    // Check if the target directory was previously empty before the move
    const emptyFolders = getEmptyFolderPaths(branch, project)
    if (emptyFolders.includes(targetDirectory)) {
      removeEmptyFolderFromLocalStorage(targetDirectory, branch, project)
    }

    // Check if the source directory is now empty after the move
    const pipelineFolderNode = findFolderNode(tree, path)
    if (pipelineFolderNode?.children.length === 0) {
      addNewEmptyFolderToLocalStorage(path, branch, project)
    }
  }

  return {
    ...state,
    pipelineTree: sort(tree)
  }
}

const handleOverwriteTree = (
  state: PipelineTreeState,
  summaries?: JobSummary[],
  branch?: string,
  project?: string
): PipelineTreeState => {
  if (summaries && branch && project) {
    const pipelineTree = buildPipelineTree(summaries)
    const treeWithEmptyFolders = addEmptyFoldersToTree(
      pipelineTree,
      branch,
      project
    )

    return {
      ...state,
      pipelineTree: sort(treeWithEmptyFolders)
    }
  }

  return initialPipelineTreeReducerState
}

const handleCreateFolder = (
  state: PipelineTreeState,
  path?: string
): PipelineTreeState => {
  const tree = [...state.pipelineTree]

  if (path && hasReachedNestedFolderLimit(tree, path)) {
    return {
      ...state,
      hasReachedFolderLimit: true
    }
  }

  return {
    ...state,
    isCreatingFolder: true,
    folderPath: path ?? null
  }
}

export const handleDeleteFolder = (
  state: PipelineTreeState,
  path?: string,
  branch?: string,
  project?: string
): PipelineTreeState => {
  if (path && branch && project) {
    const tree = [...state.pipelineTree]
    const folderNode = findFolderNode(tree, path)

    if (folderNode) {
      // If the folder is empty, just remove it from the tree client-side.
      // We don't set isDeletingFolder because there's nothing to warn the
      // user about or update server-side in the WTS.
      const childNodes = collectChildNodes(folderNode)
      const isSubTreeEmpty = childNodes.every(isNodeEmptyFolder)
      const isFolderNodeEmpty = folderNode.children.every(
        (child) => child.type === 'folder'
      )
      if (isSubTreeEmpty && isFolderNodeEmpty) {
        removeFolderNode(tree, path)
        removeEmptyFolderFromLocalStorage(
          path,
          branch,
          project,
          (emptyFolderPath: string) => {
            return !emptyFolderPath.includes(path)
          }
        )

        return {
          ...state,
          pipelineTree: sort(tree)
        }
      }

      // If the folder node is not empty, then collect an array of all
      // pipeline IDs to be deleted and prepare the state for the modal.
      const children = collectChildNodes(folderNode)
      const pipelineNodes = children.filter((node) => node.type === 'pipeline')
      const pipelineIds = pipelineNodes
        .map((node) => node.pipeline?.jobId)
        .filter((id): id is string => !!id)

      return {
        ...state,
        folderPath: path,
        isDeletingFolder: true,
        deletedPipelines: pipelineIds
      }
    }
  }

  return state
}

const handleSetFolderPath = (
  state: PipelineTreeState,
  path?: string
): PipelineTreeState => {
  return {
    ...state,
    folderPath: path ?? null
  }
}

const handleReset = (state: PipelineTreeState): PipelineTreeState => {
  return {
    ...initialPipelineTreeReducerState,
    pipelineTree: state.pipelineTree
  }
}

const pipelineTreeReducer = (
  state: PipelineTreeState,
  {
    type,
    name,
    path,
    branch,
    project,
    summaries,
    targetDirectory,
    pipelineType
  }: PipelineTreeAction
): PipelineTreeState => {
  switch (type) {
    case PipelineTreeReducerType.ADD_FOLDER: {
      return handleAddFolder(state, name, path, branch, project)
    }
    case PipelineTreeReducerType.ADD_ROOT_FOLDER: {
      return handleAddRootFolder(state, name, branch, project)
    }
    case PipelineTreeReducerType.REMOVE_FOLDER: {
      return handleRemoveFolder(state, path, branch, project)
    }
    case PipelineTreeReducerType.REMOVE_PIPELINE: {
      return handleRemovePipeline(
        state,
        path,
        name,
        pipelineType,
        branch,
        project
      )
    }
    case PipelineTreeReducerType.MOVE_PIPELINE: {
      return handleMovePipeline(
        state,
        path,
        name,
        pipelineType,
        branch,
        project,
        targetDirectory
      )
    }
    case PipelineTreeReducerType.OVERWRITE_TREE: {
      return handleOverwriteTree(state, summaries, branch, project)
    }
    case PipelineTreeReducerType.CREATE_FOLDER: {
      return handleCreateFolder(state, path)
    }
    case PipelineTreeReducerType.DELETE_FOLDER: {
      return handleDeleteFolder(state, path, branch, project)
    }
    case PipelineTreeReducerType.SET_FOLDER_PATH: {
      return handleSetFolderPath(state, path)
    }
    case PipelineTreeReducerType.RESET: {
      return handleReset(state)
    }
    default: {
      return state
    }
  }
}

export default pipelineTreeReducer
