import { keyBy } from 'lodash'
import { type Parameters } from 'types/Pipeline'

import {
  VisibleWhenOperator,
  type ComponentMetadata,
  type ComponentParameter,
  type VisibleWhen
} from 'api/hooks/useGetComponentMetadata/types'

import { isDPLParameterCollection } from 'job-lib/store/jobSlice/utils/isDPLParameterCollection'
import { setParameterDPLValue } from 'job-lib/store/jobSlice/utils/setParameterDPLValue'
import {
  type ParameterCollection,
  type ParameterSlot
} from 'job-lib/types/Parameters'

import {
  getDPLParameters,
  getParameterDPLValue
} from 'modules/ComponentParameters/utils/getParameterValue'

export type ValueLookupHandler = (param: string) => string

export interface ParameterVisibility {
  visibleParameters: string[]
  hiddenParameters: string[]
}

function isParameterVisible(
  visibleWhen: VisibleWhen[],
  valueLookupHandler: ValueLookupHandler
): boolean {
  return visibleWhen.every(({ param, value, operator }) => {
    if (operator === VisibleWhenOperator.NEVER) {
      return false
    }

    if (operator === VisibleWhenOperator.DYNAMIC_VISIBILITY_LOOKUP) {
      // This will default to true until we properly implement this
      return true
    }

    if (!param) {
      return true
    }

    const paramValue = valueLookupHandler(param)

    switch (operator) {
      case VisibleWhenOperator.EQUALS:
        return paramValue === value
      case VisibleWhenOperator.NOT_EQUALS:
        return paramValue !== value
      case VisibleWhenOperator.IN:
        return value.includes(paramValue)
      case VisibleWhenOperator.NOT_IN:
        return !value.includes(paramValue)
      case VisibleWhenOperator.HAS_VALUE:
        return Boolean(paramValue)
      case VisibleWhenOperator.MATCHES_REGEX_PATTERN:
        return new RegExp(value).test(paramValue)

      default:
        return true
    }
  })
}

function getParameterMetadata(
  parameterPath: string[],
  metadata: ComponentMetadata
) {
  return parameterPath.reduce(
    (
      currentParameter: ComponentParameter[],
      parameterId
    ): ComponentParameter[] => {
      const parameterList = currentParameter.find(
        (param) => param.dplID === parameterId
      )

      if (parameterList?.childProperties) {
        return parameterList.childProperties
      }
      return currentParameter
    },
    metadata?.parameters
  )
}

/*
 * flattens metadata into a single key/value store
 * key is the path to the parameter
 * value is the component parameter
 * e.g. {
 *  "param": ComponentParameter,
 *  "param.child": ComponentParameter,
 *  "param.child.grandchild": ComponentParameter
 * }
 */
function getKeyedMetadata(
  metadata: ComponentMetadata
): Record<string, ComponentParameter> {
  const metadataKeyedById: Record<string, ComponentParameter> = {}

  metadata.parameters.forEach((componentParam) => {
    const dplId = componentParam.dplID

    if (componentParam.childProperties) {
      processChildProperties(
        componentParam.childProperties,
        metadataKeyedById,
        dplId
      )
    }

    metadataKeyedById[dplId] = componentParam
  })
  return metadataKeyedById
}

function processChildProperties(
  childProperties: ComponentParameter[],
  metadataKeyedById: Record<string, ComponentParameter>,
  parentDplId: string
) {
  childProperties.forEach((childParam) => {
    const childDplId = `${parentDplId}.${childParam.dplID}`

    if (childParam.childProperties) {
      processChildProperties(
        childParam.childProperties,
        metadataKeyedById,
        childDplId
      )
    }

    metadataKeyedById[childDplId] = childParam
  })
}

function checkVisibility(
  parameter: ComponentParameter,
  metadata: ComponentMetadata,
  parameterPath: string[],
  dplParams: unknown,
  visibleParameters: string[],
  hiddenParameters: string[]
) {
  const { visibleWhen, dplID, childProperties } = parameter
  const newParameterPath = [...parameterPath, dplID]

  const valueLookupHandler: ValueLookupHandler = (param) => {
    const pathToParam = getPathToParameter(parameterPath, param)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return getParameterDPLValue(pathToParam, dplParams as any) as string
  }

  if (visibleWhen) {
    const visible = isParameterVisible(visibleWhen, valueLookupHandler)
    if (visible) {
      visibleParameters.push(newParameterPath.join('.'))
    } else {
      hiddenParameters.push(newParameterPath.join('.'))
      return
    }
  } else {
    visibleParameters.push(newParameterPath.join('.'))
  }

  childProperties?.forEach((childParameter) => {
    checkVisibility(
      childParameter,
      metadata,
      newParameterPath,
      dplParams,
      visibleParameters,
      hiddenParameters
    )
  })
}

function findParameter(
  path: string[],
  parameters: ComponentParameter[]
): ComponentParameter | null {
  const [firstPart, ...remainingPath] = path
  const parameter = parameters.find((p) => p.dplID === firstPart)

  if (!parameter) return null

  if (remainingPath.length === 0) return parameter

  if (parameter.childProperties) {
    return findParameter(remainingPath, parameter.childProperties)
  }

  return null
}

function getPathToParameter(parameterPath: string[], param: string) {
  if (param.includes('.')) {
    return param.split('.')
  }

  return [...parameterPath, param]
}

/**
 *
 * @param slot the metlSlot when dealing with classic METL or the dplID when dealing with dplParams
 * @param parameters the METL parameter collection
 * @param metadata CIS component metadata
 * @param parameterPath the path to the parameter that visibility is being checked for
 * @returns boolean
 */
export const isMetlParameterVisible = (
  slot: ParameterSlot | string,
  parameters: ParameterCollection,
  metadata?: ComponentMetadata,
  parameterPath: string[] = []
) => {
  if (!metadata) {
    return true
  }

  const valueLookupHandler: ValueLookupHandler = (param) => {
    const metadataKeyedById: Record<string, ComponentParameter> =
      getKeyedMetadata(metadata)

    const depMetadata = metadataKeyedById[param]
    const pathToParam = getPathToParameter(parameterPath, param)

    if (isDPLParameterCollection(parameters)) {
      const dpl = getDPLParameters(null, parameters)

      return getParameterDPLValue(pathToParam, dpl) as string
    }

    return parameters?.[depMetadata.metlSlot]?.elements[1]?.values[1]?.value
  }

  const parameterMetadata = getParameterMetadata(parameterPath, metadata)
  const parameterKey = typeof slot === 'string' ? 'dplID' : 'metlSlot'
  const metadataKeyedBySlot = keyBy(parameterMetadata, parameterKey)
  const visibleWhen = metadataKeyedBySlot[slot]?.visibleWhen

  if (!visibleWhen || visibleWhen.length === 0) {
    return true
  }

  return isParameterVisible(visibleWhen, valueLookupHandler)
}

/**
 * Checks if a parameter is visible based on all visibleWhen parameter's default values
 * @param metadata CIS component metadata
 * @param visibleWhen a list of visibleWhen rules
 * @param parameterPath the path to the parameter that visibility is being checked for
 * @returns boolean
 */
export const isParameterDefaultVisible = (
  metadata: ComponentMetadata,
  visibleWhen: VisibleWhen[] | null | undefined,
  parameterPath: string[]
) => {
  if (!metadata || !visibleWhen || visibleWhen.length === 0) {
    return true
  }

  const valueLookupHandler: ValueLookupHandler = (param) => {
    const metadataKeyedById: Record<string, ComponentParameter> =
      getKeyedMetadata(metadata)
    const pathToParam = getPathToParameter(parameterPath, param)
    const parameter = metadataKeyedById[pathToParam.join('.')]

    return getParameterDefaultValue(parameter) as string
  }

  return isParameterVisible(visibleWhen, valueLookupHandler)
}

/**
 * Returns a list of visible and hidden parameters based on the provided component metadata
 * @param metadata CIS component metadata
 * @param parameters DPL parameter collection
 * @returns a list of both visibleParameters and hiddenParameter paths
 */
export const getParameterVisibility = (
  metadata: ComponentMetadata,
  parameters: Parameters
): ParameterVisibility => {
  const visibleParameters: string[] = []
  const hiddenParameters: string[] = []

  metadata.parameters.forEach((parameter) => {
    checkVisibility(
      parameter,
      metadata,
      [],
      parameters,
      visibleParameters,
      hiddenParameters
    )
  })

  return { visibleParameters, hiddenParameters }
}

/**
 * Returns a list of visible and hidden parameters based on the provided component metadata
 * @param metadata CIS component metadata
 * @param parameters The METL component parameter collection
 * @returns a list of both visibleParameters and hiddenParameters
 */
export const getMetlParameterVisibility = (
  metadata: ComponentMetadata,
  parameters: ParameterCollection
): ParameterVisibility => {
  const dplParams = getDPLParameters(null, parameters)

  return getParameterVisibility(metadata, dplParams)
}

/**
 * Takes a list of parameter paths and removes them from the provided DPL parameter collection
 * @param hiddenParameters A list of parameter paths that should be removed from the DPL
 * @param parameters DPL parameter collection
 * @returns Modified DPL Parameters
 */
export const removeHiddenParameters = (
  hiddenParameters: string[],
  parameters: Parameters
): Parameters => {
  const hiddenParametersArr = hiddenParameters.map((x) => x.split('.'))

  hiddenParametersArr.forEach((path) => {
    let currentParameter = parameters
    for (let i = 0; i < path.length - 1; i++) {
      if (currentParameter) {
        currentParameter = currentParameter[path[i]] as Parameters
      }
    }

    if (currentParameter) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete currentParameter[path[path.length - 1]]
    }
  })

  return parameters
}

/**
 * Takes a list of hidden parameters and returns a DPL collection with those parameters excluded from it
 * @param hiddenParameters
 * @param parameters
 * @returns
 */
export const removeMetlHiddenParameters = (
  hiddenParameters: string[],
  parameters: ParameterCollection
): Parameters => {
  const dplParams = getDPLParameters(null, parameters)

  return removeHiddenParameters(hiddenParameters, dplParams)
}

/**
 * Sets default values for the provided parameter paths
 * @param parameterPaths A list of parameter paths to set default values against
 * @param metadata CIS component metadata
 * @param parameterCollection the METL parameter collection used to get dplParams
 * @returns the updated DPL parameters with default values
 */
export const setMetlParameterDefaultValues = (
  parameterPaths: string[],
  metadata: ComponentMetadata,
  parameterCollection: ParameterCollection
): Parameters => {
  const dplParams = getDPLParameters(null, parameterCollection)

  return setParameterDefaultValues(parameterPaths, metadata, dplParams)
}

/**
 * Sets default values for the provided parameter paths
 * @param parameterPaths A list of parameter paths to set default values against
 * @param metadata CIS component metadata
 * @param parameters DPL parameter collection
 * @returns the updated DPL parameters with default values
 */
export function setParameterDefaultValues(
  parameterPaths: string[],
  metadata: ComponentMetadata,
  parameters: Parameters
) {
  parameterPaths.forEach((path) => {
    const splitPath = path.split('.')
    const param = findParameter(splitPath, metadata.parameters)

    if (!param) {
      return
    }

    const defaultValue = getParameterDefaultValue(param)

    setParameterDPLValue(parameters, splitPath, defaultValue)
  })

  return parameters
}

export const getParameterDefaultValue = (
  parameter: ComponentParameter
): string | null => {
  if (parameter.defaultValue) {
    return parameter.defaultValue
  }

  if (parameter.staticOptions) {
    return parameter.staticOptions?.[0].defaultValue ?? null
  }

  return null
}
