import { stubLegacyDescribeMerge } from 'api/hooks/useLegacyDescribeMerge/useLegacyDescribeMerge.handlers'
import { stubLegacyMerge } from 'api/hooks/useLegacyMerge/useLegacyMerge.handlers'
import { stubGetBranch } from 'api/hooks/useGetBranch/useGetBranch.handlers'
import { type GitMswHandlerConfig } from './types'
import { PROJECT_ID } from '__test-utils__/ids'
import { type RequestHandler } from 'msw'
import { stubDescribeMerge } from 'api/hooks/useDescribeMerge/useDescribeMerge.handlers'
import { stubMerge } from 'api/hooks/useMerge/useMerge.handlers'
import { stubHardResetBranch } from 'api/hooks/useDeleteBranch/useDeleteBranch.handlers'
import { stubInitOAuth } from 'api/hooks/useInitOAuth/useInitOAuth.handlers'
import { stubCaptureOAuthToken } from 'api/hooks/useCaptureOAuthToken/useCaptureOAuthToken.handlers'
import { stubGetRepositories } from 'api/hooks/useGetRepositories/useGetRepositories.handlers'

const gitComponentLibraryMswHandlers = (
  config?: Partial<GitMswHandlerConfig>
): RequestHandler[] => {
  const projectId = config?.project ?? PROJECT_ID

  const designerEndpoints = [
    stubLegacyDescribeMerge,
    stubGetBranch,
    stubLegacyMerge
  ].flatMap((func) => {
    return func(config?.designerBaseUrl ?? '', projectId)
  })

  const workingTreeEndpoints = [
    stubDescribeMerge,
    stubMerge,
    stubHardResetBranch
  ].flatMap((func) => {
    return func(config?.workingTreeBaseUrl ?? '', projectId)
  })

  const gitServiceEndpoints = [
    stubInitOAuth,
    stubCaptureOAuthToken,
    stubGetRepositories
  ].flatMap((func) => {
    return func(config?.gitServiceBaseUrl ?? '')
  })

  return [...designerEndpoints, ...workingTreeEndpoints, ...gitServiceEndpoints]
}

export default gitComponentLibraryMswHandlers
