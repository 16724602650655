import classes from './PushChangesModal.module.scss'
import { Modal } from '@matillion/component-library'
import { type PushChangesModalProps } from './types'
import PushChangesForm from './PushChangesForm'

const PushChangesModal = (props: PushChangesModalProps) => {
  return (
    <Modal
      size="mid"
      id="push-modal"
      disableBackdropClick
      onCancel={props.onClose}
      className={classes.modal}
      ariaLabelledBy="push-title"
    >
      <PushChangesForm {...props} />
    </Modal>
  )
}

export default PushChangesModal
