import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'

import { Loading } from 'components/Loading/Loading'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useTaskStatus } from 'modules/observability/PipelineRunExplorer/hooks/useTaskStatus'

import classes from './PipelineRunExplorer.module.scss'
import PipelineTree from './PipelineTree/PipelineTree'

const PipelineRunExplorer = () => {
  const { t } = useTranslation()
  const { flowInstanceId } = useProjectInfo()
  const { tasks, completed } = useTaskStatus()
  const { isLoading } = completed

  const taskStatus = useMemo(
    () => tasks.find((e) => e.id.toString() === flowInstanceId),
    [flowInstanceId, tasks]
  )

  if (isLoading) {
    return (
      <div className={classes.LoadingContainer}>
        <Loading data-testid="pipeline-loading-container" />
      </div>
    )
  }

  if (!taskStatus) {
    return (
      <div className={classes.Error} data-testid="pipeline-error-container">
        <Icon.InfoNoBg className={classes.Error__Icon} />
        <Typography className={classes.Error__Text}>
          {t('translation:taskExplorer.networkError')}
        </Typography>
      </div>
    )
  }

  return <PipelineTree pipelineRunId={taskStatus.flowInstanceId} />
}

export default PipelineRunExplorer
