import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type Failure } from 'api/hooks/useValidateComponent/types'

import { isDPLParameterCollection } from 'job-lib/store/jobSlice/utils/isDPLParameterCollection'
import {
  type ComponentInstanceId,
  type OrchestrationJob,
  type Parameters,
  type TransformationJob
} from 'job-lib/types/Job'
import { type ElementCollection } from 'job-lib/types/Parameters'

import { getParameterValue } from 'modules/ComponentParameters/utils/getParameterValue'
import { convertParameterValueToMetl } from 'modules/core/WorkingCopyProvider/effects/useMetlPipeline/convertParametersToMetl'

import { isMetlParameterVisible } from 'utils/isParameterVisible'

import { useClientParameterValidation } from './useClientParameterValidation'

interface UseClientSideValidationProps {
  job: OrchestrationJob | TransformationJob | null
}
export interface GetInvalidParametersProps {
  componentSummaryId: ComponentSummaryId
  componentInstanceId: ComponentInstanceId
  componentMetadata: ComponentMetadata
}

export type GetInvalidParameters = (
  opts: GetInvalidParametersProps
) => Failure[]

export const useClientSideValidation = ({
  job
}: UseClientSideValidationProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'parameterEditor.errors'
  })
  const validateParameter = useClientParameterValidation()

  const getInvalidParameters = useCallback<GetInvalidParameters>(
    ({
      componentSummaryId,
      componentInstanceId,
      componentMetadata
    }): Failure[] => {
      const invalidParameters: Failure[] = []

      function processParameter(
        param: ComponentParameter,
        path: string[] = []
      ) {
        if (!job) return

        const componentParameters =
          job.components[componentInstanceId]?.parameters
        const isDPLParameters = isDPLParameterCollection(componentParameters)

        const jobParam = getJobParam(
          isDPLParameters,
          componentParameters,
          param
        )

        const currentPath = getCurrentPath(isDPLParameters, param)
        const fullPath = [...path, currentPath.toString()]

        if (
          !jobParam ||
          !isMetlParameterVisible(
            currentPath,
            job?.components[componentInstanceId].parameters,
            componentMetadata,
            path
          )
        ) {
          return
        }
        const parameterElements = isDPLParameters
          ? convertParameterValueToMetl(
              param,
              getParameterValue(componentParameters, fullPath)
            )
          : jobParam.elements

        const value = getFirstValue(parameterElements)

        const error = validateParameter({
          parameterId: param.dplID,
          componentSummaryId,
          value
        })

        if (error && param.dataType !== 'STRUCT') {
          invalidParameters.push({
            message: error,
            path: ['parameters', ...fullPath],
            isClientSideFailure: true
          })
        }

        if (shouldShowRequiredFieldError(value, param)) {
          invalidParameters.push({
            message: t('requiredFieldErrorMessage'),
            path: ['parameters', ...fullPath],
            isClientSideFailure: true
          })
        }

        if (param.childProperties) {
          param.childProperties.forEach((childParam) => {
            processParameter(childParam, fullPath)
          })
        }
      }

      componentMetadata.parameters.forEach((param) => {
        processParameter(param)
      })

      return invalidParameters
    },
    [job, t, validateParameter]
  )

  return {
    getInvalidParameters
  }
}
function shouldShowRequiredFieldError(
  value: string,
  param: ComponentParameter
) {
  return !value && !param.optional && param.dataType !== 'STRUCT'
}

function getFirstValue(parameterElements: ElementCollection) {
  return Object.values(parameterElements).map((el) => el.values[1]?.value)[0]
}

function getCurrentPath(isDPLParameters: boolean, param: ComponentParameter) {
  return isDPLParameters ? param.dplID : param.metlSlot
}

function getJobParam(
  isDPLParameters: boolean,
  componentParameters: Parameters,
  param: ComponentParameter
) {
  return isDPLParameters
    ? componentParameters?.[2]
    : componentParameters?.[param.metlSlot]
}
