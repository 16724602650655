import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from '@matillion/component-library'
import { ServiceKey, useServiceUrl } from '@matillion/hub-client'

import { ReactComponent as ErrorConnection } from 'assets/ErrorConnection.svg'

import classes from './ErrorBoundryFallback.module.scss'

const ErrorBoundaryFallback: FC = () => {
  const { t } = useTranslation()
  const projectExplorerUrl = useServiceUrl(ServiceKey.projectExplorerFrontend)

  return (
    <div className={classes.ErrorBoundaryFallback}>
      <div className={classes.ErrorBoundaryFallback__Container}>
        <ErrorConnection />
        <Typography
          as="h2"
          format="tl"
          className={classes.ErrorBoundaryFallback__Title}
        >
          {t('errorboundary.title')}
        </Typography>
        <Typography className={classes.ErrorBoundaryFallback__Description}>
          {t('errorboundary.description')}
        </Typography>

        <a
          href={projectExplorerUrl}
          className={classes.ErrorBoundaryFallback__Link}
        >
          <Button className={classes.ErrorBoundaryFallback__LinkButton}>
            <Typography weight="bold">
              {t('errorboundary.escapeLinkText')}
            </Typography>
          </Button>
        </a>
      </div>
    </div>
  )
}

export default ErrorBoundaryFallback
