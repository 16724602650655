// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BxbAMt3B0JWvn4USqB6Q{margin-right:8px}.XKXz69IR6EMxiCHNpamx{display:flex;align-items:center;max-width:100%;overflow:hidden}.a9kP8u3JbvuXRSn48jYA{flex:1;min-width:0;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;user-select:none}`, "",{"version":3,"sources":["webpack://./src/components/JobInformation/JobInformation.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,eAAA,CAGF,sBACE,MAAA,CACA,WAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CACA,gBAAA","sourcesContent":[".Icon {\n  margin-right: 8px;\n}\n\n.wrapper {\n  display: flex;\n  align-items: center;\n  max-width: 100%;\n  overflow: hidden;\n}\n\n.textContainer {\n  flex: 1;\n  min-width: 0;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Icon": `BxbAMt3B0JWvn4USqB6Q`,
	"wrapper": `XKXz69IR6EMxiCHNpamx`,
	"textContainer": `a9kP8u3JbvuXRSn48jYA`
};
export default ___CSS_LOADER_EXPORT___;
