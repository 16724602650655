import { useMemo } from 'react'
import { type Edge, type Node } from 'reactflow'

import {
  type ComponentSummary,
  type ComponentSummaryId
} from 'api/hooks/useGetComponentSummaries'
import { useGetComponentSummary } from 'api/hooks/useGetComponentSummaries/useGetComponentSummary'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'

import { getComponentLabel } from 'job-lib/job-functions/getComponentLabel'
import { getJobConnectors } from 'job-lib/job-functions/job-functions'
import { type Port } from 'job-lib/types/Components'
import {
  type ComponentInstanceId,
  type ConnectorId,
  type OrchestrationJob,
  type TransformationJob
} from 'job-lib/types/Job'
import { type ParameterCollection } from 'job-lib/types/Parameters'

import {
  getComponentPorts,
  getComponentReactFlowId,
  getNoteReactFlowId,
  isComponentNode,
  isNodeSupported,
  parseConnectorName
} from './utils'

export type Job = OrchestrationJob | TransformationJob | null

export enum EtlCanvasNodeType {
  NODE = 'node',
  ITERATOR = 'iterator',
  NOTE = 'note'
}

export type EtlCanvasNodeId = `component-${number}` | `note-${number}`

export type NoteNode = Node<NoteNodeData>
export type ComponentNode = Node<ComponentNodeData>
export type EtlCanvasNode = NoteNode | ComponentNode

export interface ComponentNodeData {
  imageUrl: string
  label: string
  inputPorts: Port[]
  outputPorts: Port[]
  iteratorPorts: Port[]
  hasInputConnection?: boolean
  hasOutputConnection?: boolean
  attachedNode?: ComponentNode
  summaryId: ComponentSummaryId
}

export interface NoteNodeData {
  content: string
  width: number
  height: number
  theme: string
  isAIGenerated?: boolean
  selection?: string[]
}

export type EtlCanvasEdge = Edge<EtlCanvasEdgeData>

export interface EtlCanvasEdgeData {
  sourceHandle: string
}

export interface EtlCanvasModel {
  nodes: Map<EtlCanvasNodeId, EtlCanvasNode>
  edges: Map<ConnectorId, EtlCanvasEdge>
}

export type NodeType = 'note' | 'component'
export interface NodeInfo {
  type: NodeType
  id: number
}

const START_NODE_ID = 'start'

export const useCanvasModel = (
  pipeline: TransformationJob | OrchestrationJob
): EtlCanvasModel => {
  const { getIcon } = useComponentInfo()
  const { getByImplementationId } = useGetComponentSummary()

  return useMemo(() => {
    /* maps are used to preserve the order of keys in the returned collections */
    const nodes = new Map<EtlCanvasNodeId, EtlCanvasNode>()
    const edges = new Map<ConnectorId, EtlCanvasEdge>()
    const model = { nodes, edges }

    addNotesToModel(model, pipeline)
    addComponentsToModel(model, pipeline, getByImplementationId, getIcon)

    return model
  }, [pipeline, getByImplementationId, getIcon])
}

function addComponentsToModel(
  { nodes, edges }: EtlCanvasModel,
  pipeline: TransformationJob | OrchestrationJob,
  getByImplementationId: (
    implementationID: string,
    parameters: ParameterCollection
  ) => ComponentSummary | undefined,
  getIcon: (componentId: string, parameters?: ParameterCollection) => string
): void {
  const startNodes: ComponentInstanceId[] = []

  Object.entries(pipeline.components).forEach(([, component]) => {
    if (!isNodeSupported(component)) {
      console.warn('useCanvasModel: component is not supported', component)
      return
    }

    const componentSummary = getByImplementationId(
      component.implementationID.toString(),
      component.parameters
    ) as ComponentSummary

    const id = componentSummary.componentId
    const imageUrl = getIcon(id, component.parameters)
    const label = getComponentLabel(component)

    if (id === START_NODE_ID) {
      startNodes.push(component.id)
    }

    const { inputPorts, outputPorts, iteratorPorts } =
      getComponentPorts(componentSummary)

    const componentId: EtlCanvasNodeId = getComponentReactFlowId(component.id)

    nodes.set(componentId, {
      id: componentId,
      type:
        iteratorPorts.length > 0
          ? EtlCanvasNodeType.ITERATOR
          : EtlCanvasNodeType.NODE,
      position: {
        x: component.x,
        y: component.y
      },
      data: {
        imageUrl,
        label,
        inputPorts,
        outputPorts,
        iteratorPorts,
        summaryId: id
      }
    })
  })

  /*
   * if there is only component node on the canvas, we want
   * it to be selected by default
   */
  if (nodes.size === 1) {
    const onlyNode = nodes.values().next().value
    onlyNode.selected = true
  }

  /*
   * the last start component in the pipeline should not be able to be deleted,
   * so we mark it as such to prevent the canvas library from processing
   * delete events on it
   */
  if (startNodes.length === 1) {
    const reactFlowId = getComponentReactFlowId(startNodes[0])
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const lastStartNode = nodes.get(reactFlowId)!

    lastStartNode.deletable = false
  }

  const { iterationConnectors = {}, ...connectors } = getJobConnectors(pipeline)

  /* iteration connectors are not rendered--instead, the iterator and the connected node are rendered as a group */
  Object.entries(iterationConnectors).forEach(([_, { sourceID, targetID }]) => {
    const nodeSourceId: EtlCanvasNodeId = getComponentReactFlowId(sourceID)
    const nodeTargetId: EtlCanvasNodeId = getComponentReactFlowId(targetID)
    const attachedNode = nodes.get(nodeTargetId)
    const parentNode = nodes.get(nodeSourceId)

    if (!isComponentNode(attachedNode) || !isComponentNode(parentNode)) {
      console.warn(
        'useCanvasModel: iteration connector is attached to non-existent node',
        sourceID,
        targetID
      )
      return
    }

    attachedNode.hidden = true
    parentNode.data = {
      ...parentNode.data,
      attachedNode
    }
  })

  Object.entries(connectors).forEach(([connectorType, connectorCollection]) => {
    Object.entries(connectorCollection).forEach(([, connector]) => {
      const nodeSourceId: EtlCanvasNodeId = getComponentReactFlowId(
        connector.sourceID
      )
      const nodeTargetId: EtlCanvasNodeId = getComponentReactFlowId(
        connector.targetID
      )

      const source = nodes.get(nodeSourceId)
      const target = nodes.get(nodeTargetId)

      if (!isComponentNode(source) || !isComponentNode(target)) {
        console.warn(
          'useCanvasModel: connector is attached to non-existent node',
          connector
        )
        return
      }

      source.data.hasOutputConnection = true
      target.data.hasInputConnection = true

      edges.set(connector.id, {
        id: connector.id.toString(),
        source: nodeSourceId,
        target: nodeTargetId,
        data: {
          sourceHandle: parseConnectorName(connectorType)
        }
      })
    })
  })
}

function addNotesToModel(
  { nodes }: EtlCanvasModel,
  pipeline: TransformationJob | OrchestrationJob
) {
  if (!pipeline.notes) {
    return
  }

  Object.entries(pipeline.notes).forEach(([key, note]) => {
    const noteId = getNoteReactFlowId(Number.parseInt(key))

    nodes.set(noteId, {
      id: noteId,
      type: EtlCanvasNodeType.NOTE,
      position: {
        x: note.position.x,
        y: note.position.y
      },
      data: {
        content: note.content,
        width: note.size.width,
        height: note.size.height,
        theme: note.theme,
        isAIGenerated: note.isAIGenerated,
        selection: note.selection
      }
    })
  })
}
