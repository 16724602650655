import { rest } from 'msw'

import config from 'config'

export const useGeneratePipelineDocsHandler = [
  rest.post(
    `${config.getApiBaseUrl()}/v1/sidekick/generate-pipeline-docs`,
    async (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          description: 'I need your clothes, your boots, and your motorcycle.'
        })
      )
    }
  )
]
