import { type ComponentMetadataParameterId } from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'

// These consts are used to trigger specific behaviour in the UI
// e.g. they are used in to warn the user that you can't use the same job name
// Hardcoded values from CIS, we want to create a RunOrchestration component or RunTransformation component when a job is dragged onto the canvas

/** the Run Transformation component id */
export const runTransformationComponentId: ComponentSummaryId =
  'run-transformation'

/** the transformation job name parameter dplID */
export const transformationJobNameParameter: ComponentMetadataParameterId =
  'transformationJob'

/** the Run Orchestration component */
export const runOrchestrationComponentId: ComponentSummaryId =
  'run-orchestration'

/** the orchestration job name parameter dplID */
export const orchestrationJobNameParameter: ComponentMetadataParameterId =
  'orchestrationJob'

/** the component name parameter dplID */
export const componentNameParameter: ComponentMetadataParameterId =
  'componentName'

/*
 * inputId, profile and secretReferenceId are modular connector specific parameters that need to be populated in the DPL
 * but do not need to be visible to the user in the UI
 */
export const inputIdParameter: ComponentMetadataParameterId = 'inputId'
export const profileParameter: ComponentMetadataParameterId = 'profile'
export const secretReferenceIdParameter: ComponentMetadataParameterId =
  'secretReferenceId'

export const queryParamsParameter: ComponentMetadataParameterId = 'queryParams'
export const headerParamsParameter: ComponentMetadataParameterId =
  'headerParams'
export const uriParamsParameter: ComponentMetadataParameterId = 'uriParams'
