import type {
  GridValue,
  GridValueFromVariable,
  ListValue,
  ParameterValue,
  ScalarValue,
  StructList,
  StructValue
} from 'types/Pipeline'

/* A value is a ScalarValue if it is a string */
export const isScalarParameterValue = (
  value: ParameterValue
): value is ScalarValue => typeof value === 'string'

/* A value is a GridValueFromVariable if it's an object with a fromGrid key */
export const isGridValueFromVariableParameterValue = (
  value: ParameterValue
): value is GridValueFromVariable =>
  value !== null &&
  typeof value === 'object' &&
  Object.hasOwn(value, 'fromGrid')

/* A value is a StructList if it's an array with every slot containing an object */
export const isStructListParameterValue = (
  value: ParameterValue
): value is StructList => {
  if (typeof value !== 'object' || !Array.isArray(value)) {
    return false
  }

  for (const item of value) {
    if (typeof item !== 'object' || Array.isArray(item)) {
      return false
    }
  }

  return true
}

/* A value is a StructValue if it is an object with arbitrary keys */
export const isStructValueParameterValue = (
  value: ParameterValue
): value is StructValue =>
  value !== null && typeof value === 'object' && !Array.isArray(value)

/* A value is a GridValue if it's a multi-dimensional array */
export const isGridValueParameterValue = (
  value: ParameterValue
): value is GridValue => {
  if (typeof value !== 'object' || !Array.isArray(value)) {
    return false
  }

  for (const item of value) {
    if (!Array.isArray(item)) {
      return false
    }
  }

  return true
}

/* A value is a ListValue if it's a one-dimensional array of scalar values */
export const isListValueParameterValue = (
  value: ParameterValue
): value is ListValue => {
  if (typeof value !== 'object' || !Array.isArray(value)) {
    return false
  }

  for (const item of value) {
    if (typeof item !== 'string') {
      return false
    }
  }

  return true
}
