import { useMutation } from '@tanstack/react-query'

import queryClient from 'api/queryClient'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useRecentlyAccessedPipelines } from 'modules/hub/PipelineAccessHistory/hooks/useRecentlyAccessedPipelines/useRecentlyAccessedPipelines'

import {
  useGetJobSummariesQueryKey,
  type JobSummaryId
} from '../useGetJobSummaries'
import { useSpringClient } from '../useSpringClient/useSpringClient'
import { renameJobApi } from './renameJobApi'

export interface RenameJobRequest {
  jobId: JobSummaryId
  newJobId: JobSummaryId
}

export const useRenameJob = () => {
  const { projectId, branchId: branchName } = useProjectInfo()
  const client = useSpringClient()
  const jobSummariesQueryKey = useGetJobSummariesQueryKey()
  const { enableRecentlyAccessedPipelines } = useFlags()
  const { renameRecentlyAccessed } = useRecentlyAccessedPipelines()

  return useMutation(
    async ({ newJobId, jobId }: RenameJobRequest) => {
      return renameJobApi({
        client,
        projectId,
        branchName,
        jobId,
        newJobId
      })
    },
    {
      onSuccess: (_, { newJobId, jobId }) => {
        enableRecentlyAccessedPipelines &&
          renameRecentlyAccessed({
            previousPipelineId: jobId,
            newPipelineId: newJobId
          })
        queryClient.invalidateQueries(jobSummariesQueryKey)
      }
    }
  )
}
