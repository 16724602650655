import { useReducer, type FC, type PropsWithChildren } from 'react'

import CreateFolderModal from '../commands/CreateFolderModal/CreateFolderModal'
import CreatePipelineModal from '../commands/CreatePipelineModal/CreatePipelineModal'
import DeleteFolderModal from '../commands/DeleteFolderModal/DeleteFolderModal'
import { DeletePipelineModal } from '../commands/DeletePipelineModal/DeletePipelineModal'
import FolderLimitReachedModal from '../commands/FolderLimitReachedModal/FolderLimitReachedModal'
import { RenamePipelineModal } from '../commands/RenamePipelineModal/RenamePipelineModal'
import {
  commandReducer,
  initialCommandState
} from '../reducers/commandReducer/commandReducer'
import pipelineTreeReducer from '../reducers/pipelineTreeReducer/pipelineTreeReducer'
import {
  initialPipelineTreeReducerState,
  PipelineTreeReducerType
} from '../reducers/pipelineTreeReducer/types'
import { PipelineBrowserContext } from './context'
import { PipelineBrowserCommand } from './types'

export const PipelineBrowserCommandProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const [state, onCommand] = useReducer(commandReducer, initialCommandState)
  const [pipelineTreeState, onPipelineTreeCommand] = useReducer(
    pipelineTreeReducer,
    initialPipelineTreeReducerState
  )

  const {
    isCreatingPipeline,
    isDeletingPipeline,
    isRenamingPipeline,
    jobSummary,
    jobType
  } = state

  const {
    pipelineTree,
    hasReachedFolderLimit,
    isCreatingFolder,
    isDeletingFolder,
    deletedPipelines,
    folderPath
  } = pipelineTreeState

  const handleCloseModal = () => {
    onCommand({
      payload: null,
      type: PipelineBrowserCommand.RESET
    })
  }

  const resetPipelineFolderState = () => {
    onPipelineTreeCommand({
      type: PipelineTreeReducerType.RESET
    })
  }

  return (
    <PipelineBrowserContext.Provider
      value={{
        pipelineTree,
        onCommand,
        onPipelineTreeCommand
      }}
    >
      {children}

      {isCreatingFolder && folderPath !== null && !hasReachedFolderLimit && (
        <CreateFolderModal
          path={folderPath}
          onClose={resetPipelineFolderState}
        />
      )}

      {isCreatingPipeline && jobType && (
        <CreatePipelineModal
          type={jobType}
          folderPath={folderPath}
          onClose={handleCloseModal}
        />
      )}

      {isRenamingPipeline && jobSummary && (
        <RenamePipelineModal
          jobSummary={jobSummary}
          folderPath={folderPath}
          onClose={handleCloseModal}
        />
      )}

      {isDeletingPipeline && jobSummary && (
        <DeletePipelineModal
          onClose={handleCloseModal}
          jobSummary={jobSummary}
        />
      )}

      {isDeletingFolder && folderPath && deletedPipelines && (
        <DeleteFolderModal
          path={folderPath}
          pipelines={deletedPipelines}
          onClose={resetPipelineFolderState}
        />
      )}

      {hasReachedFolderLimit && (
        <FolderLimitReachedModal onDismiss={resetPipelineFolderState} />
      )}
    </PipelineBrowserContext.Provider>
  )
}
