import React, { FunctionComponent, useMemo } from 'react'

import { UserInfoContext, AuthStateContext } from '../context'

import useAuth0User from './effects/useAuth0User'
import useOrganisation from './effects/useOrganisation'

const UserInfoProvider: FunctionComponent<React.PropsWithChildren> = ({
  children
}) => {
  const [user, isLoadingUser] = useAuth0User()
  const [organisationInfo, isLoadingOrganisation] = useOrganisation()

  const authState = useMemo(
    () => ({
      isLoading: isLoadingUser || isLoadingOrganisation,
      isLoggedIn: !!(user && organisationInfo)
    }),
    [isLoadingUser, isLoadingOrganisation, user, organisationInfo]
  )

  const profile = useMemo(() => {
    if (!user || !organisationInfo) {
      return undefined
    }

    return {
      email: user.email,
      name:
        user.given_name && user.family_name
          ? `${user.given_name} ${user.family_name}`
          : user.name,
      icon: user.picture,
      organisation: organisationInfo.organisation.name
    }
  }, [user, organisationInfo])

  return (
    <AuthStateContext.Provider value={authState}>
      {authState.isLoggedIn ? (
        <UserInfoContext.Provider
          value={{
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            /* these values will be non-null when `authState.isLoggedIn` is set to true */
            user: user!,
            organisation: organisationInfo!.organisation,
            roles: organisationInfo!.roles,
            profile: profile!
            /* eslint-enable */
          }}
        >
          {children}
        </UserInfoContext.Provider>
      ) : (
        children
      )}
    </AuthStateContext.Provider>
  )
}

export default UserInfoProvider
