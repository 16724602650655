import { type JobVariable } from 'job-lib/types/Variables'

import { type ProjectVariable } from './types'

export function isJobVariable(
  variable: ProjectVariable | JobVariable
): variable is JobVariable {
  return Boolean((variable as JobVariable).definition)
}

export function getVariableName(variable?: ProjectVariable | JobVariable) {
  if (!variable) {
    return undefined
  }
  if (isJobVariable(variable)) {
    return variable.definition.name
  } else {
    return variable.name
  }
}

// positive or negative number including decimals
export const isNumeric = (value: string) => /^-?\d*(\.\d+)?$/.test(value)
