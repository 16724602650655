import axios, { AxiosRequestConfig } from 'axios'

const hubApi = axios.create({
  headers: { 'Content-Type': 'application/json' }
})

export type RegionCode = 'us1' | 'eu1' | string

interface CurrentOrganisationResponse {
  id: string
  subdomain: string
  name: string
  userRoles: string[]
  region?: RegionCode
}

export const getCurrentOrganisation = async (
  token: string,
  requestConfig?: AxiosRequestConfig
) =>
  hubApi.get<CurrentOrganisationResponse>('/v1/organisations/current', {
    ...requestConfig,
    headers: { authorization: `Bearer ${token}` }
  })

export default hubApi
