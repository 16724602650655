import { useMemo, useState, type PropsWithChildren } from 'react'

import {
  type UserPreference,
  type UserPreferenceProviderContextInterface
} from 'components/UserPreferenceProvider/types'

import config from 'config'

import {
  userPreferenceInitialValue,
  UserPreferenceProviderContext
} from './UserPreferenceProviderContext'

const storeKey = config.browserStoreKeys.userPreference

export const UserPreferenceProvider = ({ children }: PropsWithChildren) => {
  const [prefValue, setPrefValue] = useState<UserPreference>(() => {
    const storedValue = window.localStorage.getItem(storeKey)
    return storedValue ? JSON.parse(storedValue) : userPreferenceInitialValue
  })

  const setUserPreference = (v: UserPreference) => {
    window.localStorage.setItem(storeKey, JSON.stringify(v))
    setPrefValue(v)
  }

  const setSpecificUserPreference = (
    key: keyof UserPreference,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => {
    const v: UserPreference = {
      ...value,
      [key]: value
    }
    window.localStorage.setItem(storeKey, JSON.stringify(v))
    setPrefValue(v)
  }

  const values: UserPreferenceProviderContextInterface = useMemo(() => {
    return {
      userPreference: prefValue,
      setUserPreference,
      setSpecificUserPreference
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefValue])
  return (
    <UserPreferenceProviderContext.Provider value={values}>
      {children}
    </UserPreferenceProviderContext.Provider>
  )
}
