import { JobVariableType, type VariableScope } from 'job-lib/types/Variables'

import {
  ErrorStatuses,
  Fields,
  ProjectVariableType,
  type FormState,
  type ReducerActions
} from 'modules/ManageVariables/types'
import { isNumeric } from 'modules/ManageVariables/utils'

import { errorMessages } from '../errorMessages'
import { isFormValid } from './isFormValid'

export const updateDefaultValue = (
  state: FormState,
  action: {
    type: ReducerActions.UPDATE_DEFAULT_VALUE
    field: Fields.DEFAULT_VALUE
    value: string
  },
  selectedVariableScope: VariableScope,
  selectedVariableType: JobVariableType | ProjectVariableType | null
): FormState => {
  const { value } = action

  const isNumberType =
    selectedVariableType &&
    [ProjectVariableType.NUMBER, JobVariableType.NUMBER].includes(
      selectedVariableType
    )

  const isDefaultValueValid =
    !isNumberType || value === '' || (isNumberType && isNumeric(value))
  const isValid = isNumberType ? isDefaultValueValid : true

  const updatedState = {
    ...state,
    [Fields.DEFAULT_VALUE]: {
      value,
      isValid,
      error: isValid ? '' : errorMessages[ErrorStatuses.INCORRECT_NUMBER_FORMAT]
    }
  }

  return {
    ...updatedState,
    isFormValid: isFormValid(updatedState, selectedVariableScope)
  }
}
