import {
  GIT_OAUTH_REDIRECT,
  type InitOAuthActionProps,
  type InitOAuthActionResponse
} from './types'
import useInitOAuth from 'api/hooks/useInitOAuth'
import { useCallback, useEffect, useState } from 'react'
import { useUser } from '@matillion/hub-client'

const useInitOAuthAction = ({
  provider,
  onSuccess,
  onFailure
}: InitOAuthActionProps): InitOAuthActionResponse => {
  const { organisation } = useUser()
  const [oAuthURL, setOAuthURL] = useState<string>()
  const { mutateAsync: initOAuth, isLoading } = useInitOAuth({ provider })

  const doInitOAuth = useCallback(async () => {
    const subdomain = organisation.subdomain
    const originUrl = window.location.origin
    const originNoSubdomain = originUrl.replace(`${subdomain}.`, '')
    const redirectUrl = `${originNoSubdomain}/${GIT_OAUTH_REDIRECT}`

    try {
      const { authorizationUrl } = await initOAuth({
        originCallbackUrl: redirectUrl,
        stateSuffix: subdomain
      })

      onSuccess?.()
      return authorizationUrl
    } catch (e) {
      onFailure?.()
    }
  }, [initOAuth, onFailure, onSuccess, organisation.subdomain])

  useEffect(() => {
    doInitOAuth().then((authorisationURL) => {
      if (authorisationURL) {
        setOAuthURL(authorisationURL)
      }
    })
  }, [doInitOAuth])

  return {
    loading: isLoading,
    authorizationURL: oAuthURL
  }
}

export default useInitOAuthAction
