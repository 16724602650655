import { AgentHostEnum } from 'api/hooks/useAgentDetails/useAgentDetails'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

import { type ConnectorComponentSummaryResult } from '../types'

export const mockConnectorComponentInfo: ConnectorComponentSummaryResult = {
  results: [
    {
      schemaVersion: 1,
      componentId: 'modular-api-extract-input-v1',
      connectorId: 'flex-snapchat',
      classification: 'MODULAR',
      displayName: 'Snapchat',
      icon: 'https://connector-logos.matillion.com/Snapchat.svg',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '720344340',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        }
      ],
      dwhTypes: ['SNOWFLAKE', 'REDSHIFT', 'DATABRICKS'],
      cloudPlatforms: ['AWS', 'AZURE'],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion]
    },
    {
      schemaVersion: 1,
      componentId: 'modular-api-extract-input-v1',
      classification: 'MODULAR',
      connectorId: 'custom-1ea7c3cd-e32f-4672-be57-e8b33eb84c42',
      displayName: 'Star Wars API',
      icon: 'https://connector-logos.matillion.com/CustomConnector.svg',
      presentationResourceVersion: 1,
      componentType: JobType.Orchestration,
      emeraldId: '720344340',
      inputPorts: [
        {
          portId: ConnectionPortType.INPUT,
          cardinality: Cardinality.ONE
        }
      ],
      outputPorts: [
        {
          portId: ConnectionPortType.SUCCESS,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.UNCONDITIONAL,
          cardinality: Cardinality.MANY
        },
        {
          portId: ConnectionPortType.FAILURE,
          cardinality: Cardinality.MANY
        }
      ],
      dwhTypes: ['SNOWFLAKE', 'REDSHIFT', 'DATABRICKS'],
      cloudPlatforms: ['AWS', 'AZURE'],
      agentHosts: [AgentHostEnum.Customer, AgentHostEnum.Matillion]
    }
  ]
}
