import React, { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Tab, Tabs } from '@matillion/component-library'
import classnames from 'classnames'

import { ComponentEditorTabType } from 'api/hooks/useGetComponentMetadata/types'

import ExternalLink from 'components/ExternalLink/ExternalLink'
import { Loading } from 'components/Loading/Loading'
import { EmptyPanel } from 'components/Panels/EmptyPanel'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'
import { useComponentInstanceMetadataQuery } from 'hooks/useComponentInstanceMetadataQuery/useComponentInstanceMetadataQuery'
import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { getComponentLabel } from 'job-lib/job-functions/getComponentLabel'

import { ComponentParametersContainer } from 'modules/ComponentParameters/ComponentParametersContainer'

import { ComponentOverview } from './components/ComponentOverview'
import { SampleComponent } from './components/SampleComponent'
import { SampleProvider } from './components/SampleComponent/SampleProvider'
import { SQLViewer } from './components/SQLViewer'
import { getDocumentationLink } from './getDocumentationLink'
import classes from './PropertiesPanel.module.scss'

const ComponentPropertiesPanel: FC = () => {
  const { enableWizardUiForComponents } = useFlags()
  const { t } = useTranslation()

  const { componentId: componentInstanceId } = useProjectInfo()
  const {
    componentInstance,
    componentSummaryId,
    metadata,
    isLoading,
    isError
  } = useComponentInstanceMetadataQuery(componentInstanceId)

  const componentInfo = useComponentInfo()
  const displayName = componentSummaryId
    ? componentInfo.getDisplayName(componentSummaryId)
    : ''

  if (isLoading) {
    return (
      <Loading
        data-testid="footer-left-loading"
        className={classes.ComponentLoader}
      />
    )
  }

  if (!componentInstance || !componentSummaryId || !metadata) {
    return (
      <EmptyPanel data-testid="panel-not-loaded">
        {isError
          ? t('translation:sideBar.componentPanel.networkError')
          : t('translation:sideBar.componentPanel.noComponentSelected')}
      </EmptyPanel>
    )
  }

  const tabs: Array<boolean | JSX.Element> = [
    <Tab
      key="properties"
      title={t('translation:componentProperties.panelTitle')}
      data-testid="tab-component-properties"
    >
      <ComponentOverview
        componentId={componentSummaryId}
        componentInstance={componentInstance}
        metadata={metadata}
      />
      <ComponentParametersContainer
        componentInstance={componentInstance}
        componentSummaryId={componentSummaryId}
        componentMetadata={metadata}
      />
    </Tab>,
    metadata.editorTabs.includes(ComponentEditorTabType.SAMPLE) && (
      <Tab
        key="sampling"
        title={t('translation:sampleComponent.tabTitle')}
        data-testid="tab-component-properties-sample"
      >
        <SampleComponent
          componentInstance={componentInstance}
          componentName={getComponentLabel(componentInstance)}
          jobType={metadata.componentType}
        />
      </Tab>
    ),
    metadata.editorTabs.includes(ComponentEditorTabType.SQL) && (
      <Tab key="sql" title="SQL" data-testid="tab-component-properties-sql">
        <SQLViewer />
      </Tab>
    )
  ]

  return (
    <section
      // this key ensures that the panel is remounted when the component changes;
      // without this, the selected tab state is maintained between components
      // in the same canvas node
      key={componentInstanceId}
      data-boundary="auto-complete-property-dropdown"
      aria-label={t('translation:componentProperties.panelTitle')}
      className={classnames(classes.ComponentPropertiesWrapper, {
        [classes.ComponentPropertiesWrapper__ScrollContainer]:
          enableWizardUiForComponents
      })}
    >
      <SampleProvider>
        {enableWizardUiForComponents ? (
          <ComponentParametersContainer
            componentInstance={componentInstance}
            componentSummaryId={componentSummaryId}
            componentMetadata={metadata}
          />
        ) : (
          <Tabs
            className={classes.ComponentProperties__Tabs}
            listClassName={classes.ComponentProperties__TabList}
            contentClassName={classes.ComponentProperties__TabPanel}
          >
            {React.Children.map(tabs.filter(Boolean), (child) => child)}
          </Tabs>
        )}

        <div className={classes.ComponentProperties__ExternalDocsLink}>
          <ExternalLink
            title="Help"
            ariaLabel={t('translation:componentProperties.docsLink', {
              componentName: displayName
            })}
            link={getDocumentationLink(componentSummaryId)}
          />
        </div>
      </SampleProvider>
    </section>
  )
}

export default ComponentPropertiesPanel
