import {
  ComponentEditorTabType,
  EditorType,
  ExportVariableType,
  LookUpType,
  ParameterDataType,
  UseVariable,
  VisibleWhenOperator,
  type ComponentMetadataResponse
} from 'api/hooks/useGetComponentMetadata/types'
import { ColumnEditorType } from 'api/hooks/useGetParameterOptions/types'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

export const mockTableIteratorMetadata: ComponentMetadataResponse = {
  componentId: 'table-iterator',
  metadata: {
    schemaVersion: 1,
    emeraldId: '1227580116',
    componentType: JobType.Orchestration,
    classification: 'CONTROL_FLOW',
    inputPorts: [
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.ONE
      }
    ],
    outputPorts: [
      {
        portId: ConnectionPortType.SUCCESS,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.FAILURE,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.ITERATION,
        cardinality: Cardinality.ONE
      }
    ],
    deprecated: false,
    exports: [
      ExportVariableType.COMPLETED_AT,
      ExportVariableType.COMPONENT_NAME,
      ExportVariableType.DURATION,
      ExportVariableType.ITERATIONS_ATTEMPTED,
      ExportVariableType.ITERATIONS_GENERATED,
      ExportVariableType.ITERATIONS_SUCCESSFUL,
      ExportVariableType.MESSAGE,
      ExportVariableType.ROW_COUNT,
      ExportVariableType.STARTED_AT,
      ExportVariableType.STATUS
    ],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.EXPORT,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'mode',
        metlSlot: 10,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            type: 'TEXT',
            options: ['Advanced', 'Basic'],
            columnEditorType: null,
            defaultValue: 'Basic',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null
      },
      {
        dplID: 'query',
        metlSlot: 11,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT_SQL,
        editorType: EditorType.SQL_EDITOR_ADVANCED,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'mode',
            value: 'Advanced',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'database',
        metlSlot: 8,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_DWH_DATABASE,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'mode',
            value: 'Basic',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: '[Environment Default]'
      },
      {
        dplID: 'schema',
        metlSlot: 6,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_DWH_SCHEMAS,
        lookupDependencies: ['param.database'],
        visibleWhen: [
          {
            param: 'mode',
            value: 'Basic',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: '[Environment Default]'
      },
      {
        dplID: 'targetTable',
        metlSlot: 2,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_DWH_TABLES,
        lookupDependencies: ['param.database', 'param.schema'],
        visibleWhen: [
          {
            param: 'mode',
            value: 'Basic',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'concurrency',
        metlSlot: 7,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            type: 'TEXT',
            options: ['Concurrent', 'Sequential'],
            columnEditorType: null,
            defaultValue: 'Sequential',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null
      },
      {
        dplID: 'columnMapping',
        metlSlot: 3,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'mode',
            value: 'Basic',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: [
          {
            name: 'Column Name',
            type: 'TEXT',
            options: null,
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: null,
            lookupType: LookUpType.DYNAMIC_DWH_COLUMNS,
            lookupDependencies: [
              'param.database',
              'param.schema',
              'param.target-table:param.table'
            ]
          },
          {
            name: 'Variable Name',
            type: 'TEXT',
            options: null,
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: null,
            lookupType: LookUpType.CIS_VARIABLES,
            lookupDependencies: null
          }
        ],
        defaultValue: null
      },
      {
        dplID: 'columnMapping1',
        metlSlot: 12,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'mode',
            value: 'Advanced',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: [
          {
            name: 'Column Name',
            type: 'TEXT',
            options: null,
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: null,
            lookupType: LookUpType.DYNAMIC_QUERY_COLUMNS,
            lookupDependencies: ['param.query']
          },
          {
            name: 'Variable Name',
            type: 'TEXT',
            options: null,
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: null,
            lookupType: LookUpType.CIS_VARIABLES,
            lookupDependencies: null
          }
        ],
        defaultValue: null
      },
      {
        dplID: 'orderBy',
        metlSlot: 4,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.MULTI_OPTION_SELECTOR,
        lookupType: LookUpType.DYNAMIC_DWH_COLUMNS,
        lookupDependencies: [
          'param.database',
          'param.schema',
          'param.target-table:param.table'
        ],
        visibleWhen: [
          {
            param: 'mode',
            value: 'Basic',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'concurrency',
            value: 'Sequential',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: true,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'sort',
        metlSlot: 9,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'mode',
            value: 'Basic',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'concurrency',
            value: 'Sequential',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: [
          {
            name: null,
            type: 'TEXT',
            options: ['Ascending', 'Descending'],
            columnEditorType: null,
            defaultValue: 'Ascending',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null
      },
      {
        dplID: 'breakOnFailure',
        metlSlot: 5,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'concurrency',
            value: 'Sequential',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: [
          {
            name: null,
            type: 'TEXT',
            options: ['No', 'Yes'],
            columnEditorType: null,
            defaultValue: 'No',
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null
      }
    ]
  }
}
