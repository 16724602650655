import { type RequestHandler, rest } from 'msw'

import {
  MockedDifferentGetBranchInformation,
  MockedGetBranchInformation
} from 'api/hooks/useGetBranch/useGetBranch.responses'
import { type GetBranchMetadataResponse } from 'api/hooks/useGetBranch/types'
import { PROJECT_ID } from '__test-utils__/ids'

export const stubGetBranch = (projectId = PROJECT_ID) => {
  return Object.keys(MockedGetBranchInformation).map((branchName) =>
    rest.get(
      `/working-tree-service/v1/projects/${projectId}/branches/${branchName}`,
      async (req, res, ctx) => {
        const url = new URL(req.url)

        const remoteBranchName = url.searchParams.get('remoteBranchName')
        const response =
          remoteBranchName == null
            ? MockedGetBranchInformation[branchName]
            : MockedDifferentGetBranchInformation[remoteBranchName]

        return res(ctx.json<GetBranchMetadataResponse>(response))
      }
    )
  )
}

export const useGetBranchHandlers: RequestHandler[] = [
  ...[PROJECT_ID, 'test-project'].flatMap((projectId) => {
    return stubGetBranch(projectId)
  }),
  rest.get('/branches/500', async (_req, res, ctx) => {
    return res(ctx.status(500))
  }),
  rest.get('/branches/400', async (_req, res, ctx) => {
    return res(ctx.status(400))
  })
]
