export interface OAuthContextBag {
  /**
   * A function that should be called when an action has
   * failed to auth being required. Invoking this will prompt
   * the user to auth with their Git provider and initialise
   * the OAuth flow.
   */
  onAuthRequired: () => void

  /**
   * Sets an action to be performed when the OAuth flow
   * is completed successfully. The action will only be
   * performed on the next completion.
   *
   * @param action A function that does the desired post oauth action.
   */
  setSuccessAction: (action: OAuthAction) => unknown

  /**
   * Sets an action to be performed when the user chooses to close
   * the OAuth modal and abandon the flow. The action will only be
   * performed on the next time a user abandons.
   *
   * @param action A function that does the desired abandon action.
   */
  setCloseAction: (action: OAuthAction) => void

  /**
   * Sets an additional piece of information to be rendered in the
   * auth modal. By default, the modal will render a generic description
   * about authorization. This function lets you set more specific information
   * that you want to render in a certain scenario.
   *
   * @param info A string of the additional info to render.
   */
  setModalHelpText: (text: string) => void
}

export const noOpAuthAction = () => async () => {}

export type OAuthAction = () => Promise<unknown>
