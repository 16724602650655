import { type ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Field,
  Modal,
  Textarea,
  Typography,
  Alert
} from '@matillion/component-library'
import ConflictsAlert from 'components/ConflictsAlert/ConflictsAlert'

import ModalTitle from 'components/ModalTitle'
import useMergeAction from 'hooks/useMergeAction/useMergeAction'
import classes from './MergeModal.module.scss'

import ConflictResolutionTable from 'components/ConflictResolutionTable/ConflictResolutionTable'
import PublishJobsForm from 'components/PublishJobsForm/PublishJobsForm'
import useGitContext from 'provider/GitProvider/useGitContext'
import { type MergeIntoBranchModalProps } from './types'
import useResolveConflicts from 'hooks/useResolveConflicts'
import { queryKeys } from 'api/query-keys'
import { type PublishToggleEvent } from 'components/PublishJobsForm/types'
import { useQueryClient } from '@tanstack/react-query'
import useDescribeMergeDecision from 'hooks/useDescribeMergeDecision/useDescribeMergeDecision'
import BranchSelector from '../BranchSelector'
import useMergeFeatureFlag from 'modules/Merge/hooks/useMergeFeatureFlag'

export const MergeModal = ({
  onClose,
  branches,
  environments,
  onPublishPipelines
}: MergeIntoBranchModalProps) => {
  const queryClient = useQueryClient()
  const { project } = useGitContext()
  const { keyDiscriminator } = useMergeFeatureFlag()

  const [sourceBranch, setSourceBranch] = useState<string>()
  const [targetBranch, setTargetBranch] = useState<string>()
  const [isInvalidating, setIsInvalidating] = useState(false)
  const [commitMessage, setCommitMessage] = useState<string>('')
  const [uncommittedChangesPreventMerge, setUncommittedChangesPreventMerge] =
    useState(false)

  const { t } = useTranslation('translation', { keyPrefix: 'merge' })
  const {
    shouldShowPublishForm,
    isLegacyDescribeLoadingAndNotIdle,
    isLoading: isDescribeLoading,
    mergeDescription
  } = useDescribeMergeDecision({
    sourceBranch,
    targetBranch,
    onUncommittedChangesPreventMerge: () => {
      setUncommittedChangesPreventMerge(true)
    }
  })

  const { sourceCommitId, targetCommitId, conflicts } = mergeDescription
  const hasConflicts = !!conflicts && conflicts.length > 0
  const { selections, hasResolvedConflicts, updateSelection } =
    useResolveConflicts(conflicts)

  const [publish, setPublish] = useState(false)
  const [environment, setEnvironment] = useState<string | undefined>(undefined)
  const [envsUnavailable, setEnvsUnavailable] = useState(false)
  const publishAvailable = targetBranch === 'main'

  const handleMergeSuccess = useCallback(
    async (commitId?: string) => {
      if (targetBranch && environment && commitId) {
        await onPublishPipelines({
          commitId,
          environment,
          branch: targetBranch
        })
      }

      onClose()
    },
    [onClose, onPublishPipelines, targetBranch, environment]
  )

  const {
    isValid: isMergeValid,
    isLoading: isMergeLoading,
    merge
  } = useMergeAction({
    conflictSelections: selections,
    mergeMessage: commitMessage,
    sourceBranch,
    sourceCommitId,
    targetCommitId,
    targetBranch,
    onSuccess: handleMergeSuccess,
    onFailure: onClose
  })

  const onMergeCommitMessageChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setCommitMessage(e.target.value)
    },
    []
  )

  const handleEnvsUnavailable = useCallback(() => {
    setPublish(false)
    setEnvsUnavailable(true)
  }, [setEnvsUnavailable])

  const handlePublishFormChange = useCallback(
    ({ enabled, environment: newEnvironment }: PublishToggleEvent) => {
      setPublish(enabled)
      setEnvironment(newEnvironment)
    },
    [setPublish, setEnvironment]
  )

  useEffect(() => {
    if (targetBranch) {
      setCommitMessage(`Merge from [${sourceBranch}] in to [${targetBranch}]`)

      if (!publishAvailable) {
        setPublish(false)
      }
    }
  }, [sourceBranch, conflicts, targetBranch, publishAvailable, setPublish])

  const isLoading = isMergeLoading
  const isPublishValid = environment && !envsUnavailable
  const cannotMerge =
    !isMergeValid ||
    (publish && !isPublishValid) ||
    (hasConflicts && !hasResolvedConflicts) ||
    isDescribeLoading

  useEffect(() => {
    setIsInvalidating(true)
    queryClient
      .invalidateQueries({
        queryKey: [queryKeys.legacyMerge],
        fetchStatus: 'idle'
      })
      .then(() => {
        setIsInvalidating(false)
      })
  }, [targetBranch, project, queryClient])

  return (
    <Modal
      size="mid"
      onCancel={onClose}
      id="merge-dialog"
      disableBackdropClick
      ariaLabelledBy="merge-title"
      className={classes.modal}
    >
      <ModalTitle
        titleId={'merge-title'}
        title={t(`${keyDiscriminator}.title`)}
        description={t(`${keyDiscriminator}.subtitle`)}
      />

      <BranchSelector
        branches={branches}
        sourceBranch={sourceBranch}
        targetBranch={targetBranch}
        isInvalidating={isInvalidating}
        onSourceBranchChange={setSourceBranch}
        onTargetBranchChange={setTargetBranch}
      />

      {uncommittedChangesPreventMerge && (
        <Alert
          theme="dark"
          type="error"
          message={t('uncommitted-changes-prevent-merge-alert', {
            branch: sourceBranch
          })}
          data-testid="uncommitted-changes-prevent-merge-alert"
        />
      )}

      {targetBranch && !uncommittedChangesPreventMerge && (
        <>
          {!isLegacyDescribeLoadingAndNotIdle && (
            <ConflictsAlert
              hasConflicts={hasConflicts}
              hasResolvedConflicts={hasResolvedConflicts}
            />
          )}

          {hasConflicts && (
            <>
              <p className={classes.conflictsLabel}>
                <Typography format="bcm" weight="bold" as="span">
                  {t('conflicts')}
                </Typography>{' '}
                <Typography format="bcm" as="span">
                  ({conflicts.length})
                </Typography>
              </p>

              <ConflictResolutionTable
                selections={selections}
                onVersionSelection={updateSelection}
              />
            </>
          )}

          <Field
            value={commitMessage}
            inputComponent={Textarea}
            id="merge-commit-message"
            title={t('commit-message')}
            aria-label={t('commit-message')}
            className={classes.commitMessage}
            data-testid="commit-message-text"
            onChange={onMergeCommitMessageChange}
            disabled={isMergeLoading || isLegacyDescribeLoadingAndNotIdle}
          />

          {shouldShowPublishForm && (
            <div className={classes.publishContainer}>
              <PublishJobsForm
                environments={environments}
                unavailable={!publishAvailable}
                onChange={handlePublishFormChange}
                onUnavailable={handleEnvsUnavailable}
                warningKey={'publish.message.not-default-target-branch'}
              />
            </div>
          )}
        </>
      )}

      <div className={classes.footer}>
        <Button
          id="cancel"
          alt="secondary"
          onClick={onClose}
          text={t('cancel')}
          data-testid="cancel-merge-button"
        />

        {!uncommittedChangesPreventMerge && (
          <Button
            alt="positive"
            onClick={merge}
            text={t('submit')}
            id="perform-merge"
            waiting={isLoading}
            disabled={cannotMerge}
            data-testid="modal-merge-submit"
          />
        )}
      </div>
    </Modal>
  )
}

export default MergeModal
