import { type ExternalGitProvider } from '../../types/ExternalGitProvider'

export interface InitOAuthActionProps {
  /**
   * The provider with which to configure an OAuth.
   */
  provider?: ExternalGitProvider

  onSuccess?: () => void
  onFailure?: () => void
}

export interface InitOAuthActionResponse {
  /**
   * Whether the operation is still in progress.
   */
  loading: boolean

  /**
   * The authorisation URL which will be used for authentication.
   */
  authorizationURL?: string
}

export const GIT_OAUTH_REDIRECT = 'git-oauth-redirect'
