import {
  type ComponentSummary,
  type ComponentSummaryId
} from 'api/hooks/useGetComponentSummaries'

import { type IPoint } from 'components/ComponentDrag/ComponentDropDestination'

import {
  EtlCanvasNodeType,
  type ComponentNodeData
} from './hooks/useCanvasModel/useCanvasModel'
import { getComponentPorts } from './hooks/useCanvasModel/utils'
import { type EtlFlowInstance } from './hooks/useEtlFlow'

export const addTempComponent = (
  id: ComponentSummaryId,
  label: string,
  point: IPoint,
  reactFlow: EtlFlowInstance,
  draggedSummary: ComponentSummary | undefined,
  getIcon: (componentId: string) => string
) => {
  if (!draggedSummary) {
    console.debug(`Failed to add unknown temporary component: ${id}`)
    return
  }

  const { inputPorts, outputPorts, iteratorPorts } =
    getComponentPorts(draggedSummary)

  /**
   * Add a temporary node which will get tidied up next time the job changes
   * There is a potential for this to get removed if _another_ request causes the job to change
   * while we're still waiting for the metadata service
   **/
  const data: ComponentNodeData = {
    imageUrl: getIcon(id),
    label,
    inputPorts,
    outputPorts,
    iteratorPorts,
    summaryId: draggedSummary.componentId
  }
  reactFlow.addNodes({
    id: `component-temp-${id}`,
    type:
      iteratorPorts.length > 0
        ? EtlCanvasNodeType.ITERATOR
        : EtlCanvasNodeType.NODE,
    position: point,
    selectable: false,
    draggable: false,
    deletable: false,
    data
  })
}
