import { useRef, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Panel, PanelGroup } from 'react-resizable-panels'

import { ComponentSummaries } from 'modules/ComponentSummaries'
import PipelineBrowser from 'modules/PipelineBrowser/PipelineBrowser'
import { SchemaBrowser, SchemaMenu } from 'modules/ui/SchemaBrowser'

import PanelWrapper from '../PanelWrapper/PanelWrapper'
import { type SimpleRefetchRef } from './types'

const SideBar: FunctionComponent = () => {
  const { t } = useTranslation()
  const schemaMenuRef = useRef<SimpleRefetchRef>(null)

  return (
    <PanelGroup direction="vertical" autoSaveId="sidebar">
      <Panel order={1} id="pipelines">
        <PipelineBrowser data-testid="pipeline-browser" />
      </Panel>

      <PanelWrapper
        id="components"
        header={t('sideBar.componentPanel.addComponents')}
        order={2}
        searchEnabled={false}
      >
        <ComponentSummaries />
      </PanelWrapper>

      <PanelWrapper
        id="schemas"
        header={t('sideBar.schemaPanel.title')}
        order={3}
        contextMenu={
          <SchemaMenu refetch={() => schemaMenuRef.current?.refetch()} />
        }
        collapsed
      >
        <SchemaBrowser ref={schemaMenuRef} />
      </PanelWrapper>
    </PanelGroup>
  )
}

export default SideBar
