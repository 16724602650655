import {
  ComponentEditorTabType,
  EditorType,
  LookUpType,
  ParameterDataType,
  UseVariable,
  type ComponentMetadataResponse
} from 'api/hooks/useGetComponentMetadata/types'
import { ColumnEditorType } from 'api/hooks/useGetParameterOptions/types'

import {
  Cardinality,
  ConnectionPortType,
  type ConnectionPortTypeT
} from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

export const mockRankMetadata: ComponentMetadataResponse = {
  componentId: 'rank',
  metadata: {
    schemaVersion: 1,
    emeraldId: '2139809017',
    classification: 'TRANSFORMATION',
    componentType: JobType.Transformation,
    inputPorts: [
      {
        portId: 'INPUT' as ConnectionPortTypeT,
        cardinality: 'ONE' as Cardinality
      }
    ],
    outputPorts: [
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      }
    ],
    deprecated: false,
    exports: [],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.SAMPLE,
      ComponentEditorTabType.METADATA,
      ComponentEditorTabType.SQL,
      ComponentEditorTabType.PLAN,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'includeInputColumns',
        metlSlot: 2,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            type: 'TEXT',
            options: ['No', 'Yes'],
            columnEditorType: null,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null
      },
      {
        dplID: 'partitionData',
        metlSlot: 3,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.MULTI_OPTION_SELECTOR,
        lookupType: LookUpType.DYNAMIC_TRANSFORM_SOURCE_COLUMNS,
        lookupDependencies: ['transform.sql'],
        visibleWhen: null,
        optional: true,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'orderingWithinPartitions',
        metlSlot: 4,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_TRANSFORM_SOURCE_COLUMNS_WITH_ORDER_BY,
        lookupDependencies: ['transform.sql'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'functions',
        metlSlot: 5,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: 'Window Function',
            type: 'TEXT',
            options: [
              'Cumulative Distribution',
              'Dense Rank',
              'Percent Rank',
              'Rank',
              'Row Number'
            ],
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          },
          {
            name: 'Output Column',
            type: 'TEXT',
            options: null,
            columnEditorType: ColumnEditorType.Freetext,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null
      }
    ]
  }
}
