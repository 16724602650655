import { type FC } from 'react'

import { Table as T, Typography } from '@matillion/component-library'

import {
  type SampleComponentResponse,
  type SampleComponentResponseColumns
} from 'api/hooks/useSampleComponent/types'

import classes from './SampleComponent.module.scss'

const { Table, TableHead, TableRow, TableHeaderCell, TableBody, TableCell } = T

interface SampleTableProps {
  title: string
  data: SampleComponentResponse
}

export const SampleTable: FC<SampleTableProps> = ({ title, data }) => {
  const getRowData = () => {
    const cells = data.rows.map((row, rowIndex) => {
      const values = row.values ?? []
      if (!values.length) {
        return null
      }
      return (
        <TableRow
          key={`row-${String(rowIndex)}`}
          className={classes.SampleComponentResults__Row}
          id={rowIndex}
        >
          {values.map((value: string, cellIndex: number) => (
            <TableCell
              key={`cell-${String(rowIndex)}-${String(cellIndex)}`}
              className={classes.SampleComponentResults__Cell}
              id={cellIndex}
            >
              {value}
            </TableCell>
          ))}
        </TableRow>
      )
    })

    return cells
  }

  return (
    <div
      className={classes.SampleComponentResults}
      data-testid="sample-table-scroll-container"
    >
      <Table
        data-testid="sample-table-data"
        className={classes.SampleComponentResults__Table}
        aria-label={title}
      >
        <TableHead>
          <TableRow className={classes.SampleComponentResults__Row}>
            {data.metadata.map((column: SampleComponentResponseColumns) => (
              <TableHeaderCell
                key={column.name}
                className={classes.SampleComponentResults__HeaderCell}
              >
                <Typography format="bcs" weight="bold">
                  {column.name}
                </Typography>
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{getRowData()}</TableBody>
      </Table>
    </div>
  )
}
