import { useQuery } from '@tanstack/react-query'

import { queryKeys } from 'api/queryKeys'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useProjectExplorerClient } from '../useProjectExplorerClient/useProjectExplorerClient'
import {
  type ListProjectVariablesFailResponse,
  type ListProjectVariablesResponse
} from './types'

interface UseListProjectVariablesProps {
  enabled?: boolean
  staleTime?: number
}

export const useListProjectVariables = ({
  enabled = true,
  staleTime
}: UseListProjectVariablesProps = {}) => {
  const { projectId } = useProjectInfo()
  const client = useProjectExplorerClient()

  return useQuery<
    ListProjectVariablesResponse,
    ListProjectVariablesFailResponse
  >(
    [queryKeys.listProjectVariables, projectId],
    async () => {
      const { data } = await client.get<ListProjectVariablesResponse>(
        `projects/${projectId}/variables`
      )
      return data
    },
    { enabled, staleTime }
  )
}
