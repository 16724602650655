import { type PropsWithChildren, useEffect, useMemo, useState } from 'react'

import { FileStatus } from 'types/FileStatus'

import { GitContext } from './GitContext'
import { type GitContextBag, type GitProviderProps } from './types'
import { type FileSummary } from 'types/JobSummaries'
import ApiContextProvider from 'api/context/ApiContextProvider'
import { I18nextProvider } from 'react-i18next'
import i18n from 'i18n'
import { FlagProvider } from '@matillion/hub-client'
import {
  FeatureFlagDefaults,
  launchDarklyClientId
} from 'hooks/useFeatureFlags/types'
import OAuthProvider from 'provider/OAuthProvider'

const GitProvider = ({
  children,
  designerClient,
  project,
  branch,
  environment,
  fileSummaries,
  isNativeGit
}: PropsWithChildren<GitProviderProps>) => {
  const [hasUncommittedChanges, setHasUncommittedChanges] = useState(false)

  useEffect(() => {
    if (fileSummaries && fileSummaries.length > 0) {
      const hasChanges = fileSummaries.some((summary: FileSummary) => {
        return summary.status !== FileStatus.UNMODIFIED
      })

      setHasUncommittedChanges(hasChanges)
    }
  }, [fileSummaries])

  const providerValues: GitContextBag = useMemo(
    () => ({
      branch: branch ?? '',
      project: project ?? '',
      designerClient,
      hasUncommittedChanges,
      isNativeGit: isNativeGit ?? false,
      isLegacyWTS: !isNativeGit
    }),
    [designerClient, isNativeGit, project, branch, hasUncommittedChanges]
  )

  return (
    <FlagProvider
      waitForFlags
      useCamelCaseFlagKeys
      flags={FeatureFlagDefaults[environment]}
      launchDarklyClientId={launchDarklyClientId[environment]}
    >
      <I18nextProvider i18n={i18n}>
        <ApiContextProvider environment={environment}>
          <OAuthProvider>
            <GitContext.Provider value={providerValues}>
              {children}
            </GitContext.Provider>
          </OAuthProvider>
        </ApiContextProvider>
      </I18nextProvider>
    </FlagProvider>
  )
}

export default GitProvider
