import { useMutation } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useProjectExplorerClient } from '../useProjectExplorerClient/useProjectExplorerClient'
import { deleteProjectVariablesApi } from './deleteProjectVariablesApi'

export const useDeleteProjectVariables = () => {
  const { projectId } = useProjectInfo()
  const client = useProjectExplorerClient()
  return useMutation(async (variableId: string) => {
    return deleteProjectVariablesApi(client, projectId, variableId)
  })
}
