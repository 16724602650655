import { Icon } from '@matillion/component-library'

import { State } from 'api/hooks/useGetRunTasks/types'

import { ReactComponent as CancelledSvg } from 'assets/cancelled-small.svg'
import { ReactComponent as ErrorSvg } from 'assets/failed-small.svg'
import { ReactComponent as SuccessSvg } from 'assets/success-small.svg'

// ssss
const StateIconMapper = {
  [State.Failed]: ErrorSvg,
  [State.Success]: SuccessSvg,
  [State.Running]: Icon.Loading,
  [State.Queued]: Icon.Loading,
  [State.Cancelled]: CancelledSvg,
  [State.Cancelling]: CancelledSvg
}

export const getStateIcon = (status: State | undefined) =>
  status && StateIconMapper[status] ? StateIconMapper[status] : Icon.Warning
