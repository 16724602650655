import { useCallback, useEffect, useMemo, useState } from 'react'
import axios from 'axios'

import useEnvironment from '../../../effects/useEnvironment'
import useFlags from '../../../effects/useFlags'
import getConfig from '../../../config'

import defaultMenuStructure from '../constants/default_menu_structure.json'

export interface PlatformMenuService {
  title: string
  description: string
  url: string
  key: string
}

export interface PlatformMenuSubMenus {
  title: string
  description: string
  options: Array<{
    title: string
    description: string
    url: string
    key: string
  }>
}

export interface PlatformMenuGroup {
  title: string
  options: Array<{
    title: string
    url: string
  }>
}

export interface PlatformMenuDefinition {
  services: PlatformMenuService[]
  subMenus?: PlatformMenuSubMenus[]
  groups?: PlatformMenuGroup[]
  conditionalServices?: PlatformMenuService[]
}

const usePlatformMenuSource = () => {
  const { environment, offline } = useEnvironment()
  const { enableUpdatedPlatformMenu } = useFlags()

  return useMemo(() => {
    if (offline) {
      return undefined
    }

    const getConfigSource = () =>
      enableUpdatedPlatformMenu
        ? getConfig(environment).platformNavigation.source
        : getConfig(environment).platformNavigation.sourceOld

    return getConfigSource()
  }, [environment, offline, enableUpdatedPlatformMenu])
}

const usePlatformMenuStructure = () => {
  const source = usePlatformMenuSource()
  const [payload, setPayload] =
    useState<PlatformMenuDefinition>(defaultMenuStructure)
  const [isLoading, setIsLoading] = useState(true)

  const loadPlatformMenuStructure = useCallback(async () => {
    setIsLoading(true)

    try {
      if (!source) {
        return setPayload(defaultMenuStructure)
      }

      const { data } = await axios.get<PlatformMenuDefinition>(source)

      setPayload(data)
    } catch (e) {
      console.error('Could not load platform menu!', e)
      setPayload(defaultMenuStructure)
    } finally {
      setIsLoading(false)
    }
  }, [source, setIsLoading, setPayload])

  useEffect(() => {
    loadPlatformMenuStructure()
  }, [loadPlatformMenuStructure])

  return [payload, isLoading] as const
}

export default usePlatformMenuStructure
