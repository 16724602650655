export const mockTransformationValues = {
  TABLE_INPUT_0: {
    id: 129,
    name: 'Table Input 0'
  },
  FILTER_0: {
    id: 131,
    name: 'Filter 0'
  },
  REWRITE_TABLE_0: {
    id: 134,
    name: 'Rewrite Table 0'
  },
  DISTINCT_0: {
    id: 770194,
    name: 'Distinct 0'
  },
  FIXED_FLOW_0: {
    id: 697172,
    name: 'Fixed Flow 1'
  },
  GENERATE_SEQUENCE_0: {
    id: 958781,
    name: 'Generate Sequence 0'
  },
  MULTI_TABLE_INPUT_0: {
    id: 14274,
    name: 'Multi Table Input 0'
  },
  FLATTEN_VARIANT_0: {
    id: 1125507,
    name: 'Flatten Variant 0'
  },
  TRANSPOSE_COLUMNS_0: {
    id: 10000000,
    name: 'Transpose Columns 0'
  },
  JOIN_0: {
    id: 3,
    name: 'Join 0'
  },
  RANK_0: {
    id: 1118248,
    name: 'Rank 0'
  }
}
