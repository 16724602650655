import { useFlags } from 'hooks/useFlags'

import { type ProjectType } from '../../api/hooks/useGetProject/types'
import { getConfig } from './getConfig'

/**
 * Returns a map of component ID -> list of enabled warehouse types.
 */
export const useComponentEnabledWarehouses = (): Map<string, ProjectType[]> => {
  const hubFlags = useFlags()

  const flagPrefix = 'warehouses-'
  const warehouseFlags = new Map<string, ProjectType[]>(
    Object.entries(hubFlags)
      .filter(([k]) => k.startsWith(flagPrefix))
      .map(([k, v]) => [k.replace(flagPrefix, ''), v as ProjectType[]])
  )

  const result = new Map(getConfig().componentsEnabledWarehouses)
  warehouseFlags.forEach((warehouses, component) => {
    const enabledWarehouses = result.get(component) ?? ([] as ProjectType[])
    const combinedWarehouses = enabledWarehouses.concat(warehouses)
    result.set(component, combinedWarehouses)
  })

  return result
}

/**
 * Given a map of component ID -> list of enabled warehouses, return a list of components that are enabled for the
 * supplied warehouse.
 * @param componentEnabledWarehouses A map of component ID -> list of supported warehouses
 * @param warehouse The warehouse to return the enabled components for
 */
export const getEnabledComponents = (
  componentEnabledWarehouses: Map<string, ProjectType[]>,
  warehouse: ProjectType
) => {
  return Array.from(componentEnabledWarehouses.keys()).filter((component) =>
    componentEnabledWarehouses.get(component)?.includes(warehouse)
  )
}
