import { Icon } from '@matillion/component-library'

import classes from './UncommittedChangesIcon.module.scss'

export interface UncommittedChangesIconProps {
  className?: string
}

const UncommittedChangesIcon = ({ className }: UncommittedChangesIconProps) => {
  return (
    <span data-testid="uncommitted-changes-icon">
      <span className={classes.Stroke} />
      <Icon.Info className={className} />
    </span>
  )
}

export default UncommittedChangesIcon
