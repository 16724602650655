import { useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { datadogLogs } from '@datadog/browser-logs'
import { Alert, Typography } from '@matillion/component-library'

import { type Failure } from 'api/hooks/useValidateComponent/types'

import { type ProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import classes from './ParameterFailures.module.scss'

interface ParameterFailuresProps {
  failures: Failure[]
  failureMessage: string | null
  projectInfo?: ProjectInfo
}

const MAX_ERROR_LENGTH = 100
export const PARAMETER_PATH_IDENTIFIER = 'parameters'

const getParameterFailures = (failures: Failure[]) => {
  const parameterFailures = failures.filter(
    ({ path }) => path.at(0)?.toLowerCase() === PARAMETER_PATH_IDENTIFIER
  )

  return {
    parameterFailures,
    parameterFailureCount: parameterFailures.length
  }
}

const getComponentFailures = (
  failures: Failure[],
  failureMessage: string | null
) => {
  const componentFailures = failures.filter(
    ({ path }) => path.at(0)?.toLowerCase() !== PARAMETER_PATH_IDENTIFIER
  )

  if (
    failureMessage &&
    !componentFailures.some(
      (failure) =>
        failure.message.toLowerCase() === failureMessage.toLowerCase()
    )
  ) {
    componentFailures.push({
      message: failureMessage,
      path: []
    })
  }

  return {
    componentFailures,
    componentFailureCount: componentFailures.length
  }
}

export const ParameterFailures: FC<ParameterFailuresProps> = ({
  failures,
  failureMessage,
  projectInfo
}) => {
  const { t } = useTranslation()

  const [isShowingMore, setIsShowingMore] = useState<boolean>(false)

  const { parameterFailureCount } = getParameterFailures(failures)
  const { componentFailures, componentFailureCount } = getComponentFailures(
    failures,
    failureMessage
  )

  const hasMultipleErrors =
    parameterFailureCount > 0 && componentFailureCount > 0
  const hasMultipleComponentErrors = componentFailureCount > 1
  const isSingleErrorExceedingMaxLength =
    componentFailureCount === 1 &&
    componentFailures[0].message.length > MAX_ERROR_LENGTH

  const hasShowMoreAction =
    hasMultipleErrors ||
    hasMultipleComponentErrors ||
    isSingleErrorExceedingMaxLength

  const getAlertTitle = () => {
    if (parameterFailureCount > 0 && componentFailureCount === 0) {
      return t('translation:componentProperties.status.parameterError', {
        count: parameterFailureCount
      })
    }

    if (parameterFailureCount === 0 && componentFailureCount > 0) {
      return t('translation:componentProperties.status.componentError', {
        count: componentFailureCount
      })
    }

    if (!failureMessage && failures.length === 0) {
      datadogLogs.logger.info(
        'Component error unknown. No failure message or failures returned in validation request.',
        {
          ...projectInfo,
          failureMessage,
          failures
        }
      )
      return t('translation:componentProperties.status.unknownError')
    }

    return t('translation:componentProperties.status.multipleErrors')
  }

  const getFailureMessages = () => {
    const isMultipleError =
      parameterFailureCount > 0 && componentFailureCount > 0

    if (isMultipleError && isShowingMore) {
      return (
        <ul>
          <li>
            <Typography format="bcs">
              {t('translation:componentProperties.status.parameterError', {
                count: parameterFailureCount
              })}
            </Typography>
          </li>
          {componentFailures.map((failure, index) => (
            <li key={`${failure.message}-${index}`}>
              <Typography format="bcs">{failure.message}</Typography>
            </li>
          ))}
        </ul>
      )
    }

    if (
      !isMultipleError &&
      componentFailureCount > 0 &&
      (isShowingMore || componentFailureCount === 1)
    ) {
      return (
        <ul>
          {componentFailures.map((failure, index) => (
            <li key={`${failure.message}-${index}`}>
              <Typography format="bcs">
                {failure.message.length > MAX_ERROR_LENGTH && !isShowingMore
                  ? `${failure.message.substring(0, MAX_ERROR_LENGTH)}...`
                  : failure.message}
              </Typography>
            </li>
          ))}
        </ul>
      )
    }
  }

  const getShowMoreAction = () => {
    if (!hasShowMoreAction) {
      return
    }

    const showMoreText = t('translation:componentProperties.status.showMore')
    const showLessText = t('translation:componentProperties.status.showLess')

    return {
      text: isShowingMore ? showLessText : showMoreText,
      onClick: () => {
        setIsShowingMore((prevState) => !prevState)
      }
    }
  }

  return (
    <Alert
      data-testid="component-status-error"
      className={classes.ParameterFailures}
      type="error"
      title={getAlertTitle()}
      message={getFailureMessages()}
      action={getShowMoreAction()}
    />
  )
}
