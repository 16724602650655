import { LookUpType } from 'api/hooks/useGetComponentMetadata/types'
import { useGetParameterOptions } from 'api/hooks/useGetParameterOptions/useGetParameterOptions'

import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'
import { StorageEditorType } from 'modules/ParameterEditors/types'

export const useGetContainers = (
  fullPath: string[],
  storageEditorType?: StorageEditorType
) => {
  const { job } = useWorkingCopy()
  const variables = job?.variables ?? {}
  const bucketsQuery = useGetParameterOptions({
    lookupType:
      storageEditorType === StorageEditorType.S3
        ? LookUpType.DYNAMIC_S3_BUCKETS
        : LookUpType.DYNAMIC_BLOB_STORAGE_ACCOUNTS,
    isEnabled: !!storageEditorType,
    variables,
    requestType: 'parameter-options'
  })
  const paramName =
    storageEditorType === StorageEditorType.S3
      ? 'param.bucket'
      : 'param.storageAccount'

  const foldersQuery = useGetParameterOptions({
    lookupDefinition: {
      parameters: {
        [paramName]: fullPath[0] || '',
        'param.path': fullPath.slice(1).join('/') || ''
      }
    },
    variables,
    lookupType:
      storageEditorType === StorageEditorType.S3
        ? LookUpType.DYNAMIC_S3_FOLDERS
        : LookUpType.DYNAMIC_BLOB_STORAGE_CONTAINERS,
    isEnabled: Boolean(storageEditorType && Boolean(fullPath[0])),
    requestType: 'parameter-options'
  })

  const folders = foldersQuery.data?.editorColumns?.[0]?.options ?? null

  const buckets = bucketsQuery.data?.editorColumns?.[0]?.options ?? null
  const foldersError = foldersQuery.isError ? foldersQuery.error : null
  const bucketsError = bucketsQuery.isError ? bucketsQuery.error : null
  const isBucketsLoading =
    bucketsQuery.isFetching ||
    bucketsQuery.isLoading ||
    bucketsQuery.isRefetching
  const isFoldersLoading =
    foldersQuery.isFetching ||
    foldersQuery.isLoading ||
    foldersQuery.isRefetching

  return {
    isBucketsLoading,
    isFoldersLoading,
    folders,
    buckets,
    foldersError,
    bucketsError
  }
}
