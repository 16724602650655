import { useCallback, useMemo } from 'react'

import { type ComponentMetadataParameterId } from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'

import {
  orchestrationJobNameParameter,
  runOrchestrationComponentId
} from 'job-lib/cisIds/knownComponentParameters'

export interface ClientDataFilteringProps {
  componentSummaryId?: ComponentSummaryId
  parameterId?: ComponentMetadataParameterId
}

export type FilterOptionsFn = (column: EditorColumn[]) => EditorColumn[]

export interface Filters {
  [key: ComponentMetadataParameterId]: FilterOptionsFn
}

const useFilterRunOrchestrationParameter = (): Filters => {
  const { pipelineSummary } = useActivePipelineSummary()

  return useMemo<Filters>(
    () => ({
      [orchestrationJobNameParameter]: (columns) =>
        columns.map((column) => {
          if (!pipelineSummary || !column.options) {
            return column
          }

          return {
            ...column,
            options: column.options.filter(
              (option) => option !== pipelineSummary.runtimeName
            )
          }
        })
    }),
    [pipelineSummary]
  )
}

export const useClientDataFiltering = ({
  parameterId,
  componentSummaryId
}: ClientDataFilteringProps) => {
  const runOrchestrationFilters = useFilterRunOrchestrationParameter()

  const filterColumns = useCallback(
    (editorColumns: EditorColumn[]) => {
      if (componentSummaryId === runOrchestrationComponentId) {
        const filter = parameterId ? runOrchestrationFilters[parameterId] : null

        if (filter) {
          return filter(editorColumns)
        }
      }

      return editorColumns
    },
    [parameterId, componentSummaryId, runOrchestrationFilters]
  )

  return {
    filterColumns
  }
}
