import {
  ComponentEditorTabType,
  EditorType,
  ExportVariableType,
  ParameterDataType,
  UseVariable,
  type ComponentMetadataResponse
} from 'api/hooks/useGetComponentMetadata/types'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

export const mockStartMetadata: ComponentMetadataResponse = {
  componentId: 'start',
  metadata: {
    schemaVersion: 1,
    emeraldId: '444132438',
    componentType: JobType.Orchestration,
    classification: 'CONTROL_FLOW',
    inputPorts: [],
    outputPorts: [
      {
        portId: ConnectionPortType.SUCCESS,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.FAILURE,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      }
    ],
    deprecated: false,
    exports: [
      ExportVariableType.COMPLETED_AT,
      ExportVariableType.COMPONENT_NAME,
      ExportVariableType.DURATION,
      ExportVariableType.MESSAGE,
      ExportVariableType.ROW_COUNT,
      ExportVariableType.STARTED_AT,
      ExportVariableType.STATUS
    ],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.EXPORT,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        dplID: 'componentName',
        resourceID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      }
    ]
  }
}
