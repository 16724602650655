import { FunctionComponent, useMemo } from 'react'

import useEnvironment from '../../../effects/useEnvironment'
import encodeLocation from '../../../utils/encodeLocation'

const MyDetailsLink: FunctionComponent<React.PropsWithChildren> = ({
  children,
  ...props
}) => {
  const { hubUrl, subdomain, services } = useEnvironment()

  const returnToSlug = useMemo(() => {
    if (!subdomain) {
      return ''
    }

    const home = new URL(services.homeUrl)
    home.host = `${subdomain}.${home.host}`

    const returnTo = {
      origin: home.toString()
    }

    return `returnTo=${encodeLocation(returnTo)}`
  }, [subdomain, services])

  return (
    <a
      target="_blank"
      rel="noreferrer"
      {...props}
      href={`${hubUrl}/profile?${returnToSlug}`}
    >
      {children}
    </a>
  )
}

export default MyDetailsLink
