import { Problem } from 'hooks/useProblemDetails/Problem'
import { type ProblemDetails } from 'types/ProblemDetails'

const getProblemResponse = (type: Problem): ProblemDetails => {
  switch (type) {
    case Problem.WTS_REMOTE_BRANCH_NOT_FOUND: {
      return {
        type,
        status: 404,
        title: 'Not Found',
        detail: 'Branch named [test-branch] not found on remote'
      }
    }
    case Problem.WTS_COMMIT_MESSAGE_TOO_LONG: {
      return {
        type,
        status: 400,
        title: 'Bad Request',
        detail: 'Commit message is too long'
      }
    }
    case Problem.WTS_PUSH_REJECTED: {
      return {
        type,
        status: 409,
        title: 'Rejected',
        detail: 'Push to remote was rejected'
      }
    }
    case Problem.WTS_NO_CHANGES_TO_COMMIT: {
      return {
        type,
        status: 400,
        title: 'Bad Request',
        detail:
          'Branch [test-branch] in account [test-account] and project [test-project] does not contain changes'
      }
    }
    case Problem.WTS_NOTHING_TO_COMMIT: {
      return {
        type,
        status: 409,
        title: 'Conflict',
        detail: 'There are no changes to commit'
      }
    }
    case Problem.WTS_LOCAL_BRANCH_BEHIND: {
      return {
        type,
        status: 409,
        title: 'Conflict',
        detail: 'Local branch is behind remote'
      }
    }
    case Problem.WTS_UNCOMMITTED_CHANGES_PREVENT_MERGE: {
      return {
        type,
        status: 409,
        title: 'Conflict',
        detail:
          'Uncommitted local changes prevent merge of [branch-name] from remote'
      }
    }
    case Problem.GIT_PROVIDER_NOT_FOUND: {
      return {
        type,
        status: 404,
        title: 'Not Found',
        detail: 'The git provider [unknown-provider] was not found'
      }
    }
    case Problem.GIT_MANUAL_MERGE_REQUIRED: {
      return {
        type,
        status: 409,
        title: 'Conflict',
        detail:
          'Manual merge required from [source-branch] to [target-branch] in repository [test-repo] for account [test-account]'
      }
    }
    case Problem.GIT_PROVIDER_AUTH_REQUIRED:
    case Problem.WTS_PROVIDER_AUTH_REQUIRED: {
      return {
        type,
        status: 401,
        provider: 'github',
        title: 'Unauthorised',
        detail: 'Authorisation required for provider [github]'
      }
    }
    case Problem.GIT_PROVIDER_AUTH_FAILED:
    case Problem.WTS_PROVIDER_AUTH_FAILED: {
      return {
        type,
        status: 401,
        provider: 'github',
        title: 'Unauthorised',
        detail: 'Authorisation failed for provider [github]'
      }
    }
    case Problem.WTS_BRANCH_NOT_FOUND: {
      return {
        type,
        status: 404,
        title: 'Not Found',
        detail:
          'Branch [branch-name] in account [test-account] and project [test-project] not found'
      }
    }
  }
}

export default getProblemResponse
