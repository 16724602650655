import { memo, type FC, type HTMLProps, type PropsWithChildren } from 'react'

import { Tooltip, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import classes from './EtlComponent.module.scss'

export interface EtlComponentProps extends HTMLProps<HTMLDivElement> {
  imageUrl?: string
  isAttached?: boolean
  isSelected?: boolean
  isIterator?: boolean
  isError?: boolean
  isSuccess?: boolean
  isLoading?: boolean
  hasDoubleClickNavigation?: boolean
  showLabel?: boolean
  tooltipContent: string
}

export const EtlComponent: FC<PropsWithChildren<EtlComponentProps>> = memo(
  ({
    imageUrl,
    isSelected,
    isAttached,
    isIterator,
    isError,
    isSuccess,
    isLoading,
    hasDoubleClickNavigation,
    showLabel = true,
    tooltipContent,
    children,
    ...props
  }) => (
    <div
      {...props}
      className={classNames(classes.EtlComponent, {
        [classes['EtlComponent--isSelected']]: isSelected,
        [classes['EtlComponent--isError']]: isError,
        [classes['EtlComponent--isSuccess']]: isSuccess,
        [classes['EtlComponent--isLoading']]: isLoading,
        [classes['EtlComponent--isIterator']]: isIterator,
        [classes['EtlComponent--isAttached']]: isAttached,
        [classes['EtlComponent--hasDoubleClickNavigation']]:
          hasDoubleClickNavigation
      })}
    >
      <Tooltip content={tooltipContent}>
        <div
          data-testid="canvas-component-image"
          className={classes.EtlComponent__Image}
          style={{ backgroundImage: imageUrl && `url(${imageUrl})` }}
        />
      </Tooltip>

      <div
        className={classNames(classes.EtlComponent__LabelContainer, {
          'u-visually-hidden': !showLabel
        })}
      >
        <Typography
          format="bcs"
          className={classes.EtlComponent__Label}
          weight={isSelected ? 'bold' : 'normal'}
        >
          {children}
        </Typography>
      </div>

      <div className={classes.EtlComponent__Badge} />
    </div>
  )
)

EtlComponent.displayName = 'EtlComponent'
