import {
  ComponentEditorTabType,
  EditorType,
  ExportVariableType,
  LookUpType,
  ParameterDataType,
  UseVariable,
  type ComponentMetadataResponse
} from 'api/hooks/useGetComponentMetadata/types'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

export const mockRewriteTableMetadata: ComponentMetadataResponse = {
  componentId: 'rewrite-table',
  metadata: {
    schemaVersion: 1,
    emeraldId: '335239555',
    classification: 'TRANSFORMATION',
    componentType: JobType.Transformation,
    inputPorts: [
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.ONE
      }
    ],
    outputPorts: [],
    deprecated: false,
    exports: [
      ExportVariableType.STARTED_AT,
      ExportVariableType.COMPLETED_AT,
      ExportVariableType.COMPONENT_NAME,
      ExportVariableType.DURATION,
      ExportVariableType.MESSAGE,
      ExportVariableType.ROW_COUNT,
      ExportVariableType.STATUS
    ],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.EXPORT,
      ComponentEditorTabType.SAMPLE,
      ComponentEditorTabType.METADATA,
      ComponentEditorTabType.SQL,
      ComponentEditorTabType.PLAN,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'warehouse',
        metlSlot: 8,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_DWH_WAREHOUSES,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'database',
        metlSlot: 6,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_DWH_DATABASES,
        lookupDependencies: ['param.warehouse'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'schema',
        metlSlot: 7,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_DWH_SCHEMAS,
        lookupDependencies: ['param.warehouse', 'param.database'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'targetTable',
        metlSlot: 2,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'orderBy',
        metlSlot: 9,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_TRANSFORM_SOURCE_COLUMNS_WITH_ORDER_BY,
        lookupDependencies: ['transform.sql'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      }
    ]
  }
}
