import { useReactFlow, type Node } from 'reactflow'

export const useUpdateContextNodeSelection = () => {
  const reactFlowInstance = useReactFlow()

  const updateNodeSelection = (hoveredNode?: Node) => {
    const nodes = reactFlowInstance.getNodes()
    const isNodeHoveredAndNotSelected =
      hoveredNode &&
      !nodes.some((node) => node.id === hoveredNode.id && node.selected)

    // if the hovered node is in the selection then keep the selection
    // otherwise select only the hovered node
    if (isNodeHoveredAndNotSelected) {
      for (const node of nodes) {
        node.selected = node.id === hoveredNode.id
      }
      reactFlowInstance.setNodes(nodes)
    }
  }

  return { updateNodeSelection }
}
