import { type FunctionComponent } from 'react'
import ReactFlow, { Background, BackgroundVariant } from 'reactflow'

import usePopOverContext from 'components/PopOverMenu/usePopOverContext'
import { useUserPreference } from 'components/UserPreferenceProvider/useUserPreference'

import { FlowConnectionLine } from 'modules/Canvas/components/FlowCanvas/components/FlowConnectionLine'
import { FlowEdge } from 'modules/Canvas/components/FlowCanvas/components/FlowEdge'
import { FlowMarkers } from 'modules/Canvas/components/FlowCanvas/components/FlowMarkers'
import classes from 'modules/Canvas/components/FlowCanvas/FlowCanvas.module.scss'
import { useSyncedCanvasModel } from 'modules/Canvas/components/FlowCanvas/hooks/useSyncedCanvasModel'

import { EtlCanvasNodeType } from '../../hooks/useCanvasModel/useCanvasModel'
import { AddNextComponent } from '../AddNextComponent/AddNextComponent'
import { useCanvasHandlers } from './hooks/useCanvasHandlers'
import { EtlNode } from './nodes/EtlNode'
import { IteratorNode } from './nodes/IteratorNode'
import { NoteNode } from './nodes/NoteNode'
import { type FlowCanvasProps } from './types'

export const successFailNode = 'success-fail-node'
export const unconditionalNode = 'unconditional-node'
export const connectorNode = 'connect-node'
export const conditionalNode = 'conditional-node'

const EDGE_TYPES = {
  default: FlowEdge
}

const NODE_TYPES = {
  [EtlCanvasNodeType.NODE]: EtlNode,
  [EtlCanvasNodeType.ITERATOR]: IteratorNode,
  [EtlCanvasNodeType.NOTE]: NoteNode
}

const DELETE_KEYCODES = ['Backspace', 'Delete']

const FlowCanvas: FunctionComponent<FlowCanvasProps> = ({ job }) => {
  const { canvasModel, syncCanvasModel } = useSyncedCanvasModel(job)
  const { nodes, edges } = canvasModel
  const canvasHandlers = useCanvasHandlers(nodes, syncCanvasModel)
  const { setHoveredNode } = usePopOverContext()

  const { userPreference } = useUserPreference()
  return (
    <ReactFlow
      defaultNodes={[...nodes.values()]}
      defaultEdges={[...edges.values()]}
      edgeTypes={EDGE_TYPES}
      nodeTypes={NODE_TYPES}
      connectionLineComponent={FlowConnectionLine}
      connectionRadius={0}
      elevateEdgesOnSelect
      selectNodesOnDrag={false}
      minZoom={0.25}
      maxZoom={4}
      zoomOnDoubleClick={false}
      deleteKeyCode={DELETE_KEYCODES}
      fitView
      fitViewOptions={{
        padding: 0.1,
        minZoom: 0.25,
        maxZoom: 1
      }}
      proOptions={{ hideAttribution: true }}
      snapToGrid={userPreference.snapToGridEnabled}
      selectionKeyCode={['Meta', 'Shift']}
      multiSelectionKeyCode={['Meta', 'Shift']}
      nodeDragThreshold={1}
      onNodeMouseEnter={(_, node) => {
        setHoveredNode(node)
      }}
      onNodeMouseLeave={() => {
        setHoveredNode(undefined)
      }}
      {...canvasHandlers}
    >
      {nodes.size === 0 && (
        <div className={classes.FlowContainer}>
          <AddNextComponent />
        </div>
      )}

      <FlowMarkers />

      <Background variant={BackgroundVariant.Dots} size={4} color="#ddd" />
    </ReactFlow>
  )
}

export { FlowCanvas }
