import { useCallback } from 'react'
import { type GetRepositoriesArgs, type GetRepositoriesResponse } from './types'
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'api/query-keys'
import { type ExternalGitProvider } from 'types/ExternalGitProvider'
import useGitClient from 'api/clients/useGitClient'
import useGetProviderDisplayName from 'modules/BringYourOwnGit/hooks/useGetProviderDisplayName'

export const useGetRepositoriesQueryKey = (provider?: ExternalGitProvider) => {
  return [queryKeys.getRepositories, provider]
}

const useGetRepositories = ({
  provider,
  enabled
}: GetRepositoriesArgs = {}) => {
  const client = useGitClient()
  const { providerName } = useGetProviderDisplayName(provider)

  const getRepositories = useCallback(async () => {
    const url = `/providers/${provider}/repositories`
    const { data } = await client.get<GetRepositoriesResponse>(url)
    return data
  }, [client, provider])

  const queryKey = useGetRepositoriesQueryKey(provider)

  return useQuery(queryKey, getRepositories, {
    enabled,
    meta: {
      error: {
        title: 'api.toast.get-repositories.title',
        message: 'api.toast.get-repositories.message',
        args: [
          {
            name: 'provider',
            value: providerName
          }
        ]
      }
    }
  })
}

export default useGetRepositories
