import { Panel, PanelGroup } from 'react-resizable-panels'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { GitEventProvider } from '@matillion/git-component-library'

import { Orientation, ResizeableHandler } from 'components/ResizeableHandler'

import { useFlags } from 'hooks/useFlags'

import { AgentStatus } from 'modules/core/AgentStatus'
import { ComponentValidationProvider } from 'modules/core/ComponentValidation'
import { PipelineAccessHistoryProvider } from 'modules/hub/PipelineAccessHistory/PipelineAccessHistoryProvider'
import { JobTabs } from 'modules/JobTabs'
import { ManageVariables } from 'modules/ManageVariables/ManageVariables'

import { Footer } from './components/Footer/Footer'
import HeaderBar from './components/HeaderBar/HeaderBar'
import SideBar from './components/SideBar/SideBar'
import { Taskbar } from './components/Taskbar/Taskbar'
import WorkingCopyProvider from './components/WorkingCopyProvider'
import classes from './EtlDesigner.module.scss'
import useRefreshPipelines from './hooks/useRefreshPipelines'
import { UndoRedoCapture } from './UndoRedoCapture'

const EtlDesigner = () => {
  const {
    enableAgentAvailabilityModal,
    enableWizardUiForComponents,
    enableRecentlyAccessedPipelines
  } = useFlags()
  const { refreshPipelines } = useRefreshPipelines()
  const navigate = useNavigate()
  const location = useLocation()
  const isEditPanelVisible = location.state?.isEditPanelVisible

  const children = (
    <>
      <HeaderBar />

      <div className={classes.ApplicationContent}>
        <PanelGroup direction="horizontal" autoSaveId="all-panels">
          <Panel
            defaultSize={15}
            minSize={5}
            maxSize={50}
            id="sidebar"
            order={1}
          >
            <SideBar />
          </Panel>
          <ResizeableHandler orientation={Orientation.VERTICAL} id="sidebar" />

          <Panel order={2}>
            <PanelGroup
              id="jobs-panel-group"
              direction={
                enableWizardUiForComponents ? 'horizontal' : 'vertical'
              }
              autoSaveId="canvas"
            >
              <Panel id="designer">
                <UndoRedoCapture className={classes.CanvasContainer}>
                  <JobTabs />
                  <Outlet />
                </UndoRedoCapture>
              </Panel>

              <ResizeableHandler
                orientation={
                  enableWizardUiForComponents
                    ? Orientation.VERTICAL
                    : Orientation.HORIZONTAL
                }
                id="footer"
              />
              <Panel defaultSize={35} maxSize={75} id="footer-container">
                <Footer />
              </Panel>
            </PanelGroup>
          </Panel>
          {isEditPanelVisible && (
            <>
              <ResizeableHandler
                orientation={Orientation.VERTICAL}
                id="right-panel"
                dragHandle
              />
              <Panel
                defaultSize={36}
                minSize={36}
                maxSize={60}
                className={classes.RightPanel}
                id="right-panel"
                order={3}
              >
                <div className={classes.RightPanel__Content}>
                  <ManageVariables
                    onCancel={() => {
                      navigate(location, {
                        replace: true,
                        state: {
                          ...location.state,
                          isEditPanelVisible: false
                        }
                      })
                    }}
                  />
                </div>
              </Panel>
            </>
          )}
        </PanelGroup>
      </div>

      {enableWizardUiForComponents && <Taskbar />}

      {enableAgentAvailabilityModal && <AgentStatus />}
    </>
  )

  return (
    <GitEventProvider onRefreshWorkingTreeStatus={refreshPipelines}>
      <WorkingCopyProvider>
        <ComponentValidationProvider>
          {enableRecentlyAccessedPipelines ? (
            <PipelineAccessHistoryProvider>
              {children}
            </PipelineAccessHistoryProvider>
          ) : (
            <>{children}</>
          )}
        </ComponentValidationProvider>
      </WorkingCopyProvider>
    </GitEventProvider>
  )
}
export default EtlDesigner
