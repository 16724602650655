import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'

import {
  SpringClientVersion,
  useSpringClient
} from '../useSpringClient/useSpringClient'

export interface StackTraceMessages {
  messages: StackTraceMessage[]
}

export interface StackTraceMessage {
  id: string
  dataplaneId: string
  detailedMessage: string
}

export const useAgentStackTraces = (workerRequestIds: string) => {
  const client = useSpringClient(SpringClientVersion.V1)

  const getAgentStackTraces = useCallback(async () => {
    const { data } = await client.get<StackTraceMessages>(
      `/tasks/traces?ids=${encodeURIComponent(workerRequestIds)}`
    )

    return data
  }, [client, workerRequestIds])

  return useQuery(['agentStackTraces', workerRequestIds], getAgentStackTraces)
}
