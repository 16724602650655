import { createContext } from 'react'
import { type EventContextBag, type WorkingTreeRefreshEvent } from './types'

export const EventContext = createContext<EventContextBag>({
  refreshWorkingTreeStatus: async (event: WorkingTreeRefreshEvent) => {
    console.debug(
      `Tried to invoke refreshWorkingTreeStatus(event: ${JSON.stringify(
        event
      )}), but the GCL EventContext was not initialised.`
    )
  }
})
