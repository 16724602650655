import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { type XYPosition } from 'reactflow'

import { jobActions } from 'job-lib/store'

export const DEFAULT_NOTE_WIDTH = 150
export const DEFAULT_NOTE_HEIGHT = 58

export const useMakeNote = () => {
  const dispatch = useDispatch()
  const makeNote = useCallback(
    (
      position: XYPosition | null,
      content?: string,
      width?: number,
      height?: number,
      isAIGenerated?: boolean,
      selection?: string[]
    ) => {
      if (!position) return

      dispatch(
        jobActions.addNote({
          position: {
            x: position.x,
            y: position.y
          },
          content: content ?? '',
          size: {
            width: width ?? DEFAULT_NOTE_WIDTH * 2,
            height: height ?? DEFAULT_NOTE_HEIGHT
          },
          theme: 'white',
          isAIGenerated,
          selection
        })
      )
    },
    [dispatch]
  )

  return { makeNote }
}
