// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.llEeqLgKMx9AJWtnr_7J{padding-top:60px}.ZN4ZrJfqj3S4Cc9BYBeZ{margin-bottom:20px}.ki67gqhokfvsNyrBTNWS{margin-bottom:20px}.dE0ztFKT7DCGO6fJb3KM{display:flex;justify-content:space-between;margin-bottom:-20px;padding-top:32px}`, "",{"version":3,"sources":["webpack://./src/components/PushChangesModal/PushChangesModal.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,YAAA,CACA,6BAAA,CACA,mBAAA,CACA,gBAAA","sourcesContent":[".modal {\n  padding-top: 60px;\n}\n\n.title {\n  margin-bottom: 20px;\n}\n\n.upToDateInfo {\n  margin-bottom: 20px;\n}\n\n.buttons {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: -20px;\n  padding-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `llEeqLgKMx9AJWtnr_7J`,
	"title": `ZN4ZrJfqj3S4Cc9BYBeZ`,
	"upToDateInfo": `ki67gqhokfvsNyrBTNWS`,
	"buttons": `dE0ztFKT7DCGO6fJb3KM`
};
export default ___CSS_LOADER_EXPORT___;
