import { type RequestHandler, rest } from 'msw'

import { PROJECT_ID } from '__test-utils__/ids'

import { type MergeRequest } from 'api/hooks/useMerge/types'
import { MockedMergeResponses } from 'api/hooks/useMerge/useMerge.responses'

export const stubMerge = (baseUrl = '', projectId = PROJECT_ID) => {
  return Object.keys(MockedMergeResponses).map((branchName) => {
    const url = `${baseUrl}/working-tree-service/v1/projects/${projectId}/branches/${branchName}/merges`

    return rest.post(url, async (req, res, ctx) => {
      const body: MergeRequest = await req.json()
      if (body.remoteCommitId === '500') {
        return res(ctx.status(500))
      } else {
        return res(ctx.status(MockedMergeResponses[branchName]))
      }
    })
  })
}

export const useMergeHandlers: RequestHandler[] = [...stubMerge()]
