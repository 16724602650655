import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '@matillion/component-library'

import EnvironmentDropdown from 'components/EnvironmentDropdown/EnvironmentDropdown'
import PublishJobsToggle from 'components/PublishJobsToggle/PublishJobsToggle'
import classes from './PublishJobsForm.module.scss'
import { type PublishJobsFormProps } from './types'

const PublishJobsForm = ({
  disabled,
  onChange,
  warningKey,
  unavailable,
  environments,
  onUnavailable
}: PublishJobsFormProps) => {
  const { t } = useTranslation()

  const [publish, setPublish] = useState(false)
  const [environment, setEnvironment] = useState<string | undefined>(undefined)
  const [hasClickedPublish, setHasClickedPublish] = useState(false)
  const [envsUnavailable, setEnvsUnavailable] = useState(false)

  useEffect(() => {
    onChange({ enabled: publish, environment })
  }, [publish, environment, onChange])

  const onEnvsUnavailable = useCallback(() => {
    setEnvsUnavailable(true)
    onUnavailable()
  }, [setEnvsUnavailable, onUnavailable])

  const onTogglePublish = useCallback(() => {
    setPublish(!publish)
    if (publish) {
      setEnvironment(undefined)
    }
  }, [setPublish, publish])

  const onClickPublishToggle = useCallback(() => {
    setHasClickedPublish(true)
  }, [setHasClickedPublish])

  return (
    <div data-testid="publish-jobs-form">
      <PublishJobsToggle
        onToggle={onTogglePublish}
        onClick={onClickPublishToggle}
        checked={publish && !envsUnavailable && !unavailable}
        disabled={(disabled ?? false) || unavailable || envsUnavailable}
      />

      {!unavailable && publish && (
        <EnvironmentDropdown
          onSelect={setEnvironment}
          environments={environments}
          disabled={disabled ?? false}
          onUnavailable={onEnvsUnavailable}
        />
      )}

      {unavailable && hasClickedPublish && (
        <Alert
          theme="dark"
          type="warning"
          message={t(warningKey)}
          className={classes.NotAvailableAlert}
          data-testid="not-default-branch-publish-help-alert"
        />
      )}
    </div>
  )
}

export default PublishJobsForm
