import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '../../queryKeys'
import { type FlowInstanceId } from '../useGetRunTasks/types'
import {
  SpringClientVersion,
  useSpringClient
} from '../useSpringClient/useSpringClient'
import { type JobStatsResponse } from './__types__/job-stats-response'
import { type JobStatsTreeResponse } from './types'
import { preprocessStrandStats } from './useGetStatsStrands'

export const preprocessJobStats = (
  job: JobStatsTreeResponse | undefined
): JobStatsTreeResponse | undefined => {
  if (!job) return undefined

  const { rootJob, flowInstanceId, ...rest } = job

  const safeRootJob = preprocessStrandStats(flowInstanceId, rootJob)
  const statsTree: JobStatsTreeResponse = {
    ...rest,
    flowInstanceId,
    rootJob: safeRootJob || rootJob
  }
  return statsTree
}

export const useGetStatsFlowInstance = (
  flowInstanceId: FlowInstanceId,
  identifier: string
) => {
  const client = useSpringClient(SpringClientVersion.V2)

  const tasks = useCallback(async () => {
    const { data } = await client.get<JobStatsResponse>(
      `tasks/${flowInstanceId}`
    )
    return preprocessJobStats(data.statsTree)
  }, [client, flowInstanceId])

  return useQuery<JobStatsTreeResponse | undefined>(
    [queryKeys.taskDetails, flowInstanceId, identifier],
    tasks
  )
}
