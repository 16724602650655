import React, { useCallback, useState, type PropsWithChildren } from 'react'

import { Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { useListProjects } from 'api/hooks/useListProjects'

import { ReactComponent as FolderIcon } from 'assets/root-folder.svg'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import MovePipelineModal from '../../commands/MovePipelineModal/MovePipelineModal'
import useMovePipeline from '../../hooks/useMovePipeline'
import PipelineFolderDropDestination from '../PipelineFolderDropDestination/PipelineFolderDropDestination'
import classes from './RootFolder.module.scss'

const RootFolder = ({ children }: PropsWithChildren) => {
  const { projectId } = useProjectInfo()
  const { data: projects } = useListProjects()
  const [isDropping, setIsDropping] = useState(false)
  const { movePipeline, onConfirmMove, showWarningModal, setShowWarningModal } =
    useMovePipeline()

  const currentProject = projects?.results.find(
    (project) => project.id === projectId
  )?.name

  const handleFolderLeave = useCallback(() => {
    setIsDropping(false)
  }, [])

  const handleFolderOver = useCallback(() => {
    setIsDropping(true)
  }, [])

  return (
    <>
      <PipelineFolderDropDestination
        id="root"
        onDropPipeline={movePipeline}
        onFolderEnter={handleFolderOver}
        onFolderLeave={handleFolderLeave}
      >
        <div
          data-testid="root-level-folder"
          className={classNames(classes.Wrapper, {
            [classes.Active]: isDropping
          })}
        >
          <FolderIcon className={classes.Icon} />

          <Typography format="bcs" className={classes.ProjectName}>
            {currentProject ?? '...'}
          </Typography>
        </div>
      </PipelineFolderDropDestination>

      {children}

      {showWarningModal && (
        <MovePipelineModal
          onConfirm={onConfirmMove}
          onClose={() => {
            setShowWarningModal(false)
          }}
        />
      )}
    </>
  )
}

export default RootFolder
