import { useGetComponentMetadata } from 'api/hooks/useGetComponentMetadata/useGetComponentMetadata'
import { useGetComponentSummary } from 'api/hooks/useGetComponentSummaries/useGetComponentSummary'
import { useGetProject } from 'api/hooks/useGetProject/useGetProject'

import { type ComponentInstanceId } from 'job-lib/types/Job'
import { type ParameterCollection } from 'job-lib/types/Parameters'

import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'

import { removeUnsupportedParametersAndReplaceSlot } from 'utils/removeUnsupportedParametersAndReplaceSlot'

export const useComponentInstanceMetadataQuery = (
  componentInstanceId?: ComponentInstanceId | null
) => {
  const { job, isError: isJobError, isLoading: isJobLoading } = useWorkingCopy()
  const project = useGetProject()

  const componentInstance =
    componentInstanceId != null ? job?.components?.[componentInstanceId] : null

  const {
    getByImplementationId,
    isLoading: isComponentSummaryLoading,
    isError: isComponentSummaryError
  } = useGetComponentSummary()
  const componentSummary = getByImplementationId(
    componentInstance?.implementationID?.toString() as string,
    componentInstance?.parameters as ParameterCollection
  )
  const {
    data: componentMetadata,
    isError: isMetadataError,
    isLoading: isMetadataLoading,
    isFetching: isMetadataFetching
  } = useGetComponentMetadata(componentSummary?.componentId)

  const isLoading =
    (isMetadataLoading && isMetadataFetching) ||
    isComponentSummaryLoading ||
    isJobLoading ||
    project.isLoading

  const isError =
    isMetadataError || isComponentSummaryError || isJobError || project.isError

  return {
    componentInstance,
    componentSummaryId: componentSummary?.componentId,
    metadata:
      project.data?.warehouse &&
      componentMetadata?.metadata &&
      removeUnsupportedParametersAndReplaceSlot(
        project.data.warehouse,
        componentMetadata.metadata
      ),
    isLoading,
    isError
  }
}
