import { gitComponentLibraryMswHandlers } from '@matillion/git-component-library'

import { useGeneratePipelineDocsHandler } from 'api/hooks/sidekick/useGeneratePipelineDocs/__handlers__/useGeneratePipelineDocs.handlers'
import { useAgentDetailsHandlers } from 'api/hooks/useAgentDetails/__handlers__/useAgentDetails.handlers'
import { useCreateJobHandlers } from 'api/hooks/useCreateJob/__handlers__/useCreateJob.handlers'
import { useDeleteBranchHandlers } from 'api/hooks/useDeleteBranch/__handlers__/useDeleteBranch.handlers'
import { useGetComponentMetadataHandlers } from 'api/hooks/useGetComponentMetadata/__handlers__/useGetComponentMetadata.handlers'
import { useGetComponentSummariesHandlers } from 'api/hooks/useGetComponentSummaries/__handlers__/useGetComponentSummaries.handlers'
import { useGetJobHandlers } from 'api/hooks/useGetJob/__handlers__/useGetJob.handlers'
import { useGetJobSummariesHandlers } from 'api/hooks/useGetJobSummaries/__handlers__/useGetJobSummaries.handlers'
import { useGetParameterOptionsHandlers } from 'api/hooks/useGetParameterOptions/__handlers__/useGetParameterOptions.handlers'
import { useGetProjectHandlers } from 'api/hooks/useGetProject/__handlers__/useGetProject.handlers'
import { useGetRunTasksHandlers } from 'api/hooks/useGetRunTasks/__handlers__/useGetRunTasks.handlers'
import { useListBranchesHandlers } from 'api/hooks/useListBranches/__handlers__/useListBranches.handlers'
import { useListEnvironmentsHandlers } from 'api/hooks/useListEnvironments/__handlers__/useListEnvironments.handlers'
import { useListProjectsHandlers } from 'api/hooks/useListProjects/__handlers__/useListProjects.handlers'
import { useListProjectVariablesHandler } from 'api/hooks/useListProjectVariables/__handlers__/useProjectVariables.handlers'
import { usePublishJobsHandlers } from 'api/hooks/usePublishJobs/__handlers__/usePublishJobs.handlers'
import { useRunJobHandlers } from 'api/hooks/useRunJob/__handlers__/useRunJob.handlers'
import { useSampleComponentHandlers } from 'api/hooks/useSampleComponent/__handlers__/useSampleComponent.handlers'
import { useSaveJobHandlers } from 'api/hooks/useSaveJob/__handlers__/useSaveJob.handlers'
import { useValidateComponentHandlers } from 'api/hooks/useValidateComponent/__handlers__/useValidateComponent.handlers'

import config from 'config'

import { checkPermissionHandlers } from './external/checkPermission/__handlers__/checkPermission.handlers'

export const mswHandlers = [
  ...useGetComponentSummariesHandlers,
  ...useGetJobSummariesHandlers,
  ...useGetJobHandlers,
  ...useRunJobHandlers,
  ...useGetRunTasksHandlers,
  ...useGetComponentMetadataHandlers,
  ...useGetParameterOptionsHandlers,
  ...useListBranchesHandlers,
  ...useListEnvironmentsHandlers,
  ...useSaveJobHandlers,
  ...useCreateJobHandlers,
  ...useValidateComponentHandlers,
  ...usePublishJobsHandlers,
  ...useDeleteBranchHandlers,
  ...useSampleComponentHandlers,
  ...useListProjectVariablesHandler,
  ...useListProjectsHandlers,
  ...useGetProjectHandlers,
  ...useAgentDetailsHandlers,
  ...useGeneratePipelineDocsHandler,
  ...checkPermissionHandlers,
  ...gitComponentLibraryMswHandlers({
    designerBaseUrl: `${config.getApiBaseUrl()}/v1`
  })
]
