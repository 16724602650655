import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'

import { useSpringClient } from 'api/hooks/useSpringClient/useSpringClient'
import { queryKeys } from 'api/queryKeys'

import { useFlags } from 'hooks/useFlags'

import { type JobType } from 'job-lib/types/JobType'

import { useAgentDetails } from '../useAgentDetails/useAgentDetails'
import { type ProjectType } from '../useGetProject/types'
import useGetProject from '../useGetProject/useGetProject'
import {
  type ComponentSummaryResult,
  type ConnectorComponentSummary,
  type ConnectorComponentSummaryResult
} from './types'

export const getComponentSummariesQueryKey = (jobType?: JobType) => [
  queryKeys.componentSummaries,
  jobType
]

export const getConnectorComponentSummariesQueryKey = (jobType?: JobType) => [
  queryKeys.connectorComponentSummaries,
  jobType
]

export const useConnectorComponentSummaries = (jobType?: JobType) => {
  const client = useSpringClient()
  const project = useGetProject()
  const warehouse = project?.data?.warehouse
  const { data: agentDetails } = useAgentDetails()
  const agentVersion = agentDetails?.agentVersion ?? ''
  const { enableCustomConnectors } = useFlags()

  return useQuery(
    getConnectorComponentSummariesQueryKey(jobType),
    async () => {
      const { data } = await client.get<ConnectorComponentSummaryResult>(
        `/connector-components?agentVersion=${agentVersion}`
      )
      data.results.forEach((summary) => {
        summary.componentId = summary.connectorId
      })
      return getFilteredSummaries(
        data,
        warehouse,
        jobType
      ) as ConnectorComponentSummary[]
    },
    { enabled: !!warehouse && enableCustomConnectors && !!agentVersion }
  )
}

export const useConnectorComponentSummary = () => {
  const summaries = useConnectorComponentSummaries()?.data

  return {
    getSummary: useCallback(
      (componentId: string) =>
        summaries?.find((summary) => summary.componentId === componentId),
      [summaries]
    )
  }
}

/**
 * returns an object containing the component summaries indexed by emeraldId
 */
export const useStandardComponentSummaries = (jobType?: JobType) => {
  const client = useSpringClient()
  const project = useGetProject()
  const warehouse = project?.data?.warehouse
  const { data: agentDetails } = useAgentDetails()
  const agentVersion = agentDetails?.agentVersion ?? ''

  return useQuery(
    getComponentSummariesQueryKey(jobType),
    async () => {
      const { data } = await client.get<ComponentSummaryResult>(
        `/components?agentVersion=${agentVersion}`
      )

      const filteredComponentSummaries = getFilteredSummaries(
        data,
        warehouse,
        jobType
      )

      return filteredComponentSummaries
    },
    { enabled: !!warehouse && !!agentVersion }
  )
}

export const useComponentSummaries = (jobType?: JobType) => {
  const {
    data: standardSummaries = [],
    isLoading: isStandardLoading,
    isError: isStandardError,
    isSuccess: isStandardSuccess
  } = useStandardComponentSummaries(jobType)
  const {
    data: customSummaries = [],
    isLoading: isCustomLoading,
    isError: isCustomError,
    isSuccess: isCustomSuccess
  } = useConnectorComponentSummaries(jobType)

  const { enableCustomConnectors } = useFlags()

  return enableCustomConnectors
    ? {
        isLoading: isCustomLoading || isStandardLoading,
        isError: isStandardError || isCustomError,
        isSuccess: isStandardSuccess && isCustomSuccess,
        data: [...standardSummaries, ...customSummaries]
      }
    : {
        isLoading: isStandardLoading,
        isError: isStandardError,
        isSuccess: isStandardSuccess,
        data: standardSummaries
      }
}

export * from './types'
function getFilteredSummaries(
  data: ComponentSummaryResult,
  warehouse: ProjectType | undefined,
  jobType: JobType | undefined
) {
  return data.results.filter((component) => {
    const isSupportedByWarehouse =
      warehouse && component.dwhTypes
        ? component.dwhTypes.some(
            (wh) => wh.toLowerCase() === warehouse.toLowerCase()
          )
        : true
    const isCorrectJobType = jobType
      ? component.componentType === jobType
      : true

    return isCorrectJobType && isSupportedByWarehouse
  })
}
