import { rest } from 'msw'

import config from 'config'

import { mockComponentInfo } from './mockComponentInfo'
import { mockConnectorComponentInfo } from './mockConnectorComponentInfo'

export const useGetComponentSummariesHandlers = [
  rest.get(`${config.getApiBaseUrl()}/v1/components`, async (_req, res, ctx) =>
    res(ctx.json(mockComponentInfo))
  ),
  rest.get(
    `${config.getApiBaseUrl()}/v1/connector-components`,
    async (_req, res, ctx) => res(ctx.json(mockConnectorComponentInfo))
  )
]
