import { useTranslation } from 'react-i18next'
import { Button, Typography } from '@matillion/component-library'
import { type ExternalGitProvider } from 'types/ExternalGitProvider'
import useGetProviderDisplayName from 'modules/BringYourOwnGit/hooks/useGetProviderDisplayName'
import classes from '../AuthModalForm.module.scss'

export interface AuthModalLoadingStateProps {
  provider: ExternalGitProvider
  onAbandon: () => void
}

const AuthModalWaitingState = ({
  provider,
  onAbandon
}: AuthModalLoadingStateProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'auth.modal.waiting'
  })

  const { providerName } = useGetProviderDisplayName(provider)

  return (
    <div data-testid="git-auth-modal-waiting">
      <Typography
        className={classes.title}
        data-testid="git-auth-modal-title"
        id="git-auth-title"
        format="tm"
      >
        {t('title', { provider: providerName })}
      </Typography>

      <Typography
        className={classes.body}
        data-testid="git-auth-modal-body-1"
        format="bcm"
      >
        {t('body.first', { provider: providerName })}
      </Typography>

      <Typography
        className={classes.body}
        data-testid="git-auth-modal-body-2"
        format="bcm"
      >
        {t('body.second')}
      </Typography>

      <div className={classes.buttons}>
        <Button
          id="cancel"
          alt="danger"
          onClick={onAbandon}
          text={t('abandon')}
          className={classes.button}
          data-testid="git-auth-abandon"
        />
      </div>
    </div>
  )
}

export default AuthModalWaitingState
