import { Icon } from '@matillion/component-library'

import classes from './GitActionLoadingIcon.module.scss'

const GitActionLoadingIcon = () => {
  return (
    <span data-testid="git-action-loading-icon">
      <span className={classes.Stroke} />
      <Icon.Refresh className={classes.Refresh} />
    </span>
  )
}

export default GitActionLoadingIcon
