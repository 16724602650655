import { EventContext } from './EventContext'
import { type EventContextBag, type EventProviderProps } from './types'
import { type PropsWithChildren, useMemo } from 'react'

const EventProvider = ({
  children,
  onRefreshWorkingTreeStatus
}: PropsWithChildren<EventProviderProps>) => {
  const values: EventContextBag = useMemo(
    () => ({
      refreshWorkingTreeStatus: onRefreshWorkingTreeStatus
    }),
    [onRefreshWorkingTreeStatus]
  )

  return (
    <EventContext.Provider value={values}>{children}</EventContext.Provider>
  )
}

export default EventProvider
