import { type FC, type MouseEvent, type PropsWithChildren } from 'react'

import { Typography } from '@matillion/component-library'
import classnames from 'classnames'

import classes from './PopOverMenu.module.scss'
import usePopOverContext from './usePopOverContext'

interface PopOverItemProps extends PropsWithChildren {
  'data-testid'?: string
  'data-heapid'?: string
  'data-heap-prop-jobtype'?: string
  disabled?: boolean
  shortcut?: string
  onClick: (event: MouseEvent<HTMLButtonElement>) => void
}

export const PopOverItem: FC<PopOverItemProps> = ({
  'data-testid': dataTestid,
  'data-heapid': dataHeapid,
  'data-heap-prop-jobtype': dataHeapPropJobtype,
  children,
  disabled = false,
  onClick,
  shortcut
}) => {
  const { setIsOpen } = usePopOverContext()

  return (
    <li>
      <button
        disabled={disabled}
        data-testid={dataTestid}
        data-heapid={dataHeapid}
        data-heap-prop-jobtype={dataHeapPropJobtype}
        type="button"
        className={classnames(classes.PopOverItem, {
          [classes['PopOverItem--Disabled']]: disabled
        })}
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          onClick(event)
          setIsOpen(false)
        }}
      >
        <div className={classes.PopOverItem__Text}>
          <Typography
            format="bcs"
            as="span"
            className={classes.PopOverItem__Text}
          >
            {children}
          </Typography>
          {shortcut && (
            <Typography
              format="mc"
              as="span"
              className={classes.PopOverItem__Shortcut}
            >
              {shortcut}
            </Typography>
          )}
        </div>
      </button>
    </li>
  )
}
