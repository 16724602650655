import { type AxiosInstance } from 'axios'

export interface DeleteJobApi {
  client: AxiosInstance
  projectId: string
  branchId: string
  jobId: string
}

export const deleteJobApi = async ({
  client,
  projectId,
  branchId,
  jobId
}: DeleteJobApi) => {
  const { status } = await client.delete(
    `/projects/${projectId}/branches/${branchId}/jobs/${jobId}`
  )

  return {
    status
  }
}
