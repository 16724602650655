import { useCallback } from 'react'

import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import { type AxiosInstance } from 'axios'

import {
  useProjectInfo,
  type ProjectInfo
} from 'hooks/useProjectInfo/useProjectInfo'

import { queryKeys } from '../../queryKeys'
import { useAgentDetails } from '../useAgentDetails/useAgentDetails'
import {
  useConnectorComponentSummary,
  type ComponentSummaryId
} from '../useGetComponentSummaries'
import { useSpringClient } from '../useSpringClient/useSpringClient'
import {
  type ComponentMetadataResponse,
  type ConnectorComponentMetadataResponse
} from './types'

export const getComponentMetadataQueryKey = (
  { projectId, branchId, agentId }: ProjectInfo,
  id: ComponentSummaryId
) => [projectId, branchId, queryKeys.componentMetadata, id, agentId]

export const getConnectorComponentMetadataQueryKey = (
  { projectId, branchId, agentId }: ProjectInfo,
  id: ComponentSummaryId
) => [projectId, branchId, queryKeys.connectorComponentMetadata, id, agentId]

export const getComponentMetadata = async (
  client: AxiosInstance,
  cisId: ComponentSummaryId,
  agentVersion: string
) => {
  const { data } = await client.get<ComponentMetadataResponse>(
    `/components/${encodeURIComponent(cisId)}?agentVersion=${agentVersion}`
  )
  return data
}

export const getConnectorComponentMetadata = async (
  client: AxiosInstance,
  cisId: ComponentSummaryId,
  agentVersion: string
): Promise<ConnectorComponentMetadataResponse> => {
  const { data } = await client.get<ConnectorComponentMetadataResponse>(
    `/connector-components/${encodeURIComponent(
      cisId
    )}?agentVersion=${agentVersion}`
  )

  return data
}

const useComponentMetadataQueryDefinition = () => {
  const client = useSpringClient()
  const { getSummary } = useConnectorComponentSummary()
  const { data: agentDetails } = useAgentDetails()
  const projectInfo = useProjectInfo()
  const agentVersion = agentDetails?.agentVersion ?? ''

  return useCallback(
    (
      cisId?: ComponentSummaryId
    ): {
      enabled: boolean
      queryKey: string[]
      queryFn?: () => Promise<ComponentMetadataResponse>
    } => {
      if (!cisId) {
        return {
          enabled: false,
          queryKey: ['pending-component-metadata-query']
        }
      }

      const isCustomSummary = getSummary(cisId)

      if (isCustomSummary) {
        return {
          enabled: !!agentVersion,
          queryKey: getConnectorComponentMetadataQueryKey(projectInfo, cisId),
          queryFn: async () => {
            return getConnectorComponentMetadata(client, cisId, agentVersion)
          }
        }
      }

      return {
        enabled: !!agentVersion,
        queryKey: getComponentMetadataQueryKey(projectInfo, cisId),
        queryFn: async () => {
          return getComponentMetadata(client, cisId, agentVersion)
        }
      }
    },
    [agentVersion, client, getSummary, projectInfo]
  )
}

export const useGetBulkComponentMetadata = (cisIds: ComponentSummaryId[]) => {
  const getComponentMetadataQueryDefinition =
    useComponentMetadataQueryDefinition()

  return useQueries({
    queries: cisIds.map(getComponentMetadataQueryDefinition)
  })
}

export const useFetchComponentMetadata = () => {
  const queryClient = useQueryClient()
  const getComponentMetadataQueryDefinition =
    useComponentMetadataQueryDefinition()

  return useCallback(
    async (componentId: string) => {
      return queryClient.fetchQuery(
        getComponentMetadataQueryDefinition(componentId)
      )
    },
    [getComponentMetadataQueryDefinition, queryClient]
  )
}

export const useGetComponentMetadata = (cisId?: ComponentSummaryId) => {
  const getComponentMetadataQueryDefinition =
    useComponentMetadataQueryDefinition()

  return useQuery(getComponentMetadataQueryDefinition(cisId))
}
