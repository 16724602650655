import { type Dispatch } from 'react'

import { type JobSummary } from 'api/hooks/useGetJobSummaries'

import { type JobType } from 'job-lib/types/JobType'

import {
  type PipelineTree,
  type PipelineTreeAction
} from '../reducers/pipelineTreeReducer/types'

export enum PipelineBrowserCommand {
  RESET,
  DELETE_PIPELINE,
  CREATE_PIPELINE,
  CREATE_FOLDER,
  RENAME_PIPELINE
}

export interface PipelineBrowserContextBag {
  onCommand: Dispatch<PipelineBrowserCommandAction>
  onPipelineTreeCommand: Dispatch<PipelineTreeAction>
  pipelineTree: PipelineTree
}

export interface PipelineBrowserCommandState {
  jobSummary: JobSummary | null
  jobType: JobType | null
  isDeletingPipeline: boolean
  isCreatingPipeline: boolean
  isRenamingPipeline: boolean
}

export interface PipelineBrowserCommandAction {
  type: PipelineBrowserCommand
  payload: JobSummary | JobSummary[] | JobType | null
}
