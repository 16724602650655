import {
  HeaderMenuItemGroup,
  PlatformMenu,
  PlatformSubMenu
} from '@matillion/component-library'

import usePlatformMenuStructure from './effects/usePlatformMenuStructure'

import OldPlatformMenu from './OldPlatformMenu'
import HubPlatformMenuServiceItem from './HubPlatformMenuServiceItem'
import HubPlatformSubMenuItem from './HubPlatformSubMenuItem'
import useFlags from '../../effects/useFlags'

const HubPlatformMenu = () => {
  const [{ services, subMenus }] = usePlatformMenuStructure()
  const { enableUpdatedPlatformMenu } = useFlags()

  const buildUrlWithoutSubdomain = (parent: string) => {
    // split returns a value not a reference so shift doesn't affect the original source
    const splitHost = window.location.hostname.split('.')
    splitHost.shift()
    const builtUrl =
      parent === 'Manage'
        ? `https://${splitHost.join('.')}`
        : `https://${window.location.hostname}`

    return builtUrl
  }

  return enableUpdatedPlatformMenu ? (
    <PlatformMenu data-testid="hub-platform-menu" newDesignEnabled>
      <HeaderMenuItemGroup>
        {services.map((service) => (
          <HubPlatformMenuServiceItem
            key={service.title}
            serviceKey={service.key}
            name={service.title}
            description={service.description}
          />
        ))}
      </HeaderMenuItemGroup>
      <HeaderMenuItemGroup>
        {subMenus?.map((subMenu) => (
          <PlatformSubMenu
            key={subMenu.title}
            title={subMenu.title}
            description={subMenu.description}
            active={subMenu.options.find(
              (option) => option.url === buildUrlWithoutSubdomain(subMenu.title)
            )}
          >
            {subMenu.options.map((option) => (
              <HubPlatformSubMenuItem
                key={option.title}
                serviceKey={option.key}
                url={option.url}
                description={option.description}
                title={option.title}
                active={option.url === buildUrlWithoutSubdomain(subMenu.title)}
              />
            ))}
          </PlatformSubMenu>
        ))}
      </HeaderMenuItemGroup>
    </PlatformMenu>
  ) : (
    <OldPlatformMenu />
  )
}

export default HubPlatformMenu
