import {
  type PipelineNode,
  type PipelineTree as PipelineTreeData
} from '../../reducers/pipelineTreeReducer/types'
import PipelineFolder from '../PipelineFolder/PipelineFolder'
import PipelineItem from '../PipelineItem/PipelineItem'

interface PipelineTreeProps {
  data: PipelineTreeData
}

const PipelineTree = ({ data }: PipelineTreeProps) => {
  const extractFolderPathFromPipelinePath = (path: string): string => {
    const segments = path.split('/')
    const pathSegments = segments.slice(0, segments.length - 1)
    return pathSegments.join('/')
  }

  return (
    <div>
      {data.map((node: PipelineNode) => {
        if (node.type === 'pipeline' && node.pipeline) {
          return (
            <PipelineItem
              isLeaf={!node.root}
              pipeline={node.pipeline}
              key={node.pipeline.jobId}
              path={extractFolderPathFromPipelinePath(node.path)}
            />
          )
        }

        return (
          <PipelineFolder id={node.path} key={node.path} name={node.name}>
            <PipelineTree data={node.children} />
          </PipelineFolder>
        )
      })}
    </div>
  )
}

export default PipelineTree
