import {
  ComponentEditorTabType,
  EditorType,
  ExportVariableType,
  LookUpType,
  ParameterDataType,
  UseVariable,
  VisibleWhenOperator,
  type ComponentMetadataResponse
} from 'api/hooks/useGetComponentMetadata/types'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

export const mockS3LoadMetadata: ComponentMetadataResponse = {
  componentId: 's3-load',
  metadata: {
    schemaVersion: 1,
    emeraldId: '773867971',
    classification: 'EMERALD',
    componentType: JobType.Orchestration,
    inputPorts: [
      { portId: ConnectionPortType.INPUT, cardinality: Cardinality.ONE }
    ],
    outputPorts: [
      { portId: ConnectionPortType.SUCCESS, cardinality: Cardinality.MANY },
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      },
      { portId: ConnectionPortType.FAILURE, cardinality: Cardinality.MANY }
    ],
    deprecated: false,
    exports: [
      ExportVariableType.COMPLETED_AT,
      ExportVariableType.COMPONENT_NAME,
      ExportVariableType.DURATION,
      ExportVariableType.MESSAGE,
      ExportVariableType.ROW_COUNT,
      ExportVariableType.STARTED_AT,
      ExportVariableType.STATUS
    ],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.EXPORT,
      ComponentEditorTabType.SQL,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'stage',
        metlSlot: 200,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'authentication',
        metlSlot: 143,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'stage',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'storageIntegration',
        metlSlot: 144,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'authentication',
            value: 'Storage Integration',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 's3ObjectPrefix',
        metlSlot: 4,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.S3_URL,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'stage',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'pattern',
        metlSlot: 201,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'encryption',
        metlSlot: 140,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'stage',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'masterKey',
        metlSlot: 141,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'encryption',
            value: 'Client Side Encryption',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'stage',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'kmsKeyId',
        metlSlot: 142,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'encryption',
            value: 'KMS Encryption',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'stage',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'warehouse',
        metlSlot: 5,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'database',
        metlSlot: 6,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'schema',
        metlSlot: 7,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'targetTable',
        metlSlot: 8,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'loadColumns',
        metlSlot: 9,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.MULTI_OPTION_SELECTOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'matchByColumnName',
            value: 'None',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'format',
        metlSlot: 10,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'fileType',
        metlSlot: 11,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'format',
            value: '[Custom]',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'compression',
        metlSlot: 12,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'ORC',
            operator: VisibleWhenOperator.NOT_EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'recordDelimiter',
        metlSlot: 20,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'fileType',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'fieldDelimiter',
        metlSlot: 21,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'fileType',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'skipHeader',
        metlSlot: 22,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'fileType',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'skipBlankLines',
        metlSlot: 35,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'dateFormat',
        metlSlot: 23,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'fileType',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'timeFormat',
        metlSlot: 24,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'fileType',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'timestampFormat',
        metlSlot: 25,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'fileType',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'escape',
        metlSlot: 27,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'fileType',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'escapeUnenclosedField',
        metlSlot: 28,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'fileType',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'trimSpace',
        metlSlot: 29,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'fieldOptionallyEnclosed',
        metlSlot: 30,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'fileType',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'nullIf',
        metlSlot: 31,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'errorOnColumnCountMismatch',
        metlSlot: 32,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'emptyFieldAsNull',
        metlSlot: 33,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'replaceInvalidCharacters',
        metlSlot: 36,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'encodingType',
        metlSlot: 34,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'CSV',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'enableOctal',
        metlSlot: 40,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'JSON',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'allowDuplicates',
        metlSlot: 41,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'JSON',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'stripOuterArray',
        metlSlot: 42,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'JSON',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'stripNullValues',
        metlSlot: 43,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'JSON',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'ignoreUtf8Errors',
        metlSlot: 44,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'JSON',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'nestColumns',
        metlSlot: 45,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: null,
            value: null,
            operator: VisibleWhenOperator.NEVER
          },
          {
            param: 'fileType',
            value: 'JSON',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'nullIf1',
        metlSlot: 46,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'JSON',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'trimSpace1',
        metlSlot: 47,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'JSON',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'nullIf2',
        metlSlot: 50,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'AVRO',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'trimSpace2',
        metlSlot: 51,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'AVRO',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'nullIf4',
        metlSlot: 70,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'ORC',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'trimSpace4',
        metlSlot: 71,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'ORC',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'nullIf3',
        metlSlot: 66,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'PARQUET',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'trimSpace3',
        metlSlot: 67,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'PARQUET',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'ignoreUtf8Errors1',
        metlSlot: 60,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'XML',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'preserveSpace',
        metlSlot: 61,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'XML',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'stripOuterElement',
        metlSlot: 62,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'XML',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'disableSnowflakeData',
        metlSlot: 63,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'XML',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'disableAutoConvert',
        metlSlot: 64,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'fileType',
            value: 'XML',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'onError',
        metlSlot: 120,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'n',
        metlSlot: 121,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'onError',
            value: ['Skip File When n Errors', 'Skip File When n% Errors'],
            operator: VisibleWhenOperator.IN
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'sizeLimitB',
        metlSlot: 122,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'purgeFiles',
        metlSlot: 123,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'matchByColumnName',
        metlSlot: 126,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'truncateColumns',
        metlSlot: 124,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'forceLoad',
        metlSlot: 125,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      }
    ]
  }
}
