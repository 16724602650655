import {
  ComponentEditorTabType,
  EditorType,
  LookUpType,
  ParameterDataType,
  UseVariable,
  type ComponentMetadataResponse
} from 'api/hooks/useGetComponentMetadata/types'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

export const mockMultiTableInputMetadata: ComponentMetadataResponse = {
  componentId: 'multi-table-input',
  metadata: {
    schemaVersion: 1,
    emeraldId: '-2009723820',
    componentType: JobType.Transformation,
    classification: 'TRANSFORMATION',
    inputPorts: [],
    outputPorts: [
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      }
    ],
    deprecated: false,
    exports: [],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.SAMPLE,
      ComponentEditorTabType.METADATA,
      ComponentEditorTabType.SQL,
      ComponentEditorTabType.PLAN,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'patternType',
        metlSlot: 4,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            type: 'TEXT',
            options: ['ILike', 'Like', 'Regex'],
            columnEditorType: null,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: 'Like'
      },
      {
        dplID: 'database',
        metlSlot: 5,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_DWH_DATABASES,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: '[Environment Default]'
      },
      {
        dplID: 'schema',
        metlSlot: 6,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_DWH_SCHEMAS,
        lookupDependencies: ['param.database'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: '[Environment Default]'
      },
      {
        dplID: 'pattern',
        metlSlot: 7,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'castTypes',
        metlSlot: 9,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.BOOLEAN,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            type: 'BOOLEAN',
            options: ['False', 'True'],
            columnEditorType: null,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: 'False'
      },
      {
        dplID: 'addSourceTable',
        metlSlot: 10,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.BOOLEAN,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            type: 'BOOLEAN',
            options: ['False', 'True'],
            columnEditorType: null,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: 'False'
      },
      {
        dplID: 'columns',
        metlSlot: 8,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.MULTI_OPTION_SELECTOR,
        lookupType: LookUpType.DYNAMIC_MULTI_TABLE_INPUT_COLUMNS,
        lookupDependencies: [
          'param.pattern-type',
          'param.database',
          'param.schema',
          'param.pattern'
        ],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      }
    ]
  }
}
