import { Typography, Tooltip } from '@matillion/component-library'

import { JobTypeIcon } from 'components/JobTypeIcon'

import classes from './JobInformation.module.scss'
import { JobType } from 'types/JobType'
import { type JobInformationProps } from './types'
import { useMemo } from 'react'

const JobInformation = ({ name }: JobInformationProps) => {
  const { fileName, jobType } = useMemo(() => {
    // DPL Format
    if (name.endsWith('.yaml')) {
      const [dplFileName, type] = name.split('.')
      const jobTypeValue =
        type === 'orch' ? JobType.Orchestration : JobType.Transformation
      return {
        fileName: dplFileName,
        jobType: jobTypeValue
      }
    }

    // Legacy Format
    const [legacyFileName, extension] = name.split('.')
    return {
      fileName: legacyFileName,
      jobType: extension as JobType
    }
  }, [name])

  return (
    <div className={classes.wrapper}>
      <span className={classes.Icon}>
        <JobTypeIcon jobType={jobType} />
      </span>

      <Tooltip content={fileName} onlyShowOnOverflow>
        <div className={classes.textContainer}>
          <Typography as="span" format="bcm">
            {fileName}
          </Typography>
        </div>
      </Tooltip>
    </div>
  )
}

export default JobInformation
