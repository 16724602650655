import {
  BRANCH_BEHIND_NO_CONFLICTS,
  BRANCH_BEHIND_WITH_CONFLICTS,
  BRANCH_ID,
  BRANCH_NAME
} from '__test-utils__/ids'

import { type GetBranchMetadataResponse } from 'api/hooks/useGetBranch/types'

export const MockedGetBranchInformation: Record<
  string,
  GetBranchMetadataResponse
> = {
  [BRANCH_ID]: {
    commitId: '197b33fb8e170dc88d6a7674fb1dd975c68fae5c',
    commitMessage:
      'here is such a good description. \n I am good at commit messages',
    dateTime: '2022-05-10T15:18:32Z'
  },
  main: {
    commitId: '579435ab17bf62fb92df6a92c2b964b58efdba53',
    commitMessage:
      'feat(ETLD-1020): Merged feature/ETLD-1020 into main. Approved by Tom.',
    dateTime: '2023-11-01T15:23:30Z'
  },
  BranchNoConflicts: {
    commitId: 'a94a8fe5ccb19ba61c4c0873d391e987982fbbd3',
    commitMessage: 'Here is a branch that has no conflicts upon merging',
    dateTime: '2022-12-25T15:18:32Z'
  },
  BranchWithConflicts: {
    commitId: '6d3c60eeb2ddd9cce8de6c092c091ae23ffd2264',
    commitMessage: 'Here is a branch with a few conflicts upon merging',
    dateTime: '2022-11-20T15:18:32Z'
  },
  BranchManyConflicts: {
    commitId: 'da39a3ee5e6b4b0d3255bfef95601890afd80709',
    commitMessage: 'Here is a branch that has many conflicts upon merging',
    dateTime: '2023-01-12T16:10:32Z'
  },
  BranchFullOffset: {
    commitId: '1f39000fd3ba5a048c30b4252a9cc071c0c3b392',
    commitMessage:
      'feat(ETLD-1017): a commit whose timestamp has a full -hh:mm:ss offset',
    dateTime: '2022-11-19T15:18:32-02:15:10' // Should add 2h 15m to our local TZ to compensate
  },
  BranchPartialOffset: {
    commitId: 'df8abcc7d6244b112c2016a617140356c99434d3',
    commitMessage:
      'feat(ETLD-1017): a commit whose timestamp has an +hh:mm offset',
    dateTime: '2022-11-18T15:18:32+01:30' // Should subtract 1h 30m from our local TZ to compensate
  },
  BranchNoOffset: {
    commitId: 'df8abcc7d6244b112c2016a617140356c99434d3',
    commitMessage: 'feat(ETLD-1017): a commit whose timestamp is UTC',
    dateTime: '2022-11-18T15:18:32Z' // Has zero offset from UTC, so should not compensate
  },
  [BRANCH_NAME]: {
    commitId: 'df8abcc7d6244b112c2016a617140356c99434d3',
    commitMessage: 'feat(ETLD-1017): an example commit message.',
    dateTime: '2022-11-25T15:18:32Z'
  },
  [BRANCH_BEHIND_NO_CONFLICTS]: {
    commitId: 'df8abcc7d6244b112c2016a617140356c99434d3',
    commitMessage: 'example commit message',
    dateTime: '2022-05-10T15:18:32Z'
  },
  [BRANCH_BEHIND_WITH_CONFLICTS]: {
    commitId: 'df8abcc7d6244b112c2016a617140356c99434d3',
    commitMessage: 'example commit message',
    dateTime: '2022-05-10T15:18:32Z'
  }
}

export const MockedDifferentGetBranchInformation: Record<
  string,
  GetBranchMetadataResponse
> = {
  ...MockedGetBranchInformation, // <-- All other branches should have the same commit ID (not-behind)
  [BRANCH_BEHIND_NO_CONFLICTS]: {
    commitId: '90edb29efcf90ea0dd45fd724cf73f235ea3fb5a', // <-- Differs from the other record
    commitMessage: 'example commit message',
    dateTime: '2022-05-10T15:18:32Z'
  },
  [BRANCH_BEHIND_WITH_CONFLICTS]: {
    commitId: '90edb29efcf90ea0dd45fd724cf73f235ea3fb5a', // <-- Differs from the other record
    commitMessage: 'example commit message',
    dateTime: '2022-05-10T15:18:32Z'
  }
}
