import { type RequestHandler, rest } from 'msw'

import { PROJECT_ID } from '__test-utils__/ids'

import { MockedHardResetBranchResponses } from 'api/hooks/useDeleteBranch/useDeleteBranch.responses'

export const stubHardResetBranch = (baseUrl = '', projectId = PROJECT_ID) => {
  return Object.keys(MockedHardResetBranchResponses).map((branchName) => {
    const url = `${baseUrl}/working-tree-service/v1/projects/${projectId}/branches/${branchName}`
    return rest.delete(url, async (_req, res, ctx) => {
      const responseStatus = MockedHardResetBranchResponses[branchName]
      if (responseStatus === 500) {
        return res(ctx.status(500))
      }
      return res(ctx.json<number>(responseStatus))
    })
  })
}

export const useHardResetBranchHandlers: RequestHandler[] = [
  ...stubHardResetBranch(),
  ...stubHardResetBranch('', 'test-project')
]
