import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useTaskStatus } from 'modules/observability/PipelineRunExplorer/hooks/useTaskStatus'

import TreeGrid from './components/TreeGrid'
import EmptyFlowStats from './EmptyFlowExplorer'
import classes from './FlowStatsExplorer.module.scss'
import { LoadingFlowExplorer } from './LoadingFlowExplorer'

const FlowStatsExplorer = () => {
  const { flowInstanceId } = useProjectInfo()
  const { tasks, completed } = useTaskStatus()
  const { isLoading } = completed

  const taskStatus = tasks.find((e) => e.id.toString() === flowInstanceId)

  if (isLoading) return <LoadingFlowExplorer />

  if (!taskStatus) return <EmptyFlowStats />

  return (
    <div className={classes.Container}>
      <TreeGrid task={taskStatus} />
    </div>
  )
}

export default FlowStatsExplorer
