export const queryKeys = {
  legacyMerge: 'legacyMerge',
  getWorkingTreeBranch: 'getWorkingTreeBranch',
  getBranch: 'getBranch',
  legacyDescribeMerge: 'legacyDescribeMerge',
  getBranches: 'getBranches',
  merge: 'merge',
  describeMerge: 'describeMerge',
  push: 'push',
  delete: 'delete',
  getRepositories: 'getRepositories',
  initOAuth: 'initOAuth',
  captureOAuthToken: 'captureOAuthToken',
  entitlements: 'entitlements',
  commit: 'commit'
}
