import { BRANCH_ID } from '__test-utils__/ids'

import { type ProblemDetails } from 'types/ProblemDetails'
import { type CommitResponse } from 'api/hooks/useCommit/types'
import { Problem } from 'hooks/useProblemDetails/Problem'

const problemDetailBody = (type: string, status: number): ProblemDetails => {
  return {
    type,
    title: 'Some title',
    detail: 'Some message',
    status
  }
}

export const MockCommitResponses: Record<string, CommitResponse> = {
  [BRANCH_ID]: {
    commitId: 'YourCommitID'
  },
  main: {
    commitId: '3cf22a06f0fa3dc6a1304b02485c1c8501e3aba7'
  },
  BranchNoConflicts: {
    commitId: '3cf22a06f0fa3dc6a1304b02485c1c8501e3aba7'
  },
  BranchWithConflicts: {
    commitId: '3cf22a06f0fa3dc6a1304b02485c1c8501e3aba7'
  }
}

export const MockCommitErrorResponse: Record<string, ProblemDetails> = {
  '400-commit-message-length': problemDetailBody(
    Problem.WTS_COMMIT_MESSAGE_TOO_LONG,
    400
  ),
  '400-no-changes': problemDetailBody(Problem.WTS_NO_CHANGES_TO_COMMIT, 400),
  '409-branch-behind': problemDetailBody(Problem.WTS_LOCAL_BRANCH_BEHIND, 409)
}
