import { useEffect, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@matillion/component-library'

import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'
import { type JobSummary } from 'api/hooks/useGetJobSummaries'
import useGetProject from 'api/hooks/useGetProject/useGetProject'
import useRunJob from 'api/hooks/useRunJob/useRunJob'

import { ReactComponent as ExecutingOrchestrationSvg } from 'assets/canvas/executing-orchestration.svg'
import { ReactComponent as ExecutingTransformationSvg } from 'assets/canvas/executing-transformation.svg'

import { IconButton } from 'components/IconButton/IconButton'

import { JobType } from 'job-lib/types/JobType'

import { ToolboxButton } from './ToolboxButton'

interface RunButtonProps {
  summary: JobSummary
  context: {
    context: string
  }
  showExpanded: boolean
}

export const RunButton: FC<RunButtonProps> = ({
  summary,
  context,
  showExpanded
}) => {
  const { t } = useTranslation()
  const project = useGetProject()
  const warehouse = project?.data?.warehouse?.toUpperCase() ?? ''

  const { mutate: onRunJob } = useRunJob({
    jobId: summary.jobId,
    warehouse
  })
  const [showInProgressSvg, setShowInProgressSvg] = useState(false)
  const { hasPermission: canRun } = useProjectPermission('run_pipelines')

  const ExecutingJobSvg =
    summary.type === JobType.Transformation
      ? ExecutingTransformationSvg
      : ExecutingOrchestrationSvg
  const onButtonClick = () => {
    if (!showExpanded) {
      triggerJobExecution()
    }
  }
  const triggerJobExecution = () => {
    setShowInProgressSvg(true)
    onRunJob()
  }

  useEffect(() => {
    let timeout: number

    if (showInProgressSvg) {
      timeout = window.setTimeout(() => {
        setShowInProgressSvg(false)
      }, 1000)
    }

    return () => {
      window.clearTimeout(timeout)
    }
  }, [showInProgressSvg])
  const buttonIcon = showInProgressSvg ? (
    <ExecutingJobSvg data-testid="executing-job-svg" aria-hidden />
  ) : (
    <Icon.Play />
  )
  const label = t('canvasToolbox.runJob')
  const shortcut = t('shortcuts.commandEnter', context)
  const disabled = showInProgressSvg || !canRun
  const dataTestId = 'canvas-toolbox-run-job'
  const button = (
    <div>
      <IconButton
        label={label}
        shortcut={shortcut}
        data-testid={dataTestId}
        data-heap-prop-jobtype={summary.type}
        data-heapid="etld_toolbox-run-job-cta_click"
        onClick={onButtonClick}
        disabled={disabled}
        hideTooltip={!canRun}
        align={'end'}
      >
        {buttonIcon}
      </IconButton>
    </div>
  )

  return (
    <ToolboxButton
      showExpanded={showExpanded}
      onClick={triggerJobExecution}
      button={button}
      buttonIcon={buttonIcon}
      text={t('canvasToolbox.buttons.run')}
      label={label}
      shortcut={shortcut}
      disabled={disabled}
      dataTestId={dataTestId}
    />
  )
}
