import { useApiContext } from 'api/context/useApiContext'
import useClient from '../useClient'

const useWorkingTreeClient = () => {
  const { wts } = useApiContext()

  return useClient({
    host: wts,
    contextUrl: '/working-tree-service/v1'
  })
}

export default useWorkingTreeClient
