import getHeapInstance from './getHeapInstance'

const identify = (identity: string) => {
  const heapInstance = getHeapInstance()
  if (!heapInstance) {
    console.debug('The Heap library has not been loaded.')
    return
  }

  try {
    heapInstance.identify(identity)
  } catch (e) {
    console.error('Could not identify with Heap!', e)
  }
}

const track = (event: string, properties?: Record<string, unknown>) => {
  const heapInstance = getHeapInstance()
  if (!heapInstance) {
    console.debug('The Heap library has not been loaded.')
    return
  }

  try {
    heapInstance.track(event, properties)
  } catch (e) {
    console.error('Could not send event to Heap!', e)
  }
}

export { getHeapInstance, identify, track }
