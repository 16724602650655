import ModalTitle from '../ModalTitle'
import classes from './PushChangesModal.module.scss'
import PublishJobsForm from '../PublishJobsForm'
import { Button } from '@matillion/component-library'
import useGitContext from '../../provider/GitProvider/useGitContext'
import { useCallback, useState } from 'react'
import usePushAction from '../../hooks/usePushAction'
import { useTranslation } from 'react-i18next'
import { type PublishToggleEvent } from '../PublishJobsForm/types'
import { type PushChangesModalProps } from './types'

const PushChangesForm = ({
  environments,
  onClose,
  onPublishPipelines
}: PushChangesModalProps) => {
  const { branch } = useGitContext()
  const [publish, setPublish] = useState(false)
  const [environment, setEnvironment] = useState<string>()
  const [envsUnavailable, setEnvsUnavailable] = useState(false)
  const { pushChanges, loading: pushLoading } = usePushAction()
  const isPublishValid =
    !publish || (publish && environment && !envsUnavailable)
  const { t } = useTranslation('translation', { keyPrefix: 'push.modal' })

  const handlePublishToggle = ({
    enabled,
    environment: newEnvironment
  }: PublishToggleEvent) => {
    setPublish(enabled)
    setEnvironment(newEnvironment)
  }

  const handleSubmit = async () => {
    const response = await pushChanges()

    if (publish && isPublishValid && environment && response && branch) {
      await onPublishPipelines({
        branch,
        environment,
        commitId: response.commitId
      })
    }

    onClose()
  }

  const handleEnvsUnavailable = useCallback(() => {
    setEnvsUnavailable(true)
  }, [])

  return (
    <>
      <ModalTitle
        title={t('title')}
        className={classes.title}
        titleId="push-modal-title"
        description={t('sub-title')}
        data-testId="push-modal-title"
      />

      <PublishJobsForm
        environments={environments}
        onChange={handlePublishToggle}
        unavailable={branch !== 'main'}
        onUnavailable={handleEnvsUnavailable}
        warningKey={t('not-main-branch-warning')}
      />

      <div className={classes.buttons}>
        <Button
          id="cancel"
          alt="secondary"
          onClick={onClose}
          text={t('cancel')}
          data-testid="push-modal-cancel"
        />

        <Button
          id="push"
          alt="positive"
          waiting={pushLoading}
          onClick={handleSubmit}
          disabled={!isPublishValid}
          data-testid="push-modal-submit"
          text={t(publish ? 'submit-alt' : 'submit')}
        />
      </div>
    </>
  )
}

export default PushChangesForm
