import { useMemo, type PropsWithChildren } from 'react'

import {
  GitProvider,
  type GitProviderProps
} from '@matillion/git-component-library'

import { useGetJobSummaries } from './api/hooks/useGetJobSummaries'
import { useSpringClient } from './api/hooks/useSpringClient/useSpringClient'
import config from './config'
import { useProjectInfo } from './hooks/useProjectInfo/useProjectInfo'

export const GitLibraryProvider = ({ children }: PropsWithChildren) => {
  const environment = config.environment
  const designerClient = useSpringClient()
  const { branchId, projectId } = useProjectInfo()
  const { data: jobSummaries } = useGetJobSummaries({ includeDeleted: true })

  const props: GitProviderProps = useMemo(
    () => ({
      branch: branchId,
      project: projectId,
      environment,
      designerClient,
      fileSummaries: jobSummaries?.results ?? [],
      isNativeGit: jobSummaries?.isUsingJGit ?? false
    }),
    [
      branchId,
      projectId,
      environment,
      designerClient,
      jobSummaries?.results,
      jobSummaries?.isUsingJGit
    ]
  )

  return <GitProvider {...props}>{children}</GitProvider>
}
