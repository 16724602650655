import { useRef, type FC, type PropsWithChildren } from 'react'

import { EtlComponent } from 'components/EtlComponent/EtlComponent'

import { useComponentInfo } from 'hooks/useComponentInfo/useComponentInfo'

import { type InitialParameterValues } from 'job-lib/builders/createComponent/types'

import classes from './ComponentDragSource.module.scss'
import {
  DATA_TRANSFER_KEY_DRAG_ORIGIN,
  DATA_TRANSFER_KEY_ID,
  DATA_TRANSFER_KEY_NAME,
  DATA_TRANSFER_KEY_PARAMETERS
} from './ComponentDropDestination'
import { PortalDiv } from './PortalDiv'

export interface ComponentDragSourceProps extends PropsWithChildren {
  componentId: string
  dragOrigin: string
  isIterator?: boolean
  imageUrl?: string
  name?: string
  initialParameters?: InitialParameterValues
}

export const ComponentDragSource: FC<ComponentDragSourceProps> = ({
  children,
  isIterator,
  componentId,
  name,
  dragOrigin,
  imageUrl,
  initialParameters = {}
}) => {
  const { getDisplayName } = useComponentInfo()
  const draggableEl = useRef<HTMLDivElement>(null)
  const displayName = getDisplayName(componentId)

  return (
    <>
      <PortalDiv
        className={classes.ComponentDragSource__Draggable}
        childRef={draggableEl}
        childTestId={`dragging-component-ui-node-${displayName}`}
      >
        <EtlComponent
          id={componentId}
          imageUrl={imageUrl}
          isIterator={isIterator}
          tooltipContent={displayName}
        >
          {displayName}
        </EtlComponent>
      </PortalDiv>

      <div
        tabIndex={-1}
        draggable="true"
        onDragStart={(evt) => {
          // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API
          evt.dataTransfer.setData(DATA_TRANSFER_KEY_ID, componentId)
          evt.dataTransfer.setData(
            DATA_TRANSFER_KEY_PARAMETERS,
            JSON.stringify(initialParameters)
          )
          evt.dataTransfer.setData(DATA_TRANSFER_KEY_DRAG_ORIGIN, dragOrigin)

          if (name) {
            evt.dataTransfer.setData(DATA_TRANSFER_KEY_NAME, name)
          }

          if (draggableEl.current) {
            evt.dataTransfer.setDragImage(draggableEl.current, 40, 0)
          }
        }}
        data-testid={`draggable-${dragOrigin}-${displayName}`}
      >
        {children}
      </div>
    </>
  )
}
