import { rest, type RequestHandler } from 'msw'

import { PROJECT_ID, PROJECT_ID_WITH_NO_ENVS } from '__test-utils__/ids'

import { MockedBranches } from 'api/hooks/useListBranches/__handlers__/MockedBranches'

import config from 'config'

export const useListBranchesHandlers: RequestHandler[] = [
  rest.get(`${config.getApiBaseUrl()}/v1/branches`, async (req, res, ctx) => {
    const projectId = req.url.searchParams.get('projectId')

    if (projectId === PROJECT_ID_WITH_NO_ENVS || projectId === PROJECT_ID) {
      return res(ctx.json(MockedBranches))
    }

    if (projectId === 'empty') {
      return res(ctx.json([]))
    }

    if (projectId === '500') {
      res(ctx.status(500))
    }

    return res(ctx.json([]))
  })
]
