import { Button, Modal, Typography } from '@matillion/component-library'
import classes from './AuthSuccessModal.module.scss'
import { useTranslation } from 'react-i18next'
import { type ExternalGitProvider } from 'types/ExternalGitProvider'
import useGetProviderDisplayName from 'modules/BringYourOwnGit/hooks/useGetProviderDisplayName'

export interface AuthModalSuccessStateProps {
  provider: ExternalGitProvider
  onClose: () => void
}

const AuthSuccessModal = ({
  provider,
  onClose
}: AuthModalSuccessStateProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'auth.modal.success'
  })
  const { providerName } = useGetProviderDisplayName(provider)

  return (
    <Modal
      size="mid"
      onCancel={onClose}
      disableBackdropClick
      className={classes.modal}
      id={`${provider}-auth-success-modal`}
      ariaLabelledBy="git-auth-title"
      data-testid={`${provider}-auth-success-modal`}
    >
      <Typography className={classes.title} format="tm" id="git-auth-title">
        {t('title', { provider: providerName })}
      </Typography>

      <Typography className={classes.body} format="bcm">
        {t('body.first', { provider: providerName })}
      </Typography>

      <div className={classes.buttons}>
        <Button
          alt="primary"
          id="auth-close"
          onClick={onClose}
          className={classes.button}
          data-testid={`${provider}-auth-success-close`}
        >
          <Typography>{t('close')}</Typography>
        </Button>
      </div>
    </Modal>
  )
}
export default AuthSuccessModal
