import { createContext } from 'react'

import { type GitContextBag } from './types'
import axios from 'axios'

export const GitContext = createContext<GitContextBag>({
  isNativeGit: true,
  isLegacyWTS: false,
  hasUncommittedChanges: false,
  designerClient: axios.create(),
  branch: 'branch-not-initialised',
  project: 'project-not-initialised'
})
