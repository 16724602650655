import { rest, type RequestHandler } from 'msw'

import config from 'config'

import { type ComponentInstance } from 'job-lib/types/Job'

import { ColumnEditorType, type ComponentDependency } from '../types'
import { mockAutofillResponse } from './mockAutofillResponse'
import { mockNestedAutofillResponse } from './mockNestedAutofillResponse'
import {
  mockDynamicEmeraldParametersCreateTableColumns,
  mockInvalidRequestResponse,
  mockLookupTypeData,
  mockLookupTypeHandlers,
  mockParameterOptionsFailedResponse
} from './mockParameterOptions'

const useGetParameterOptionsHandlers: RequestHandler[] = [
  rest.post<
    ComponentDependency,
    {
      lookupType: keyof typeof mockLookupTypeData
    }
  >(
    `${config.getApiBaseUrl()}/v1/parameter-options/:lookupType`,
    async (req, res, ctx) => {
      const { lookupType } = req.params

      const body = await req.json()

      if (!body?.variables) {
        return res(ctx.status(400), ctx.json(mockInvalidRequestResponse))
      }

      let mockOptionsData = mockLookupTypeData[lookupType]

      const mockHandler = mockLookupTypeHandlers[lookupType]
      if (typeof mockHandler === 'function') {
        mockOptionsData = mockHandler(body)
      }

      if (body?.component && typeof body.component === 'object') {
        const { component, metlSlot } = body as {
          component: ComponentInstance
          metlSlot: number
        }
        if (component.implementationID === 1611478312 && metlSlot === 5) {
          mockOptionsData = mockDynamicEmeraldParametersCreateTableColumns
        }
      }

      if (mockOptionsData) {
        return res(ctx.json(mockOptionsData))
      }

      return res(ctx.status(400), ctx.json(mockParameterOptionsFailedResponse))
    }
  )
]

const useGetParameterOptionsNoItemsResponseHandler: RequestHandler[] = [
  rest.post(
    `${config.getApiBaseUrl()}/v1/parameter-options/:lookupType`,
    async (req, res, ctx) =>
      res(
        ctx.json({
          success: true,
          errorMessage: null,
          editorColumns: [
            {
              name: '',
              type: 'TEXT',
              options: [],
              ColumnEditorType: ColumnEditorType.Dropdown
            }
          ]
        })
      )
  )
]

const useGetParameterOptionsAutofillHandlers: RequestHandler[] = [
  rest.post(
    `${config.getApiBaseUrl()}/v1/autofill/:lookupType`,
    async (req, res, ctx) => {
      return res(ctx.json(mockAutofillResponse))
    }
  )
]

const useGetParameterOptionsNoDataHandler: RequestHandler = rest.post(
  `${config.getApiBaseUrl()}/v1/autofill/:lookupType`,
  async (req, res, ctx) => {
    return res(ctx.json([]))
  }
)

const useGetParameterOptionsNestedAutofillHandlers: RequestHandler[] = [
  rest.post(
    `${config.getApiBaseUrl()}/v1/autofill/:lookupType`,
    async (req, res, ctx) => {
      return res(ctx.json(mockNestedAutofillResponse))
    }
  )
]

export {
  useGetParameterOptionsHandlers,
  useGetParameterOptionsNoItemsResponseHandler,
  useGetParameterOptionsAutofillHandlers,
  useGetParameterOptionsNoDataHandler,
  useGetParameterOptionsNestedAutofillHandlers
}
