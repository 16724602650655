import { useMutation } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useProjectExplorerClient } from '../useProjectExplorerClient/useProjectExplorerClient'
import { type UpdateProjectVariablesRequest } from './types'
import { updateProjectVariablesApi } from './updateProjectVariablesApi'

export const useUpdateProjectVariables = () => {
  const { projectId } = useProjectInfo()
  const client = useProjectExplorerClient()
  return useMutation(
    async ({
      variableId,
      updateProjectVariablesRequest
    }: {
      variableId: string
      updateProjectVariablesRequest: UpdateProjectVariablesRequest
    }) => {
      return updateProjectVariablesApi(
        client,
        projectId,
        variableId,
        updateProjectVariablesRequest
      )
    }
  )
}
