import { useApiContext } from 'api/context/useApiContext'
import useClient from '../useClient'

const useGitClient = () => {
  const { git } = useApiContext()

  return useClient({
    host: git,
    contextUrl: '/git-service/v1'
  })
}

export default useGitClient
