import React, { type CSSProperties, type FC } from 'react'

import {
  type DraggableProvided,
  type DraggableStateSnapshot
} from '@hello-pangea/dnd'
import { Icon, Typography } from '@matillion/component-library'
import classnames from 'classnames'

import { DragHandle } from '../svg'
import { type OptionProps } from '../types'
import { highlightText } from '../utils/utils'
import classes from './ListItem.module.scss'

interface ListItemProps {
  invalidItems: string[]
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  style?: CSSProperties
  item: string
  filter?: string
  isSelected?: boolean
  isGhosting?: boolean
  toggleSelection?: (item: string) => void
  toggleSelectionInGroup?: (item: string) => void
  multiSelectTo?: (item: string) => void
  selectionCount?: number
  options: OptionProps
}

/* istanbul ignore next */
export const ListItem: FC<ListItemProps> = ({
  invalidItems,
  provided,
  snapshot,
  style,
  item,
  filter = '',
  isSelected,
  isGhosting,
  toggleSelection,
  toggleSelectionInGroup,
  multiSelectTo,
  selectionCount,
  options
}) => {
  const shouldShowSelection =
    snapshot.isDragging && selectionCount && selectionCount > 1

  const wasToggleInSelectionGroupKeyUsed = (
    event:
      | MouseEvent
      | React.KeyboardEvent<Element>
      | React.MouseEvent<Element, MouseEvent>
  ) => {
    const isUsingWindows = navigator.userAgent.includes('Win')
    return isUsingWindows ? event.ctrlKey : event.metaKey
  }

  const wasMultiSelectKeyUsed = (
    event:
      | MouseEvent
      | React.KeyboardEvent<Element>
      | React.MouseEvent<Element, MouseEvent>
  ) => event.shiftKey

  const handleClick = (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => {
    if (event.defaultPrevented) {
      return
    }

    event.preventDefault()

    if (wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup?.(item)
      return
    }

    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo?.(item)
      return
    }

    toggleSelection?.(item)
  }

  const isInvalidItem = invalidItems.includes(item)

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{ ...provided.draggableProps.style, ...style }}
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleClick(e)
        }
      }}
      tabIndex={0}
      title={item}
    >
      <div
        data-testid={`list-item-${item}`}
        className={classnames(classes.ListItem, {
          [classes['ListItem--dragging']]: snapshot.isDragging && !isGhosting,
          [classes['ListItem--selected']]: isSelected && !isGhosting,
          [classes['ListItem--ghosting']]: isGhosting,
          [classes['ListItem--Invalid']]: isInvalidItem
        })}
      >
        {options.showDragHandle && <DragHandle />}
        {isInvalidItem && (
          <div className={classes.Cross}>
            <Icon.LittleCross />
          </div>
        )}
        <Typography format="bcm">{highlightText(item, filter)}</Typography>
        {shouldShowSelection ? (
          <span className={classes.ListItem__SelectionCount}>
            {selectionCount}
          </span>
        ) : null}
      </div>
    </div>
  )
}
