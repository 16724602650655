import type { ReactNode } from 'react'

import { QuitButton, Typography } from '@matillion/component-library'

import classes from './EditPanel.module.scss'

interface EditPanelProps {
  title: string
  subtext?: string
  onCancel: () => void
  children: ReactNode
}

export const EditPanel = ({
  children,
  title,
  subtext,
  onCancel
}: EditPanelProps) => {
  return (
    <div className={classes.EditPanel}>
      <div className={classes.EditPanel__Header}>
        <Typography
          id="edit-panel-title"
          format="ts"
          as="h2"
          className={classes.EditPanel__Title}
        >
          {title}
        </Typography>
        <QuitButton
          alt="transparent"
          data-testid="close-edit-panel"
          aria-label="Close edit panel"
          onClick={onCancel}
          className={classes.EditPanel__Close}
        />
        {subtext && (
          <Typography format="bcs" className={classes.EditPanel__SubText}>
            {subtext}
          </Typography>
        )}
      </div>
      {children}
    </div>
  )
}
