import {
  forwardRef,
  useEffect,
  useState,
  type ChangeEvent,
  type HTMLAttributes,
  type KeyboardEvent
} from 'react'

import { Input } from '@matillion/component-library'
import classnames from 'classnames'

import classes from './TextInput.module.scss'

export interface TextInputProps {
  value: string
  onChange: (newValue: string) => unknown
  error?: boolean
  disabled?: boolean
  className?: string
  containerClassName?: string
  autocompleteDisabled?: boolean
}

export const TextInput = forwardRef<
  HTMLInputElement,
  TextInputProps & Omit<HTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>
>(
  (
    {
      value,
      onChange,
      error,
      className,
      autocompleteDisabled,
      containerClassName,
      ...attrs
    },
    ref
  ) => {
    const [editedValue, setEditedValue] = useState<string>('')
    const [isEditable, setIsEditable] = useState<boolean>(false)

    useEffect(() => {
      setEditedValue(value)
      setIsEditable(false)
    }, [value])

    const getKey = (event: KeyboardEvent<HTMLInputElement>) => {
      if (!isEditable) {
        return
      }

      switch (event.key) {
        case 'Enter':
          onChange(editedValue)
          setIsEditable(false)
          event.stopPropagation()
          break
        case 'Tab':
          onChange(editedValue)
          setIsEditable(false)
          break
        case 'Escape':
          setEditedValue(value)
          setIsEditable(false)
          event.stopPropagation()
          break
      }
    }

    return (
      <Input
        {...attrs}
        ref={ref}
        error={error}
        value={editedValue}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setEditedValue(e.target.value)
        }}
        className={classnames(
          classes.TextInput,
          {
            [classes.TextInput__Error]: error,
            [classes.TextInput__ReadOnly]: !isEditable,
            [classes.TextInput__Editable]: isEditable
          },
          className
        )}
        containerClassName={containerClassName}
        onKeyDown={getKey}
        onBlur={() => {
          if (value !== editedValue) {
            onChange(editedValue)
          }
          setIsEditable(false)
        }}
        readOnly={isEditable ? undefined : 'readonly'}
        onFocus={() => {
          setIsEditable(true)
        }}
        onClick={() => {
          setIsEditable(true)
        }}
        autoComplete={autocompleteDisabled && 'off'}
      />
    )
  }
)

TextInput.displayName = 'TextInput'
