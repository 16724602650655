export interface Props {
  orientation: Orientation
  dragHandle?: boolean
  disabled?: boolean
  id?: string
}

export enum Orientation {
  HORIZONTAL = 'Horizontal',
  VERTICAL = 'Vertical'
}
