import { type ExternalGitProvider } from 'types/ExternalGitProvider'
import { useMemo } from 'react'

const useGetProviderDisplayName = (provider?: ExternalGitProvider) => {
  const providerName = useMemo(() => {
    if (provider === 'github') {
      return 'GitHub'
    }

    return undefined
  }, [provider])

  return {
    providerName
  }
}

export default useGetProviderDisplayName
