export const DEFAULT_MARKETING_PAYLOAD = {
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  utm_content: '',
  utm_term: ''
}

type MarketingPayload = typeof DEFAULT_MARKETING_PAYLOAD

enum UTMZZ_TO_MARKETING_PAYLOAD_KEYS {
  utmcsr = 'utm_source',
  utmcmd = 'utm_medium',
  utmccn = 'utm_campaign',
  utmcct = 'utm_content',
  utmctr = 'utm_term'
}

const isMarketingPayloadKey = (key: string): key is keyof MarketingPayload =>
  key in DEFAULT_MARKETING_PAYLOAD

const getMarketingPayloadKeyFromUtmzz = (key: string) =>
  UTMZZ_TO_MARKETING_PAYLOAD_KEYS[
    key as keyof typeof UTMZZ_TO_MARKETING_PAYLOAD_KEYS
  ]

const getMarketingPayloadFromEntries = (
  entries: string[][]
): MarketingPayload | undefined => {
  const payload = entries.reduce<
    Partial<Record<keyof MarketingPayload, string>>
  >((acc, [key, value]) => {
    if (!isMarketingPayloadKey(key)) {
      return acc
    }

    return {
      ...acc,
      [key]: value
    }
  }, {})

  return Object.keys(payload).length > 0
    ? {
        ...DEFAULT_MARKETING_PAYLOAD,
        ...payload
      }
    : undefined
}

export const getMarketingPayloadFromURL = () => {
  const queryParams = new URLSearchParams(window.location.search)
  return getMarketingPayloadFromEntries(Array.from(queryParams.entries()))
}

export const getMarketingPayloadFromCookie = () => {
  const utmzzCookie = document.cookie.match(/(^| )__utmzz=([^;]+)/)
  const marketingCookie = utmzzCookie?.[2]

  if (!marketingCookie) {
    return undefined
  }

  const entries = marketingCookie
    .split('|')
    .reduce<string[][]>((acc, utmKV) => {
      const [key, value] = utmKV.split('=')
      return [...acc, [getMarketingPayloadKeyFromUtmzz(key), value]]
    }, [])

  return getMarketingPayloadFromEntries(entries)
}

/**
 * Returns the user's current Google UTM parameters, referred to
 * as the user's MarketingPayload. This method will first look for
 * marketing parameters in the URL, and then fall back to looking in
 * the user's cookies. If a payload doesn't exist in either of these
 * places, `undefined` will be returned.
 *
 * @returns Either the current MarketingPayload, or `undefined`
 */
const getMarketingPayload = () =>
  getMarketingPayloadFromURL() ?? getMarketingPayloadFromCookie()

export default getMarketingPayload
