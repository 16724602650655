import { type PropsWithChildren, useCallback, useMemo } from 'react'
import { ApiContext } from './ApiContext'
import { type ApiContextBag, type ApiContextProviderProps } from './types'
import { apiConfig } from '../config'
import { useUser } from '@matillion/hub-client'

const ApiContextProvider = ({
  children,
  environment
}: PropsWithChildren<ApiContextProviderProps>) => {
  const { organisation } = useUser()

  const interpolateRegion = useCallback(
    (host: string) => {
      const region = organisation.region ?? 'eu1'
      return host.replace('{{region}}', region)
    },
    [organisation]
  )

  const value: ApiContextBag = useMemo(() => {
    const config = apiConfig[environment] ?? apiConfig.test

    return {
      audience: config.audience,
      git: interpolateRegion(config.git),
      wts: interpolateRegion(config.wts),
      entitlements: interpolateRegion(config.entitlements)
    }
  }, [environment, interpolateRegion])

  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
}

export default ApiContextProvider
