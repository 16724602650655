import { useCallback } from 'react'

import { type ParameterCollection } from 'job-lib/types/Parameters'

import {
  getDPLParameters,
  getParameterDPLValue
} from 'modules/ComponentParameters/utils/getParameterValue'

import { useComponentSummaries } from '.'

const API_EXTRACT_IMPLEMENTATION_ID = '720344340'

export const useGetComponentSummary = () => {
  const componentSummaries = useComponentSummaries()
  return {
    isLoading: componentSummaries.isLoading,
    isError: componentSummaries.isError,
    getByComponentId: useCallback(
      (componentId: string) =>
        componentSummaries.data.find((x) => x.componentId === componentId),
      [componentSummaries]
    ),
    /**
     * The problem here is that we use implementation ID
     * as the key in the job model. All custom connectors have the same implementation ID, the API extract ID.
     * Therefore, we must use the profile parameter to determine which custom connector is being used.
     */
    getByImplementationId: useCallback(
      (implementationId: string, parameters: ParameterCollection) => {
        if (implementationId === API_EXTRACT_IMPLEMENTATION_ID) {
          const params = getDPLParameters(null, parameters)
          const connectorId = getParameterDPLValue(
            ['api-extract-input-v1', 'profile'],
            params
          )
          return componentSummaries.data.find(
            (x) => x.connectorId === connectorId
          )
        } else {
          return componentSummaries.data.find(
            (x) => x.emeraldId === implementationId
          )
        }
      },
      [componentSummaries]
    )
  }
}
