import { type CaseReducer, type PayloadAction } from '@reduxjs/toolkit'

import { type JobVariable } from 'job-lib/types/Variables'

import { type JobState } from '../../job.types'

export const addJobVariable: CaseReducer<
  JobState,
  PayloadAction<JobVariable>
> = (state, { payload: variable }) => {
  const { jobType, job } = state

  if (jobType !== null || job !== null) {
    job.variables = {
      ...job.variables,
      [variable.definition.name]: { ...variable }
    }
  }
  return state
}
