import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { JobType } from 'job-lib/types/JobType'

import { useSpringClient } from '../useSpringClient/useSpringClient'
import {
  type SampleRowCountRequest,
  type SampleRowCountRequestBody,
  type SampleRowCountResponse
} from './types'

const jobTypeToUrlMap = {
  [JobType.Orchestration]: '/sample/orchestration/rowcount',
  [JobType.Transformation]: '/sample/transformation/rowcount'
}

export const useSampleRowCount = (jobType: JobType) => {
  const { projectId, agentId, environmentId } = useProjectInfo()
  const client = useSpringClient()

  return useMutation(async ({ componentCache }: SampleRowCountRequest) => {
    const requestBody: SampleRowCountRequestBody = {
      projectId,
      agentId,
      environmentId
    }

    if (jobType === JobType.Transformation) {
      requestBody.componentCache = componentCache
    }

    try {
      const { data } = await client.post<SampleRowCountResponse>(
        jobTypeToUrlMap[jobType],
        requestBody
      )
      return data
    } catch (err) {
      if (axios.isAxiosError(err)) {
        throw err.response?.data
      }
      throw Error((err as Error).message)
    }
  })
}
