import { type FunctionComponent } from 'react'

import { Typography } from '@matillion/component-library'
import classnames from 'classnames'

import classes from './ComponentParameterMessage.module.scss'

interface ComponentParameterMessageProps {
  message?: string
  type: 'warning' | 'error'
}

export const ComponentParameterMessage: FunctionComponent<
  ComponentParameterMessageProps
> = ({ message, type }) => (
  <output
    className={classnames(classes.ComponentParameterMessage, {
      [classes['ComponentParameterMessage--Warning']]: type === 'warning',
      [classes['ComponentParameterMessage--Error']]: type === 'error'
    })}
  >
    <div
      className={classnames(
        classes.ComponentParameterMessage__IconWrapper,
        classes.IconWrapper
      )}
    >
      <span
        className={classnames(classes.IconWrapper__Icon, classes.Icon, {
          [classes['Icon--Warning']]: type === 'warning',
          [classes['Icon--Error']]: type === 'error'
        })}
      />
    </div>

    <p className={classes.ComponentParameterMessage__Message}>
      <Typography format="mc" as="span">
        {message}
      </Typography>
    </p>
  </output>
)
