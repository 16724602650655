import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

import {
  ComponentEditorTabType,
  EditorType,
  ExportVariableType,
  LookUpType,
  ParameterDataType,
  UseVariable,
  VisibleWhenOperator,
  type ComponentMetadataResponse
} from '../../types'

export const mockAssertTableMetadata: ComponentMetadataResponse = {
  componentId: 'assert-table',
  metadata: {
    schemaVersion: 1,
    emeraldId: '-377483579',
    componentType: JobType.Orchestration,
    classification: 'EMERALD',
    inputPorts: [
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.ONE
      }
    ],
    outputPorts: [
      {
        portId: ConnectionPortType.SUCCESS,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.FAILURE,
        cardinality: Cardinality.MANY
      }
    ],
    deprecated: false,
    exports: [
      ExportVariableType.COMPLETED_AT,
      ExportVariableType.COMPONENT_NAME,
      ExportVariableType.DURATION,
      ExportVariableType.MESSAGE,
      ExportVariableType.ROW_COUNT,
      ExportVariableType.STARTED_AT,
      ExportVariableType.STATUS
    ],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.EXPORT,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'database',
        metlSlot: 2,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'schema',
        metlSlot: 3,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'tableName',
        metlSlot: 4,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'exists',
        metlSlot: 100,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'tableMetadata',
        metlSlot: 5,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'exists',
            value: 'Yes',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'rowCountComparisonType',
        metlSlot: 101,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_EMERALD_OPTIONS,
        lookupDependencies: ['component', 'metlSlot'],
        visibleWhen: [
          {
            param: 'exists',
            value: 'Yes',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'lowerValue',
        metlSlot: 103,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'rowCountComparisonType',
            value: 'Range',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'exists',
            value: 'Yes',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'upperValue',
        metlSlot: 104,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'rowCountComparisonType',
            value: 'Range',
            operator: VisibleWhenOperator.EQUALS
          },
          {
            param: 'exists',
            value: 'Yes',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'rowCountValue',
        metlSlot: 102,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: [
          {
            param: 'rowCountComparisonType',
            value: 'Range',
            operator: VisibleWhenOperator.NOT_EQUALS
          },
          {
            param: 'exists',
            value: 'Yes',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        staticOptions: null,
        defaultValue: null
      }
    ]
  }
}
