import {
  GIT_AUTH_EVENT_ID,
  type GitAuthMessageHandlerProps,
  type GitAuthMessagePayload
} from 'modules/BringYourOwnGit/components/AuthMessageListener/types'
import useCaptureOAuthToken from 'api/hooks/useCaptureOAuthToken'
import { useCallback, useEffect } from 'react'

const AuthMessageListener = ({
  provider,
  onAuthError,
  onAuthSuccess
}: GitAuthMessageHandlerProps) => {
  const { mutateAsync: captureAuthToken } = useCaptureOAuthToken({
    provider
  })

  const handleSuccess = useCallback(async () => {
    await onAuthSuccess()
  }, [onAuthSuccess])

  const handleError = useCallback(async () => {
    await onAuthError()
  }, [onAuthError])

  const handleMessage = useCallback(
    (event: MessageEvent<GitAuthMessagePayload>) => {
      if (event.data.type === GIT_AUTH_EVENT_ID) {
        const { code, state } = event.data

        captureAuthToken({ state, code }).then(handleSuccess).catch(handleError)
      }
    },
    [captureAuthToken, handleError, handleSuccess]
  )

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [handleMessage])

  return null
}

export default AuthMessageListener
