import { useCallback, useContext } from 'react'
import { type XYPosition } from 'reactflow'

import { type Dispatch } from 'redux'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { jobActions } from 'job-lib/store'

import { useEtlFlow } from 'modules/Canvas/hooks/useEtlFlow'
import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'

import { calculatePastedComponentsPositions } from './calculatePastedComponentsPositions'
import {
  CopyPasteContext,
  type BaseCopiedComponentContent
} from './CopyPasteContext'

export const usePasteComponent = (dispatch: Dispatch) => {
  const {
    componentInstances,
    componentType,
    currentJobId,
    currentConnectors,
    copiedComponentOffsets,
    setCopiedComponentContent
  } = useContext(CopyPasteContext)
  const reactFlowInstance = useEtlFlow()
  const { jobSummaryId } = useProjectInfo()
  const { jobType } = useWorkingCopy()

  const pasteComponent = useCallback(
    (manualPosition?: XYPosition) => {
      if (!componentInstances || componentInstances.length === 0) {
        console.warn('Paste component failed, no component has been copied')
        return
      }
      if (componentType && jobType && jobType !== componentType) {
        console.warn(
          `Cannot paste ${componentType.toLowerCase()} component into ${jobType.toLowerCase()} job`
        )
        return
      }

      // @ts-expect-error the union is collapsed when we deconstruct the componentInstances and componentType
      const pastedState: BaseCopiedComponentContent = {
        componentInstances,
        componentType,
        currentConnectors
      }

      pastedState.componentInstances = calculatePastedComponentsPositions(
        componentInstances,
        {
          reactFlowInstance,
          copiedComponentOffsets,
          manualPosition,
          currentJobId,
          jobSummaryId
        }
      )

      setCopiedComponentContent(jobSummaryId, pastedState)

      if (pastedState.componentInstances) {
        dispatch(jobActions.cloneComponentGroup(pastedState))
      }
    },
    [
      dispatch,
      reactFlowInstance,
      jobType,
      componentInstances,
      componentType,
      copiedComponentOffsets,
      currentConnectors,
      currentJobId,
      jobSummaryId,
      setCopiedComponentContent
    ]
  )

  return { pasteComponent }
}
