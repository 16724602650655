import { type RequestHandler, rest } from 'msw'

import {
  MockCommitErrorResponse,
  MockCommitResponses
} from 'api/hooks/useCommit/useCommit.responses'

export const useCommitHandler: RequestHandler[] = [
  ...Object.keys(MockCommitResponses).map((branchName) => {
    return rest.post(`/branches/${branchName}/commits`, async (_, res, ctx) => {
      return res(ctx.status(201), ctx.json(MockCommitResponses[branchName]))
    })
  }),
  ...Object.keys(MockCommitErrorResponse).map((branchName) => {
    return rest.post(`/branches/${branchName}/commits`, async (_, res, ctx) => {
      return res(
        ctx.status(400),
        ctx.set('content-type', 'application/problem+json'),
        ctx.body(JSON.stringify(MockCommitErrorResponse[branchName]))
      )
    })
  }),
  rest.post('/branches/500/commits', async (_, res, ctx) => {
    return res(ctx.status(500))
  })
]
