import { type BranchSelectorProps } from './types'
import SourceBranchSelector from '../SourceBranchSelector'
import TargetBranchSelector from '../TargetBranchSelector'
import useMergeFeatureFlag from '../../hooks/useMergeFeatureFlag'
import { useCallback } from 'react'
import useGitContext from 'provider/GitProvider/useGitContext'

const BranchSelector = ({
  sourceBranch,
  targetBranch,
  branches,
  onSourceBranchChange,
  onTargetBranchChange,
  isInvalidating
}: BranchSelectorProps) => {
  const { branch } = useGitContext()
  const { isMergingFromBranch } = useMergeFeatureFlag()

  const handleChange = useCallback(
    (selectedBranch?: string) => {
      if (isMergingFromBranch) {
        onSourceBranchChange(selectedBranch)
        onTargetBranchChange(branch)
      } else {
        onSourceBranchChange(branch)
        onTargetBranchChange(selectedBranch)
      }
    },
    [branch, isMergingFromBranch, onSourceBranchChange, onTargetBranchChange]
  )

  if (isMergingFromBranch) {
    return (
      <SourceBranchSelector
        branches={branches}
        branch={sourceBranch}
        setBranch={handleChange}
        isInvalidating={isInvalidating}
      />
    )
  }

  return (
    <TargetBranchSelector
      branches={branches}
      branch={targetBranch}
      setBranch={handleChange}
      isInvalidating={isInvalidating}
    />
  )
}

export default BranchSelector
