import { useEffect, useMemo } from 'react'
import useLegacyDescribeMerge from 'api/hooks/useLegacyDescribeMerge'
import useDescribeMerge from 'api/hooks/useDescribeMerge'
import useFeatureFlags from '../useFeatureFlags'
import useGitContext from 'provider/GitProvider/useGitContext'
import { type DescribeMergeArgs, type DescribeMergeResponse } from './types'
import useProblemDetails from '../useProblemDetails'
import { Problem } from '../useProblemDetails/Problem'

const useDescribeMergeDecision = ({
  sourceBranch,
  targetBranch,
  onUncommittedChangesPreventMerge
}: DescribeMergeArgs) => {
  const { branch, isLegacyWTS } = useGitContext()
  const { enableWorkingTreeMerge } = useFeatureFlags()
  const { isProblemType } = useProblemDetails()

  const shouldUseLegacyImpl = isLegacyWTS || !enableWorkingTreeMerge

  const {
    data: legacyMergeDescription,
    isLoading: isLegacyDescribeLoading,
    fetchStatus: legacyDescribeFetchStatus
  } = useLegacyDescribeMerge(branch, targetBranch, {
    enabled: shouldUseLegacyImpl
  })

  const {
    error: describeError,
    data: wtsMergeDescription,
    isLoading: isDescribeLoading,
    fetchStatus: describeFetchStatus
  } = useDescribeMerge({
    sourceBranch,
    targetBranch,
    enabled: !shouldUseLegacyImpl
  })

  useEffect(() => {
    if (
      describeError &&
      isProblemType(
        describeError,
        Problem.WTS_UNCOMMITTED_CHANGES_PREVENT_MERGE
      )
    ) {
      onUncommittedChangesPreventMerge()
    }
  }, [describeError, isProblemType, onUncommittedChangesPreventMerge])

  const mergeDescription: DescribeMergeResponse = useMemo(() => {
    if (shouldUseLegacyImpl) {
      return {
        targetCommitId: legacyMergeDescription?.targetCommit.id,
        ...legacyMergeDescription
      }
    } else {
      return {
        sourceCommitId: wtsMergeDescription?.localCommitId,
        targetCommitId: wtsMergeDescription?.remoteCommitId,
        ...wtsMergeDescription
      }
    }
  }, [legacyMergeDescription, shouldUseLegacyImpl, wtsMergeDescription])

  const isLegacyDescribeLoadingAndNotIdle =
    isLegacyDescribeLoading && legacyDescribeFetchStatus !== 'idle'

  const isDescribeLoadingAndNotIdle =
    isDescribeLoading && describeFetchStatus !== 'idle'

  const isLoading =
    isLegacyDescribeLoadingAndNotIdle || isDescribeLoadingAndNotIdle

  return {
    shouldShowPublishForm: shouldUseLegacyImpl,
    isLegacyDescribeLoadingAndNotIdle,
    isLoading,
    mergeDescription
  }
}

export default useDescribeMergeDecision
