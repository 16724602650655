import {
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import { type ElementCollection } from 'job-lib/types/Parameters'

import { type ParameterOptions } from '../../types'

export interface NestedDataPickerEditorProps extends ParameterOptions {
  onDone: (editedValue: ElementCollection) => void
  elements: ElementCollection
  metadata: ComponentMetadata
  componentId: string
  parameter: ComponentParameter
  parameterName: string
}

export enum DataStructureType {
  VARCHAR = 'VARCHAR',
  NUMBER = 'NUMBER',
  FLOAT = 'FLOAT',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  TIMESTAMP = 'TIMESTAMP',
  TIME = 'TIME',
  VARIANT = 'VARIANT'
}

export interface SharedDataStructure {
  key: string
  children: ChildDataStructure[]
}

export interface DataStructure extends SharedDataStructure {
  type: DataStructureType.VARIANT
}

export interface ChildDataStructure extends SharedDataStructure {
  type: DataStructureType
  size: number
  decimalPlaces: number
  column: string
  alias: string
  array: boolean
  selected: boolean
}
