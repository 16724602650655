import { createContext } from 'react'
import { type OAuthContextBag } from './types'

export const OAuthContext = createContext<OAuthContextBag>({
  setSuccessAction: () => {
    console.debug(
      'Tried to invoke setSuccessAction() from the OAuthContext, but it has not been initialised.'
    )
  },
  setCloseAction: () => {
    console.debug(
      'Tried to invoke setCloseAction() from the OAuthContext, but it has not been initialised.'
    )
  },
  onAuthRequired: () => {
    console.debug(
      'Tried to invoke onAuthRequired() from the OAuthContext, but it has not been initialised.'
    )
  },
  setModalHelpText: (text: string) => {
    console.debug(
      `Tried to invoke setModalHelpText(text: ${text}) from the OAuthContext, but it has not been initialised.`
    )
  }
})
