import useGitContext from 'provider/GitProvider/useGitContext'
import { type DesignerClientResponse } from './types'

const useDesignerClient = (): DesignerClientResponse => {
  const { branch, project, designerClient } = useGitContext()

  if (!designerClient) {
    throw new Error(
      'Error calling API hook from the git component library.\n' +
        'The client instance for the ETL Designer API is undefined.\n' +
        'Make sure the AxiosInstance has been passed into the GitProvider.'
    )
  }

  return {
    client: designerClient,
    branch,
    project
  }
}

export default useDesignerClient
