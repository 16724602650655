import React, { type Dispatch, type FunctionComponent } from 'react'

import { type ModalProps } from '@matillion/component-library'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'
import { Loading } from 'components/Loading/Loading'

import { ErrorContextWrapper } from './components/ErrorContextWrapper'
import classes from './ParameterOverlay.module.scss'

export interface ParameterOverlayProps extends Pick<ModalProps, 'size'> {
  isLoading: boolean
  setShowEditor: Dispatch<boolean>
}

export const ParameterOverlay: FunctionComponent<
  React.PropsWithChildren<ParameterOverlayProps>
> = ({ size = 'default', setShowEditor, isLoading, children }) => {
  return (
    <DesignerModal
      className={classes.ParameterOverlay}
      size={size}
      onCancel={() => {
        setShowEditor(false)
      }}
      ariaLabelledBy="parameter-overlay-title"
      disableBackdropClick={true}
    >
      <ErrorContextWrapper>
        {isLoading && (
          <div className={classes.MultiOptionSelector__Loading}>
            <Loading />
          </div>
        )}
        {!isLoading && children}
      </ErrorContextWrapper>
    </DesignerModal>
  )
}
