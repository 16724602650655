import { type ExternalGitProvider } from 'types/ExternalGitProvider'

export const GIT_AUTH_EVENT_ID = 'matillion.git.oauth.redirect'

export interface GitAuthMessageHandlerProps {
  /**
   * The name of the external git provider that
   * the user is authorizing with.
   */
  provider?: ExternalGitProvider

  /**
   * A callback function that is invoked when
   * an error is encountered during the oauth
   * token capture process.
   */
  onAuthError: () => Promise<void>

  /**
   * A callback function that is invoked when
   * the oauth token capture process completes
   * successfully and the users tokens are stored
   * in the vault.
   */
  onAuthSuccess: () => Promise<void>
}

export interface GitAuthMessageHandlerResponse {
  /**
   * Indicates whether the token capture process
   * is currently in-progress.
   */
  loading: boolean
}

export interface GitAuthMessagePayload {
  /**
   * A random string generated by the external git
   * provider that was taken from the redirect URI
   * after the user authorized.
   */
  code: string

  /**
   * The raw, encoded state string value that was set
   * when the oauth flow process began.
   */
  state: string

  /**
   * A string value that uniquely identifies the
   * message event to prevent rogue messages from
   * being acted upon.
   */
  type: string
}
