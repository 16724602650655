// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dam_RE_iyE5p1Eq5tU2k{display:flex;justify-content:space-between;margin:0;padding-top:36px;padding-bottom:36px}.dam_RE_iyE5p1Eq5tU2k .kWSecTUoDf6XKs7UwsV0{width:320px;padding:5px}.dam_RE_iyE5p1Eq5tU2k .Qzoym76359ZhD1mBRg3V{min-width:16px;display:flex;justify-content:center;align-items:center;height:45px;margin-top:30px}`, "",{"version":3,"sources":["webpack://./src/modules/Merge/components/TargetBranchSelector/TargetBranchSelector.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,QAAA,CACA,gBAAA,CACA,mBAAA,CAEA,4CACE,WAAA,CACA,WAAA,CAGF,4CACE,cAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,eAAA","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: space-between;\n  margin: 0;\n  padding-top: 36px;\n  padding-bottom: 36px;\n\n  .branch {\n    width: 320px;\n    padding: 5px;\n  }\n\n  .iconWrapper {\n    min-width: 16px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 45px;\n    margin-top: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `dam_RE_iyE5p1Eq5tU2k`,
	"branch": `kWSecTUoDf6XKs7UwsV0`,
	"iconWrapper": `Qzoym76359ZhD1mBRg3V`
};
export default ___CSS_LOADER_EXPORT___;
