// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.It0E8R9dmJnb8rIQCyP3{display:flex;justify-content:space-between;margin-bottom:-25px;padding-top:32px}.It0E8R9dmJnb8rIQCyP3 .evJt81J4yrn6QnC4AqZI{width:100px}.QljQOoFRBvpg6Fxwch8p{margin:15px 0}`, "",{"version":3,"sources":["webpack://./src/components/ConflictsModal/ConflictsModal.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,mBAAA,CACA,gBAAA,CAEA,4CACE,WAAA,CAIJ,sBACE,aAAA","sourcesContent":[".Footer {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: -25px;\n  padding-top: 32px;\n\n  .Button {\n    width: 100px;\n  }\n}\n\n.Alert {\n  margin: 15px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Footer": `It0E8R9dmJnb8rIQCyP3`,
	"Button": `evJt81J4yrn6QnC4AqZI`,
	"Alert": `QljQOoFRBvpg6Fxwch8p`
};
export default ___CSS_LOADER_EXPORT___;
