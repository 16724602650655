import type { ParameterValue } from 'types/Pipeline'

import {
  ParameterDataType,
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import { isDPLParameterCollection } from 'job-lib/store/jobSlice/utils/isDPLParameterCollection'
import {
  type ComponentInstanceId,
  type OrchestrationJob,
  type TransformationJob
} from 'job-lib/types/Job'
import {
  type ElementCollection,
  type ParameterCollection
} from 'job-lib/types/Parameters'

import { convertElementCollectionToValue } from 'modules/ComponentParameters/utils/convertElementCollectionToValue'
import {
  getDPLParameters,
  getParameterDPLValue
} from 'modules/ComponentParameters/utils/getParameterValue'

import { parseLookupDependencyKey } from './parseLookupDependencyKey'

export interface GetParameterDependencyOptions {
  lookupDependencies: string[]
  componentMetaData: ComponentMetadata
  componentId: ComponentInstanceId | null
  job: OrchestrationJob | TransformationJob | null
}

export interface GetParameterValueParams {
  parameterPath: string[]
  parameterId: string
  parameterPathIndex?: number
  componentParameters: ComponentParameter[]
  componentParameterCollection: ParameterCollection
  alias: string
}

const getMETLParameterValue = (
  parameterCollection: ParameterCollection,
  parameterDataType: ParameterDataType,
  metlSlot: number
) => {
  switch (parameterDataType) {
    case ParameterDataType.LIST:
    case ParameterDataType.GRID: {
      return convertElementCollectionToValue(
        parameterCollection[metlSlot]?.elements ?? {},
        parameterDataType
      )
    }
    default: {
      return parameterCollection[metlSlot]?.elements[1]?.values[1]?.value
    }
  }
}

const getParameterValue = ({
  parameterPath,
  parameterId,
  parameterPathIndex = 0,
  componentParameters,
  componentParameterCollection,
  alias
}: GetParameterValueParams): ParameterValue | ElementCollection => {
  const parameter = componentParameters.find(
    (param) => param.dplID === parameterId
  )

  if (!parameter) {
    return null
  }

  const isLastItemInPath = parameterPathIndex === parameterPath.length - 1

  // we only want to look in child properties if the parameter has them
  // and we're not at the end of the parameter path
  if (parameter.childProperties && !isLastItemInPath) {
    return getParameterValue({
      parameterPath,
      parameterId: parameterPath[parameterPathIndex + 1],
      parameterPathIndex: parameterPathIndex + 1,
      componentParameters: parameter.childProperties,
      componentParameterCollection,
      alias
    })
  }

  const isDPLParameters = isDPLParameterCollection(componentParameterCollection)

  const parameterValue: ParameterValue | ElementCollection = isDPLParameters
    ? getParameterDPLValue(
        parameterPath,
        getDPLParameters(null, componentParameterCollection)
      )
    : getMETLParameterValue(
        componentParameterCollection,
        parameter.dataType,
        parameter.metlSlot
      )

  // specially treat fields aliased as inputConnector, we need to wrap in "parameters" in this case
  if (alias === 'param.inputConnector') {
    return {
      parameters: parameterValue
    }
  }

  return parameterValue
}

export const getParameterDependencies = ({
  lookupDependencies,
  componentMetaData,
  componentId,
  job
}: GetParameterDependencyOptions) => {
  return lookupDependencies?.reduce((definition, dependency) => {
    const parsedDependencyKey = parseLookupDependencyKey('param', dependency)
    if (!parsedDependencyKey) {
      return definition
    }
    const { alias, parameterPath } = parsedDependencyKey
    const componentParameterCollection =
      job?.components[componentId as number]?.parameters ?? {}

    const parameterValue = getParameterValue({
      parameterPath,
      componentParameterCollection,
      componentParameters: componentMetaData.parameters,
      parameterId: String(parameterPath.at(0)),
      alias
    })

    return {
      ...definition,
      [alias]: parameterValue
    }
  }, {})
}
