import { useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Checkbox,
  Grid,
  Label,
  Section,
  Typography
} from '@matillion/component-library'
import { useIntercom } from '@matillion/hub-client'

import { ReactComponent as PlusIcon } from 'assets/add-plus-plain-circle.svg'
import { ReactComponent as WorkflowIcon } from 'assets/workflow-pull-request.svg'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import config from 'config'

import { Stack } from '../Stack'
import { OnboardingCard } from './components/OnboardingCard'
import classes from './OnboardingModal.module.scss'

const MODAL_TITLE_ID = 'onboarding-modal-title'

interface OnboardingModalProps {
  onClose: (isHiddenNextTime: boolean) => void
}

export const OnboardingModal: FunctionComponent<OnboardingModalProps> = ({
  onClose
}) => {
  const { startTour } = useIntercom()
  const [isChecked, setIsChecked] = useState(false)
  const { t } = useTranslation()

  function handleModalClose() {
    onClose(isChecked)
  }

  return (
    <DesignerModal
      data-testid="onboarding-modal"
      size="mid-large"
      onCancel={handleModalClose}
      ariaLabelledBy={MODAL_TITLE_ID}
    >
      <Stack isCentered>
        <Typography id={MODAL_TITLE_ID} format="tl" as="h2">
          {t('translation:onboarding.title')}
        </Typography>
        <Typography format="bcm">
          {t('translation:onboarding.subtitle')}
        </Typography>
      </Stack>
      <Grid>
        <Section>
          <div className={classes.CardsContainer}>
            <OnboardingCard
              title={t('translation:onboarding.buildPipelineCard.title')}
              description={t(
                'translation:onboarding.buildPipelineCard.description'
              )}
              icon={<PlusIcon />}
              onClick={handleModalClose}
            />
            <OnboardingCard
              title={t('translation:onboarding.testSampleCard.title')}
              description={t(
                'translation:onboarding.testSampleCard.description'
              )}
              icon={<WorkflowIcon />}
              onClick={() => {
                handleModalClose()
                startTour(config.intercomTourId)
              }}
            />
          </div>
        </Section>
        <Section>
          <Label>
            <Checkbox
              checked={isChecked}
              onChange={(e) => {
                setIsChecked(e.currentTarget.checked)
              }}
              text={t('translation:onboarding.notShowAgain')}
            />
          </Label>
        </Section>
      </Grid>
    </DesignerModal>
  )
}
