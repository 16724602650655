import { type FC } from 'react'

import { Icon, Typography } from '@matillion/component-library'

import classes from './NameCell.module.scss'

interface NameCellProps {
  containerName: string
  onClick: () => void
  upOneLevel: () => void
}

const NameCell: FC<NameCellProps> = ({
  containerName,
  onClick,
  upOneLevel
}) => {
  return (
    <button
      type="button"
      className={classes.Cell}
      onClick={containerName === '..' ? upOneLevel : onClick}
    >
      <div className={classes.Icon}>
        {containerName === '..' ? <Icon.ParentArrow /> : <Icon.FolderSmall />}
      </div>
      <Typography className={classes.Name} format="bcs">
        {containerName}
      </Typography>
    </button>
  )
}

export default NameCell
