import { useTranslation } from 'react-i18next'

import { Alert } from '@matillion/component-library'

import classes from './ConflictsAlert.module.scss'
import { type ConflictsAlertProps } from './types'
import classNames from 'classnames'

const ConflictsAlert = ({
  className,
  hasConflicts,
  hasResolvedConflicts
}: ConflictsAlertProps) => {
  const { t } = useTranslation()

  const theme = 'dark'

  if (hasConflicts) {
    if (!hasResolvedConflicts) {
      return (
        <Alert
          theme={theme}
          type="warning"
          data-testid="conflicts-alert"
          message={t('merge.alerts.hasConflicts')}
          className={classNames(classes.Alert, className)}
        />
      )
    }

    return (
      <Alert
        theme={theme}
        type="success"
        data-testid="conflicts-resolved-alert"
        message={t('merge.alerts.resolved')}
        className={classNames(classes.Alert, className)}
      />
    )
  }

  return (
    <Alert
      theme={theme}
      type="success"
      data-testid="no-conflicts-alert"
      message={t('merge.alerts.noConflicts')}
      className={classNames(classes.Alert, className)}
    />
  )
}

export default ConflictsAlert
