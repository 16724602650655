export enum Problem {
  WTS_REMOTE_BRANCH_NOT_FOUND = 'working-tree/remote_branch_not_found',
  WTS_COMMIT_MESSAGE_TOO_LONG = 'working-tree/commit_message_too_long',
  WTS_PUSH_REJECTED = 'working-tree/push_to_remote_rejected',
  WTS_NO_CHANGES_TO_COMMIT = 'working-tree/no_changes_to_commit',
  WTS_NOTHING_TO_COMMIT = 'working-tree/nothing_to_commit',
  WTS_LOCAL_BRANCH_BEHIND = 'working-tree/local_branch_behind_remote',
  WTS_BRANCH_NOT_FOUND = 'working-tree/branch_not_found',
  WTS_UNCOMMITTED_CHANGES_PREVENT_MERGE = 'working-tree/uncommitted_changes_prevent_merge',
  WTS_PROVIDER_AUTH_REQUIRED = 'working-tree/provider_authorisation_required',
  WTS_PROVIDER_AUTH_FAILED = 'working-tree/provider_authorisation_failed',

  GIT_PROVIDER_AUTH_REQUIRED = 'git/provider_authorisation_required',
  GIT_PROVIDER_AUTH_FAILED = 'git/provider_authorisation_failed',
  GIT_PROVIDER_NOT_FOUND = 'git/provider_not_found',
  GIT_MANUAL_MERGE_REQUIRED = 'git/manual_merge_required'
}
