import { createInstance } from 'i18next'

import en from './locales/en/translation.json'

const resources = {
  en: {
    translation: { ...en }
  }
}

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

const i18n = createInstance({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false // react already safes from xss
  },
  returnNull: false
})

i18n.init()

export default i18n
