import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { StorageEditorType } from 'modules/ParameterEditors/types'

import { AzureUrlEditor } from './components/AzureUrlEditor/AzureUrlEditor'
import { S3UrlEditor } from './components/S3UrlEditor/S3UrlEditor'

export interface UrlEditorProps {
  storageEditorType: StorageEditorType
  editorColumns: EditorColumn[]
  parameterName: string
  value: string[]
  onDone: (editedValue: string[]) => void
}

export const UrlEditor = ({
  storageEditorType,
  editorColumns,
  parameterName,
  value,
  onDone
}: UrlEditorProps) => {
  return storageEditorType === StorageEditorType.S3 ? (
    <S3UrlEditor
      editorColumns={editorColumns}
      parameterName={parameterName}
      value={value}
      onDone={onDone}
    />
  ) : (
    <AzureUrlEditor
      editorColumns={editorColumns}
      parameterName={parameterName}
      value={value}
      onDone={onDone}
    />
  )
}
