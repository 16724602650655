import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { EmptyPanel } from 'components/Panels/EmptyPanel'

import { type JobVariableCollection } from 'job-lib/types/Variables'

import classes from '../Manage.module.scss'
import { JobVariablesList } from './JobVariablesList'

interface JobVariablesProps {
  variables?: JobVariableCollection
  onDelete: (name: string) => unknown
  onEdit: (name: string) => unknown
}

export const JobVariables: FC<JobVariablesProps> = ({
  variables,
  onDelete,
  onEdit
}) => {
  const { t } = useTranslation()

  const jobVariables = variables ?? {}
  const hasJobVariables = Object.keys(jobVariables).length > 0

  if (!hasJobVariables) {
    return (
      <EmptyPanel className={classes.ManageVariables__EmptyPanel}>
        {t('manageVariables.empty.infoTextJobVariable')}
      </EmptyPanel>
    )
  }

  return (
    <JobVariablesList
      variables={jobVariables}
      onDelete={onDelete}
      onEdit={onEdit}
    />
  )
}
