import { rest, type RequestHandler } from 'msw'

import { PROJECT_ID } from '__test-utils__/ids'

import config from 'config'

import { type ListEnvironmentsResponse } from '../types'
import { mockedEnvironments } from './MockedEnvironments'

export const useListEnvironmentsHandlers: RequestHandler[] = [
  rest.get(
    `${config.getApiBaseUrl()}/v1/environments`,
    async (_req, res, ctx) => {
      const projectId = _req.url.searchParams.get('projectId') ?? PROJECT_ID
      return res(
        ctx.json<ListEnvironmentsResponse>(mockedEnvironments[projectId])
      )
    }
  )
]
