// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bEnCVDVJP0RfbpjZkzrb input+span{opacity:.5;cursor:default !important}`, "",{"version":3,"sources":["webpack://./src/components/PublishJobsToggle/PublishJobsToggle.module.scss"],"names":[],"mappings":"AAAA,iCACE,UAAA,CACA,yBAAA","sourcesContent":[".disabled input + span {\n  opacity: 0.5;\n  cursor: default !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disabled": `bEnCVDVJP0RfbpjZkzrb`
};
export default ___CSS_LOADER_EXPORT___;
