import {
  ComponentEditorTabType,
  EditorType,
  LookUpType,
  ParameterDataType,
  UseVariable,
  type ComponentMetadataResponse
} from 'api/hooks/useGetComponentMetadata/types'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

import { ColumnEditorType } from '../../../useGetParameterOptions/types'

export const mockFilterMetadata: ComponentMetadataResponse = {
  componentId: 'filter',
  metadata: {
    schemaVersion: 1,
    emeraldId: '-1760161015',
    componentType: JobType.Transformation,
    classification: 'TRANSFORMATION',
    inputPorts: [
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.ONE
      }
    ],
    outputPorts: [
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      }
    ],
    deprecated: false,
    exports: [],
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.SAMPLE,
      ComponentEditorTabType.METADATA,
      ComponentEditorTabType.SQL,
      ComponentEditorTabType.PLAN,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'filterConditions',
        metlSlot: 2,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_TRANSFORM_FILTER_CONDITIONS,
        lookupDependencies: ['transform.sql'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'combineConditions',
        metlSlot: 3,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: null,
            type: 'TEXT',
            options: ['AND', 'OR'],
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null
      }
    ]
  }
}
