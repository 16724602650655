import type {
  GridValue,
  ListValue,
  ParameterValue,
  ScalarValue,
  StructList,
  StructValue
} from 'types/Pipeline'

import { ParameterDataType } from 'api/hooks/useGetComponentMetadata/types'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'

import { type ElementCollection } from 'job-lib/types/Parameters'

const convertElementsToList = (elements: ElementCollection): ListValue =>
  Object.values(elements).map((element) => element.values[1].value)

const convertElementsToGrid = (elements: ElementCollection): GridValue => {
  return Object.values(elements).map((element) =>
    Object.values(element.values).map(({ value }) => value)
  )
}

const convertElementsToStruct = (
  elements: ElementCollection,
  editorColumns?: EditorColumn[]
): StructValue => {
  if (!editorColumns) {
    return {}
  }

  return editorColumns.reduce((struct, column, index) => {
    const element = elements?.[index + 1]
    const structKey = column.resourceID as string

    return {
      ...struct,
      [structKey]: element?.values[1].value ?? null
    }
  }, {})
}

const convertElementsToStructList = (
  elements: ElementCollection,
  editorColumns?: EditorColumn[]
): StructList => {
  if (!editorColumns) {
    return []
  }

  return Object.values(elements).map((element) => {
    return editorColumns.reduce((struct, column, index) => {
      const structKey = column.resourceID as string

      return {
        ...struct,
        [structKey]: element?.values[index + 1].value ?? null
      }
    }, {})
  })
}

const convertElementsToScalarValue = (
  elements: ElementCollection
): ScalarValue => elements[1].values[1].value

export const convertElementCollectionToValue = (
  collection: ElementCollection,
  dataType: ParameterDataType,
  editorColumns?: EditorColumn[]
): ParameterValue => {
  switch (dataType) {
    case ParameterDataType.LIST:
      return convertElementsToList(collection)
    case ParameterDataType.GRID:
      return convertElementsToGrid(collection)
    case ParameterDataType.STRUCT:
      return convertElementsToStruct(collection, editorColumns)
    case ParameterDataType.STRUCT_LIST:
      return convertElementsToStructList(collection, editorColumns)
    default:
      return convertElementsToScalarValue(collection)
  }
}
