import {
  type InitOAuthArgs,
  type InitOAuthRequest,
  type InitOAuthResponse
} from './types'
import { useCallback } from 'react'
import { type ExternalGitProvider } from 'types/ExternalGitProvider'
import { queryKeys } from '../../query-keys'
import { useMutation } from '@tanstack/react-query'
import useGitClient from 'api/clients/useGitClient'

export const useInitOAuthQueryKey = (
  provider: ExternalGitProvider | undefined
) => {
  return [queryKeys.initOAuth, provider]
}

const useInitOAuth = ({ provider }: InitOAuthArgs) => {
  const client = useGitClient()

  const initOAuth = useCallback(
    async (request: InitOAuthRequest) => {
      const url = `/providers/${provider}/oauth`

      const { data } = await client.post<InitOAuthResponse>(url, request)
      return data
    },
    [client, provider]
  )

  const queryKey = useInitOAuthQueryKey(provider)
  return useMutation(queryKey, initOAuth)
}

export default useInitOAuth
