import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'
import {
  type ConflictResolutionSelection,
  type LegacyDescribeMergeConflict
} from '@matillion/git-component-library'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import ConflictResolutionForm from './ConflictResolutionForm'

export interface ConflictsModalProps {
  submit: string
  close: () => void
  conflicts: LegacyDescribeMergeConflict[]
  onResolve: (resolutions: ConflictResolutionSelection[]) => unknown
}

const ConflictsModal = ({
  conflicts,
  onResolve,
  close,
  submit
}: ConflictsModalProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'conflicts.modal'
  })

  const { makeToast } = Toaster.useToaster()

  const handleCancel = () => {
    makeToast({
      type: 'info',
      title: t('toast.dismiss.title'),
      message: t('toast.dismiss.message')
    })
    close()
  }

  return (
    <DesignerModal
      size="mid"
      disableBackdropClick
      onCancel={handleCancel}
      ariaLabelledBy="conflicts-modal"
    >
      <ConflictResolutionForm
        submit={submit}
        onResolve={onResolve}
        conflicts={conflicts}
        onCancel={handleCancel}
      />
    </DesignerModal>
  )
}

export default ConflictsModal
