import {
  type ComponentParameter,
  type LookUpType
} from 'api/hooks/useGetComponentMetadata/types'
import { type GridParameter } from 'api/hooks/useGetParameterOptions/types'

import { type ParameterOptionsRequestType } from './useParameterOptionsTypes'

export type Parameter = ComponentParameter | GridParameter

export const isGridParameter = (
  parameter?: ComponentParameter | GridParameter
): parameter is GridParameter => parameter !== undefined && 'id' in parameter

export const getLookupData = (
  parameter: Parameter | undefined,
  requestType: ParameterOptionsRequestType
): {
  lookupType: LookUpType | null
  lookupDependencies: string[]
} => {
  switch (requestType) {
    case 'autofill':
    case 'autofill-nested':
      return {
        lookupDependencies: parameter?.autoFill?.lookupDependencies ?? [],
        lookupType: parameter?.autoFill?.lookupType ?? null
      }
    default:
      return {
        lookupDependencies: parameter?.lookupDependencies ?? [],
        lookupType: parameter?.lookupType ?? null
      }
  }
}
