import { useEffect, useState, type ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AutoComplete,
  Button,
  Field,
  Modal,
  Radio,
  Typography
} from '@matillion/component-library'
import classNames from 'classnames'

import { JobVariableType, VariableScope } from 'job-lib/types/Variables'

import {
  Stages,
  type ManageVariableStageProps
} from 'modules/ManageVariables/types'

import { Footer } from '../Footer/Footer'
import classes from '../Form/Form.module.scss'

export const CreateModal = ({
  setStage,
  setSelectedVariable,
  setSelectedVariableScope,
  selectedVariableScope,
  selectedVariableType,
  setSelectedVariableType,
  onCancel,
  onClose,
  isModalOpen
}: ManageVariableStageProps) => {
  const { t } = useTranslation()
  const [isValid, setIsValid] = useState<boolean | null>()
  const [isTouched, setIsTouched] = useState(false)

  useEffect(() => {
    setIsValid(
      selectedVariableType !== null && selectedVariableType !== undefined
    )
  }, [selectedVariableType])

  const onNext = () => {
    setIsTouched(true)
    if (isValid) {
      setStage(Stages.CREATE)
      onClose?.()
    }
  }
  return (
    <Modal
      ariaLabelledBy="modal-title"
      onCancel={onCancel}
      className={classes.Form__CreateModal}
    >
      <div className={classes.Form__Header}>
        <Typography
          id="modal-title"
          format="ts"
          as="h2"
          className={classes.Form__Title}
        >
          {t('manageVariables.create.title')}
        </Typography>
      </div>

      <fieldset className={classNames(classes.Form__ScopeFieldset)}>
        <legend className="u-visually-hidden">
          {t('manageVariables.jobVariables.fields.variableScopes')}
        </legend>

        <Typography format="bcs" weight="bold" className={classes.Form__Scope}>
          {t('manageVariables.manage.projectOrPipeline')}
        </Typography>
        <Field
          data-testid="project-variable-radio-button"
          className={classNames(classes.Field, classes['Field--Radio'])}
          inputComponent={Radio}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSelectedVariableScope?.(VariableScope.PROJECT_VARIABLE)
          }}
          text={t('manageVariables.jobVariables.fields.projectVariable')}
          supportText={t(
            'manageVariables.jobVariables.fields.projectRadioSupportText'
          )}
          value={VariableScope.PROJECT_VARIABLE}
          checked={selectedVariableScope === VariableScope.PROJECT_VARIABLE}
        />
        <Field
          data-testid="job-variable-radio-button"
          className={classNames(classes.Field, classes['Field--Radio'])}
          inputComponent={Radio}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSelectedVariableScope?.(VariableScope.JOB_VARIABLE)
          }}
          text={t('manageVariables.jobVariables.fields.jobVariable')}
          supportText={t(
            'manageVariables.jobVariables.fields.jobRadioSupportText'
          )}
          value={VariableScope.JOB_VARIABLE}
          checked={selectedVariableScope === VariableScope.JOB_VARIABLE}
        />
      </fieldset>
      <Field
        name={t('manageVariables.jobVariables.fields.type')}
        className={classNames(classes.Field, classes['Field--Type'])}
        title={t('manageVariables.jobVariables.fields.type')}
        errorText={
          !isValid &&
          isTouched &&
          t('manageVariables.jobVariables.fields.errors.pleaseSelectAnOption')
        }
        value={selectedVariableType}
      >
        <AutoComplete
          placeholder={t(
            'manageVariables.jobVariables.fields.dropdownPlaceholder'
          )}
          onChange={(value) => {
            setSelectedVariableType?.(value.target.value?.id as JobVariableType)
          }}
          onBlur={() => {
            !isValid &&
              t('manageVariables.jobVariables.errors.pleaseSelectAnOption')
          }}
          availableItems={[
            {
              id: JobVariableType.TEXT,
              name: t('manageVariables.jobVariables.fields.variableType.text')
            },
            {
              id: JobVariableType.NUMBER,
              name: t('manageVariables.jobVariables.fields.variableType.number')
            }
          ]}
          value={selectedVariableType}
        />
      </Field>

      <Footer isModalOpen={isModalOpen}>
        <Button alt="secondary" onClick={onCancel} text={t('common.cancel')} />
        <Button
          data-testid={'next-button'}
          type="button"
          text={t('manageVariables.create.next')}
          onClick={onNext}
        />
      </Footer>
    </Modal>
  )
}
