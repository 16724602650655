import { useTranslation } from 'react-i18next'

import { type AxiosError } from 'axios'
import { type ProblemDetails } from 'types/ProblemDetails'
import {
  type ProblemDetailRegistry,
  type ProblemDetailResponse,
  type ResolveProblemDetailsResponse,
  type TranslationArg,
  type TranslationKeys
} from 'hooks/useProblemDetails/types'
import { Problem } from './Problem'
import useTranslationInterpolation from 'hooks/useTranslationInterpolation'
import { Toaster } from '@matillion/component-library'

const isAxiosError = (error: unknown): error is AxiosError => {
  // @ts-expect-error not much we can do in a type-guard
  return 'isAxiosError' in error
}

const useProblemDetails = (
  registry: ProblemDetailRegistry = [],
  messageArgs?: TranslationArg[]
): ResolveProblemDetailsResponse => {
  const { t } = useTranslation()
  const { makeToast } = Toaster.useToaster()
  const { computeInterpolationMap } = useTranslationInterpolation()

  const findTranslationKeyRecord = (problemType: string | undefined) => {
    return registry.find((value) => value.type === problemType)
  }

  const computeTranslationText = (
    keys: TranslationKeys
  ): ProblemDetailResponse => {
    const interpolationMap = computeInterpolationMap(messageArgs)

    return {
      title: t(keys.titleKey, interpolationMap),
      message: t(keys.messageKey, interpolationMap)
    }
  }

  const parseErrorResponse = (error: unknown): ProblemDetails | undefined => {
    const isCorrectErrorType = isAxiosError(error)

    if (isCorrectErrorType && error.response && error.response.headers) {
      const contentTypeHeaders = error.response.headers['content-type']
      const hasCorrectContentType = contentTypeHeaders?.includes(
        'application/problem+json'
      )

      if (hasCorrectContentType) {
        return error.response.data as ProblemDetails
      }
    }

    return undefined
  }

  const resolveProblemDetails = (error: unknown): void => {
    const problem = parseErrorResponse(error)
    if (problem) {
      const translationKeyRecord = findTranslationKeyRecord(problem.type)
      const translationKeys = translationKeyRecord?.translation

      if (translationKeys) {
        const { title, message } = computeTranslationText(translationKeys)
        makeToast({
          title,
          message,
          type: 'error'
        })
      }
    }
  }

  const isProblemType = (error: unknown, ...type: Problem[]): boolean => {
    const problem = parseErrorResponse(error)

    if (problem?.type) {
      return type.map((it) => it.valueOf()).includes(problem.type)
    }

    return false
  }

  const isKnownProblem = (error: unknown): boolean => {
    const problemResponse = parseErrorResponse(error)

    if (problemResponse?.type) {
      return Object.values(Problem).includes(problemResponse.type as Problem)
    }

    return false
  }

  return {
    isKnownProblem,
    isProblemType,
    resolveProblemDetails
  }
}

export default useProblemDetails
