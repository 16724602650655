import { useMutation } from '@tanstack/react-query'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { useProjectExplorerClient } from '../useProjectExplorerClient/useProjectExplorerClient'
import { createProjectVariablesApi } from './createProjectVariablesApi'
import { type CreateProjectVariablesRequest } from './types'

export const useCreateProjectVariables = () => {
  const { projectId } = useProjectInfo()
  const client = useProjectExplorerClient()
  return useMutation(
    async (createProjectVariablesRequest: CreateProjectVariablesRequest) => {
      return createProjectVariablesApi(
        client,
        projectId,
        createProjectVariablesRequest
      )
    }
  )
}
