import { type DeploymentEnvironment } from 'api/context/types'

export interface GitFeatureFlags {
  [key: string]: boolean | string[]
  testFlagOn: boolean
  testFlagOff: boolean
  enableWorkingTreeMerge: boolean
}

/**
 * The flag keys must be in their original form as known to
 * LaunchDarkly, rather than in their camel-cased form.
 * https://docs.launchdarkly.com/sdk/client-side/react/react-web#configuring-the-react-sdk
 */
export const FeatureFlagDefaults: Record<
  DeploymentEnvironment,
  Record<string, boolean>
> = {
  local: {
    'test-flag-on': true,
    'test-flag-off': false,
    'enable-working-tree-merge': false
  },
  test: {
    'test-flag-on': true,
    'test-flag-off': false,
    'enable-working-tree-merge': true
  },
  dev: {
    'test-flag-on': true,
    'test-flag-off': false,
    'enable-working-tree-merge': false
  },
  perf: {
    'test-flag-on': true,
    'test-flag-off': false,
    'enable-working-tree-merge': false
  },
  preprod: {
    'test-flag-on': true,
    'test-flag-off': false,
    'enable-working-tree-merge': false
  },
  prod: {
    'test-flag-on': true,
    'test-flag-off': false,
    'enable-working-tree-merge': false
  }
}

export const launchDarklyClientId: Record<DeploymentEnvironment, string> = {
  local: '6516d673cfc87c12829607c5',
  test: '6516d673cfc87c12829607c5',
  perf: '6516d6c0cc1af31267a42c8f',
  dev: '6516d673cfc87c12829607c5',
  preprod: '6516d6b3de0eaf11af70c696',
  prod: '6513044ac4520b12efd4ab73'
}
