const MOCK_JWT = 'mock-hub-client-token'
export const MOCK_USER = {
  user: {
    given_name: 'Jeffrey',
    email: 'Jeff@rey.com',
    name: 'Jeffrey McElroy',
    picture: 'https://placekitten.com/200/200',
    nickname: 'jeffrey.mcelroy',
    updated_at: '2021-06-15T13:21:30.395Z'
  },
  organisation: {
    id: '00000000-0000-0000-0000-000000000000',
    name: 'Munch Squad',
    subdomain: 'munch',
    region: 'eu1'
  },
  profile: {
    email: 'jeff@rey.com',
    name: 'Jeffrey McElroy',
    icon: 'https://placekitten.com/200/200',
    organisation: 'Munch Squad'
  },
  roles: new Set(['saas-etl:user'])
}

export const MOCK_FLAGS = {}

let isLoggedIn = true

const useAuth = () => ({
  getToken: () => MOCK_JWT,
  isLoading: false,
  isLoggedIn,
  login: () => {
    isLoggedIn = true
  },
  logout: () => {
    isLoggedIn = false
  }
})

const useUser = () => MOCK_USER

const useFlags = () => MOCK_FLAGS

export * from '@matillion/hub-client'

export { useAuth, useUser, useFlags }
