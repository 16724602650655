import { type TFunction } from 'react-i18next'

import {
  EditorType,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'

import { isModularFlexOrCustom } from 'job-lib/cisIds/idType'

const shouldHideGroupName = (
  componentId: string,
  parameter: ComponentParameter
) => {
  return (
    isModularFlexOrCustom(componentId) &&
    (parameter.editorType === EditorType.PARAMETER_GROUP ||
      parameter.editorType === EditorType.CONNECTION_EDITOR)
  )
}

export const getParameterName = (
  componentId: string,
  parameter: ComponentParameter,
  t: TFunction
): {
  parameterName: string
  shouldHideParameterGroupName: boolean
} => {
  const parameterName = isModularFlexOrCustom(componentId)
    ? parameter.displayName ?? ''
    : t([
        `componentProperties.parameterIds.${parameter.resourceID as string}`,
        parameter.displayName ??
          `componentProperties.parameterIds.${parameter.dplID}`
      ])
  const shouldHideParameterGroupName = shouldHideGroupName(
    componentId,
    parameter
  )
  return {
    parameterName,
    shouldHideParameterGroupName
  }
}
