import { type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'
import { ServiceKey, useServiceUrl } from '@matillion/hub-client'

import { type ProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { getProjectExplorerLink } from 'utils/getProjectExplorerLink'

import classes from '../ProjectToolbar.module.scss'

const ProjectExplorerLink: FC<{ projectInfo: ProjectInfo }> = ({
  projectInfo
}) => {
  const { t } = useTranslation()
  const projectExplorerOrigin = useServiceUrl(
    ServiceKey.projectExplorerFrontend,
    true
  )
  const { projectId } = projectInfo

  return (
    <div className={classes.ProjectExplorer__LinkContainer}>
      <a
        className={classes.ProjectExplorer__ManageLink}
        data-testid="project-toolbar-projectExplorer"
        href={getProjectExplorerLink(projectExplorerOrigin, projectId)}
        rel="noopener norefserrer noreferrer"
      >
        <span className={classes.ProjectExplorer__IconWrapper}>
          <Icon.ChevronFilled />
        </span>
        <Typography format="bcs">
          {t('projectToolbox.projectExplorer')}
        </Typography>
      </a>
    </div>
  )
}

export default ProjectExplorerLink
