import React, { type PropsWithChildren, useEffect } from 'react'

import { type PactHookConfig } from './types'
import { QueryClientProvider } from '@tanstack/react-query'
import { GitContext, type GitContextBag } from 'provider/GitProvider'
import axios from 'axios'
import {
  PROJECT_ID,
  BRANCH_NAME,
  PACT_ID_HEADER,
  ACCOUNT_ID
} from '__test-utils__/pact.config'
import { clientConfig } from 'api/clients/useClient/useClient'
import EventProvider from 'provider/EventProvider'
import { type EventProviderProps } from 'provider/EventProvider/types'
import useGitQueryClient from 'api/useGitQueryClient'
import { I18nextProvider } from 'react-i18next'
import I18n from 'i18n'
import ApiContextProvider from 'api/context/ApiContextProvider'
import GitQueryClientProvider from 'api/provider/GitQueryClientProvider'

type PactHookProvidersProps = PropsWithChildren<Partial<PactHookConfig>>

export const pactDesignerClient = axios.create({
  headers: {
    'account-id': ACCOUNT_ID
  }
})

const defaultProviderProps = (): GitContextBag => {
  return {
    hasUncommittedChanges: false,
    isLegacyWTS: true,
    branch: BRANCH_NAME,
    project: PROJECT_ID,
    isNativeGit: false,
    designerClient: pactDesignerClient
  }
}

const TestQueryClient = ({ children }: PropsWithChildren) => {
  const queryClient = useGitQueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false
      }
    }
  })

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

const defaultEventProviderProps: EventProviderProps = {
  onRefreshWorkingTreeStatus: async () => {
    console.debug('onRefreshWorkingTreeStatus was called during a pact test.')
  }
}

const GitTestProviders = ({
  children,
  providerProps
}: PropsWithChildren<{ providerProps: GitContextBag }>) => {
  return (
    <I18nextProvider i18n={I18n}>
      <ApiContextProvider environment="test">
        <GitQueryClientProvider>
          <GitContext.Provider value={providerProps}>
            {children}
          </GitContext.Provider>
        </GitQueryClientProvider>
      </ApiContextProvider>
    </I18nextProvider>
  )
}

const CoreProviders = ({
  gitProvider,
  eventProvider,
  interactionId,
  children
}: PactHookProvidersProps) => {
  const providerProps = {
    ...defaultProviderProps(),
    ...gitProvider
  }

  const eventProviderProps = {
    ...defaultEventProviderProps,
    ...eventProvider
  }

  useEffect(() => {
    if (interactionId) {
      clientConfig.headers = {
        [PACT_ID_HEADER]: interactionId
      }
    }
  }, [interactionId])

  return (
    <div data-testid="core-pact-providers">
      <EventProvider {...eventProviderProps}>
        <GitTestProviders providerProps={providerProps}>
          <TestQueryClient>{children}</TestQueryClient>
        </GitTestProviders>
      </EventProvider>
    </div>
  )
}

const PactHookProviders = (props: PactHookProvidersProps) => {
  const CustomWrapper = props.wrapper

  if (CustomWrapper) {
    return (
      <CustomWrapper>
        <CoreProviders {...props} />
      </CustomWrapper>
    )
  }

  return <CoreProviders {...props} />
}

export default PactHookProviders
