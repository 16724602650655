import { useMemo, useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import classnames from 'classnames'
import { isEqual } from 'lodash'

import {
  type ComponentMetadata,
  type ComponentMetadataParameterId
} from 'api/hooks/useGetComponentMetadata/types'
import { type ProblemDetails } from 'api/types/http-problem-details'

import { isModularFlexOrCustom } from 'job-lib/cisIds/idType'
import {
  headerParamsParameter,
  queryParamsParameter,
  uriParamsParameter
} from 'job-lib/cisIds/knownComponentParameters'
import {
  type ComponentInstance,
  type ComponentInstanceId
} from 'job-lib/types/Job'
import { type ElementCollection } from 'job-lib/types/Parameters'

import { useComponentValidationResult } from 'modules/core/ComponentValidation'

import classes from '../../ComponentParameters.module.scss'
import { type ComponentParameterBag } from '../../types'
import { NameRenderer } from '../NameRenderer/NameRenderer'
import { ValueRenderer } from '../ValueRenderer/ValueRenderer'
import { ComponentParameterMessage } from './ComponentParameterMessage'

interface ComponentParameterProps {
  componentInstance: ComponentInstance
  componentMetadata: ComponentMetadata
  parameter: ComponentParameterBag
  onEdit: (editedValue: ElementCollection) => void
  path: string[]
}

const useComponentParameterValidationError = ({
  componentInstanceId,
  parameterSlot,
  dplId,
  path
}: {
  componentInstanceId: ComponentInstanceId
  parameterSlot: number
  dplId: ComponentMetadataParameterId | undefined
  path: string[]
}) => {
  const { failures } = useComponentValidationResult(componentInstanceId)

  return useMemo(
    () =>
      failures?.find(
        (failure) => failure.path.at(1) === String(parameterSlot)
      ) ?? failures?.find((failure) => isEqual(path, failure.path.slice(1))),

    [failures, parameterSlot, path]
  )
}

export const ComponentParameter: FunctionComponent<ComponentParameterProps> = ({
  componentInstance,
  componentMetadata,
  parameter,
  onEdit,
  path
}) => {
  const { t } = useTranslation()
  const [editorError, setEditorError] = useState<ProblemDetails>()

  const validationError = useComponentParameterValidationError({
    componentInstanceId: componentInstance.id,
    parameterSlot: parameter.parameterSlot,
    dplId: parameter.parameterMetadata?.dplID,
    path
  })

  const parameterLabelId =
    parameter.parameterMetadata?.dplID ?? parameter.parameterSlot

  const componentSummaryId = parameter.componentSummaryId
  const labelId = `param-label-${parameterLabelId}`
  const inputId = `param-input-${parameterLabelId}`

  const visuallyHideOptionalLabel =
    isModularFlexOrCustom(componentSummaryId) &&
    [queryParamsParameter, headerParamsParameter, uriParamsParameter].includes(
      parameterLabelId as string
    )

  return (
    <div
      role="row"
      className={classnames(
        classes.ComponentParameters__Row,
        classes.ComponentParameter,
        {
          [classes['ComponentParameter--Error']]:
            validationError ?? editorError,
          'u-visually-hidden': parameter.isVisuallyHidden
        }
      )}
    >
      <div
        role="cell"
        className={classnames(
          classes.ComponentParameters__Cell,
          classes['ComponentParameters__Cell--Name'],
          classes.ComponentParameter__Name
        )}
      >
        <NameRenderer
          parameterName={parameter.parameterName}
          labelId={labelId}
          inputId={inputId}
          isOptional={visuallyHideOptionalLabel ? false : parameter.isOptional}
        />
      </div>

      <div
        role="cell"
        className={classnames(
          classes.ComponentParameters__Cell,
          classes['ComponentParameters__Cell--Value'],
          classes.ComponentParameter__Value
        )}
      >
        <ValueRenderer
          labelId={labelId}
          inputId={inputId}
          value={parameter.value}
          hasError={Boolean(validationError)}
          elements={parameter.elements}
          componentSummaryId={componentSummaryId}
          componentMetadata={componentMetadata}
          componentInstanceId={componentInstance.id}
          parameter={parameter.parameterMetadata}
          parameterName={parameter.parameterName}
          onEdit={onEdit}
          onEditorError={setEditorError}
        />
      </div>

      {validationError && (
        <div
          role="cell"
          className={classnames(
            classes.ComponentParameters__Cell,
            classes['ComponentParameters__Cell--Message'],
            classes.ComponentParameter__Message
          )}
        >
          <ComponentParameterMessage
            type="error"
            message={validationError.message}
          />
        </div>
      )}

      {editorError && (
        <div
          role="cell"
          className={classnames(
            classes.ComponentParameters__Cell,
            classes['ComponentParameters__Cell--Message'],
            classes.ComponentParameter__Message
          )}
        >
          <ComponentParameterMessage
            type="error"
            message={t('componentProperties.status.lookupFailed', {
              parameterName: parameter.parameterName,
              errorMessage: editorError.detail
            })}
          />
        </div>
      )}
    </div>
  )
}
