import { type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import {
  DataGrid,
  type DataGridColumnProps
} from '@matillion/component-library'

import classes from './Grid.module.scss'
import { type GridProps, type GridRow } from './types'
import { generateGridColumns } from './utils'

export const Grid: FunctionComponent<GridProps> = ({
  rows,
  updateRows,
  columns,
  isSelectable,
  elements,
  selectedRows,
  onSelectedChange,
  componentMetaData,
  componentInstanceId,
  componentSummaryId,
  parameter,
  allSelectable,
  isSelectAll,
  onSelectAllChange
}) => {
  const { t } = useTranslation()

  const gridColumns: Array<DataGridColumnProps<GridRow>> = generateGridColumns({
    columns,
    elements,
    onChange: updateRows,
    componentMetaData,
    componentInstanceId,
    componentSummaryId,
    parameter,
    t
  })

  const selectableDataGrid = {
    isSelectable: true,
    selectedRows,
    selectRowLabelName: (id: string) => `Select to delete row ${id}`,
    onSelectedChange,
    selectColumnLabelName: allSelectable && t('common.selectAll'),
    allSelectable,
    isSelectAll,
    onSelectAllChange
  }

  return (
    <DataGrid
      columns={gridColumns}
      rows={rows}
      className={classes.DataGrid_Override}
      {...(isSelectable ? selectableDataGrid : {})}
      compact
    />
  )
}
