import { GIT_AUTH_EVENT_ID } from 'modules/BringYourOwnGit/components/AuthMessageListener/types'

class AuthUtility {
  /**
   * We can't go off origin to the third-party git provider
   * and have it redirect to the interstitial page. Instead, we're
   * emulating the postMessage() call that it makes, so we can test the
   * listener that will catch the message on the origin.
   */
  public emulateMessageFromInterstitialPage() {
    cy.url().then((originUrl) => {
      cy.window().then((win) => {
        win.postMessage(
          {
            type: GIT_AUTH_EVENT_ID,
            state: 'test_state',
            code: 'test_code'
          },
          originUrl
        )
      })
    })
  }
}

const authUtility = new AuthUtility()

export default authUtility
