export const mockOrchestrationValues = {
  START_0: {
    id: 1670,
    name: 'Start 0'
  },
  CREATE_TABLE_0: {
    id: 15614,
    name: 'Create Table 0'
  },
  AND_0: {
    id: 15652,
    name: 'And 0'
  },
  END_FAILURE_0: {
    id: 15678,
    name: 'End Failure 0'
  },
  END_SUCCESS_0: {
    id: 15680,
    name: 'End Success 0'
  },
  RUN_TRANSFORMATION_0: {
    id: 16069,
    name: 'Run Transformation 0'
  },
  CREATE_TABLE_1: {
    id: 16092,
    name: 'Create Table 1'
  },
  SQL_SCRIPT_0: {
    id: 971494,
    name: 'SQL Script 0'
  },
  S3_LOAD_0: {
    id: 832458,
    name: 'S3 Load 0'
  },
  RETRY_0: {
    id: 13694,
    name: 'Retry 0'
  },
  SQS_MESSAGE_0: {
    id: 983249,
    name: 'SQS Message 0'
  },
  CREATE_FILE_FORMAT_0: {
    id: 655623,
    name: 'Create File Format 0'
  },
  SNS_MESSAGE_0: {
    id: 78654,
    name: 'SNS Message 0'
  },
  ASSERT_TABLE_0: {
    id: 34527,
    name: 'Assert Table 0'
  },
  IF_0: {
    id: 34528,
    name: 'If 0'
  }
}
