import {
  LookUpType,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import {
  type ComponentDependency,
  type GridParameter
} from 'api/hooks/useGetParameterOptions/types'

import {
  type ComponentInstanceId,
  type OrchestrationComponentInstance,
  type OrchestrationJob,
  type TransformationComponentInstance,
  type TransformationJob
} from 'job-lib/types/Job'

interface ComponentDependenciesProps {
  parameter: ComponentParameter | GridParameter | undefined
  componentId: ComponentInstanceId | null
  job: OrchestrationJob | TransformationJob | null
}

export const getComponentDependencies = ({
  parameter,
  componentId,
  job
}: ComponentDependenciesProps): ComponentDependency | undefined => {
  if (!parameter) return undefined
  const { lookupType, lookupDependencies } = parameter

  if (
    lookupType === LookUpType.DYNAMIC_EMERALD_OPTIONS &&
    lookupDependencies?.includes('component') &&
    lookupDependencies?.includes('metlSlot')
  ) {
    const fullComponent:
      | OrchestrationComponentInstance
      | TransformationComponentInstance = Object.values(
      job?.components ?? {}
    ).find((e) => e.id === componentId)

    if (!fullComponent) return undefined

    return {
      component: fullComponent,
      metlSlot: parameter.metlSlot
    }
  }

  return undefined
}
