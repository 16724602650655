import { useMemo } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { type ValidateComponentParsedResponse } from 'api/hooks/useValidateComponent/types'
import { useValidateComponent } from 'api/hooks/useValidateComponent/useValidateComponent'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { getComponentLabel } from 'job-lib/job-functions/getComponentLabel'
import { type JobState } from 'job-lib/store/jobSlice/job.types'
import { JobType } from 'job-lib/types/JobType'

import { type ComponentQueryKeys } from '../types'
import { getDefaultQueryKeys } from '../utils/getDefaultQueryKeys'
import { getDependentComponentNames } from '../utils/getDependentComponentNames'
import { getQueryKey } from '../utils/getQueryKey'

export const useGenerateTransformationQueries = ({
  jobState,
  lastValidated,
  componentQueryKeys
}: {
  jobState: JobState
  lastValidated: number
  componentQueryKeys: ComponentQueryKeys | null
}) => {
  const { job, jobType } = jobState
  const queryClient = useQueryClient()
  const { jobSummaryId } = useProjectInfo()
  const validateComponent = useValidateComponent({ jobState })

  const queries = useMemo(() => {
    if (jobType !== JobType.Transformation || !componentQueryKeys) {
      return null
    }

    const componentQueryKeyNames = Object.keys(componentQueryKeys)

    return componentQueryKeyNames.map((componentName) => {
      const componentId = Object.keys(job.components).find(
        (cId) =>
          job.components[parseInt(cId)].parameters[1].elements[1].values[1]
            .value === componentName
      )
      const connectors = Object.values(job.connectors)

      const inputConnections = connectors.filter(
        (connection) => Number(connection.targetID) === Number(componentId)
      )

      const queryCache =
        queryClient.getQueriesData<ValidateComponentParsedResponse>(
          getDefaultQueryKeys(jobSummaryId)
        )

      const hasQueryCache = queryCache.find(
        (cache) => cache[1]?.componentName.toString() === componentName
      )

      const hasUpstreamCache = inputConnections.every((connection) => {
        const upstreamCache = queryCache.find(([cacheQueryKeys]) => {
          return getDependentComponentNames(cacheQueryKeys, jobSummaryId).some(
            (key) =>
              key.at(-1) ===
              getComponentLabel(job.components[Number(connection.sourceID)])
          )
        })

        return (
          upstreamCache?.[1] !== undefined &&
          upstreamCache[1]?.status !== 'INVALID'
        )
      })

      const componentCache = inputConnections.reduce((cache, connection) => {
        const sourceId = Number(connection.sourceID)
        const sourceName: string | undefined =
          job?.components[sourceId]?.parameters[1]?.elements[1]?.values[1]
            ?.value
        const cacheItem = queryCache.find(([cacheQueryKeys]) =>
          getDependentComponentNames(cacheQueryKeys, jobSummaryId).some(
            (key) => key.at(-1) === sourceName
          )
        )

        if (sourceName !== undefined && cacheItem?.[1]) {
          return {
            ...cache,
            [sourceName]: cacheItem[1].onSuccess
          }
        }
        return cache
      }, {})

      const componentKeys = componentQueryKeys[componentName]

      const isComponentValidationEnabled =
        Boolean(lastValidated) &&
        componentKeys !== undefined &&
        (inputConnections.length === 0 || hasUpstreamCache) &&
        !hasQueryCache

      return {
        queryKey: getQueryKey(jobSummaryId, componentKeys),
        enabled: isComponentValidationEnabled,
        staleTime: 0,
        queryFn: async () =>
          validateComponent({
            component: job?.components[Number(componentId)],
            cache: componentCache
          })
      }
    })
  }, [
    job,
    jobType,
    queryClient,
    jobSummaryId,
    lastValidated,
    componentQueryKeys,
    validateComponent
  ])

  return queries
}
