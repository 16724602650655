import {
  EditorType,
  LookUpType,
  ParameterDataType,
  UseVariable,
  VisibleWhenOperator,
  type ComponentMetadataResponse
} from 'api/hooks/useGetComponentMetadata/types'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

export const mockAnaplanModularConnectorMetadata: ComponentMetadataResponse = {
  componentId: 'modular-anaplan-input-v1',
  metadata: {
    schemaVersion: 1,
    emeraldId: '1457434621',
    classification: 'MODULAR',
    componentType: JobType.Orchestration,
    inputPorts: [
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.ONE
      }
    ],
    outputPorts: [
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.MANY
      },
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.MANY
      }
    ],
    deprecated: false,
    exports: [],
    editorTabs: [],
    parameters: [
      {
        dplID: 'componentName',
        resourceID: 'componentName',
        metlSlot: 0,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        optional: false,
        displayName: 'Name'
      },
      {
        dplID: 'inputId',
        resourceID: 'inputId',
        metlSlot: 0,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        optional: false,
        defaultValue: 'anaplan-input-v1',
        displayName: 'Input ID'
      },
      {
        dplID: 'anaplan-input-v1',
        resourceID: 'AnaplanInput',
        metlSlot: 0,
        dataType: ParameterDataType.STRUCT,
        editorType: EditorType.PARAMETER_GROUP,
        visibleWhen: [
          {
            param: 'inputId',
            value: 'anaplan-input-v1',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        childProperties: [
          {
            dplID: 'connection',
            resourceID: 'connection',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.STRUCT,
            editorType: EditorType.CONNECTION_EDITOR,
            optional: false,
            childProperties: [
              {
                dplID: 'authType',
                resourceID: 'authType',
                metlSlot: 0,
                useVariable: UseVariable.NO,
                dataType: ParameterDataType.TEXT,
                editorType: EditorType.DROPDOWN,
                optional: false,
                options: ['USERNAME_PASSWORD'],
                displayName: 'Authentication Type'
              },
              {
                dplID: 'secretReferenceId',
                resourceID: 'secretReferenceId',
                metlSlot: 0,
                useVariable: UseVariable.YES,
                dataType: ParameterDataType.TEXT_SECRET_REF,
                authProvider: 'anaplan',
                editorType: EditorType.DROPDOWN,
                lookupType: LookUpType.DESIGNER_SECRET_TYPE,
                lookupDependencies: [
                  'param.anaplan-input-v1.connection.authType:secretType',
                  'this.authProvider:authProvider'
                ],
                visibleWhen: [
                  {
                    param: 'anaplan-input-v1.connection.authType',
                    value: null,
                    operator: VisibleWhenOperator.HAS_VALUE
                  },
                  {
                    param: 'anaplan-input-v1.connection.authType',
                    value: 'NONE',
                    operator: VisibleWhenOperator.NOT_EQUALS
                  }
                ],
                optional: false,
                defaultValue: '[Custom]',
                displayName: 'Auth Secret'
              },
              {
                dplID: 'overrides',
                resourceID: 'overrides',
                metlSlot: 0,
                useVariable: UseVariable.NO,
                dataType: ParameterDataType.STRUCT,
                editorType: EditorType.FREETEXT,
                visibleWhen: [
                  {
                    param: 'anaplan-input-v1.connection.authType',
                    value: null,
                    operator: VisibleWhenOperator.HAS_VALUE
                  },
                  {
                    param: 'anaplan-input-v1.connection.authType',
                    value: 'NONE',
                    operator: VisibleWhenOperator.NOT_EQUALS
                  },
                  {
                    param: 'anaplan-input-v1.connection.secretReferenceId',
                    value: null,
                    operator: VisibleWhenOperator.HAS_VALUE
                  }
                ],
                optional: false,
                childProperties: [
                  {
                    dplID: 'username',
                    resourceID: 'AnaplanInput_connection_username',
                    metlSlot: 0,
                    useVariable: UseVariable.YES,
                    dataType: ParameterDataType.TEXT,
                    editorType: EditorType.FREETEXT,
                    visibleWhen: [
                      {
                        param: 'anaplan-input-v1.connection.secretReferenceId',
                        value: '[Custom]',
                        operator: VisibleWhenOperator.EQUALS
                      },
                      {
                        param: 'anaplan-input-v1.connection.authType',
                        value: 'USERNAME_PASSWORD',
                        operator: VisibleWhenOperator.EQUALS
                      }
                    ],
                    optional: false,
                    displayName: 'Username'
                  },
                  {
                    dplID: 'password',
                    resourceID: 'AnaplanInput_connection_password',
                    metlSlot: 0,
                    useVariable: UseVariable.YES,
                    dataType: ParameterDataType.TEXT_SECRET_REF,
                    editorType: EditorType.SECRET_REFERENCE,
                    lookupType: LookUpType.DESIGNER_PASSWORD,
                    visibleWhen: [
                      {
                        param: 'anaplan-input-v1.connection.secretReferenceId',
                        value: '[Custom]',
                        operator: VisibleWhenOperator.EQUALS
                      },
                      {
                        param: 'anaplan-input-v1.connection.authType',
                        value: 'USERNAME_PASSWORD',
                        operator: VisibleWhenOperator.EQUALS
                      }
                    ],
                    optional: false,
                    displayName: 'Password'
                  }
                ],
                displayName: 'Overrides'
              }
            ],
            displayName: 'Connection'
          },
          {
            dplID: 'workspace',
            resourceID: 'workspace',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP,
            lookupDependencies: [
              'param.anaplan-input-v1.connection',
              'paramId.anaplan-input-v1.workspace'
            ],
            optional: false,
            displayName: 'Workspace'
          },
          {
            dplID: 'model',
            resourceID: 'model',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP,
            lookupDependencies: [
              'param.anaplan-input-v1.connection',
              'param.anaplan-input-v1.workspace',
              'paramId.anaplan-input-v1.model'
            ],
            optional: false,
            displayName: 'Model'
          },
          {
            dplID: 'dataSourceType',
            resourceID: 'dataSourceType',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            optional: false,
            defaultValue: 'EXPORT',
            options: ['EXPORT'],
            displayName: 'Data Source Type'
          },
          {
            dplID: 'export',
            resourceID: 'export',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP,
            lookupDependencies: [
              'param.anaplan-input-v1.connection',
              'param.anaplan-input-v1.model',
              'param.anaplan-input-v1.workspace',
              'paramId.anaplan-input-v1.export'
            ],
            visibleWhen: [
              {
                param: 'dataSourceType',
                value: 'EXPORT',
                operator: VisibleWhenOperator.EQUALS
              }
            ],
            optional: false,
            displayName: 'Export'
          },
          {
            dplID: 'module',
            resourceID: 'module',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP,
            lookupDependencies: [
              'param.anaplan-input-v1.connection',
              'param.anaplan-input-v1.model',
              'param.anaplan-input-v1.workspace',
              'paramId.anaplan-input-v1.module'
            ],
            visibleWhen: [
              {
                param: 'dataSourceType',
                value: 'VIEW',
                operator: VisibleWhenOperator.EQUALS
              }
            ],
            optional: true,
            displayName: 'Module'
          },
          {
            dplID: 'view',
            resourceID: 'view',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP,
            lookupDependencies: [
              'param.anaplan-input-v1.connection',
              'param.anaplan-input-v1.model',
              'param.anaplan-input-v1.workspace',
              'paramId.anaplan-input-v1.view'
            ],
            visibleWhen: [
              {
                param: 'dataSourceType',
                value: 'VIEW',
                operator: VisibleWhenOperator.EQUALS
              }
            ],
            optional: false,
            displayName: 'View'
          }
        ],
        displayName: 'Anaplan Input'
      },
      {
        dplID: 'outputId',
        resourceID: 'outputId',
        metlSlot: 0,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.DROPDOWN,
        lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP,
        optional: false,
        displayName: 'Destination'
      },
      {
        dplID: 'storage-only-output-v0',
        resourceID: 'StorageOnlyOutput',
        metlSlot: 0,
        dataType: ParameterDataType.STRUCT,
        editorType: EditorType.PARAMETER_GROUP,
        visibleWhen: [
          {
            param: 'outputId',
            value: 'storage-only-output-v0',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        childProperties: [
          {
            dplID: 'folderPath',
            resourceID: 'folderPath',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.FREETEXT,
            optional: true,
            displayName: 'Folder Path'
          },
          {
            dplID: 'filePrefix',
            resourceID: 'filePrefix',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.FREETEXT,
            optional: true,
            defaultValue: 'part-',
            displayName: 'File Prefix'
          },
          {
            dplID: 'storage',
            resourceID: 'storage',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            optional: false,
            defaultValue: 'S3',
            options: ['S3', 'AZURE'],
            displayName: 'Storage'
          },
          {
            dplID: 's3#bucket',
            resourceID: 's3#bucket',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP,
            lookupDependencies: [
              'param.storage-only-output-v0.storage',
              'paramId.storage-only-output-v0.s3#bucket',
              'param.inputId',
              'param.anaplan-input-v1:param.inputConnector'
            ],
            visibleWhen: [
              {
                param: 'storage',
                value: 'S3',
                operator: VisibleWhenOperator.EQUALS
              }
            ],
            optional: false,
            displayName: 'AWS Bucket'
          },
          {
            dplID: 'azure#account',
            resourceID: 'azure#account',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP,
            lookupDependencies: [
              'param.storage-only-output-v0.storage',
              'paramId.storage-only-output-v0.azure#account',
              'param.inputId',
              'param.anaplan-input-v1:param.inputConnector'
            ],
            visibleWhen: [
              {
                param: 'storage',
                value: 'AZURE',
                operator: VisibleWhenOperator.EQUALS
              }
            ],
            optional: false,
            displayName: 'Storage Account'
          },
          {
            dplID: 'azure#container',
            resourceID: 'azure#container',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP,
            lookupDependencies: [
              'param.storage-only-output-v0.azure#account',
              'param.storage-only-output-v0.storage',
              'paramId.storage-only-output-v0.azure#container',
              'param.inputId',
              'param.anaplan-input-v1:param.inputConnector'
            ],
            visibleWhen: [
              {
                param: 'storage',
                value: 'AZURE',
                operator: VisibleWhenOperator.EQUALS
              }
            ],
            optional: false,
            displayName: 'Container'
          }
        ],
        displayName: 'Storage Only Output'
      },
      {
        dplID: 'snowflake-output-connector-v0',
        resourceID: 'SnowflakeOutputConnector',
        metlSlot: 0,
        dataType: ParameterDataType.STRUCT,
        editorType: EditorType.PARAMETER_GROUP,
        visibleWhen: [
          {
            param: 'outputId',
            value: 'snowflake-output-connector-v0',
            operator: VisibleWhenOperator.EQUALS
          }
        ],
        optional: false,
        childProperties: [
          {
            dplID: 'warehouse',
            resourceID: 'warehouse',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP,
            lookupDependencies: [
              'paramId.snowflake-output-connector-v0.warehouse',
              'param.inputId',
              'param.anaplan-input-v1:param.inputConnector'
            ],
            optional: false,
            defaultValue: '[Environment Default]',
            displayName: 'Warehouse'
          },
          {
            dplID: 'database',
            resourceID: 'database',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP,
            lookupDependencies: [
              'paramId.snowflake-output-connector-v0.database',
              'param.inputId',
              'param.anaplan-input-v1:param.inputConnector'
            ],
            optional: false,
            defaultValue: '[Environment Default]',
            displayName: 'Database'
          },
          {
            dplID: 'schema',
            resourceID: 'schema',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP,
            lookupDependencies: [
              'param.snowflake-output-connector-v0.database',
              'paramId.snowflake-output-connector-v0.schema',
              'param.inputId',
              'param.anaplan-input-v1:param.inputConnector'
            ],
            optional: false,
            defaultValue: '[Environment Default]',
            displayName: 'Schema'
          },
          {
            dplID: 'tableName',
            resourceID: 'tableName',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.FREETEXT,
            optional: false,
            displayName: 'Table Name'
          },
          {
            dplID: 'createTableMode',
            resourceID: 'createTableMode',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            optional: false,
            defaultValue: 'FAIL_IF_EXISTS',
            options: [
              'REPLACE_IF_EXISTS',
              'TRUNCATE_AND_INSERT',
              'FAIL_IF_EXISTS'
            ],
            displayName: 'Create Table Mode'
          },
          {
            dplID: 'cleanStagedFiles',
            resourceID: 'cleanStagedFiles',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.BOOLEAN,
            editorType: EditorType.DROPDOWN,
            optional: false,
            defaultValue: 'Yes',
            options: ['Yes', 'No'],
            displayName: 'Clean Staged Files'
          },
          {
            dplID: 'folderPath',
            resourceID: 'folderPath',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.FREETEXT,
            visibleWhen: [
              {
                param: 'cleanStagedFiles',
                value: 'No',
                operator: VisibleWhenOperator.EQUALS
              }
            ],
            optional: true,
            displayName: 'Folder Path'
          },
          {
            dplID: 'filePrefix',
            resourceID: 'filePrefix',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.FREETEXT,
            visibleWhen: [
              {
                param: 'cleanStagedFiles',
                value: 'No',
                operator: VisibleWhenOperator.EQUALS
              }
            ],
            optional: true,
            defaultValue: 'part-',
            displayName: 'File Prefix'
          },
          {
            dplID: 'stagePlatform',
            resourceID: 'stagePlatform',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            optional: false,
            defaultValue: 'S3',
            options: ['S3', 'SNOWFLAKE', 'AZURE_BLOB'],
            displayName: 'Stage Platform'
          },
          {
            dplID: 's3#bucket',
            resourceID: 's3#bucket',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP,
            lookupDependencies: [
              'param.snowflake-output-connector-v0.stagePlatform',
              'paramId.snowflake-output-connector-v0.s3#bucket',
              'param.inputId',
              'param.anaplan-input-v1:param.inputConnector'
            ],
            visibleWhen: [
              {
                param: 'stagePlatform',
                value: 'S3',
                operator: VisibleWhenOperator.EQUALS
              }
            ],
            optional: false,
            displayName: 'AWS Bucket'
          },
          {
            dplID: 'snowflake#internalStageType',
            resourceID: 'snowflake#internalStageType',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            visibleWhen: [
              {
                param: 'stagePlatform',
                value: 'SNOWFLAKE',
                operator: VisibleWhenOperator.EQUALS
              }
            ],
            optional: false,
            defaultValue: 'USER',
            options: ['USER'],
            displayName: 'Internal Stage Type'
          },
          {
            dplID: 'azure#account',
            resourceID: 'azure#account',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP,
            lookupDependencies: [
              'param.snowflake-output-connector-v0.stagePlatform',
              'paramId.snowflake-output-connector-v0.azure#account',
              'param.inputId',
              'param.anaplan-input-v1:param.inputConnector'
            ],
            visibleWhen: [
              {
                param: 'stagePlatform',
                value: 'AZURE_BLOB',
                operator: VisibleWhenOperator.EQUALS
              }
            ],
            optional: false,
            displayName: 'Storage Account'
          },
          {
            dplID: 'azure#container',
            resourceID: 'azure#container',
            metlSlot: 0,
            useVariable: UseVariable.YES,
            dataType: ParameterDataType.TEXT,
            editorType: EditorType.DROPDOWN,
            lookupType: LookUpType.DYNAMIC_MODULAR_OUTPUT_SCALAR_LOOKUP,
            lookupDependencies: [
              'param.snowflake-output-connector-v0.azure#account',
              'param.snowflake-output-connector-v0.stagePlatform',
              'paramId.snowflake-output-connector-v0.azure#container',
              'param.inputId',
              'param.anaplan-input-v1:param.inputConnector'
            ],
            visibleWhen: [
              {
                param: 'stagePlatform',
                value: 'AZURE_BLOB',
                operator: VisibleWhenOperator.EQUALS
              }
            ],
            optional: false,
            displayName: 'Container'
          }
        ],
        displayName: 'Snowflake Output'
      }
    ]
  }
}
