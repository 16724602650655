import { type TFunction } from 'react-i18next'

import { type ChildDataStructure, type SharedDataStructure } from '../types'
import {
  type FormStructureKeys,
  type FormValues,
  type InitialValues
} from './types'

export enum ReducerActions {
  SHOW_FIELD_VALIDATION = 'SHOW_FIELD_VALIDATION',
  UPDATE_FIELD = 'UPDATE_FIELD',
  UPDATE_TYPE = 'UPDATE_TYPE',
  VALIDATE_ALL_FIELDS = 'VALIDATE_ALL_FIELDS',
  UPDATE_KEY = 'UPDATE_KEY'
}

type FieldsWithGenericReducerAction = keyof Omit<
  ChildDataStructure,
  'key' | 'children' | 'column' | 'type'
>

export type ElementFormReducerActions =
  | {
      type: ReducerActions.UPDATE_KEY
      field: 'key'
      value: ChildDataStructure['key']
      existingKeys: Array<SharedDataStructure['key']>
      t: TFunction
    }
  | {
      type: ReducerActions.UPDATE_FIELD
      field: FieldsWithGenericReducerAction
      value: ChildDataStructure['key']
      t: TFunction
    }
  | {
      type: ReducerActions.UPDATE_TYPE
      field: 'type'
      value: ChildDataStructure['key']
      t: TFunction
    }
  | {
      type: ReducerActions.VALIDATE_ALL_FIELDS
    }
  | {
      type: ReducerActions.SHOW_FIELD_VALIDATION
      field: FormStructureKeys
    }

export const createInitialState = ({
  initialValues,
  t
}: {
  initialValues: InitialValues
  t: TFunction
}): FormValues => {
  const fields: Array<keyof InitialValues> = Object.keys(
    initialValues
  ) as Array<keyof InitialValues>

  const state = fields.reduce((acc, field) => {
    const value = initialValues[field]

    return {
      ...acc,
      [field]: {
        value,
        displayFieldError: false,
        ...validateField({
          value: value.toString(),
          field,
          t
        })
      }
    }
  }, {})

  return state as FormValues
}

const NAMESPACE = 'parameterEditor.NESTED_DATA_PICKER_EDITOR'

export function validateField({
  value,
  field,
  t,
  existingKeys
}: {
  value: string
  field: FormStructureKeys
  t: TFunction
  existingKeys?: string[]
}) {
  if (value.toString().trim() === '') {
    return {
      isValid: false,
      errorText: t(`${NAMESPACE}.VALIDATION.emptyField`, {
        field: t(`${NAMESPACE}.${field}`)
      })
    }
  }

  switch (field) {
    case 'key': {
      const isExistingKey = existingKeys?.includes(value.toString())

      return {
        isValid: existingKeys ? !isExistingKey && Boolean(value) : true,
        errorText: isExistingKey
          ? t(`${NAMESPACE}.VALIDATION.duplicateKey`, { value })
          : ''
      }
    }

    case 'size': {
      const isValid = /^\d+$/.test(value)

      return {
        isValid,
        errorText: isValid
          ? ''
          : t(`${NAMESPACE}.VALIDATION.validNumber`, {
              field: t(`${NAMESPACE}.${field}`)
            })
      }
    }

    case 'decimalPlaces': {
      const isValid = /^\d+$/.test(value)

      return {
        isValid,
        errorText: isValid
          ? ''
          : t(`${NAMESPACE}.VALIDATION.validNumber`, {
              field: t(`${NAMESPACE}.${field}`)
            })
      }
    }

    default:
      return {
        isValid: true,
        errorText: ''
      }
  }
}

export const elementFormReducer = (
  state: FormValues,
  action: ElementFormReducerActions
): FormValues => {
  switch (action.type) {
    case ReducerActions.SHOW_FIELD_VALIDATION: {
      const { field } = action

      return {
        ...state,
        [field]: {
          ...state[field],
          displayFieldError: true
        }
      }
    }

    case ReducerActions.VALIDATE_ALL_FIELDS: {
      const fields: FormStructureKeys[] = Object.keys(
        state
      ) as FormStructureKeys[]

      return fields.reduce(
        (acc, field) => ({
          ...acc,
          [field]: {
            ...state[field],
            displayFieldError: true
          }
        }),
        state
      )
    }

    case ReducerActions.UPDATE_KEY: {
      const { field, value, existingKeys, t } = action

      return {
        ...state,
        [field]: {
          value,
          displayFieldError: true,
          ...validateField({
            value,
            field,
            existingKeys,
            t
          })
        }
      }
    }

    case ReducerActions.UPDATE_FIELD: {
      const { field, value, t } = action

      return {
        ...state,
        [field]: {
          value,
          displayFieldError: true,
          ...validateField({
            value: value.toString(),
            field,
            t
          })
        }
      }
    }

    case ReducerActions.UPDATE_TYPE: {
      const { field, value, t } = action

      return {
        ...state,
        [field]: {
          value,
          displayFieldError: true,
          ...validateField({
            value: value.toString(),
            field,
            t
          })
        },
        array: {
          ...state.array,
          value: value === 'ARRAY',
          isValid: true
        }
      }
    }
  }
}
