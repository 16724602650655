import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  type FC,
  type PropsWithChildren
} from 'react'
import { type XYPosition } from 'reactflow'

import { type JobSummaryId } from 'api/hooks/useGetJobSummaries'

import { type ConnectorWithType } from 'job-lib/job-functions/job-functions'
import {
  type ConnectorCollection,
  type OrchestrationComponentInstance,
  type TransformationComponentInstance
} from 'job-lib/types/Job'
import { type JobType } from 'job-lib/types/JobType'

import { getSelectedNodeInfo } from 'modules/Canvas/hooks/useCanvasModel/utils'
import { useEtlFlow } from 'modules/Canvas/hooks/useEtlFlow'

export interface TransformationJobConnectors {
  connectors: ConnectorCollection
}
export interface OrchestrationJobConnectors {
  successConnectors: ConnectorCollection
  failureConnectors: ConnectorCollection
  unconditionalConnectors: ConnectorCollection
  trueConnectors: ConnectorCollection
  falseConnectors: ConnectorCollection
  iterationConnectors: ConnectorCollection
}

export type BaseCopiedComponentContent<
  T extends object = Record<string, unknown>
> =
  | (T & {
      componentInstances: TransformationComponentInstance[]
      componentType: JobType.Transformation
      currentConnectors: ConnectorWithType[]
    })
  | (T & {
      componentInstances: OrchestrationComponentInstance[]
      componentType: JobType.Orchestration
      currentConnectors: ConnectorWithType[]
    })
  | (T & {
      componentInstances?: null
      componentType?: null
      currentConnectors?: null
    })

export type CopiedComponentContent = BaseCopiedComponentContent<{
  currentJobId?: JobSummaryId | null
  copiedComponentOffsets: XYPosition[]
  setCopiedComponentOffsets: (offsets: XYPosition[]) => void
  setCopiedComponentContent: (
    currentJobId: JobSummaryId,
    copiedComponentContent: BaseCopiedComponentContent
  ) => void
}>

export const CopyPasteContext = createContext<CopiedComponentContent>({
  copiedComponentOffsets: [],
  componentInstances: null,
  componentType: null,
  currentConnectors: null,
  setCopiedComponentContent() {
    console.warn(
      'setCopiedComponentContent cannot be called outside of CopyPasteProvider'
    )
  },
  setCopiedComponentOffsets: () => {
    console.warn(
      'setCopiedComponentOffsets cannot be called outside of CopyPasteProvider'
    )
  }
})

export const CopyPasteProvider: FC<PropsWithChildren> = ({ children }) => {
  const [copyState, setCopyState] = useState<BaseCopiedComponentContent>({})
  const [currentJobId, setCurrentJobId] = useState<JobSummaryId | null>()
  const [copiedComponentOffsets, setCopiedComponentOffsets] = useState<
    XYPosition[]
  >([])

  const setCopiedComponentContent: CopiedComponentContent['setCopiedComponentContent'] =
    useCallback((jobId, copiedComponentInfo) => {
      setCopyState(copiedComponentInfo)
      setCurrentJobId(jobId)
    }, [])

  const value: CopiedComponentContent = useMemo(
    () => ({
      copiedComponentOffsets,
      setCopiedComponentContent,
      setCopiedComponentOffsets,
      currentJobId,
      ...copyState
    }),
    [currentJobId, copiedComponentOffsets, copyState, setCopiedComponentContent]
  )

  return (
    <CopyPasteContext.Provider value={value}>
      {children}
    </CopyPasteContext.Provider>
  )
}

export const useCanCopy = () => {
  const reactFlowInstance = useEtlFlow()

  return Boolean(getSelectedNodeInfo(reactFlowInstance).length)
}

export const useCanPaste = () => {
  const { componentInstances } = useContext(CopyPasteContext)

  return Boolean(componentInstances)
}
