import { rest, type RequestHandler } from 'msw'

import config from 'config'

import { FileStatus } from 'job-lib/types/FileStatus'
import { JobType } from 'job-lib/types/JobType'

import { type CreateJobResponse, type UseCreateJobData } from '../types'

export const useCreateJobHandlers: RequestHandler[] = [
  rest.post(
    `${config.getApiBaseUrl()}/v1/projects/*/branches/*/jobs`,
    async (req, res, ctx) => {
      // BranchID and projectID are in the search params
      const body = await req.json<UseCreateJobData>()
      const segments = body.id.split('/')
      const filename = segments[segments.length - 1]
      const extensionMatch =
        /(\.TRANSFORMATION|\.ORCHESTRATION|\.orch\.yaml|\.tran\.yaml)$/.exec(
          filename
        )
      const extension = extensionMatch ? extensionMatch[0] : ''
      const name = filename.replace(extension, '')
      const folder = segments.slice(0, segments.length - 1).join('/')

      const jobType = ['.ORCHESTRATION', '.orch.yaml'].includes(extension)
        ? JobType.Orchestration
        : JobType.Transformation

      const response: CreateJobResponse = {
        id: body.id,
        folder,
        name,
        runtimeName: folder + name,
        type: jobType,
        status: FileStatus.NEW
      }
      return res(ctx.status(201), ctx.json(response))
    }
  )
]
