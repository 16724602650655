import { useState, type ChangeEvent, type FC, type FormEvent } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  Alert,
  Button,
  Field,
  Input,
  Toaster,
  Typography
} from '@matillion/component-library'
import classnames from 'classnames'

import {
  useGetJobSummaries,
  type JobSummary
} from 'api/hooks/useGetJobSummaries'
import { useRenameJob } from 'api/hooks/useRenameJob/useRenameJob'

import { DesignerModal } from 'components/DesignerModal/DesignerModal'

import {
  isPipelineExistingPipeline,
  VALID_NAME_REGEX
} from '../../utils/validatePipelineName'
import classes from '../Modal.module.scss'

export interface RenamePipelineModalProps {
  jobSummary: JobSummary
  folderPath?: string | null
  onClose: () => void
}

export const RenamePipelineModal: FC<RenamePipelineModalProps> = ({
  jobSummary,
  folderPath,
  onClose
}) => {
  const { t } = useTranslation()

  const { data: jobSummaries, isLoading: isLoadingJobNames } =
    useGetJobSummaries()
  const { isLoading: isRenamingJob, mutateAsync: handleRename } = useRenameJob()
  const { makeToast } = Toaster.useToaster()

  const [jobName, setJobName] = useState(jobSummary.name)
  const [error, setError] = useState<string | null>(null)

  const isLoading = isRenamingJob || isLoadingJobNames
  const isValueSameValue = jobName === jobSummary.name
  const isUpdateButtonDisabled = isValueSameValue || isLoading || error !== null

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setJobName(event.target.value)

    if (!value) {
      setError(t('translation:managePipeline.error.noEmptyString'))
      return
    }

    if (VALID_NAME_REGEX.exec(value) === null) {
      setError(t('translation:managePipeline.error.noRegex'))
      return
    }

    /* istanbul ignore next */
    if (!jobSummaries?.results) {
      return
    }

    const path = folderPath ? `${folderPath}/` : ''
    const isDuplicatePipeline = isPipelineExistingPipeline(
      `${path}${value.trim()}`,
      jobSummary.type,
      jobSummaries.results
    )

    if (isDuplicatePipeline) {
      setError(t('translation:managePipeline.error.noDuplicates'))
      return
    }

    setError(null)
  }

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()

    try {
      const path = folderPath ? `${folderPath}/` : ''
      await handleRename({
        jobId: jobSummary.jobId,
        newJobId: `${path}${jobName.trim()}.${jobSummary.type}`
      })

      makeToast({
        title: t('translation:renamePipeline.success'),
        type: 'success',
        message: ''
      })

      // TODO: the following code conflicts with invalidation of stale jobs
      // used by the PipelineBrowser. We require this ticket to be resolved first:
      // https://matillion.atlassian.net/browse/DPCD-1078
      // const selectedJobTabs = selectedJobs.map((job) => {
      //   if (job === jobSummary.jobId) {
      //     return updatedJobName
      //   }
      //   return job
      // })
      // if (jobSummaryId === jobSummary.jobId) {
      //   navigateToJob(updatedJobName, selectedJobTabs)
      // } else {
      //   navigateToJob(jobSummaryId, selectedJobTabs)
      // }

      onClose()
    } catch (e) {
      makeToast({
        title: t('translation:managePipeline.error.somethingWrong'),
        type: 'error',
        message: t('translation:managePipeline.error.tryLater')
      })
    }
  }

  return (
    <DesignerModal
      id="rename-pipeline-dialog"
      onCancel={onClose}
      ariaLabelledBy="rename-pipeline-title"
      size="mid"
      disableBackdropClick
      className={classes.Modal}
      setFocus={false}
    >
      <div className={classes.Modal__Heading}>
        <Typography
          as="h2"
          format="tl"
          id="rename-pipeline-title"
          className={classes.Modal__Title}
        >
          {t('translation:renamePipeline.title')}
        </Typography>
      </div>
      <Alert
        className={classes.Modal__AlertInformation}
        theme="dark"
        type="warning"
        title=""
        message={
          <>
            <Typography format="bcs">
              <Trans i18nKey="renamePipeline.alertTextLine1">
                <strong />
              </Trans>
            </Typography>
            <Typography format="bcs">
              <Trans i18nKey="renamePipeline.alertTextLine2">
                <strong />
              </Trans>
            </Typography>
          </>
        }
      />
      <form
        onSubmit={(event) => {
          void onSubmit(event)
        }}
      >
        <div
          className={classnames(
            classes.Modal__Content,
            classes['Modal__Content--Input']
          )}
        >
          <Field
            data-testid="modal-rename-pipeline-name-input"
            title={t('translation:renamePipeline.name')}
            aria-label={t('translation:renamePipeline.name')}
            value={jobName}
            onChange={onChange}
            placeholder={t('translation:renamePipeline.namePlaceholder')}
            hasError={!!error}
            error={!!error}
            errorText={error}
            disabled={isLoading || isLoadingJobNames}
            inputComponent={Input}
          />
        </div>
        <div className={classes.Modal__Actions}>
          <Button
            id="rename-pipeline-cancel"
            data-testid="modal-rename-pipeline-cancel"
            text={t('translation:managePipeline.cancel')}
            alt="secondary"
            onClick={onClose}
          />
          <Button
            id="rename-pipeline-create"
            data-testid="modal-rename-pipeline-submit"
            text={t('translation:renamePipeline.update')}
            waiting={isLoading}
            disabled={isUpdateButtonDisabled}
            type="submit"
            alt="positive"
          />
        </div>
      </form>
    </DesignerModal>
  )
}
