import { useEffect, type FC, type ReactNode } from 'react'

import { useGetJobSummaries } from 'api/hooks/useGetJobSummaries'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'

import { useRecentlyAccessedPipelines } from 'modules/hub/PipelineAccessHistory/hooks/useRecentlyAccessedPipelines/useRecentlyAccessedPipelines'

interface PipelineAccessHistoryProviderProps {
  children: ReactNode
}

export const PipelineAccessHistoryProvider: FC<
  PipelineAccessHistoryProviderProps
> = ({ children }) => {
  const { storeRecentlyAccessed } = useRecentlyAccessedPipelines()
  const { pipelineSummary: activePipeline } = useActivePipelineSummary()
  const { data: allPipelines } = useGetJobSummaries()

  useEffect(() => {
    storeRecentlyAccessed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePipeline, allPipelines])

  return <>{children}</>
}
