import { type ComponentLayout } from './types'

export const WorkdayConfig: ComponentLayout = {
  componentId: 'modular-workday-input-v1',
  wizard: {
    steps: [
      {
        stepId: 'connect',
        displayName: 'Connect',
        previousStepId: null,
        nextStepId: 'configure',
        parameters: [
          {
            dplId: 'componentName'
          },
          {
            dplId: 'inputId'
          },
          {
            dplId: 'host'
          },
          {
            dplId: 'tenant'
          },
          {
            dplId: 'connection'
          },
          {
            dplId: 'version'
          }
        ]
      },
      {
        stepId: 'configure',
        displayName: 'Configure',
        previousStepId: 'connect',
        nextStepId: 'destination',
        parameters: [
          {
            dplId: 'workday-input-v1',
            omitParameters: ['host', 'tenant', 'connection', 'version']
          }
        ]
      },
      {
        stepId: 'destination',
        displayName: 'Destination',
        previousStepId: 'configure',
        nextStepId: null,
        parameters: [
          {
            dplId: 'outputId'
          },
          {
            dplId: 'databricks-output-connector-v0'
          },
          {
            dplId: 'storage-only-output-v0'
          },
          {
            dplId: 'snowflake-output-connector-v0'
          },
          {
            dplId: 'redshift-output-connector-v0'
          }
        ]
      }
    ]
  }
}
