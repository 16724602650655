import { useCallback } from 'react'

import { type WorkingTreeRefreshEvent } from '@matillion/git-component-library'
import { useQueryClient } from '@tanstack/react-query'

import { getJobQueryKey } from 'api/hooks/useGetJob/useGetJob'
import { useGetJobSummariesQueryKey } from 'api/hooks/useGetJobSummaries'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'
import { useSelectedJobs } from 'hooks/useSelectedJobs'

const useRefreshPipelines = () => {
  const queryClient = useQueryClient()
  const { selectedJobs } = useSelectedJobs()
  const { branchId, projectId } = useProjectInfo()
  const jobSummariesQueryKey = useGetJobSummariesQueryKey()

  const invalidatePipelines = useCallback(
    async ({
      refreshFileSummaries,
      refreshPipelines
    }: WorkingTreeRefreshEvent) => {
      if (refreshFileSummaries) {
        await queryClient.invalidateQueries(jobSummariesQueryKey)
      }

      if (refreshPipelines) {
        for (const id of selectedJobs) {
          const jobQueryKey = getJobQueryKey(projectId, branchId, id)
          queryClient.removeQueries(jobQueryKey)
        }
      }
    },
    [branchId, jobSummariesQueryKey, projectId, queryClient, selectedJobs]
  )

  return {
    refreshPipelines: invalidatePipelines
  }
}

export default useRefreshPipelines
