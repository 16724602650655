import { useMemo } from 'react'

import { escapeRegExp } from 'lodash'

import { useAgentHost } from 'api/hooks/useAgentHost/useAgentHost'
import {
  useConnectorComponentSummaries,
  useStandardComponentSummaries,
  type ComponentSummary
} from 'api/hooks/useGetComponentSummaries'
import { type ProjectType } from 'api/hooks/useGetProject/types'
import useGetProject from 'api/hooks/useGetProject/useGetProject'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'

import { isCustomConnector } from 'job-lib/cisIds/idType'

import { getPseudoComponents } from '../../api/hooks/useGetPseudoComponents/useGetPseudoComponents'
import {
  getEnabledComponents,
  useComponentEnabledWarehouses
} from '../useComponentEnabledWarehouses'
import { useComponentInfo } from '../useComponentInfo/useComponentInfo'

export interface ExtendedProps extends ComponentSummary {
  isAvailableForAgent: boolean
  displayName: string
  tags: string[]
  synonyms: string[]
  icon: string
}
export type FlatComponentList = ExtendedProps[]

const searchComponentList = (
  searchTerm: string,
  components: FlatComponentList
): FlatComponentList => {
  const sortedComponents = [...components].sort((a, b) =>
    a.displayName.localeCompare(b.displayName)
  )
  if (searchTerm === '') {
    return sortedComponents
  }

  const searchTermRegex = new RegExp('\\b' + escapeRegExp(searchTerm), 'i')

  const { nameMatches, tagMatches, synonymMatches } = sortedComponents.reduce(
    (matchBag, component) => {
      if (searchTermRegex.test(component.displayName)) {
        matchBag.nameMatches.push(component)
        return matchBag
      }

      if (component.tags.some((tag) => searchTermRegex.test(tag))) {
        matchBag.tagMatches.push(component)
        return matchBag
      }

      if (component.synonyms.some((synonym) => searchTermRegex.test(synonym))) {
        matchBag.tagMatches.push(component)
        return matchBag
      }

      return matchBag
    },
    {
      nameMatches: [] as FlatComponentList,
      tagMatches: [] as FlatComponentList,
      synonymMatches: [] as FlatComponentList
    }
  )

  return [...nameMatches, ...tagMatches, ...synonymMatches]
}

const useAvailableComponents = () => {
  const { getDisplayName, getTags, getIcon, getSynonyms } = useComponentInfo()
  const pseudoComponentMetadata = useMemo(() => getPseudoComponents(), [])

  const {
    agentHost,
    isInitialLoading,
    isError: isAgentHostError
  } = useAgentHost()

  const { data: project } = useGetProject()
  const enabledComponents = getEnabledComponents(
    useComponentEnabledWarehouses(),
    project?.warehouse as ProjectType
  )
  const { pipelineSummary } = useActivePipelineSummary()
  const {
    data: standardComponentsData,
    isLoading,
    isError
  } = useStandardComponentSummaries(pipelineSummary?.type)

  const { data: connectorComponentsData } = useConnectorComponentSummaries(
    pipelineSummary?.type
  )

  const defaultComponents: ExtendedProps[] = useMemo(() => {
    if (!standardComponentsData || !agentHost) {
      return []
    }

    return standardComponentsData
      .filter(({ componentId }) => enabledComponents.includes(componentId))
      .map<ExtendedProps>((current) => ({
        ...current,
        displayName: getDisplayName(current.componentId),
        icon: getIcon(current.componentId),
        tags: getTags(current.componentId),
        synonyms: getSynonyms(current.componentId),
        isAvailableForAgent: current.agentHosts.includes(agentHost)
      }))
  }, [
    standardComponentsData,
    agentHost,
    enabledComponents,
    getDisplayName,
    getIcon,
    getTags,
    getSynonyms
  ])

  const connectorComponents: ExtendedProps[] = useMemo(() => {
    if (!connectorComponentsData || !agentHost) {
      return []
    }

    return connectorComponentsData
      .filter(
        ({ componentId }) =>
          (enabledComponents.includes('custom-connectors') &&
            isCustomConnector(componentId)) ||
          enabledComponents.includes(componentId)
      )
      .map<ExtendedProps>((current) => ({
        ...current,
        displayName: getDisplayName(current.componentId),
        icon: getIcon(current.componentId),
        tags: getTags(current.componentId),
        synonyms: getSynonyms(current.componentId),
        isAvailableForAgent: current.agentHosts.includes(agentHost)
      }))
  }, [
    connectorComponentsData,
    agentHost,
    enabledComponents,
    getDisplayName,
    getIcon,
    getTags,
    getSynonyms
  ])

  // For each pseudo component with a matching real component, clone the real component and replace the name/id
  // with the pseudo component name/id
  const pseudoComponents: ExtendedProps[] = useMemo(() => {
    return pseudoComponentMetadata
      .flatMap((metadata) =>
        metadata.pseudoComponents.map((pseudoComponent) => ({
          componentId: metadata.componentId,
          originalComponent: [
            ...defaultComponents,
            ...connectorComponents
          ].find((c) => c.componentId === metadata.componentId),
          pseudoComponent
        }))
      )
      .filter((c) => c.originalComponent)
      .map((c) => ({
        ...(c.originalComponent as ExtendedProps),
        displayName: c.pseudoComponent.name,
        icon: c.pseudoComponent.icon
      }))
  }, [pseudoComponentMetadata, defaultComponents, connectorComponents])

  // Check if there are any pseudo components where the original component is hidden
  const hiddenComponents = useMemo(
    () =>
      pseudoComponentMetadata
        .filter((p) => p.originalComponentHidden)
        .map((p) => p.componentId),
    [pseudoComponentMetadata]
  )

  const components = useMemo(() => {
    return [...defaultComponents, ...connectorComponents]
      .filter((component) => !hiddenComponents.includes(component.componentId))
      .concat(pseudoComponents)
  }, [
    connectorComponents,
    defaultComponents,
    hiddenComponents,
    pseudoComponents
  ])

  if (!pipelineSummary) {
    return {
      isLoading: false,
      isError: false,
      components: []
    }
  }

  if (
    !standardComponentsData ||
    isError ||
    isAgentHostError ||
    isLoading ||
    isInitialLoading ||
    !agentHost
  ) {
    return {
      isLoading: isLoading || isInitialLoading,
      isError: isError || isAgentHostError,
      components: []
    }
  }

  return {
    isLoading: false,
    isError: false,
    components
  }
}

export { searchComponentList, useAvailableComponents }
