import { type FC } from 'react'

import { Tooltip } from '@matillion/component-library'

import { type PipelineStatus } from 'api/hooks/useEosClient/types/eos'

import StateIcon from 'modules/FlowStatsExplorer/components/StateIcon'

import classes from './TaskStatusIcon.module.scss'

interface TaskStatusIconProps {
  status: PipelineStatus
  depth: string
}

const TaskStatusIcon: FC<TaskStatusIconProps> = ({ status, depth }) => {
  const taskLabel = status.toLowerCase()
  return (
    <Tooltip content={taskLabel}>
      <div
        className={classes.PipelineStatus}
        data-testid={`treeGrid-item-${depth}`}
      >
        <StateIcon
          status={status}
          className={classes.PipelineStatus__Icon}
          aria-label={taskLabel}
        />
      </div>
    </Tooltip>
  )
}

export default TaskStatusIcon
