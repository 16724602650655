import {
  BRANCH_BEHIND_NO_CONFLICTS,
  BRANCH_BEHIND_WITH_CONFLICTS,
  BRANCH_ID
} from '__test-utils__/ids'

export const MockedMergeResponses: Record<string, number> = {
  [BRANCH_ID]: 204,
  BranchWithConflicts: 204,
  BranchNoConflicts: 204,
  main: 204,
  [BRANCH_BEHIND_WITH_CONFLICTS]: 204,
  [BRANCH_BEHIND_NO_CONFLICTS]: 204
}
