import {
  useMemo,
  useState,
  type FC,
  type MouseEvent,
  type ReactElement,
  type ReactNode
} from 'react'
import { type Node } from 'reactflow'

import { Popover } from '@matillion/component-library'
import classnames from 'classnames'

import { useUpdateContextNodeSelection } from 'hooks/useUpdateContextNodeSelection'

import { PopOverContext } from './PopOverContext'
import classes from './PopOverMenu.module.scss'

export declare type PopOverPosition = 'left' | 'right' | 'bottom' | 'top'

export type RenderChildren = ({
  open,
  onClick,
  onContextMenu
}: {
  open: boolean
  onClick: () => void
  onContextMenu: (e: MouseEvent) => void
}) => ReactElement

export interface RenderContentProps {
  popOverClientPosition: PosXy | null
}

export type RenderPopOverContent = ({
  popOverClientPosition
}: RenderContentProps) => ReactNode

export interface PopOverMenuProps {
  positionAtMouse?: boolean
  content: ReactNode | RenderPopOverContent
  children: RenderChildren
  position?: PopOverPosition | PopOverPosition[]
  className?: string
}

export interface PosXy {
  x: number
  y: number
}

export const PopOverMenu: FC<PopOverMenuProps> = ({
  positionAtMouse = false,
  content,
  children,
  position,
  className
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [{ x, y }, setXy] = useState<PosXy>({ x: 400, y: 400 })
  const [hoveredNode, setHoveredNode] = useState<Node>()
  const [popOverClientPosition, setPopOverClientPosition] =
    useState<PosXy | null>(null)
  const { updateNodeSelection } = useUpdateContextNodeSelection()
  const child = children({
    open: isOpen,
    onClick: () => {
      setIsOpen((prevState) => !prevState)
    },
    onContextMenu: (e: MouseEvent) => {
      e.preventDefault()
      e.stopPropagation()
      updateNodeSelection(hoveredNode)
      setXy({ x: e.pageX, y: e.pageY })
      setPopOverClientPosition({
        x: e.clientX,
        y: e.clientY
      })
      setIsOpen(true)
    }
  })

  const conditionalPopOverProps = positionAtMouse
    ? {
        contentLocation: { top: y, left: x },
        anchor: <div className="u-visually-hidden" />
      }
    : {
        anchor: child
      }

  return (
    <PopOverContext.Provider
      value={useMemo(() => {
        return { setIsOpen, setHoveredNode }
      }, [])}
    >
      <Popover
        className={classnames(classes.PopOver, className)}
        align="start"
        onClickOutside={() => {
          setIsOpen(false)
        }}
        reposition={true}
        position={position ?? ['right', 'top', 'bottom']}
        isOpen={isOpen}
        {...conditionalPopOverProps}
      >
        <ul data-testid="popover-menu" className={classes.PopOver__Content}>
          {typeof content === 'function'
            ? content({ popOverClientPosition })
            : content}
        </ul>
      </Popover>
      {positionAtMouse && child}
    </PopOverContext.Provider>
  )
}
