import classes from './SourceBranchSelector.module.scss'
import BranchSelectorLabel from '../../../../components/BranchSelectorLabel'
import CurrentBranchDisplay from '../CurrentBranchDisplay'
import MergeArrowIcon from 'icons/MergeArrow.svg'
import BranchSelector from '../../../../components/BranchSelector'
import { type SourceBranchSelectorProps } from './types'
import useMergeFeatureFlag from '../../hooks/useMergeFeatureFlag'

const SourceBranchSelector = ({
  branch,
  branches,
  setBranch,
  isInvalidating
}: SourceBranchSelectorProps) => {
  const { keyDiscriminator } = useMergeFeatureFlag()

  return (
    <div className={classes.wrapper}>
      <div className={classes.branch}>
        <BranchSelectorLabel
          prefixKey={`merge.${keyDiscriminator}.targetBranch.prefix`}
          suffixKey={`merge.${keyDiscriminator}.targetBranch.suffix`}
        />

        <BranchSelector
          branch={branch}
          branches={branches}
          loading={isInvalidating}
          onSelectBranch={setBranch}
          excludeSourceBranch={true}
        />
      </div>

      <div className={classes.iconWrapper}>
        <img
          src={MergeArrowIcon}
          alt="Merge Arrow Icon"
          className={classes.icon}
        />
      </div>

      <div className={classes.branch}>
        <BranchSelectorLabel
          prefixKey={`merge.${keyDiscriminator}.sourceBranch.prefix`}
          suffixKey={`merge.${keyDiscriminator}.sourceBranch.suffix`}
        />

        <CurrentBranchDisplay />
      </div>
    </div>
  )
}

export default SourceBranchSelector
