// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WCLQ4n9nhL0YwXfMkM8K{margin-top:60px}.CEhV2zcpgW6PKxqWIieO{margin-top:30px}`, "",{"version":3,"sources":["webpack://./src/modules/BringYourOwnGit/components/BringYourOwnGitForm/BringYourOwnGitForm.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,eAAA","sourcesContent":[".repositorySelector {\n  margin-top: 60px;\n}\n\n.loading {\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"repositorySelector": `WCLQ4n9nhL0YwXfMkM8K`,
	"loading": `CEhV2zcpgW6PKxqWIieO`
};
export default ___CSS_LOADER_EXPORT___;
