import {
  ComponentEditorTabType,
  EditorType,
  LookUpType,
  ParameterDataType,
  UseVariable,
  type ComponentMetadataResponse
} from 'api/hooks/useGetComponentMetadata/types'
import { ColumnEditorType } from 'api/hooks/useGetParameterOptions/types'

import { Cardinality, ConnectionPortType } from 'job-lib/types/Components'
import { JobType } from 'job-lib/types/JobType'

export const mockTransposeColumnsMetadata: ComponentMetadataResponse = {
  componentId: 'transpose-columns',
  metadata: {
    schemaVersion: 1,
    emeraldId: '-627810085',
    classification: 'TRANSFORMATION',
    componentType: JobType.Transformation,
    inputPorts: [
      {
        portId: ConnectionPortType.INPUT,
        cardinality: Cardinality.ONE
      }
    ],
    outputPorts: [
      {
        portId: ConnectionPortType.UNCONDITIONAL,
        cardinality: Cardinality.MANY
      }
    ],
    deprecated: false,
    exports: null,
    editorTabs: [
      ComponentEditorTabType.PROPERTIES,
      ComponentEditorTabType.SAMPLE,
      ComponentEditorTabType.METADATA,
      ComponentEditorTabType.SQL,
      ComponentEditorTabType.PLAN,
      ComponentEditorTabType.HELP
    ],
    parameters: [
      {
        dplID: 'componentName',
        metlSlot: 1,
        useVariable: UseVariable.NO,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'ordinaryColumns',
        metlSlot: 2,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.MULTI_OPTION_SELECTOR,
        lookupType: LookUpType.DYNAMIC_TRANSFORM_SOURCE_COLUMNS,
        lookupDependencies: ['transform.sql'],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'rowLabelName',
        metlSlot: 3,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.TEXT,
        editorType: EditorType.FREETEXT,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      },
      {
        dplID: 'outputColumns',
        metlSlot: 4,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: null,
        lookupDependencies: null,
        visibleWhen: null,
        optional: false,
        staticOptions: [
          {
            name: 'Name',
            type: 'TEXT',
            options: null,
            columnEditorType: ColumnEditorType.Freetext,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          },
          {
            name: 'Type',
            type: 'TEXT',
            options: [
              'VARCHAR',
              'NUMBER',
              'FLOAT',
              'BOOLEAN',
              'DATE',
              'TIMESTAMP',
              'TIME',
              'VARIANT'
            ],
            columnEditorType: ColumnEditorType.Dropdown,
            defaultValue: null,
            lookupType: null,
            lookupDependencies: null
          }
        ],
        defaultValue: null
      },
      {
        dplID: 'columnToRowMapping',
        metlSlot: 5,
        useVariable: UseVariable.YES,
        dataType: ParameterDataType.GRID,
        editorType: EditorType.GRID_EDITOR,
        lookupType: LookUpType.DYNAMIC_TRANSFORM_ROW_COLUMN_MAPPINGS,
        lookupDependencies: [
          'param.row-label-name',
          'param.output-columns.name',
          'param.output-columns.type',
          'transform.sql'
        ],
        visibleWhen: null,
        optional: false,
        staticOptions: null,
        defaultValue: null
      }
    ]
  }
}
