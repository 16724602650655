import { createContext } from 'react'
import { type Node } from 'reactflow'

interface ContextInterface {
  setIsOpen: (isOpen: boolean) => void
  setHoveredNode: (info: Node | undefined) => void
}

export const PopOverContext = createContext<ContextInterface | undefined>(
  undefined
)
