import { useCallback, useEffect, useState, type ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import {
  Icon,
  Toaster,
  Typography,
  type ModalProps
} from '@matillion/component-library'
import classnames from 'classnames'

import {
  EditorType,
  type ComponentMetadata,
  type ComponentParameter
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type EditorColumn } from 'api/hooks/useGetParameterOptions/types'
import { type ProblemDetails } from 'api/types/http-problem-details'

import { EmptyPlaceholder } from 'components/EmptyPlaceholder'
import {
  ParameterOverlay,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'

import { useParameterOptions } from 'hooks/useParameterOptions/useParameterOptions'

import { createHomogeneousElements } from 'job-lib/builders/createElementsCollection'
import { isModularFlexOrCustom } from 'job-lib/cisIds/idType'
import {
  headerParamsParameter,
  uriParamsParameter
} from 'job-lib/cisIds/knownComponentParameters'
import { type ComponentInstanceId } from 'job-lib/types/Job'
import { type ElementCollection } from 'job-lib/types/Parameters'

import {
  getComponentDependencyData,
  useComponentValidationProvider,
  type ComponentDependencyDataBag
} from 'modules/core/ComponentValidation'
import { useWorkingCopy } from 'modules/core/EtlDesigner/hooks/useWorkingCopy'
import { FreeformTextareaEditor } from 'modules/ParameterEditors/components/FreeformTextareaEditor/FreeformTextareaEditor'
import { SQLEditor } from 'modules/ParameterEditors/components/SQLEditor/SQLEditor'

import { StorageEditorType } from '../../types'
import { BashEditor } from '../BashEditor/BashEditor'
import { GridEditor } from '../GridEditor/GridEditor'
import { JoinEditor, MultiExpressionsEditor } from '../MultiExpressionsEditor'
import MultiOptionSelector from '../MultiOptionSelector/MultiOptionSelector'
import { MultiPropertiesEditor } from '../MultiPropertiesEditor/MultiPropertiesEditor'
import { NestedDataPickerEditor } from '../NestedDataPickerEditor/NestedDataPickerEditor'
import { OAuthEditor } from '../OAuthEditor/OAuthEditor'
import { PythonEditor } from '../PythonEditor/PythonEditor'
import { SecretReferenceEditor } from '../SecretReferenceEditor/SecretReferenceEditor'
import { SQLAdvancedEditor } from '../SQLAdvancedEditor/SQLAdvancedEditor'
import { UrlEditor } from '../UrlEditor/UrlEditor'
import classes from './ModalTriggerEditor.module.scss'

export interface ModalTriggerProps {
  inputId: string
  value: string[]
  hasError?: boolean
  elements?: ElementCollection
  editorType: EditorType
  componentMetaData: ComponentMetadata
  parameter: ComponentParameter
  parameterName: string
  componentInstanceId: ComponentInstanceId
  componentSummaryId: ComponentSummaryId
  onEdit: (value: ElementCollection, editorColumns?: EditorColumn[]) => void
  onEditorError?: (error?: ProblemDetails) => void
  updateElementsWithEditorColumnData?: (
    editorColumns: EditorColumn[]
  ) => ElementCollection
}

interface EditorState {
  editor: ReactNode
  modalSize?: ModalProps['size']
  isSupportedEditor?: boolean
  hasNoOptions?: boolean
}

export const ModalTriggerEditor = ({
  editorType,
  componentMetaData,
  parameter,
  parameterName,
  onEdit,
  onEditorError,
  elements = {},
  value,
  hasError = false,
  componentInstanceId,
  componentSummaryId,
  updateElementsWithEditorColumnData,
  ...props
}: ModalTriggerProps) => {
  const { t } = useTranslation()
  const [showEditor, setShowEditor] = useState(false)
  const [isQueryEnabled, setIsQueryEnabled] = useState(false)
  const { makeToast, clearToasts } = Toaster.useToaster()
  const { job } = useWorkingCopy()
  const {
    isLoading: isParameterListLoading,
    isError,
    data: options,
    helperData,
    error: problemDetail,
    refetch
  } = useParameterOptions({
    componentSummaryId,
    componentMetaData,
    parameter,
    isEnabled: isQueryEnabled
  })

  const { isValidatingJob } = useComponentValidationProvider()
  const isLoading = isValidatingJob || isParameterListLoading

  const { validationQueryCache } = useComponentValidationProvider()
  // currently only used by the MultiExpressionEditor, all editors could receive this object.
  // It contains all the validation cache results that are inputs to this component
  let componentInputCache: ComponentDependencyDataBag = { cache: {} }

  if (job) {
    componentInputCache = getComponentDependencyData(
      job,
      componentInstanceId,
      validationQueryCache
    )
  }

  const onDone = useCallback(
    (editedValue: ElementCollection) => {
      setShowEditor(false)
      onEdit(editedValue, options)
      clearToasts()
    },
    [options, onEdit, clearToasts]
  )

  const onDoneSingleValue = useCallback(
    (val: string[] | string) => {
      onDone(createHomogeneousElements(val, parameter?.dataType))
      clearToasts()
    },
    [onDone, clearToasts, parameter]
  )

  useEffect(() => {
    if (onEditorError) {
      onEditorError(isError && problemDetail ? problemDetail : undefined)
    }

    if (isError && problemDetail && showEditor) {
      makeToast({
        type: 'warning',
        title: problemDetail.title,
        message: problemDetail.detail ?? ''
      })
    }
  }, [
    isError,
    showEditor,
    problemDetail,
    makeToast,
    clearToasts,
    onEditorError
  ])

  const editorElements = updateElementsWithEditorColumnData
    ? updateElementsWithEditorColumnData(options)
    : elements

  const getEditor = (): EditorState => {
    switch (editorType) {
      case EditorType.MULTI_OPTION_SELECTOR:
        return {
          editor: (
            <MultiOptionSelector
              editorColumns={options}
              parameterName={parameterName}
              value={value}
              onDone={onDoneSingleValue}
              {...props}
            />
          ),
          modalSize: 'mid'
        }
      case EditorType.BLOB_URL_FOLDER_ONLY:
        return {
          editor: (
            <UrlEditor
              storageEditorType={StorageEditorType.AZURE}
              editorColumns={options}
              parameterName={parameterName}
              value={value}
              onDone={onDoneSingleValue}
              {...props}
            />
          ),
          hasNoOptions: true,
          modalSize: 'mid-large'
        }
      case EditorType.FREETEXT_MULTI_LINE:
        return {
          isSupportedEditor: true,
          hasNoOptions: true,
          modalSize: 'large',
          editor: (
            <FreeformTextareaEditor
              title={parameterName}
              value={value}
              onDone={onDoneSingleValue}
            />
          )
        }
      case EditorType.SQL_EDITOR:
        return {
          isSupportedEditor: true,
          hasNoOptions: true,
          modalSize: 'large',
          editor: (
            <SQLEditor
              title={parameterName}
              value={value}
              onDone={onDoneSingleValue}
            />
          )
        }
      case EditorType.SQL_EDITOR_ADVANCED:
        return {
          isSupportedEditor: true,
          hasNoOptions: true,
          modalSize: 'large',
          editor: (
            <SQLAdvancedEditor
              title={parameterName}
              value={value}
              onDone={onDoneSingleValue}
            />
          )
        }
      case EditorType.BASH:
        return {
          isSupportedEditor: true,
          hasNoOptions: true,
          modalSize: 'large',
          editor: (
            <BashEditor
              title={parameterName}
              value={value}
              onDone={onDoneSingleValue}
            />
          )
        }
      case EditorType.PYTHON:
        return {
          isSupportedEditor: true,
          hasNoOptions: true,
          modalSize: 'large',
          editor: (
            <PythonEditor
              title={parameterName}
              value={value}
              onDone={onDoneSingleValue}
            />
          )
        }
      case EditorType.S3_URL:
        return {
          editor: (
            <UrlEditor
              storageEditorType={StorageEditorType.S3}
              editorColumns={options}
              parameterName={parameterName}
              value={value}
              onDone={onDoneSingleValue}
              {...props}
            />
          ),
          hasNoOptions: true,
          modalSize: 'mid-large'
        }
      case EditorType.SECRET_REFERENCE:
        return {
          editor: (
            <SecretReferenceEditor
              value={value}
              editorColumns={options}
              parameterName={parameterName}
              onDone={onDoneSingleValue}
              {...props}
            />
          ),
          modalSize: 'mid'
        }
      case EditorType.GRID_EDITOR:
        return {
          editor: (
            <GridEditor
              editorColumns={options}
              parameter={parameter}
              parameterName={parameterName}
              componentInstanceId={componentInstanceId}
              componentSummaryId={componentSummaryId}
              componentMetaData={componentMetaData}
              onDone={onDone}
              elements={editorElements}
              problemDetail={problemDetail}
              disallowDuplicates={
                isModularFlexOrCustom(componentSummaryId) &&
                [headerParamsParameter, uriParamsParameter].includes(
                  parameter.dplID
                )
              }
              {...props}
            />
          ),
          modalSize: 'large'
        }
      case EditorType.JOIN_EDITOR:
      case EditorType.OPTIMISE_EDITOR:
        return {
          editor: (
            <JoinEditor
              editorColumns={options}
              helperData={helperData}
              parameterName={parameterName}
              onDone={onDone}
              elements={editorElements}
              problemDetail={problemDetail}
              {...props}
            />
          ),
          modalSize: 'large'
        }
      case EditorType.MULTI_EXPRESSIONS_EDITOR:
        return {
          editor: (
            <MultiExpressionsEditor
              editorColumns={options}
              parameterName={parameterName}
              onDone={onDone}
              elements={editorElements}
              componentInputDepBag={componentInputCache}
              {...props}
            />
          ),
          modalSize: 'large'
        }
      case EditorType.MULTI_PROPERTIES_EDITOR:
        return {
          editor: (
            <MultiPropertiesEditor
              editorColumns={options}
              parameterName={parameterName}
              parameter={parameter}
              componentMetadata={componentMetaData}
              componentInstanceId={componentInstanceId}
              componentSummaryId={componentSummaryId}
              onDone={onDone}
              elements={editorElements}
              {...props}
            />
          ),
          modalSize: 'mid'
        }
      case EditorType.NESTED_DATA_PICKER_SF:
        return {
          isSupportedEditor: true,
          editor: (
            <NestedDataPickerEditor
              editorColumns={options}
              onDone={onDone}
              elements={editorElements}
              metadata={componentMetaData}
              componentId={componentSummaryId}
              parameter={parameter}
              parameterName={parameterName}
              {...props}
            />
          ),
          modalSize: 'large'
        }
      case EditorType.SCALAR_JOB_VARIABLE_OVERRIDE:
        return {
          editor: (
            <GridEditor
              editorColumns={options}
              parameter={parameter}
              parameterName={parameterName}
              componentInstanceId={componentInstanceId}
              componentSummaryId={componentSummaryId}
              componentMetaData={componentMetaData}
              onDone={onDone}
              elements={editorElements}
              {...props}
            />
          ),
          modalSize: 'large'
        }
      case EditorType.OAUTH:
        return {
          editor: (
            <OAuthEditor
              value={value}
              editorColumns={options}
              parameterName={parameterName}
              onDone={onDoneSingleValue}
              {...props}
            />
          ),
          modalSize: 'mid'
        }
      default:
        return {
          isSupportedEditor: false,
          editor: (
            <Typography
              className={classes.EditorNotSupported}
              format="bcm"
              as="h2"
              id="parameter-overlay-title"
            >
              {t('parameterEditor.editorNotSupported')}
            </Typography>
          )
        }
    }
  }

  const {
    editor,
    isSupportedEditor = true,
    modalSize,
    hasNoOptions
  } = getEditor()

  const onTriggerClick = () => {
    setShowEditor(true)
    if (isQueryEnabled && parameter.lookupType) {
      refetch({ throwOnError: false })
    }
    setIsQueryEnabled(isSupportedEditor && !hasNoOptions)
  }

  return (
    <>
      <button
        className={classnames(classes.ModalTriggerEditor__Button, {
          [classes['ModalTriggerEditor__Button--Error']]: hasError
        })}
        type="button"
        data-testid={`modal-trigger-inputid-${props.inputId}`}
        aria-label={t('translation:parameterEditor.triggerLabel', {
          parameterName
        })}
        onClick={onTriggerClick}
      >
        <Typography
          aria-hidden
          className={classes.ModalTriggerEditor__Value}
          format="bcs"
          as="span"
        >
          {value.join(', ').substring(0, 100).trim()}
        </Typography>
        <Icon.Cog className={classes.ModalTriggerEditor__Icon} />
      </button>
      <Typography className="u-visually-hidden" format="bcs">
        {value.join(', ')}
      </Typography>

      {showEditor && (
        <ParameterOverlay
          size={isQueryEnabled && isLoading ? 'default' : modalSize}
          setShowEditor={setShowEditor}
          isLoading={isQueryEnabled && isLoading}
        >
          <ErrorBoundary
            fallback={
              <>
                <ParameterOverlayHeader title={parameterName} />
                <EmptyPlaceholder type="error">
                  {t('parameterOverlay.error')}
                </EmptyPlaceholder>
              </>
            }
          >
            {editor}
          </ErrorBoundary>
        </ParameterOverlay>
      )}
    </>
  )
}
