import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Icon, Typography } from '@matillion/component-library'

import { useGeneratePipelineDocs } from '../../../api/hooks/sidekick/useGeneratePipelineDocs/useGeneratePipelineDocs'
import { AiLoadingScreen } from '../AiLoadingScreen/AiLoadingScreen'
import { MarkdownTextViewer } from '../MarkdownTextViewer/MarkdownTextViewer'
import classes from './AiNoteGenerator.module.scss'

export interface AiNoteGeneratorProps {
  id: number
  selection: string[]
  onComplete: (generatedText: string) => void
}

export const AiNoteGenerator = ({
  id,
  selection,
  onComplete
}: AiNoteGeneratorProps) => {
  const { t } = useTranslation()
  const { data, refetch, isLoading, isFetching, isError } =
    useGeneratePipelineDocs(id, selection)

  useEffect(() => {
    refetch()
  }, [refetch])

  const generateNewDocHandler = () => {
    refetch()
  }

  const getTextDisplay = () => {
    if (isFetching) {
      return <AiLoadingScreen message={t('note.aiGenerator.message')} />
    } else if (isError || selection.length === 0) {
      return (
        <div className={classes.AiNoteGenerator__ErrorContainer}>
          <Icon.Error className={classes.AiNoteGenerator__ErrorIcon} />
          <Typography>{t('note.aiGenerator.error.message')}</Typography>
        </div>
      )
    } else {
      return <MarkdownTextViewer content={data?.description ?? ''} />
    }
  }

  return (
    <>
      {getTextDisplay()}
      {!isLoading && (
        <div className={classes.AiNoteGenerator__ButtonGroup}>
          <Button
            alt="secondary"
            waiting={isFetching}
            onClick={generateNewDocHandler}
          >
            <Typography>{t('note.aiGenerator.regenerate')}</Typography>
          </Button>
          <Button
            alt="primary"
            disabled={isFetching}
            onClick={() => {
              onComplete(data?.description ?? '')
            }}
          >
            <Typography>{t('note.aiGenerator.add')}</Typography>
          </Button>
        </div>
      )}
    </>
  )
}
