import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { queryKeys } from '../../query-keys'
import useWorkingTreeClient from 'api/clients/useWorkingTreeClient'
import useGitContext from 'provider/GitProvider/useGitContext'
import {
  type ApiHookProps,
  type ReactQueryMeta
} from '../../../types/ReactQueryMeta'

const defaultMeta = (branch: string | undefined): ReactQueryMeta => {
  return {
    error: {
      title: 'api.toast.delete-branch.title',
      message: 'api.toast.delete-branch.message',
      args: [
        {
          name: 'branch',
          value: branch
        }
      ]
    }
  }
}

const useDeleteBranch = ({ meta }: ApiHookProps = {}) => {
  const client = useWorkingTreeClient()
  const { project, branch } = useGitContext()

  const deleteBranch = useCallback(async () => {
    const url = `projects/${project}/branches/${branch}`
    const { data } = await client.delete(url)
    return data
  }, [client, project, branch])

  return useMutation([queryKeys.delete, branch], deleteBranch, {
    meta: meta ?? defaultMeta(branch)
  })
}

export default useDeleteBranch
