import {
  createContext,
  useContext,
  useEffect,
  useState,
  type Dispatch,
  type FC,
  type PropsWithChildren,
  type SetStateAction
} from 'react'

import { type SampleComponentResponse } from 'api/hooks/useSampleComponent/types'

import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

interface SampleContextBag {
  currentSample: SampleComponentResponse | null
  setCurrentSample: Dispatch<SetStateAction<SampleComponentResponse | null>>
}

const SampleContext = createContext<SampleContextBag>({
  currentSample: null,
  setCurrentSample: /* istanbul ignore next */ () => null
})

export const SampleProvider: FC<PropsWithChildren> = ({ children }) => {
  const { componentId } = useProjectInfo()
  const [currentSample, setCurrentSample] =
    useState<SampleComponentResponse | null>(null)

  useEffect(() => {
    setCurrentSample(null)
  }, [componentId])

  return (
    <SampleContext.Provider value={{ currentSample, setCurrentSample }}>
      {children}
    </SampleContext.Provider>
  )
}

export const useSampleProvider = () => useContext(SampleContext)
