import React, { createContext } from 'react'

import {
  UserInfo,
  Environment,
  AuthHelpers,
  AuthState,
  AuthProviderEnvironment,
  FlagSet,
  ServiceRegistry
} from './types'

export const EnvironmentContext = React.createContext<Environment>({
  environment: AuthProviderEnvironment.local,
  hubUrl: 'https://hub.matillion.com',
  hubApiUrl: 'https://api.hub.matillion.com',
  entitlementsApiUrl: 'https://entitlements-{{region}}.core.matillion.com',
  hubAssetsUrl: 'https://hub-assets.matillion.com',
  registryUrl: 'https://service-registry.matillion.com',
  permissionsApiUrl: 'https://permissions.core.matillion.com',
  appUrl: 'https://app.matillion.com',
  subdomain: undefined,
  services: {
    homeUrl: 'https://start.matillion.com/'
  },
  offline: true
})

export const UserInfoContext = React.createContext<UserInfo>({
  user: {
    email: '',
    name: '',
    nickname: '',
    picture: '',
    updated_at: ''
  },
  organisation: {
    id: '',
    name: '',
    subdomain: ''
  },
  roles: new Set(),
  profile: {
    email: '',
    name: '',
    organisation: '',
    icon: ''
  }
})

export const AuthStateContext = React.createContext<AuthState>({
  isLoading: true,
  isLoggedIn: false
})

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const AuthHelpersContext = React.createContext<AuthHelpers>({
  login: async () => {
    console.debug(
      '[AuthProvider] Login is not available--Auth0 has not been initialised!'
    )
  },
  logout: () => {
    console.debug(
      '[AuthProvider] Logout is not available--Auth0 has not been initialised!'
    )
  },
  getToken: async (options?: Parameters<AuthHelpers['getToken']>[0]) => {
    console.debug(
      '[AuthProvider] Auth0 has not been initialised! Providing mock token...'
    )

    return options?.detailedResponse === true
      ? {
          id_token: '',
          access_token: '',
          expires_in: 0
        }
      : 'access-token'
  }
} as AuthHelpers)

export const FlagContext = React.createContext<FlagSet>({})

export const ServiceRegistryContext = createContext<ServiceRegistry>({
  metadata: {
    env: '',
    region: '',
    tier: ''
  },
  endpoints: {}
})
