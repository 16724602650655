import { useState } from 'react'

interface Props {
  storage: Storage
  key: string
}

export function useBrowserStoredFlag({ storage, key }: Props) {
  const [state, setState] = useState(() => storage.getItem(key) === 'true')

  const setStoredFlag = (value: boolean) => {
    storage.setItem(key, value.toString())
    setState(value)
  }

  return [state, setStoredFlag] as const
}
