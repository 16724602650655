import { useMemo, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Typography } from '@matillion/component-library'

import { HorizontalStack } from 'components/HorizontalStack/HorizontalStack'
import { JobTypeIcon } from 'components/JobTypeIcon'

import { useActivePipelineSummary } from 'hooks/useActivePipelineSummary/useActivePipelineSummary'
import { useFlags } from 'hooks/useFlags'

import { isMacOs } from 'utils/isMacOs'

import { RunButton } from './RunButton'
import classes from './Toolbox.module.scss'
import { ValidateButton } from './ValidateButton'
import { VariablesButton } from './VariablesButton'

const Toolbox: FunctionComponent = () => {
  const { t } = useTranslation()
  const { pipelineSummary, isLoading, isError } = useActivePipelineSummary()
  const { enableExpandedPipelineActionsExperiment } = useFlags()
  const navigate = useNavigate()
  const location = useLocation()
  const context = useMemo(
    () => ({
      context: isMacOs() ? 'macOS' : 'windows'
    }),
    []
  )

  if (!pipelineSummary?.type || isLoading || isError) return null
  const validateButton = (
    <ValidateButton
      key="validate"
      context={context}
      jobType={pipelineSummary.type}
      showExpanded={enableExpandedPipelineActionsExperiment}
    />
  )
  const runButton = (
    <RunButton
      key="run"
      context={context}
      summary={pipelineSummary}
      showExpanded={enableExpandedPipelineActionsExperiment}
    />
  )
  const variablesButton = (
    <VariablesButton
      key="variables"
      showExpanded={enableExpandedPipelineActionsExperiment}
      onClick={() => {
        navigate(location, {
          replace: true,
          state: {
            ...location.state,
            isEditPanelVisible: true
          }
        })
      }}
    />
  )
  return (
    <div className={classes.Toolbox}>
      <HorizontalStack withSpacer>
        {!enableExpandedPipelineActionsExperiment && (
          <HorizontalStack>
            <div className={classes.Toolbox__JobIcon}>
              <JobTypeIcon jobType={pipelineSummary.type} />
            </div>
            <Typography className={classes.Toolbox__JobType}>
              {t(`canvasToolbox.${pipelineSummary.type}`)}
            </Typography>
          </HorizontalStack>
        )}
        {enableExpandedPipelineActionsExperiment
          ? [validateButton, runButton, variablesButton]
          : [variablesButton, validateButton, runButton]}
      </HorizontalStack>
    </div>
  )
}

export { Toolbox }
