import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useFlags } from 'hooks/useFlags'
import { useProjectInfo } from 'hooks/useProjectInfo/useProjectInfo'

import { FileStatus } from 'job-lib/types/FileStatus'

import { useRecentlyAccessedPipelines } from 'modules/hub/PipelineAccessHistory/hooks/useRecentlyAccessedPipelines/useRecentlyAccessedPipelines'

import {
  useGetJobSummariesQueryKey,
  type JobSummaries,
  type JobSummaryId
} from '../useGetJobSummaries'
import { useSpringClient } from '../useSpringClient/useSpringClient'
import { deleteJobApi } from './deleteJobApi'

export const jobSummariesQueryUpdater = (
  deleteJobId: JobSummaryId,
  summaries?: JobSummaries
): JobSummaries | undefined => {
  if (!summaries) {
    return undefined
  }

  return {
    isUsingJGit: summaries?.isUsingJGit ?? false,
    results: summaries.results.map((summary) => {
      const isDeletedJob = summary.jobId === deleteJobId
      return {
        ...summary,
        status: isDeletedJob ? FileStatus.DELETED : summary.status
      }
    })
  }
}

export type DeleteJobArgs = JobSummaryId

export const useDeleteJob = () => {
  const { projectId, branchId } = useProjectInfo()
  const client = useSpringClient()
  const queryClient = useQueryClient()
  const getJobSummariesQueryKey = useGetJobSummariesQueryKey()
  const { enableRecentlyAccessedPipelines } = useFlags()
  const { removeRecentlyAccessed } = useRecentlyAccessedPipelines()

  return useMutation(
    async (jobId: DeleteJobArgs) => {
      return deleteJobApi({
        client,
        projectId,
        branchId,
        jobId
      })
    },
    {
      onSuccess: (_, jobId) => {
        enableRecentlyAccessedPipelines && removeRecentlyAccessed(jobId)
        queryClient.setQueryData<JobSummaries>(
          getJobSummariesQueryKey,
          (summaries) => jobSummariesQueryUpdater(jobId, summaries)
        )
      }
    }
  )
}
