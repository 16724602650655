import { useCallback, useEffect, useState, type FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  Alert,
  Button as MclButton,
  type DataGridRow
} from '@matillion/component-library'
import classNames from 'classnames'
import { cloneDeep, mapValues } from 'lodash'

import {
  type ComponentMetadata,
  type ComponentParameter,
  type ParameterDataType
} from 'api/hooks/useGetComponentMetadata/types'
import { type ComponentSummaryId } from 'api/hooks/useGetComponentSummaries'
import { type ProblemDetails } from 'api/types/http-problem-details'

import { ReactComponent as AddIcon } from 'assets/add-plus-icon.svg'
import { ReactComponent as DeleteIcon } from 'assets/delete-minus-icon.svg'
import { ReactComponent as DisabledAddIcon } from 'assets/disabled-add-plus-icon.svg'
import { ReactComponent as DisabledDeleteIcon } from 'assets/disabled-delete-minus-icon.svg'

import {
  ParameterOverlayButton,
  ParameterOverlayErrors,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'
import { Button } from 'components/ParameterOverlay/components/Button'

import { useFlags } from 'hooks/useFlags'
import { useParameterOptionsAutofill } from 'hooks/useParameterOptions/useParameterOptionsAutofill'

import { type RootState } from 'job-lib/store'
import { type ComponentInstanceId } from 'job-lib/types/Job'
import { JobType } from 'job-lib/types/JobType'
import { type ElementCollection } from 'job-lib/types/Parameters'

import { useComponentValidationProvider } from 'modules/core/ComponentValidation'

import { type ParameterOptions } from '../../types'
import { Grid } from './components/Grid/Grid'
import { type GridCell, type UpdateRows } from './components/Grid/types'
import {
  generateBlobRow,
  getDuplicateField,
  getHighestRowSlot,
  init
} from './components/Grid/utils'
import classes from './GridEditor.module.scss'

export interface GridEditorProps extends ParameterOptions {
  parameterName: string
  parameter: ComponentParameter
  componentMetaData: ComponentMetadata
  componentInstanceId: ComponentInstanceId
  componentSummaryId: ComponentSummaryId
  elements: ElementCollection
  problemDetail?: ProblemDetails | null
  onDone: (editedValue: ElementCollection) => void
  disallowDuplicates?: boolean
}

export const GridEditor: FunctionComponent<GridEditorProps> = ({
  componentMetaData,
  componentInstanceId,
  componentSummaryId,
  editorColumns,
  elements,
  parameter,
  parameterName,
  problemDetail,
  onDone,
  disallowDuplicates = false
}) => {
  const { t } = useTranslation()
  const { enableSelectAll, enableAddAllGridEditor } = useFlags()
  const [rows, setRows] = useState(() => init(elements, editorColumns))
  const [isSelectAll, setIsSelectAll] = useState<boolean | undefined>()
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const hasColumns = editorColumns.length > 0
  const { isUnvalidated, setValidationEnabled } =
    useComponentValidationProvider()
  const addAllEnabled = enableAddAllGridEditor && !isUnvalidated
  const {
    data: addAllResults,
    isLoading: isLoadingAddAll,
    error: addAllError
  } = useParameterOptionsAutofill({
    componentSummaryId,
    componentMetaData,
    parameter,
    isEnabled: addAllEnabled,
    requestType: 'autofill'
  })

  const duplicateField = disallowDuplicates && getDuplicateField(rows)

  useEffect(() => {
    const allRowIds = rows.map((row) => row.id)
    const allRowsSelected = allRowIds.every((id) => selectedRows?.includes(id))
    setIsSelectAll(allRowsSelected)
  }, [selectedRows, rows])

  const nonEmptyRows = rows.filter(({ cells }) => cells[1]?.value !== '')

  const handleSubmit = useCallback(() => {
    const newElements: ElementCollection = {}

    nonEmptyRows.forEach(({ cells }, index) => {
      // paramSlot needs to be index based on submission
      // to account for the removal of rows in the job object
      const paramSlot = index + 1

      newElements[paramSlot] = {
        slot: paramSlot,
        values: {}
      }
      Object.values(cells).forEach((cell) => {
        const { type, value, slot, dataType } = cell

        newElements[paramSlot].values[slot] = {
          slot,
          value: String(value),
          type,
          dataType: dataType as ParameterDataType
        }
      })
    })

    onDone(newElements)
  }, [onDone, nonEmptyRows])

  const updateRows: UpdateRows = useCallback(
    ({ rowSlot, slot, value, type, dataType }: GridCell) => {
      setRows((prevRows) => {
        const newRows = cloneDeep(prevRows)
        newRows[rowSlot - 1].cells[slot] = {
          type,
          value,
          slot,
          rowSlot,
          dataType
        }

        return newRows
      })
    },
    []
  )

  const deleteRows = () => {
    setRows((previousRows) => {
      const updatedRows = previousRows.filter(
        (row) => !selectedRows.includes(row.id)
      )

      const reindexedRows = updatedRows.map((row, index) => ({
        ...row,
        id: `${index + 1}-${Date.now().toString()}`,
        cells: mapValues(row.cells, (cell: GridCell) => ({
          ...cell,
          rowSlot: index + 1
        }))
      }))

      return reindexedRows
    })

    setSelectedRows([])
  }

  const addRow = useCallback(() => {
    const newRowSlot = getHighestRowSlot(rows)
    setRows((prevState) => [
      ...prevState,
      generateBlobRow(newRowSlot, editorColumns)
    ])
  }, [setRows, editorColumns, rows])

  const addAll = () => {
    const hasResults = addAllResults?.length
    if (hasResults) {
      const rowsToAdd = addAllResults.map((rowValues, i) => {
        return generateBlobRow(i + 1, editorColumns, rowValues)
      })
      setRows(rowsToAdd)
    }
    setAutofillNoData(!hasResults)
  }

  const jobType = useSelector<RootState>((state) => state.job.jobType)
  const requiresValidation = isUnvalidated && jobType === JobType.Transformation
  const addAllButtonDisabled =
    !hasColumns || nonEmptyRows.length > 0 || requiresValidation
  const [autofillNoData, setAutofillNoData] = useState(false)

  return (
    <>
      <ParameterOverlayHeader title={parameterName} />
      {problemDetail?.detail && (
        <Alert
          aria-label={problemDetail.detail}
          className={classes.Alert}
          type="error"
          message={problemDetail.detail}
        />
      )}
      {duplicateField && (
        <Alert
          aria-label={t('parameterEditor.GRID_EDITOR.duplicateFieldError', {
            duplicateField
          })}
          className={classes.Alert}
          type="error"
          message={t('parameterEditor.GRID_EDITOR.duplicateFieldError', {
            duplicateField
          })}
        />
      )}
      {requiresValidation && (
        <Alert
          aria-label={t('parameterEditor.warnings.validationWarning')}
          className={classes.Alert}
          type="warning"
          title={t('parameterEditor.warnings.validationWarning')}
          message=""
          action={{
            text: t('parameterEditor.warnings.validationAction'),
            onClick: setValidationEnabled
          }}
        />
      )}
      {addAllError && (
        <Alert
          aria-label={addAllError.detail}
          className={classes.Alert}
          type="error"
          message={addAllError.detail}
        />
      )}
      {autofillNoData && (
        <Alert
          aria-label={t('parameterEditor.GRID_EDITOR.addAllNoDataError')}
          className={classes.Alert}
          type="error"
          title={t('parameterEditor.GRID_EDITOR.addAllNoDataError')}
          message=""
        />
      )}
      <ParameterOverlayErrors />
      <div className={classNames([classes.GridEditor__Container])}>
        <form
          className={classes.GridContainer__Content}
          onSubmit={(evt) => {
            evt.preventDefault()
          }}
        >
          <Grid
            componentMetaData={componentMetaData}
            componentInstanceId={componentInstanceId}
            componentSummaryId={componentSummaryId}
            elements={elements}
            parameter={parameter}
            rows={hasColumns ? rows : []}
            updateRows={updateRows}
            columns={editorColumns}
            isSelectable={true}
            selectedRows={selectedRows}
            onSelectedChange={(id: DataGridRow['id'], isSelected: boolean) => {
              if (isSelected) {
                setSelectedRows((tmpRows) => [...tmpRows, id.toString()])
              } else {
                setSelectedRows((tmpRows) =>
                  tmpRows.filter((_id) => id !== _id)
                )
                setIsSelectAll(undefined)
              }
            }}
            allSelectable={enableSelectAll && editorColumns.length > 0}
            isSelectAll={isSelectAll}
            onSelectAllChange={(isAllSelectedChecked: boolean) => {
              setIsSelectAll(isAllSelectedChecked)
              if (isAllSelectedChecked) {
                setSelectedRows(rows.map((row) => row.id))
              } else {
                setSelectedRows([])
              }
            }}
          />
        </form>
      </div>
      <div
        className={classNames([
          classes.GridEditor__ButtonContainer,
          classes.FooterContainer
        ])}
      >
        {enableAddAllGridEditor && Boolean(parameter.autoFill) && (
          <div className={classes.GridEditor__AddAll}>
            <Button
              type="submit"
              onClick={addAll}
              data-testid="add-all"
              aria-label={t('parameterEditor.GRID_EDITOR.addAll')}
              disabled={addAllButtonDisabled || isLoadingAddAll}
              text={t('parameterEditor.GRID_EDITOR.addAll')}
            />
          </div>
        )}
        <div className={classes.GridEditor__AddRow}>
          <MclButton
            alt="text"
            type="submit"
            disabled={!hasColumns}
            onClick={addRow}
            data-testid="add-row"
            aria-label={t('parameterEditor.GRID_EDITOR.addEntry')}
          >
            {!hasColumns ? <DisabledAddIcon /> : <AddIcon />}
          </MclButton>
        </div>
        <div className={classes.GridEditor__DeleteRow}>
          <MclButton
            alt="text"
            type="submit"
            onClick={deleteRows}
            data-testid="delete-rows"
            aria-label={t('parameterEditor.GRID_EDITOR.deleteEntry')}
            disabled={selectedRows.length === 0 || !hasColumns}
          >
            {selectedRows.length !== 0 ? (
              <DeleteIcon />
            ) : (
              <DisabledDeleteIcon />
            )}
          </MclButton>
        </div>
      </div>
      <ParameterOverlayFooter>
        <ParameterOverlayButton
          disabled={!hasColumns || Boolean(duplicateField)}
          data-testid="grid-editor-done-button"
          onClick={handleSubmit}
          text={t('common.save')}
        />
      </ParameterOverlayFooter>
    </>
  )
}
