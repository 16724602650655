import { useCallback } from 'react'

import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '../../queryKeys'
import { type FlowInstanceId } from '../useGetRunTasks/types'
import {
  SpringClientVersion,
  useSpringClient
} from '../useSpringClient/useSpringClient'
import { type StrandStatsResponse } from './__types__/strand-stats-response'
import { type StrandSequenceId, type StrandStatsTreeResponse } from './types'

export const preprocessStrandStats = (
  flowInstanceId: FlowInstanceId | undefined,
  strand: StrandStatsTreeResponse | undefined
): StrandStatsTreeResponse | undefined => {
  if (!strand) return undefined

  if (!strand.parentFlowInstanceId) {
    strand.parentFlowInstanceId = flowInstanceId
  }
  return { ...strand }
}

export const useGetStatsStrands = (
  flowInstanceId: FlowInstanceId,
  strandSequenceId: StrandSequenceId,
  identifier: string
) => {
  const client = useSpringClient(SpringClientVersion.V2)

  const tasks = useCallback(async () => {
    const { data } = await client.get<StrandStatsResponse>(
      `tasks/${flowInstanceId}/strands/${strandSequenceId}`
    )

    return preprocessStrandStats(flowInstanceId, data.statsTree)
  }, [client, flowInstanceId, strandSequenceId])

  return useQuery<StrandStatsTreeResponse | undefined>(
    [
      queryKeys.taskDetails,
      flowInstanceId,
      'STRAND',
      identifier,
      strandSequenceId
    ],
    tasks
  )
}
