import React, { lazy, Suspense, useState, type FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from 'components/Loading/Loading'
import {
  ParameterOverlayButton,
  ParameterOverlayFooter,
  ParameterOverlayHeader
} from 'components/ParameterOverlay'

import classes from './FreeformTextareaEditor.module.scss'

const CodeEditor = lazy(async () => import('components/CodeEditor'))

export interface FreeformTextareaProps {
  title: string
  value: string[]
  onDone: (editedValue: string[]) => unknown
  language?: string
}

export const FreeformTextareaEditor: FC<FreeformTextareaProps> = ({
  value,
  title,
  language,
  onDone
}) => {
  const { t } = useTranslation()
  const [updatedValue, setUpdatedValue] = useState(value[0] || '')
  const handleDone = () => onDone([updatedValue])

  return (
    <>
      <ParameterOverlayHeader title={title} />
      <div className={classes.FreeformTextarea}>
        <Suspense fallback={<Loading />}>
          <CodeEditor
            value={updatedValue}
            onChange={setUpdatedValue}
            language={language}
          />
        </Suspense>
      </div>
      <ParameterOverlayFooter>
        <ParameterOverlayButton text={t('common.save')} onClick={handleDone} />
      </ParameterOverlayFooter>
    </>
  )
}
