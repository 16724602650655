import { type AxiosInstance } from 'axios'

export async function deleteProjectVariablesApi(
  client: AxiosInstance,
  projectId: string,
  variableId: string
) {
  const { status } = await client.delete(
    `/projects/${projectId}/variables/${variableId}`
  )

  return status
}
