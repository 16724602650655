// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F9vwYIkAO649NEUNUSTq{display:flex;justify-content:space-between;margin:0;padding-top:36px;padding-bottom:36px}.F9vwYIkAO649NEUNUSTq .ZYHKEEhARi6DVTPqzKz6{width:320px;padding:5px}.F9vwYIkAO649NEUNUSTq .zrQH896Db5Dt3HYlNXvr{min-width:16px;display:flex;justify-content:center;align-items:center;height:45px;margin-top:30px}`, "",{"version":3,"sources":["webpack://./src/modules/Merge/components/SourceBranchSelector/SourceBranchSelector.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,QAAA,CACA,gBAAA,CACA,mBAAA,CAEA,4CACE,WAAA,CACA,WAAA,CAGF,4CACE,cAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,eAAA","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: space-between;\n  margin: 0;\n  padding-top: 36px;\n  padding-bottom: 36px;\n\n  .branch {\n    width: 320px;\n    padding: 5px;\n  }\n\n  .iconWrapper {\n    min-width: 16px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 45px;\n    margin-top: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `F9vwYIkAO649NEUNUSTq`,
	"branch": `ZYHKEEhARi6DVTPqzKz6`,
	"iconWrapper": `zrQH896Db5Dt3HYlNXvr`
};
export default ___CSS_LOADER_EXPORT___;
