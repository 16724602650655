import { type FC, type PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@matillion/component-library'
import classNames from 'classnames'

import { useProjectPermission } from 'api/external/usePermission/useProjectPermission'

import classes from './BannerContent.module.scss'

const { Warning } = Icon
const BannerContent: FC<PropsWithChildren> = ({ children }) => {
  const { hasPermission, isLoading } = useProjectPermission('update_pipelines')
  const { t } = useTranslation()

  const shouldShowBanner = !hasPermission && !isLoading

  return (
    <div
      className={classNames(
        classes.BannerContainer,
        shouldShowBanner && classes.BannerContainer__Warning
      )}
    >
      {shouldShowBanner && (
        <div className={classes.ReadOnly}>
          <Warning />
          <Typography weight="bold" format="mc">
            {t('projectToolbox.permissionBanner.warningTitle')}
            <Typography format="mc" as="p">
              {t('projectToolbox.permissionBanner.warningContent')}
            </Typography>
          </Typography>
        </div>
      )}

      {children}
    </div>
  )
}

export default BannerContent
